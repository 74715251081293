import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function InterviewRequest() {
  const query = useQuery();
  const interviewRequestId = query.get("interviewRequestId");
  const securityCode = query.get("securityCode");

  const navigate = useNavigate();

  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [interview, setInterview] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState("");

  const feedbackOptions = [
    { label: "Salary is too low/high", value: "Salary mismatch" },
    { label: "The location or working style doesn’t suit me", value: "Wrong location or working style" },
    { label: "I'm not interested in this industry", value: "Industry mismatch" },
    { label: "The role does not suit my skillset", value: "Missing skills" },
    { label: "I don't have time for new interviews", value: "Too many interviews" },
    { label: "I don't have enough information about the role", value: "Missing role info" },
    { label: "Other reason", value: "Other" }
  ];

  useEffect(() => {
    const fetchInterview = async () => {
      try {
        const response = await axios.get(
          `/api/interviews/fetchInterview?id=${interviewRequestId}`
        );

        const interview = response.data.interview;

        const transformedInterview = {
          company: interview.fields["Name (from Startup) (from Role)"],
        };

        setInterview(transformedInterview);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchInterview();
  }, [interviewRequestId]);

  const handleSubmit = async (stage, feedback = null) => {
    const updatedData = {
      fields: {
        Stage: stage,
        ...(feedback && { "MR Rejection Feedback": feedback }),
      },
      interviewId: interviewRequestId,
    };
    setError(""); // Reset error message
    try {
      const response = await axios.patch(
        `/api/interviews/updateInterviewRequest`,
        updatedData
      );

      console.log(response);
      setActive(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleReject = async () => {
    try {
      // First submit the rejection
      await handleSubmit("Rejected Interview Request");
      // Then show the feedback modal
      setShowFeedbackModal(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleFeedbackSubmit = async () => {
    const updatedData = {
      fields: {
        ...(selectedFeedback === "Other" 
          ? { "MR Rejection Feedback (other)": otherReason }
          : { "MR Rejection Feedback": selectedFeedback }
        ),
      },
      interviewId: interviewRequestId,
    };

    try {
      await axios.patch("/api/interviews/updateInterviewRequest", updatedData);
      setShowFeedbackModal(false);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          {active ? (
            <>
              <h1 className="text-xl font-normal leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                You have been invited for an interview with{" "}
                <span
                  className="font-bold"
                  style={{
                    color: "rgb(0, 185, 140)",
                  }}
                >
                  {interview.company}
                </span>{" "}
                via Jumpstart!
              </h1>
              <div className="space-y-4 md:space-y-6">
                <button
                  onClick={() => handleSubmit("Interviewing")}
                  style={{
                    backgroundColor: "rgb(0, 242, 194)",
                  }}
                  className="w-full text-black font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Accept the interview request
                </button>
                <button
                  onClick={handleReject}
                  className="w-full text-white bg-red-500 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Reject the interview request
                </button>
              </div>
              <h1>
                Please contact rachel@jumpstart-uk.com if you have any further
                questions!
              </h1>
            </>
          ) : (
            <>
              <h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Thank you for responding to this request.
              </h1>
              <span className="font-normal text-lg">
                We've let {interview.company} know about your response!
              </span>
            </>
          )}
        </div>
      </div>

      {/* Feedback Modal */}
      {showFeedbackModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Why are you rejecting this interview?</h2>
            <p className="text-sm text-gray-600 mb-4">NB: This is for Jumpstart only</p>
            <div className="space-y-3">
              {feedbackOptions.map((option) => (
                <div key={option.value} className="flex items-center">
                  <input
                    type="radio"
                    id={option.value}
                    name="feedback"
                    value={option.value}
                    checked={selectedFeedback === option.value}
                    onChange={(e) => setSelectedFeedback(e.target.value)}
                    className="mr-2"
                  />
                  <label htmlFor={option.value} className="text-sm">
                    {option.label}
                  </label>
                </div>
              ))}
              
              {selectedFeedback === "Other" && (
                <textarea
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  placeholder="Please specify your reason..."
                  className="w-full p-2 border rounded-lg mt-2"
                  rows="3"
                />
              )}
            </div>
            
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowFeedbackModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleFeedbackSubmit}
                disabled={!selectedFeedback || (selectedFeedback === "Other" && !otherReason)}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InterviewRequest;
