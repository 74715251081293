import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  ChevronDown,
  ChevronUp,
  Check,
  Calendar,
  Edit2,
  Trash2,
  X,
  Info,
} from "lucide-react";
import LogoLoader from "../../../components/LogoLoader";

// --------------------- AddSpotlightModal ---------------------
function AddSpotlightModal({
  isOpen,
  onClose,
  type, // "company" or "role"
  onAddSpotlight,
  spotlightToEdit,
  onBookCall,
}) {
  // Tabs: "jumpstart" or "recordUpload"
  const [activeTab, setActiveTab] = useState("jumpstart");
  // For "recordUpload" sub-choice:
  const [selectedSource, setSelectedSource] = useState("loom");

  // Form fields
  const [name, setName] = useState("");
  const [loomUrl, setLoomUrl] = useState("");
  const [dropboxUrl, setDropboxUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Decide tab & fill fields if editing
  useEffect(() => {
    if (!isOpen) return; // only run logic if open

    if (spotlightToEdit) {
      // Edit mode
      setActiveTab("recordUpload");
      setName(spotlightToEdit.Name || "");
      setLoomUrl(spotlightToEdit["URL Loom cleaned"] || "");
      setDropboxUrl(spotlightToEdit["URL Dropbox cleaned"] || "");
      if (spotlightToEdit["URL Loom cleaned"]) {
        setSelectedSource("loom");
      } else if (spotlightToEdit["URL Dropbox cleaned"]) {
        setSelectedSource("dropbox");
      } else {
        setSelectedSource("loom");
      }
    } else {
      // Add mode: default to "jumpstart"
      setActiveTab("jumpstart");
      setName("");
      setLoomUrl("");
      setDropboxUrl("");
      setSelectedSource("loom");
    }
  }, [isOpen, spotlightToEdit]);

  if (!isOpen) return null;

  const handleUpload = () => {
    setIsLoading(true);

    // Build the new or updated spotlight object
    const updated = spotlightToEdit
      ? { ...spotlightToEdit } // editing existing
      : {
          // creating new
          id: Date.now(), // temporary ID; real ID is assigned on creation
          Type: type === "role" ? "Role information" : "Company information",
        };

    updated.Name = name.trim();
    if (selectedSource === "loom") {
      updated["URL Loom cleaned"] = loomUrl.trim();
      updated["URL Dropbox cleaned"] = "";
    } else {
      updated["URL Loom cleaned"] = "";
      updated["URL Dropbox cleaned"] = dropboxUrl.trim();
    }

    // Pass this object back up to the parent, which does the API call
    onAddSpotlight(updated);

    setIsLoading(false);
    onClose();
  };

  return (
    <div 
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto ${isOpen ? "" : "hidden"}`}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto">
        {/* Header with close button */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {spotlightToEdit ? "Edit Spotlight" : "How would you like to record the spotlight?"}
          </h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Tabs (only show if NOT editing) */}
        {!spotlightToEdit && (
          <div className="flex mb-6 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setActiveTab("jumpstart")}
              className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
                activeTab === "jumpstart"
                  ? "bg-white shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
            >
              Record with jumpstart
            </button>
            <button
              onClick={() => setActiveTab("recordUpload")}
              className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
                activeTab === "recordUpload"
                  ? "bg-white shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
            >
              Record / upload spotlight
            </button>
          </div>
        )}

        {/* Content */}
        <div>
          {/* Jumpstart tab */}
          {(!spotlightToEdit ? activeTab : "recordUpload") === "jumpstart" ? (
            <div className="space-y-4">
              <p className="text-sm mb-4">
                Click{" "}
                <a
                  href="https://jumpstart-alumni.notion.site/Very-quick-guidance-on-recording-your-startup-s-video-5ce5688a610648c89f59d9382e42c226"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black hover:underline"
                >
                  here
                </a>{" "}
                for very simple instructions
              </p>
              <div
                className="rounded-lg overflow-hidden"
                style={{ height: "500px" }}
              >
                <iframe
                  src="https://calendly.com/d/cmhy-wb9-823/record-a-video-with-jumpstart"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  title="Calendly"
                />
              </div>
              {/* "I just booked" button only in Jumpstart tab */}
              <div className="mt-6 flex justify-end">
                <button
                  onClick={onBookCall}
                  className="px-6 py-2.5 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  I just booked
                </button>
              </div>
            </div>
          ) : (
            // Record / Upload tab content - restored to original
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={`${type === "company" ? "Company" : "Role"} spotlight name`}
                  className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#08f2c3] focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Video URL
                </label>
                <input
                  type="text"
                  value={selectedSource === "loom" ? loomUrl : dropboxUrl}
                  onChange={(e) =>
                    selectedSource === "loom"
                      ? setLoomUrl(e.target.value)
                      : setDropboxUrl(e.target.value)
                  }
                  placeholder="Paste your Loom or Dropbox URL"
                  className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#08f2c3] focus:border-transparent"
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpload}
                  disabled={!name || !selectedSource}
                  className="px-6 py-2 bg-[#08f2c3] text-black rounded-lg hover:bg-[#06c19c] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// --------------------- PreviewSpotlightModal ---------------------
function PreviewSpotlightModal({ isOpen, onClose, spotlight, onEdit, onDelete }) {
  if (!isOpen) return null;

  const loomUrl = spotlight["URL Loom cleaned"];
  const dropboxUrl = spotlight["URL Dropbox cleaned"];

  let videoElement = null;
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop();
    const loomEmbed = `https://www.loom.com/embed/${loomId}`;
    videoElement = (
      <iframe
        title={`Preview of ${spotlight.Name}`}
        src={loomEmbed}
        frameBorder="0"
        allowFullScreen
        className="w-full aspect-video rounded-lg"
      />
    );
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
    videoElement = (
      <video
        src={directVideoUrl}
        controls
        className="w-full aspect-video rounded-lg"
      />
    );
  }

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      await axios.patch(
        "/api/spotlights/updateSpotlightData",
        {
          spotlightId: spotlight.id,
          fields: { Deleted: true },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      onDelete(spotlight.id);
      onClose();
    } catch (err) {
      console.error("Error deleting spotlight:", err);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="bg-white rounded-xl w-full max-w-3xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b">
          <h3 className="text-xl font-semibold">{spotlight.Name}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {videoElement}

          {/* Actions */}
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={handleDelete}
              className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <Trash2 className="w-4 h-4" />
              Delete
            </button>
            <button
              onClick={() => {
                onEdit(spotlight);
              }}
              className="flex items-center gap-2 px-4 py-2 bg-[#08f2c3] text-black rounded-lg hover:bg-[#06c19c] transition-colors"
            >
              <Edit2 className="w-4 h-4" />
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// --------------------- CompactSpotlightCard ---------------------
function CompactSpotlightCard({ spotlight, selected, onSelect, onEdit }) {
  const [showPreview, setShowPreview] = useState(false);

  const loomUrl = spotlight["URL Loom cleaned"];
  const dropboxUrl = spotlight["URL Dropbox cleaned"];

  let previewElement = null;
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop();
    const loomEmbed = `https://www.loom.com/embed/${loomId}`;
    previewElement = (
      <div className="w-[280px] h-[157px] overflow-hidden rounded-lg">
        <iframe
          title="Loom Preview"
          src={loomEmbed}
          frameBorder="0"
          allowFullScreen
          className="w-full h-full"
        />
      </div>
    );
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
    previewElement = (
      <div className="w-[280px] h-[157px] overflow-hidden rounded-lg">
        <video src={directVideoUrl} className="w-full h-full object-cover" />
      </div>
    );
  } else {
    previewElement = (
      <div className="w-[280px] h-[157px] flex items-center justify-center bg-gray-100 rounded-lg">
        <p className="text-sm text-gray-400">No preview</p>
      </div>
    );
  }

  return (
    <>
      <div
        onClick={() => onSelect(spotlight)}
        className={`flex flex-col w-[280px] rounded-lg transition-all cursor-pointer
          ${
            selected
              ? "ring-2 ring-[#08f2c3] bg-[#059488]/5"
              : "border border-gray-200 hover:border-gray-300"
          }`}
      >
        {previewElement}
        <div className="p-3 flex items-center justify-between">
          <div className="flex-grow">
            <p className="text-sm font-medium text-gray-700 truncate">
              {spotlight.Name || "Untitled"}
            </p>
            {selected && (
              <p className="text-xs text-[#059488] mt-1">Selected</p>
            )}
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation(); // don't trigger onSelect
              setShowPreview(true);
            }}
            className="p-1.5 rounded-full hover:bg-gray-100 transition-colors"
          >
            <Edit2 className="w-4 h-4 text-gray-500" />
          </button>
        </div>
      </div>

      {showPreview && (
        <PreviewSpotlightModal
          isOpen={showPreview}
          onClose={() => setShowPreview(false)}
          spotlight={spotlight}
          onDelete={(spotlightId) => {
            const event = new CustomEvent("spotlightDeleted", {
              detail: spotlightId,
            });
            window.dispatchEvent(event);
          }}
          onEdit={(spotlightToEdit) => {
            setShowPreview(false);
            onEdit(spotlightToEdit);
          }}
        />
      )}
    </>
  );
}

// --------------------- Main: SelectSpotlight ---------------------
const SelectSpotlight = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const roleId =
    searchParams.get("roleId") || localStorage.getItem("currentRoleId");
  const [isLoading, setIsLoading] = useState(false);

  // If user came from recordSpotlight page
  const fromRecordSpotlight = searchParams.get("fromRecordSpotlight") === "true";

  // Our stored data
  const [allSpotlights, setAllSpotlights] = useState([]);
  const [companySpotlight, _setCompanySpotlight] = useState(() => {
    const storedCompany = localStorage.getItem("selectedCompanySpotlight");
    return storedCompany ? JSON.parse(storedCompany) : null;
  });
  const [roleSpotlight, _setRoleSpotlight] = useState(() => {
    const storedRole = localStorage.getItem("selectedRoleSpotlight");
    return storedRole ? JSON.parse(storedRole) : null;
  });
  const [spotlightContext, setSpotlightContext] = useState(() => {
    return localStorage.getItem("spotlightContext") || "";
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Add/Edit spotlight modal
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null); // "company" or "role"
  const [spotlightToEdit, setSpotlightToEdit] = useState(null);

  // Collapsible sections - default to expanded if incomplete
  const [isCompanySectionExpanded, setIsCompanySectionExpanded] = useState(
    !companySpotlight // expanded if no company spotlight selected
  );
  const [isRoleSectionExpanded, setIsRoleSectionExpanded] = useState(
    !roleSpotlight // expanded if no role spotlight selected
  );

  // Confirmation modals
  const [showNoCompanyVideoModal, setShowNoCompanyVideoModal] = useState(false);
  const [showNoRoleVideoModal, setShowNoRoleVideoModal] = useState(false);

  // Helper to manage local storage
  const setCompanySpotlight = (spot) => {
    _setCompanySpotlight(spot);
    if (spot) {
      localStorage.setItem("selectedCompanySpotlight", JSON.stringify(spot));
    } else {
      localStorage.removeItem("selectedCompanySpotlight");
    }
  };
  const setRoleSpotlight = (spot) => {
    _setRoleSpotlight(spot);
    if (spot) {
      localStorage.setItem("selectedRoleSpotlight", JSON.stringify(spot));
    } else {
      localStorage.removeItem("selectedRoleSpotlight");
    }
  };

  // Keep context in local storage
  useEffect(() => {
    localStorage.setItem("spotlightContext", spotlightContext);
  }, [spotlightContext]);

  // Steps for progress bar
  const steps = [
    { step: 1, route: `/create-role/basic-info?roleId=${roleId}` },
    { step: 2, route: `/create-role/select-spotlight?roleId=${roleId}` },
    { step: 3, route: `/create-role/job-description?roleId=${roleId}` },
  ];
  const handleProgressClick = (route) => {
    navigate(route);
  };

  // Called on "Back"
  const handleBack = () => {
    navigate(`/create-role/basic-info?roleId=${roleId}`);
  };

  // Called on "Skip for now"
  const handleSkip = async () => {
    localStorage.removeItem("hasBookedCall");
    await saveSpotlightContext(false);
    navigate(`/create-role/job-description?roleId=${roleId}`);
  };

  // Called on "Next"
  const handleNext = async () => {
    if (!companySpotlight) {
      setShowNoCompanyVideoModal(true);
      return;
    }
    if (!roleSpotlight && !hasBookedCall) {
      setShowNoRoleVideoModal(true);
      return;
    }
    localStorage.removeItem("hasBookedCall");
    proceedNext();
  };

  // Actually save data, then navigate
  const proceedNext = async () => {
    setIsLoading(true);
    try {
      await saveSpotlightContext(false);
      navigate(`/create-role/job-description?roleId=${roleId}`);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Save the "Spotlight Context" field to the role
  const saveSpotlightContext = async (alsoMarkFirstSaved = false) => {
    if (!roleId) return;
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const fieldsToUpdate = {
        "Spotlight Context": spotlightContext,
      };
      if (alsoMarkFirstSaved) {
        fieldsToUpdate["First saved"] = true;
      }

      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: fieldsToUpdate,
          roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.error("Error saving spotlight context:", err);
    }
  };

  // "Save draft" button in header
  const handleSaveDraft = async () => {
    await saveSpotlightContext(true);
    navigate("/roles");
  };

  // Manage user selecting a spotlight in the grid
  const handleCompanySelect = (spotlight) => {
    if (companySpotlight && spotlight.id === companySpotlight.id) {
      setCompanySpotlight(null);
    } else {
      setCompanySpotlight(spotlight);
    }
  };
  const handleRoleSelect = (spotlight) => {
    if (roleSpotlight && spotlight.id === roleSpotlight.id) {
      setRoleSpotlight(null);
    } else {
      setRoleSpotlight(spotlight);
    }
  };

  // After we fetch, auto-select the newest company if user doesn't already have one
  const [didAutoSelectCompany, setDidAutoSelectCompany] = useState(false);

  // Listen for data load, auto-select the newest company if we have none selected
  useEffect(() => {
    if (!loading) {
      const companyList = allSpotlights.filter(
        (sp) => sp.Type === "Company information"
      );
      // If we have at least one company spotlight, and none is selected in local storage, auto pick the newest
      if (!companySpotlight && companyList.length > 0 && !didAutoSelectCompany) {
        // sort descending by ID to pick "most recently added"
        const sorted = [...companyList].sort((a, b) => b.id - a.id);
        const newest = sorted[0];
        setCompanySpotlight(newest);

        // Also collapse the company section since we auto-selected one
        setIsCompanySectionExpanded(false);

        setDidAutoSelectCompany(true);
      }
    }
  }, [
    loading,
    allSpotlights,
    companySpotlight,
    didAutoSelectCompany,
    setCompanySpotlight,
  ]);

  // Actually fetch spotlights from the server
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;
        const response = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: { Authorization: `Bearer ${token}` },
          params: { roleId },
        });
        // If the request succeeds, use the returned spotlights array (or empty array)
        setAllSpotlights(response.data.spotlights || []);
      } catch (err) {
        // If the server returns a 500 error, assume it means "no spotlights found"
        if (err.response && err.response.status === 500) {
          console.warn("Server returned 500 (No spotlights found). Continuing with an empty array.");
          setAllSpotlights([]);
        } else {
          setError("Failed to load spotlights");
        }
      } finally {
        setLoading(false);
      }
    };

    if (roleId) {
      localStorage.setItem("currentRoleId", roleId);
      fetchData();
    }
  }, [roleId, fromRecordSpotlight]);

  // Listen for "spotlightDeleted" events
  useEffect(() => {
    const handleSpotlightDeleted = (event) => {
      const deletedId = event.detail;
      setAllSpotlights((prev) => prev.filter((s) => s.id !== deletedId));
      if (companySpotlight?.id === deletedId) setCompanySpotlight(null);
      if (roleSpotlight?.id === deletedId) setRoleSpotlight(null);
    };
    window.addEventListener("spotlightDeleted", handleSpotlightDeleted);
    return () =>
      window.removeEventListener("spotlightDeleted", handleSpotlightDeleted);
  }, [companySpotlight, roleSpotlight]);

  // Add a state to track if a call is booked
  const [hasBookedCall, setHasBookedCall] = useState(false);

  // Add an effect to check for booked call status on mount
  useEffect(() => {
    const hasBooked = localStorage.getItem("hasBookedCall") === "true";
    setHasBookedCall(hasBooked);
  }, []);

  // Ensure that when a new role is being created the booked call state is reset.
  useEffect(() => {
    if (roleId) {
      localStorage.removeItem("hasBookedCall");
      setHasBookedCall(false);
    }
  }, [roleId]);

  if (loading) {
    return <LogoLoader />;
  }
  if (error) {
    return <p className="text-center mt-10 text-red-600">{error}</p>;
  }

  // Filter categories for display
  const companySpotlights = allSpotlights.filter(
    (spot) => spot.Type === "Company information"
  );
  const combinedSpotlights = allSpotlights.filter(
    (spot) => spot.Type === "Company and Role"
  );
  const roleSpotlightsArr = allSpotlights.filter(
    (spot) => spot.Type === "Role information"
  );

  return (
    <div className="min-h-screen flex flex-col bg-white transition-all duration-300">
      {/* Header */}
      <div className="pt-6">
        <div className="w-full max-w-5xl mx-auto">
          <div className="relative px-6 mb-8">
            <h1 className="text-[32px] font-medium text-center mb-8">
              Create role
            </h1>
            <button
              onClick={handleSaveDraft}
              className="px-6 py-2 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-50 transition-colors absolute right-6 top-1/2 -translate-y-1/2"
            >
              Save draft
            </button>
          </div>

          {/* Clickable progress bar */}
          <div className="flex gap-2 mb-6 px-6 cursor-pointer">
            {steps.map(({ step, route }) => (
              <div
                key={step}
                onClick={() => handleProgressClick(route)}
                className={`h-2 flex-1 rounded-full ${
                  step <= 2 ? "bg-[#08f2c3]" : "bg-[#cbcbcb]"
                }`}
              />
            ))}
          </div>

          {/* What is a spotlight? */}
          <div className="px-6 mb-8 mt-10">
            <h2 className="text-2xl font-semibold mb-4">What is a spotlight?</h2>
            <p className="text-gray-600">
              Roles with spotlight videos attract 50% more engagement. Upload or
              record a one-time company video to showcase your mission, and
              create short role-specific videos to explore each new position.
              Already have videos? Select them or easily create new ones with
              Jumpstart's support.
            </p>
          </div>
        </div>
      </div>

      <main className="flex-1 w-full max-w-5xl mx-auto px-6 pb-8">
        {/* Company Spotlight */}
        <div
          className={`bg-white rounded-xl border transition-all duration-200 overflow-hidden mb-6 ${
            companySpotlight ? "border-[#059488]" : "border-gray-200"
          }`}
        >
          <div
            className={`p-6 cursor-pointer flex items-center justify-between ${
              companySpotlight ? "bg-[#F5FFFD]" : "hover:bg-gray-50"
            }`}
            onClick={() =>
              setIsCompanySectionExpanded(!isCompanySectionExpanded)
            }
          >
            <div className="flex items-center gap-3">
              <h2 className="text-lg font-medium">Company Spotlight</h2>
              {companySpotlight ? (
                <div className="w-5 h-5 rounded-full bg-[#059488] flex items-center justify-center">
                  <Check className="w-3.5 h-3.5 text-white" />
                </div>
              ) : (
                <div className="w-5 h-5 rounded-full bg-red-50 flex items-center justify-center">
                  <Info className="w-3.5 h-3.5 text-red-500" />
                </div>
              )}
            </div>
            {isCompanySectionExpanded ? (
              <ChevronUp className="text-gray-400" />
            ) : (
              <ChevronDown className="text-gray-400" />
            )}
          </div>

          {isCompanySectionExpanded && (
            <div className="px-6 pb-6 border-t border-gray-200">
              <p className="text-sm text-gray-500 mt-4 mb-4">
                Add a one-time video to showcase your mission, team, and
                culture—reusable across all roles.{" "}
                <a
                  href="https://www.notion.so/jumpstart-alumni/Startup-Spotlight-Best-Practice-18ab8f57dd84806794bdff140e4b4d39?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black hover:underline"
                >
                  Full guidance here.
                </a>
              </p>

              {/* Existing company spotlights */}
              <div>
                <div className="flex items-center gap-2 mb-3">
                  <h3 className="text-base text-gray-600">Available recordings</h3>
                  <span className="text-sm text-gray-400">
                    ({companySpotlights.length} available)
                  </span>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  {companySpotlights.map((spot) => (
                    <CompactSpotlightCard
                      key={spot.id}
                      spotlight={spot}
                      selected={companySpotlight?.id === spot.id}
                      onSelect={handleCompanySelect}
                      onEdit={(toEdit) => {
                        setModalType(
                          toEdit.Type === "Role information"
                            ? "role"
                            : "company"
                        );
                        setSpotlightToEdit(toEdit);
                        setShowModal(true);
                      }}
                    />
                  ))}

                  {/* Add new box */}
                  <div
                    onClick={() => {
                      setModalType("company");
                      setSpotlightToEdit(null);
                      setShowModal(true);
                    }}
                    className="w-[280px] flex flex-col cursor-pointer rounded-lg border-2 border-dashed border-[#08f2c3] hover:bg-[#08f2c3]/5 transition-all h-full"
                  >
                    <div className="flex-1 flex flex-col items-center justify-center">
                      <span className="text-4xl text-[#08f2c3] mb-2">+</span>
                      <p className="text-sm font-medium">Add New Company Spotlight</p>
                    </div>
                    <div className="p-3" />
                  </div>
                </div>
              </div>

              {/* If no dedicated "company" but have old combined */}
              {companySpotlights.length === 0 && combinedSpotlights.length > 0 && (
                <div className="mt-6">
                  <div className="p-4 bg-yellow-50 border border-yellow-100 rounded-md">
                    <h4 className="text-sm font-semibold text-yellow-700 mb-1">
                      Legacy combined spotlights
                    </h4>
                    <p className="text-xs text-gray-700 mb-3">
                      Previously, we had a single spotlight covering both the
                      company and the role. You can still reuse them here. In
                      the future, you only need a shorter role spotlight once a
                      dedicated company video is in place.
                    </p>
                    <div className="grid grid-cols-3 gap-6">
                      {combinedSpotlights.map((spot) => (
                        <CompactSpotlightCard
                          key={spot.id}
                          spotlight={spot}
                          selected={companySpotlight?.id === spot.id}
                          onSelect={handleCompanySelect}
                          onEdit={(toEdit) => {
                            setModalType(
                              toEdit.Type === "Role information"
                                ? "role"
                                : "company"
                            );
                            setSpotlightToEdit(toEdit);
                            setShowModal(true);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Role Spotlight */}
        <div
          className={`bg-white rounded-xl border transition-all duration-200 overflow-hidden mb-8 ${
            roleSpotlight || hasBookedCall ? "border-[#059488]" : "border-gray-200"
          }`}
        >
          <div
            className={`p-6 cursor-pointer flex items-center justify-between ${
              roleSpotlight || hasBookedCall ? "bg-[#F5FFFD]" : "hover:bg-gray-50"
            }`}
            onClick={() => setIsRoleSectionExpanded(!isRoleSectionExpanded)}
          >
            <div className="flex items-center gap-3">
              <h2 className="text-lg font-medium">Role Spotlight</h2>
              {roleSpotlight || hasBookedCall ? (
                <div className="w-5 h-5 rounded-full bg-[#059488] flex items-center justify-center">
                  <Check className="w-3.5 h-3.5 text-white" />
                </div>
              ) : (
                <div className="w-5 h-5 rounded-full bg-red-50 flex items-center justify-center">
                  <Info className="w-3.5 h-3.5 text-red-500" />
                </div>
              )}
            </div>
            {isRoleSectionExpanded ? (
              <ChevronUp className="text-gray-400" />
            ) : (
              <ChevronDown className="text-gray-400" />
            )}
          </div>

          {isRoleSectionExpanded && (
            <div className="px-6 pb-6 border-t border-gray-200">
              <p className="text-sm text-gray-500 mt-4 mb-4">
                Add a short video tailored to each role, highlighting key
                responsibilities and why it's exciting.{" "}
                <a
                  href="https://www.notion.so/jumpstart-alumni/Startup-Spotlight-Best-Practice-18ab8f57dd84806794bdff140e4b4d39?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black hover:underline"
                >
                  Full guidance here.
                </a>
              </p>

              {/* Existing role spotlights */}
              <div>
                <div className="flex items-center gap-2 mb-3">
                  <h3 className="text-base text-gray-600">Available recordings</h3>
                  <span className="text-sm text-gray-400">
                    ({roleSpotlightsArr.length} available)
                  </span>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  {roleSpotlightsArr.map((spot) => (
                    <CompactSpotlightCard
                      key={spot.id}
                      spotlight={spot}
                      selected={roleSpotlight?.id === spot.id}
                      onSelect={handleRoleSelect}
                      onEdit={(toEdit) => {
                        setModalType(
                          toEdit.Type === "Company information"
                            ? "company"
                            : "role"
                        );
                        setSpotlightToEdit(toEdit);
                        setShowModal(true);
                      }}
                    />
                  ))}

                  {/* Add new role spotlight box */}
                  {hasBookedCall ? (
                    <div className="w-[280px] flex flex-col rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 h-full">
                      <div className="flex-1 flex flex-col items-center justify-center p-4">
                        <p className="text-sm font-medium text-gray-600 text-center">
                          Waiting for scheduled call with Jumpstart
                        </p>
                        <p className="text-xs text-gray-400 mt-2 text-center">
                          We'll help you create your spotlight
                        </p>
                      </div>
                      <div className="p-3" />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setModalType("role");
                        setSpotlightToEdit(null);
                        setShowModal(true);
                      }}
                      className="w-[280px] flex flex-col cursor-pointer rounded-lg border-2 border-dashed border-[#08f2c3] hover:bg-[#08f2c3]/5 transition-all h-full"
                    >
                      <div className="flex-1 flex flex-col items-center justify-center">
                        <span className="text-4xl text-[#08f2c3] mb-2">+</span>
                        <p className="text-sm font-medium">Add New Role Spotlight</p>
                      </div>
                      <div className="p-3" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Spotlight Context */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Spotlight Context
          </label>
          <input
            type="text"
            className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 
              focus:ring-[#08f2c3] focus:border-transparent transition-all"
            placeholder="e.g., 'Skip to minute 2 for the product demo'"
            value={spotlightContext}
            onChange={(e) => setSpotlightContext(e.target.value)}
          />
          <p className="text-xs text-gray-500 mt-2">
            This note will appear below the spotlight video.
          </p>
        </div>
      </main>

      {/* Sticky footer */}
      <div className="sticky bottom-0 w-full bg-white border-t border-gray-200">
        <div className="w-full max-w-5xl mx-auto px-6 py-3 flex justify-between items-center">
          <button
            onClick={handleBack}
            className="px-6 py-2.5 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
          >
            Back
          </button>
          <div className="flex gap-4">
            <button
              onClick={handleSkip}
              className="px-6 py-2.5 text-gray-500 hover:text-gray-700 transition-colors"
            >
              Skip for now
            </button>
            <button
              onClick={handleNext}
              disabled={isLoading}
              className="px-6 py-2.5 bg-[#08f2c3] text-black rounded-lg hover:bg-[#06c19c] 
                disabled:opacity-50 disabled:cursor-not-allowed transition-colors inline-flex items-center"
            >
              {isLoading ? (
                <span className="inline-flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-4 w-4 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </span>
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* "Add/Edit Spotlight" Modal */}
      <AddSpotlightModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setSpotlightToEdit(null);
        }}
        type={modalType}
        onAddSpotlight={async (updated) => {
          // Parent logic to create/edit in Airtable
          try {
            const token = localStorage.getItem("token");
            if (!token) return;

            const isTemp = typeof updated.id === "number"; // local only
            if (isTemp) {
              // CREATE
              const dataToCreate = {
                fields: {
                  Name: updated.Name,
                  Type: updated.Type,
                  "URL Loom": updated["URL Loom cleaned"] || "",
                  "URL Dropbox": updated["URL Dropbox cleaned"] || "",
                  Deleted: false,
                },
              };
              const resp = await axios.post("/api/spotlights/createSpotlight", dataToCreate, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              });
              const newFields = resp?.data?.newSpotlightData?.fields;
              if (newFields) {
                const realSpotlight = {
                  id: newFields.id,
                  Name: newFields.Name,
                  "URL Loom cleaned": newFields["URL Loom cleaned"] || "",
                  "URL Dropbox cleaned": newFields["URL Dropbox cleaned"] || "",
                  Type: newFields.Type || updated.Type,
                };
                setAllSpotlights((prev) => [...prev, realSpotlight]);
                // auto-select
                if (realSpotlight.Type === "Company information") {
                  setCompanySpotlight(realSpotlight);
                } else {
                  setRoleSpotlight(realSpotlight);
                }
              }
            } else {
              // EDIT
              const dataToUpdate = {
                spotlightId: updated.id,
                fields: {
                  Name: updated.Name,
                  "URL Loom": updated["URL Loom cleaned"] || "",
                  "URL Dropbox": updated["URL Dropbox cleaned"] || "",
                },
              };
              const resp = await axios.patch("/api/spotlights/updateSpotlightData", dataToUpdate, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              });
              const updatedFields = resp?.data?.updatedSpotlight?.fields;
              if (updatedFields) {
                const realSpotlight = {
                  id: updatedFields.id,
                  Name: updatedFields.Name,
                  "URL Loom cleaned": updatedFields["URL Loom cleaned"] || "",
                  "URL Dropbox cleaned": updatedFields["URL Dropbox cleaned"] || "",
                  Type: updatedFields.Type || updated.Type,
                };
                setAllSpotlights((prev) => {
                  const idx = prev.findIndex((s) => s.id === realSpotlight.id);
                  if (idx < 0) return [...prev, realSpotlight];
                  const copy = [...prev];
                  copy[idx] = realSpotlight;
                  return copy;
                });
                if (
                  companySpotlight &&
                  companySpotlight.id === realSpotlight.id
                ) {
                  setCompanySpotlight(realSpotlight);
                }
                if (roleSpotlight && roleSpotlight.id === realSpotlight.id) {
                  setRoleSpotlight(realSpotlight);
                }
              }
            }
          } catch (err) {
            console.error("Error creating/updating spotlight:", err);
          }
        }}
        spotlightToEdit={spotlightToEdit}
        onBookCall={() => {
          setHasBookedCall(true);
          localStorage.setItem("hasBookedCall", "true");
          localStorage.removeItem("selectedCompanySpotlight");
          setShowModal(false);
        }}
      />

      {/* Confirm skipping if no company video */}
      {showNoCompanyVideoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto">
            <h2 className="text-xl font-semibold mb-4">
              No company video selected
            </h2>
            <p className="text-gray-600 mb-4">
              Would you like to skip the company spotlight for now?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowNoCompanyVideoModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  localStorage.removeItem("hasBookedCall");
                  setShowNoCompanyVideoModal(false);
                  proceedNext();
                }}
                className="ml-3 px-6 py-2 bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c]"
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirm skipping if no role video */}
      {showNoRoleVideoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto">
            <h2 className="text-xl font-semibold mb-4">No role video selected</h2>
            <p className="text-gray-600 mb-4">
              Are you sure? Adding a role video will help attract more relevant
              candidates.
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  // Let them add it now
                  setShowNoRoleVideoModal(false);
                  proceedNext();
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Add video
              </button>
              <button
                onClick={() => {
                  // Skip role video
                  setShowNoRoleVideoModal(false);
                  proceedNext();
                }}
                className="ml-3 px-6 py-2 bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c]"
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectSpotlight;
