import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EditJobDescriptionModal from "../../components/interview_request2/edit_job_description";
import { Edit2, Trash2, X } from "lucide-react";
import RolePreview from "../../components/new_interview_request/role_preview";

// --------------------- Utility: URL Param Parsing ---------------------
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// --------------------- AddSpotlightModal (no Jumpstart tab) ---------------------
function AddSpotlightModal({ isOpen, onClose, type, onAddSpotlight, spotlightToEdit }) {
  // We'll only show the record/upload UI
  const isEditing = !!spotlightToEdit;
  const [name, setName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (!isOpen) return;

    // Reset validation errors on open
    setValidationErrors({});

    if (isEditing && spotlightToEdit) {
      // Only set values if we're actually editing an existing spotlight
      setName(spotlightToEdit.Name || "");
      const loom = spotlightToEdit["URL Loom cleaned"] || "";
      const dropbox = spotlightToEdit["URL Dropbox cleaned"] || "";
      setVideoUrl(loom || dropbox);
    } else {
      // Creating new - ensure fields are empty
      setName("");
      setVideoUrl("");
    }
  }, [isOpen, isEditing, spotlightToEdit]);

  if (!isOpen) return null;

  const validateForm = () => {
    const errors = {};
    
    if (!name.trim()) {
      errors.name = "Please provide a name for your video";
    }
    
    if (!videoUrl.trim()) {
      errors.url = "Please provide a video URL";
    } else if (!videoUrl.includes("loom.com") && !videoUrl.includes("dropbox.com")) {
      errors.url = "Please enter a valid Loom or Dropbox URL";
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isLoomUrl = (url) => {
    return url.includes("loom.com");
  };

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    const updated = isEditing
      ? { ...spotlightToEdit }
      : {
          id: Date.now(),
          Type: type === "role" ? "Role information" : "Company information",
        };

    updated.Name = name.trim();
    if (isLoomUrl(videoUrl)) {
      updated["URL Loom cleaned"] = videoUrl.trim();
      updated["URL Dropbox cleaned"] = "";
    } else {
      updated["URL Loom cleaned"] = "";
      updated["URL Dropbox cleaned"] = videoUrl.trim();
    }

    onAddSpotlight(updated);
    setIsLoading(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto"
         onClick={onClose}>
      <div className="bg-white rounded-lg p-6 max-w-lg w-full relative"
           onClick={(e) => e.stopPropagation()}>
        <button 
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100 transition-colors"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </button>
        <h3 className="text-xl font-semibold mb-5">
          {isEditing ? "Edit Spotlight" : `Add ${type === "role" ? "Role" : "Company"} Spotlight`}
        </h3>
        
        <div className="space-y-4">
          {/* Upload new content */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Video Title</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (validationErrors.name) {
                  setValidationErrors({...validationErrors, name: null});
                }
              }}
              placeholder={type === "company" 
                ? "e.g. About Our Company" 
                : "e.g. Software Engineer Role Overview"}
              className={`block w-full p-3 border ${validationErrors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent`}
            />
            {validationErrors.name && (
              <p className="mt-1 text-sm text-red-600">{validationErrors.name}</p>
            )}
            <p className="mt-1 text-xs text-gray-500">
              Choose a descriptive name that will help you identify this video later
            </p>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Video URL (Loom or Dropbox)
            </label>
            <input
              type="url"
              value={videoUrl}
              onChange={(e) => {
                setVideoUrl(e.target.value);
                if (validationErrors.url) {
                  setValidationErrors({...validationErrors, url: null});
                }
              }}
              placeholder="https://www.loom.com/share/... or https://www.dropbox.com/s/..."
              className={`block w-full p-3 border ${validationErrors.url ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent`}
            />
            {validationErrors.url && (
              <p className="mt-1 text-sm text-red-600">{validationErrors.url}</p>
            )}
            <p className="mt-1 text-xs text-gray-500">
              Paste the share URL from Loom or Dropbox for your video
            </p>
          </div>
          
          {/* Recording Tips Link */}
          <div className="text-center">
            <a
              href="https://www.notion.so/jumpstart-alumni/Startup-Spotlight-Best-Practice-18ab8f57dd84806794bdff140e4b4d39?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-[#02B491] hover:underline inline-block"
            >
              View recording tips →
            </a>
          </div>
          
          {/* Submit button */}
          <div>
            <button
              onClick={handleSave}
              disabled={isLoading}
              className={`w-full py-3 bg-[#02B491] text-white rounded-lg hover:bg-[#029478] flex items-center justify-center font-medium 
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? "Saving..." : (isEditing ? "Update Spotlight" : "Add Spotlight")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// --------------------- PreviewSpotlightModal ---------------------
function PreviewSpotlightModal({ isOpen, onClose, spotlight, onEdit, onDelete }) {
  if (!isOpen) return null;

  const loomUrl = spotlight["URL Loom cleaned"];
  const dropboxUrl = spotlight["URL Dropbox cleaned"];

  let videoElement = null;
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop();
    const loomEmbed = `https://www.loom.com/embed/${loomId}`;
    videoElement = (
      <iframe
        title={`Preview of ${spotlight.Name}`}
        src={loomEmbed}
        frameBorder="0"
        allowFullScreen
        className="w-full aspect-video rounded-lg"
      />
    );
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
    videoElement = (
      <video
        src={directVideoUrl}
        controls
        className="w-full aspect-video rounded-lg"
      />
    );
  }

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="bg-white rounded-xl w-full max-w-3xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b">
          <h3 className="text-xl font-semibold">{spotlight.Name}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {videoElement}

          {/* Actions */}
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => onDelete()}
              className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <Trash2 className="w-4 h-4" />
              Delete
            </button>
            <button
              onClick={() => onEdit()}
              className="flex items-center gap-2 px-4 py-2 bg-[#02B491] text-white rounded-lg hover:bg-[#029478] transition-colors"
            >
              <Edit2 className="w-4 h-4" />
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// --------------------- CompactSpotlightCard ---------------------
function CompactSpotlightCard({ 
  spotlight, 
  selected, 
  onSelect, 
  onDelete,
  onEdit,
  setModalType,
  setSpotlightToEdit,
  setShowAddSpotlightModal,
  setShowPreviewModal,
  setRoleSpotlightName,
  setRoleSpotlightUrl,
  setShowSelectOrUploadRole,
  setCompanySpotlightName,
  setCompanySpotlightUrl,
  setShowSelectOrUploadCompany
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const loomUrl = spotlight["URL Loom cleaned"] || spotlight["URL Loom"];
  const dropboxUrl = spotlight["URL Dropbox cleaned"] || spotlight["URL Dropbox"];

  // Handle clicking outside the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  let previewElement = null;
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop();
    const loomEmbed = `https://www.loom.com/embed/${loomId}`;
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg overflow-hidden">
        <iframe
          title="Loom Preview"
          src={loomEmbed}
          frameBorder="0"
          allowFullScreen
          className="w-full h-full"
          sandbox="allow-same-origin allow-scripts allow-popups"
        />
      </div>
    );
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg overflow-hidden">
        <video src={directVideoUrl} className="w-full h-full object-cover" />
      </div>
    );
  } else {
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg flex items-center justify-center">
        <div className="flex flex-col items-center justify-center text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 mb-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
            />
          </svg>
          <p className="text-sm">No preview</p>
        </div>
      </div>
    );
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      await axios.patch(
        "/api/spotlights/updateSpotlightData",
        {
          spotlightId: spotlight.id,
          fields: { Deleted: true },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      onDelete(spotlight.id);
      setShowDeleteModal(false);
    } catch (err) {
      console.error("Error deleting spotlight:", err);
    }
  };

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(spotlight);
  };

  return (
    <>
      <div
        onClick={handleSelect}
        className={`group bg-white rounded-lg transition-all duration-200 overflow-hidden cursor-pointer
          ${
            selected
              ? "ring-2 ring-[#02B491] shadow-md"
              : "border border-gray-200 hover:border-gray-300 hover:shadow-sm"
          }`}
      >
        <div className="relative cursor-pointer">
          {previewElement}
          {selected && (
            <div className="absolute inset-0 bg-[#02B491]/10 flex items-center justify-center">
              <div className="bg-[#02B491] text-white p-2 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
          )}
          {/* Three-dot menu button */}
          <div className="absolute top-2 right-2 z-10" ref={dropdownRef}>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowDropdown(!showDropdown);
              }}
              className="bg-white p-1.5 rounded-full shadow-md hover:bg-gray-100 transition-all"
              title="Options"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
            
            {/* Dropdown menu */}
            {showDropdown && (
              <div className="absolute right-0 mt-1 w-40 bg-white rounded-md shadow-lg overflow-hidden z-20 border border-gray-200">
                <div className="py-1">
                  {/* Preview option */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDropdown(false);
                      setModalType(spotlight.Type === "Role information" ? "role" : "company");
                      setSpotlightToEdit(spotlight);
                      setShowPreviewModal(true);
                    }}
                    className="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 items-center"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4 mr-2 text-gray-500" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" 
                      />
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" 
                      />
                    </svg>
                    Preview
                  </button>
                  
                  {/* Edit option */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDropdown(false);
                      const type = spotlight.Type === "Role information" ? "role" : "company";
                      setModalType(type);
                      setSpotlightToEdit(spotlight);
                      
                      // Set up data for edit and open correct modal
                      if (type === "role") {
                        if (setRoleSpotlightName) setRoleSpotlightName(spotlight.Name || "");
                        if (setRoleSpotlightUrl) setRoleSpotlightUrl(spotlight["URL Loom"] || spotlight["URL Dropbox"] || "");
                        if (setShowSelectOrUploadRole) setShowSelectOrUploadRole(true);
                      } else {
                        if (setCompanySpotlightName) setCompanySpotlightName(spotlight.Name || "");
                        if (setCompanySpotlightUrl) setCompanySpotlightUrl(spotlight["URL Loom"] || spotlight["URL Dropbox"] || "");
                        if (setShowSelectOrUploadCompany) setShowSelectOrUploadCompany(true);
                      }
                    }}
                    className="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 items-center"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4 mr-2 text-gray-500" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" 
                      />
                    </svg>
                    Edit
                  </button>
                  
                  {/* Delete option */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDropdown(false);
                      setShowDeleteModal(true);
                    }}
                    className="flex w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100 items-center"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4 mr-2 text-red-500" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                      />
                    </svg>
                    Delete
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="p-4 flex items-center justify-between border-t border-gray-100">
          <div className="flex-grow">
            <p className="text-sm font-medium text-gray-700 truncate">
              {spotlight.Name || "Untitled"}
            </p>
          </div>
        </div>
      </div>

      {showPreview && (
        <PreviewSpotlightModal
          isOpen={showPreview}
          onClose={() => setShowPreview(false)}
          spotlight={spotlight}
          onDelete={() => {
            setShowPreview(false);
            setShowDeleteModal(true);
          }}
          onEdit={() => {
            setShowPreview(false);
            const type = spotlight.Type === "Role information" ? "role" : "company";
            setModalType(type);
            setSpotlightToEdit(spotlight);
            
            // Use the props passed from parent
            if (type === "role") {
              if (setRoleSpotlightName) setRoleSpotlightName(spotlight.Name || "");
              if (setRoleSpotlightUrl) setRoleSpotlightUrl(spotlight["URL Loom"] || spotlight["URL Dropbox"] || "");
              if (setShowSelectOrUploadRole) setShowSelectOrUploadRole(true);
            } else {
              if (setCompanySpotlightName) setCompanySpotlightName(spotlight.Name || "");
              if (setCompanySpotlightUrl) setCompanySpotlightUrl(spotlight["URL Loom"] || spotlight["URL Dropbox"] || "");
              if (setShowSelectOrUploadCompany) setShowSelectOrUploadCompany(true);
            }
          }}
        />
      )}

      {showDeleteModal && (
        <div
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <h2 className="text-xl font-semibold mb-4">Delete Spotlight</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this spotlight? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// --------------------- SelectExistingSpotlightModal ---------------------
function SelectExistingSpotlightModal({
  isOpen,
  onClose,
  spotlights = [],
  currentlySelectedId,
  onSelect,
  setModalType,
  setSpotlightToEdit,
  setShowAddSpotlightModal
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  
  useEffect(() => {
    // Reset selected spotlight when modal opens
    if (isOpen) {
      setSelectedSpotlight(null);
      setSearchTerm("");
    }
  }, [isOpen]);
  
  if (!isOpen) return null;

  const handleSelect = (spotlight) => {
    setSelectedSpotlight(spotlight);
  };
  
  const confirmSelection = () => {
    if (selectedSpotlight) {
      onSelect(selectedSpotlight);
      onClose();
    }
  };
  
  const filteredSpotlights = searchTerm.trim() === "" 
    ? spotlights 
    : spotlights.filter(sp => 
        sp.Name?.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-4xl my-8"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Select a spotlight video</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100 transition-colors"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-6 w-6" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        </div>
        
        {/* Search bar */}
        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search videos by name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 absolute left-3 top-3.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
        
        {filteredSpotlights.length === 0 && (
          <div className="text-center py-12">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-12 w-12 text-gray-300 mx-auto mb-4" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
            {searchTerm ? (
              <p className="text-gray-600">No videos found matching "{searchTerm}"</p>
            ) : (
              <p className="text-gray-600">No spotlight videos available in this category yet.</p>
            )}
            <button
              onClick={() => {
                onClose();
                // Determine which type of spotlight to create
                const type = spotlights.some(s => s.Type === "Role information") ? "role" : "company";
                setModalType(type);
                setSpotlightToEdit(null);
                setShowAddSpotlightModal(true);
              }}
              className="mt-4 px-4 py-2 bg-[#02B491] text-white rounded-lg hover:bg-[#029478]"
            >
              Create a new video
            </button>
          </div>
        )}
        
        {filteredSpotlights.length > 0 && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 min-h-[300px] max-h-[500px] overflow-y-auto p-1">
              {filteredSpotlights.map((sp) => (
                <div 
                  key={sp.id} 
                  onClick={() => handleSelect(sp)}
                  className={`cursor-pointer border rounded-lg transition-all overflow-hidden ${
                    (selectedSpotlight?.id === sp.id || currentlySelectedId === sp.id) 
                      ? "ring-2 ring-[#02B491] shadow-sm" 
                      : "border-gray-200 hover:border-gray-300"
                  }`}
                >
                  {/* Video preview */}
                  <div className="aspect-video bg-gray-100 relative">
                    {sp["URL Loom cleaned"] ? (
                      <iframe
                        title="Loom Preview"
                        src={`https://www.loom.com/embed/${sp["URL Loom cleaned"].split("/").pop()}`}
                        frameBorder="0"
                        allowFullScreen
                        className="w-full h-full"
                        sandbox="allow-same-origin allow-scripts allow-popups"
                      />
                    ) : sp["URL Dropbox cleaned"] ? (
                      <video
                        src={sp["URL Dropbox cleaned"].replace("?dl=0", "?raw=1")}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <p className="text-sm text-gray-400">No preview</p>
                      </div>
                    )}
                    
                    {/* Selected overlay */}
                    {selectedSpotlight?.id === sp.id && (
                      <div className="absolute inset-0 bg-[#02B491]/10 flex items-center justify-center">
                        <div className="bg-[#02B491] text-white p-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {/* Video info */}
                  <div className="p-3">
                    <p className="font-medium text-gray-800 truncate">{sp.Name}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      {sp.Type === "Role information" 
                        ? "Role Video" 
                        : sp.Type === "Company information" 
                          ? "Company Video" 
                          : "Company & Role Video"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="flex justify-between items-center mt-6 pt-4 border-t border-gray-200">
              <div className="text-sm text-gray-500">
                {filteredSpotlights.length} {filteredSpotlights.length === 1 ? 'video' : 'videos'} available
              </div>
              <div className="flex space-x-3">
                <button 
                  onClick={onClose} 
                  className="px-4 py-2 text-gray-600 hover:text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmSelection}
                  disabled={!selectedSpotlight}
                  className={`px-4 py-2 bg-[#02B491] text-white rounded-lg flex items-center ${
                    selectedSpotlight ? 'hover:bg-[#029478]' : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  {selectedSpotlight ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-1.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Select Video
                    </>
                  ) : (
                    "Select a Video"
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}


// Add this function inside the EditRole component
const getNextFortnightlyMonday = () => {
  // Starting date: Monday, March 10th, 2025
  const startDate = new Date(2025, 2, 10); // Month is 0-indexed (2 = March)
  const today = new Date();
  
  // If today is before our start date, just return the start date
  if (today < startDate) {
    return formatDate(startDate);
  }
  
  // Calculate days since start date
  const daysSinceStart = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
  // Calculate how many complete fortnights have passed
  const completedFortnights = Math.floor(daysSinceStart / 14);
  // Calculate the next fortnightly Monday
  const nextMonday = new Date(startDate);
  nextMonday.setDate(startDate.getDate() + (completedFortnights + 1) * 14);
  
  return formatDate(nextMonday);
};

// Helper function to format the date with proper ordinal suffix
const formatDate = (date) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  const dayName = days[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  // Get correct ordinal suffix
  const getOrdinalSuffix = (n) => {
    if (n > 3 && n < 21) return 'th';
    switch (n % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  
  const ordinal = getOrdinalSuffix(day);
  
  return `${dayName} ${day}${ordinal} of ${month} ${year}`;
};

// --------------------- The main EditRole component ---------------------
function EditRole() {
  const query = useQuery();
  const roleId = query.get("roleId");
  const navigate = useNavigate();

  const [selectedRoleToggle, setSelectedRoleToggle] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [urlId, setUrlId] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    job_description_link: "",
    email: "",
    key_experience: "",
    compensation: "",
    working_style: "",
    hiring_lead_name: "",
    hiring_lead_linkedin: "",
    equity: "",
    hiring_lead_email: "",
    interview_process: "",
    additional_info: "",
    hiring_lead_phone_number: "",
    additionalTeamEmails: "",
    interviewLink: "",
    open_to_applications: false,
    spotlight_context: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // All spotlights
  const [spotlights, setSpotlights] = useState([]);
  const [companySpotlights, setCompanySpotlights] = useState([]);
  const [roleSpotlights, setRoleSpotlights] = useState([]);

  // Instead of storing a single object that means "selected,"
  // store the chosen object plus a boolean that indicates isSelected.
  const [companySpotlightInUse, setCompanySpotlightInUse] = useState(null);
  const [companySpotlightIsSelected, setCompanySpotlightIsSelected] = useState(false);

  const [roleSpotlightInUse, setRoleSpotlightInUse] = useState(null);
  const [roleSpotlightIsSelected, setRoleSpotlightIsSelected] = useState(false);

  const [spotlightNeedUpdate, setSpotlightNeedUpdate] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJobDescriptionModalOpen, setIsJobDescriptionModalOpen] = useState(false);

  // "Select or Upload"
  const [showSelectOrUploadCompany, setShowSelectOrUploadCompany] = useState(false);
  const [showSelectOrUploadRole, setShowSelectOrUploadRole] = useState(false);
  const [showSelectSpotlightModal, setShowSelectSpotlightModal] = useState(false);
  const [selectSpotlightType, setSelectSpotlightType] = useState(null);

  // Add/Edit Spotlight
  const [showAddSpotlightModal, setShowAddSpotlightModal] = useState(false);
  const [addSpotlightType, setAddSpotlightType] = useState(null);
  const [spotlightToEdit, setSpotlightToEdit] = useState(null);
  
  // Direct spotlight creation states
  const [companySpotlightName, setCompanySpotlightName] = useState("");
  const [companySpotlightUrl, setCompanySpotlightUrl] = useState("");
  const [roleSpotlightName, setRoleSpotlightName] = useState("");
  const [roleSpotlightUrl, setRoleSpotlightUrl] = useState("");
  
  // Collapsible recording tips
  const [showCompanyRecordingTips, setShowCompanyRecordingTips] = useState(false);
  const [showRoleRecordingTips, setShowRoleRecordingTips] = useState(false);
  
  const [deleteSpotlight, setDeleteSpotlight] = useState(null);
  const [file, setFile] = useState(null);

  // Collapsible
  const [spotlightsOpen, setSpotlightsOpen] = useState(true);

  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // --------------------- Fetch role & spotlights ---------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token || !roleId) {
          setLoading(false);
          return;
        }

        // 1) Get the role
        const roleResp = await axios.get("/api/roles/fetchRoleById", {
          headers: { Authorization: `Bearer ${token}` },
          params: { id: roleId },
        });
        const r = roleResp.data.role;
        if (!r) {
          throw new Error("No role found for this ID");
        }
        const fields = r.fields || {};
        setUrlId(fields["URL ID"]);
        setFormData((prev) => ({
          ...prev,
          title: fields["Role Title"] || "",
          job_description_link: fields["Job Description PDF"] || "",
          key_experience: fields["Specific Experience"] || "",
          compensation: fields["Compensation"] || "",
          working_style: fields["Working Style"] || "",
          hiring_lead_name: fields["Hiring Lead Name"] || "",
          hiring_lead_email: fields["Hiring Lead Email Address"] || "",
          hiring_lead_linkedin: fields["Hiring Lead Linkedin"] || "",
          hiring_lead_phone_number: fields["Hiring Lead Phone Number"] || "",
          equity: fields["Equity"] || "",
          interview_process: fields["Interview Process"] || "",
          spotlight_context: fields["Spotlight Context"] || "",
          open_to_applications: fields["Open to applications"] === "Open to apps",
          additionalTeamEmails: fields["CCs"] || "",
          interviewLink: fields["Hiring Lead Calendly Link"] || "",
        }));
      } catch (err) {
        console.error(err);
        setError("Failed to load role data.");
        setLoading(false);
        return;
      }

      try {
        // 2) Get all spotlights
        const token = localStorage.getItem("token");
        if (!token) return;
        const spotsResp = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: { Authorization: `Bearer ${token}` },
        });
        let sps = spotsResp.data.spotlights || [];
        sps = sps.filter((s) => !s.Deleted);
        setSpotlights(sps);

        // separate categories
        const comp = sps.filter((sp) =>
          ["Company information", "Company and Role"].includes(sp.Type)
        );
        const roleSps = sps.filter((sp) => sp.Type === "Role information");
        setCompanySpotlights(comp);
        setRoleSpotlights(roleSps);

        // check assigned from the DB
        const fieldsResp = await axios.get("/api/roles/fetchRoleById", {
          headers: { Authorization: `Bearer ${token}` },
          params: { id: roleId },
        });
        const roleFields = fieldsResp.data.role?.fields || {};
        const assignedIDs = roleFields["Spotlight"] || [];

        // If there's a company-type spotlight assigned, set it as inUse + selected
        const assignedCompany = sps.find(
          (sp) =>
            assignedIDs.includes(sp.id) &&
            ["Company information", "Company and Role"].includes(sp.Type)
        );
        if (assignedCompany) {
          setCompanySpotlightInUse(assignedCompany);
          setCompanySpotlightIsSelected(true);
        }

        // If there's a role-type spotlight assigned, set it as inUse + selected
        const assignedRole = sps.find(
          (sp) => assignedIDs.includes(sp.id) && sp.Type === "Role information"
        );
        if (assignedRole) {
          setRoleSpotlightInUse(assignedRole);
          setRoleSpotlightIsSelected(true);
        }
      } catch (err) {
        console.error("Failed to load spotlights. Continuing with none.", err);
        setSpotlights([]);
        setCompanySpotlights([]);
        setRoleSpotlights([]);
      }

      setLoading(false);
    };

    fetchData();
  }, [roleId]);

  // Re-fetch if needed
  useEffect(() => {
    if (!spotlightNeedUpdate) return;
    const fetchAgain = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;
        const resp = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: { Authorization: `Bearer ${token}` },
        });
        let sps = resp.data.spotlights || [];
        sps = sps.filter((s) => !s.Deleted);

        setSpotlights(sps);
        const comp = sps.filter((sp) =>
          ["Company information", "Company and Role"].includes(sp.Type)
        );
        const roleSps = sps.filter((sp) => sp.Type === "Role information");
        setCompanySpotlights(comp);
        setRoleSpotlights(roleSps);

        setSpotlightNeedUpdate(false);
      } catch (err) {
        console.error("Failed to reload spotlights", err);
        setSpotlights([]);
        setCompanySpotlights([]);
        setRoleSpotlights([]);
        setSpotlightNeedUpdate(false);
      }
    };
    fetchAgain();
  }, [spotlightNeedUpdate]);

  // --------------------- Add/Edit Spotlight ---------------------
  const handleAddSpotlight = async (updated) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      const isTemp = typeof updated.id === "number";
      let newSpotlight;

      if (isTemp) {
        // CREATE
        const dataToCreate = {
          fields: {
            Name: updated.Name,
            Type: updated.Type,
            "URL Loom": updated["URL Loom cleaned"] || "",
            "URL Dropbox": updated["URL Dropbox cleaned"] || "",
            Deleted: false,
          },
        };
        const response = await axios.post("/api/spotlights/createSpotlight", dataToCreate, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.data || !response.data.newSpotlightData) {
          throw new Error("Server response missing spotlight data");
        }

        const newFields = response.data.newSpotlightData;
        newSpotlight = {
          id: newFields.id,
          Name: newFields.Name || updated.Name,
          Type: newFields.Type || updated.Type,
          "URL Loom cleaned": updated["URL Loom cleaned"] || "",
          "URL Dropbox cleaned": updated["URL Dropbox cleaned"] || "",
          Deleted: false,
        };
      } else {
        // EDIT
        const dataToUpdate = {
          spotlightId: updated.id,
          fields: {
            Name: updated.Name,
            Type: updated.Type,
            "URL Loom": updated["URL Loom cleaned"] || "",
            "URL Dropbox": updated["URL Dropbox cleaned"] || "",
          },
        };
        const response = await axios.patch("/api/spotlights/updateSpotlightData", dataToUpdate, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        // For edit case, use the updated data directly
        newSpotlight = {
          id: updated.id,
          Name: updated.Name,
          Type: updated.Type,
          "URL Loom cleaned": updated["URL Loom cleaned"] || "",
          "URL Dropbox cleaned": updated["URL Dropbox cleaned"] || "",
          Deleted: false,
        };
      }

      if (!newSpotlight || !newSpotlight.id) {
        throw new Error("Failed to process spotlight data");
      }

      // Update spotlights state immediately
      setSpotlights((prev) => {
        const filtered = prev.filter((s) => s.id !== newSpotlight.id);
        return [...filtered, newSpotlight];
      });

      // Update the appropriate spotlight type state
      if (newSpotlight.Type === "Role information") {
        setRoleSpotlights((prev) => {
          const filtered = prev.filter((s) => s.id !== newSpotlight.id);
          return [...filtered, newSpotlight];
        });
        setRoleSpotlightInUse(newSpotlight);
        setRoleSpotlightIsSelected(true);
      } else if (["Company information", "Company and Role"].includes(newSpotlight.Type)) {
        setCompanySpotlights((prev) => {
          const filtered = prev.filter((s) => s.id !== newSpotlight.id);
          return [...filtered, newSpotlight];
        });
        setCompanySpotlightInUse(newSpotlight);
        setCompanySpotlightIsSelected(true);
      }

      // Close the modal and reset states
      setShowAddSpotlightModal(false);
      setSpotlightToEdit(null);
      setAddSpotlightType(null);

      // Force a re-render by triggering spotlight need update
      setSpotlightNeedUpdate(true);
    } catch (err) {
      console.error("Error in handleAddSpotlight:", err);
      const errorMessage = err.response?.data?.message || err.message || "Failed to save spotlight";
      alert(errorMessage);
    }
  };

  // --------------------- Delete Spotlight (mark as Deleted) ---------------------
  const handleDeleteSpotlight = async (event) => {
    event.preventDefault();
    if (!deleteSpotlight) return;
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      await axios.patch(
        "/api/spotlights/updateSpotlightData",
        {
          spotlightId: deleteSpotlight.id,
          fields: { Deleted: true },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      if (companySpotlightInUse?.id === deleteSpotlight.id) {
        setCompanySpotlightInUse(null);
        setCompanySpotlightIsSelected(false);
      }
      if (roleSpotlightInUse?.id === deleteSpotlight.id) {
        setRoleSpotlightInUse(null);
        setRoleSpotlightIsSelected(false);
      }
      setSpotlightNeedUpdate(true);
      setDeleteSpotlight(null);
    } catch (err) {
      console.error(err);
    }
  };

  // --------------------- Save the Role ---------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!roleId) return;

    setIsSaving(true);
    // Only store the IDs if the user wants them "selected" for the role
    const companyIdToInclude = companySpotlightIsSelected
      ? companySpotlightInUse?.id
      : null;
    const roleIdToInclude = roleSpotlightIsSelected ? roleSpotlightInUse?.id : null;

    const chosenIDs = [companyIdToInclude, roleIdToInclude].filter(Boolean);

    const openToApplicationsValue = formData.open_to_applications
      ? "Open to apps"
      : "Not open to apps";

    const fieldsToUpdate = {
      "Role Title": formData.title || "",
      "Job Description PDF": formData.job_description_link || "",
      "Specific Experience": formData.key_experience || "",
      "Compensation": formData.compensation || "",
      "Working Style": formData.working_style || "",
      "Hiring Lead Name": formData.hiring_lead_name || "",
      "Hiring Lead Email Address": formData.hiring_lead_email || "",
      "Hiring Lead Linkedin": formData.hiring_lead_linkedin || "",
      "Hiring Lead Phone Number": formData.hiring_lead_phone_number || "",
      "Interview Process": formData.interview_process || "",
      "Spotlight Context": formData.spotlight_context || "",
      "Open to applications": openToApplicationsValue,
      "CCs": formData.additionalTeamEmails || "",
      "Hiring Lead Calendly Link": formData.interviewLink || "",
      "Spotlight": chosenIDs,
    };
    const eqVal = formData.equity.trim();
    if (eqVal) {
      fieldsToUpdate["Equity"] = eqVal;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      await axios.patch(
        "/api/roles/updateRoleData",
        { fields: fieldsToUpdate, roleId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      setShowSaveSuccess(true);
      setTimeout(() => setShowSaveSuccess(false), 3000); // Hide after 3 seconds
    } catch (err) {
      console.error("Error saving role data:", err);
      let errorMessage = "Failed to save role data";
      if (err.response?.data?.message) {
        errorMessage += `: ${err.response.data.message}`;
      } else if (err.response?.data?.error) {
        errorMessage += `: ${err.response.data.error}`;
      }
      setError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  // --------------------- Publish the Role ---------------------
  const handlePublish = async () => {
    if (!roleId) return;

    const companyIdToInclude = companySpotlightIsSelected
      ? companySpotlightInUse?.id
      : null;
    const roleIdToInclude = roleSpotlightIsSelected ? roleSpotlightInUse?.id : null;
    const chosenIDs = [companyIdToInclude, roleIdToInclude].filter(Boolean);

    const fieldsToUpdate = {
      "Role Title": formData.title || "",
      "Job Description PDF": formData.job_description_link || "",
      "Specific Experience": formData.key_experience || "",
      "Compensation": formData.compensation || "",
      "Working Style": formData.working_style || "",
      "Hiring Lead Name": formData.hiring_lead_name || "",
      "Hiring Lead Email Address": formData.hiring_lead_email || "",
      "Hiring Lead Linkedin": formData.hiring_lead_linkedin || "",
      "Hiring Lead Phone Number": formData.hiring_lead_phone_number || "",
      "Interview Process": formData.interview_process || "",
      "Spotlight Context": formData.spotlight_context || "",
      "Open to applications": "Open to apps", // forced
      "CCs": formData.additionalTeamEmails || "",
      "Hiring Lead Calendly Link": formData.interviewLink || "",
      "Spotlight": chosenIDs,
    };
    const eqVal = formData.equity.trim();
    if (eqVal) {
      fieldsToUpdate["Equity"] = eqVal;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      await axios.patch(
        "/api/roles/updateRoleData",
        { fields: fieldsToUpdate, roleId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      navigate("/roles");
    } catch (err) {
      console.error("Error publishing role:", err);
      setError("Failed to publish role");
    }
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleJobDescriptionButtonClick = () => {
    setIsJobDescriptionModalOpen(true);
  };
  const closeJobDescriptionModal = () => {
    setIsJobDescriptionModalOpen(false);
  };

  const isFormComplete = () => {
    const requiredTextFieldsFilled =
      (formData.title || "").trim() !== "" &&
      (formData.compensation || "").trim() !== "" &&
      (formData.working_style || "").trim() !== "" &&
      (formData.hiring_lead_name || "").trim() !== "" &&
      (formData.hiring_lead_email || "").trim() !== "" &&
      (formData.hiring_lead_linkedin || "").trim() !== "" &&
      (formData.hiring_lead_phone_number || "").trim() !== "";
    const hasJD = (formData.job_description_link || "").trim() !== "";
    // Not strictly forcing a spotlight
    return requiredTextFieldsFilled && (hasJD || true);
  };


  // "Select or Upload" modals
  const openCompanySelectOrUpload = () => {
    // Reset form fields when opening the modal
    setCompanySpotlightName("");
    setCompanySpotlightUrl("");
    setSpotlightToEdit(null);
    setShowSelectOrUploadCompany(true);
  };
  
  const openRoleSelectOrUpload = () => {
    // Reset form fields when opening the modal
    setRoleSpotlightName("");
    setRoleSpotlightUrl("");
    setSpotlightToEdit(null);
    setShowSelectOrUploadRole(true);
  };
  const closeAllSelectOrUploadModals = () => {
    setShowSelectOrUploadCompany(false);
    setShowSelectOrUploadRole(false);
    setShowSelectSpotlightModal(false);
    setAddSpotlightType(null);
    setSpotlightToEdit(null);
  };
  const handleSelectSpotlight = (type) => {
    setSelectSpotlightType(type);
    setShowSelectOrUploadCompany(false);
    setShowSelectOrUploadRole(false);
    setShowSelectSpotlightModal(true);
  };
  const handleUploadSpotlight = (type) => {
    setShowSelectOrUploadCompany(false);
    setShowSelectOrUploadRole(false);
    setAddSpotlightType(type);
    setSpotlightToEdit(null);
    
    // Reset form fields when adding a new spotlight (not editing)
    if (type === "company") {
      setCompanySpotlightName("");
      setCompanySpotlightUrl("");
    } else {
      setRoleSpotlightName("");
      setRoleSpotlightUrl("");
    }
    
    setShowAddSpotlightModal(true);
  };
  const onSelectExistingSpotlight = (sp) => {
    if (selectSpotlightType === "company") {
      setCompanySpotlightInUse(sp);
      setCompanySpotlightIsSelected(true);
    } else {
      setRoleSpotlightInUse(sp);
      setRoleSpotlightIsSelected(true);
    }
    setShowSelectSpotlightModal(false);
    setSelectSpotlightType(null);
  };

  // The "toggle" logic: If user clicks a card that's already in use, just flip the boolean
  const handleCompanyCardClick = (spot) => {
    if (!spot) return;
    if (!companySpotlightInUse || companySpotlightInUse.id !== spot.id) {
      // They clicked a different spotlight
      setCompanySpotlightInUse(spot);
      setCompanySpotlightIsSelected(true);
    } else {
      // They clicked the same spotlight => toggle
      setCompanySpotlightIsSelected((prev) => !prev);
    }
  };

  const handleRoleCardClick = (spot) => {
    if (!spot) return;
    if (!roleSpotlightInUse || roleSpotlightInUse.id !== spot.id) {
      setRoleSpotlightInUse(spot);
      setRoleSpotlightIsSelected(true);
    } else {
      setRoleSpotlightIsSelected((prev) => !prev);
    }
  };

  const handleSpotlightDelete = (spotlightId) => {
    setDeleteSpotlight(spotlightId);
  };

  // Create a new company spotlight directly from the popup
  const handleAddCompanySpotlight = async () => {
    if (!companySpotlightName.trim() || !companySpotlightUrl.trim()) return;
    
    const isLoomUrl = companySpotlightUrl.includes("loom.com");
    
    const newSpotlight = {
      id: Date.now(), // Temporary ID
      Name: companySpotlightName.trim(),
      Type: "Company information",
      "URL Loom cleaned": isLoomUrl ? companySpotlightUrl.trim() : "",
      "URL Dropbox cleaned": !isLoomUrl ? companySpotlightUrl.trim() : "",
    };
    
    // Use the existing handleAddSpotlight function to process the spotlight
    await handleAddSpotlight(newSpotlight);
    
    // Reset form and close modal
    setCompanySpotlightName("");
    setCompanySpotlightUrl("");
    closeAllSelectOrUploadModals();
  };
  
  // Create a new role spotlight directly from the popup
  const handleAddRoleSpotlight = async () => {
    if (!roleSpotlightName.trim() || !roleSpotlightUrl.trim()) return;
    
    const isLoomUrl = roleSpotlightUrl.includes("loom.com");
    
    const newSpotlight = {
      id: Date.now(), // Temporary ID
      Name: roleSpotlightName.trim(),
      Type: "Role information",
      "URL Loom cleaned": isLoomUrl ? roleSpotlightUrl.trim() : "",
      "URL Dropbox cleaned": !isLoomUrl ? roleSpotlightUrl.trim() : "",
    };
    
    // Use the existing handleAddSpotlight function to process the spotlight
    await handleAddSpotlight(newSpotlight);
    
    // Reset form and close modal
    setRoleSpotlightName("");
    setRoleSpotlightUrl("");
    closeAllSelectOrUploadModals();
  };

  // Convert form data to role preview format (not currently used, but left here if needed)
  const getRolePreviewData = () => {
    return {
      title: formData.title,
      job_description_pdf: formData.job_description_link,
      key_experience: formData.key_experience,
      compensation: formData.compensation,
      working_style: formData.working_style,
      hiring_lead_name: formData.hiring_lead_name,
      hiring_lead_email: formData.hiring_lead_email,
      hiring_lead_linkedin: formData.hiring_lead_linkedin,
      interview_process: formData.interview_process,
      spotlight_context: formData.spotlight_context,
      dropbox_url:
        roleSpotlightInUse?.["URL Dropbox cleaned"] ||
        companySpotlightInUse?.["URL Dropbox cleaned"],
      loom_url:
        roleSpotlightInUse?.["URL Loom cleaned"] ||
        companySpotlightInUse?.["URL Loom cleaned"],
    };
  };

  // --------------------- Render ---------------------
  return (
    <div className="flex flex-col pt-20 pl-72 bg-white">
      <div className="max-w-[1000px] w-full flex flex-col bg-white pb-20 pr-8">
        {loading ? (
          <div className="flex justify-center items-center h-[70vh] w-full">
            <img 
              src="/images/coding_logo/Untitled design (3).gif" 
              alt="Loading..." 
              className="w-64 h-64 object-contain"
            />
          </div>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              {/* Header */}
              <div className="border-gray-900/10 pb-12">
                <button
                  type="button"
                  onClick={() => navigate("/roles")}
                  className="mb-4 text-sm text-gray-600 hover:text-gray-900"
                >
                  ← Back to all roles
                </button>

                <h2 className="text-4xl pt-2 font-semibold leading-7 text-gray-900 mb-2">
                  Edit the role:
                  <span className="pl-2 font-semibold" style={{ color: "#02B491" }}>
                    {formData.title}
                  </span>
                </h2>
                <p className="mt-1 text-md leading-6 text-gray-600 mb-8">
                  This information will appear when candidates review your job roles.
                </p>

                {/* --------------------------------------- */}
                {/* 1) Basic Information & Hiring Manager */}
                {/* --------------------------------------- */}
                <div className="grid grid-cols-2 gap-x-8 gap-y-6 mb-10">
                  {/* Left Column: Basic Info */}
                  <div>
                    <h2 className="text-2xl font-light leading-6 text-gray-900 mb-4">
                      Basic Information
                    </h2>
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="title"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Role Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                            focus:ring-2 focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. Senior Software Engineer"
                          value={formData.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="compensation"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Compensation
                        </label>
                        <input
                          type="text"
                          name="compensation"
                          id="compensation"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                            focus:ring-2 focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. £60,000 - £80,000"
                          value={formData.compensation}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="working_style"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Working Style
                        </label>
                        <input
                          type="text"
                          name="working_style"
                          id="working_style"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                            focus:ring-2 focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. Hybrid - 2 days in office"
                          value={formData.working_style}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="equity" className="block text-md font-semibold text-gray-900">
                          Would you be open to offering equity?
                        </label>
                        <select
                          id="equity"
                          name="equity"
                          value={formData.equity}
                          onChange={handleChange}
                          className="bg-transparent mt-2 border border-gray-300 text-gray-900 text-md
                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 p-1.5"
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="TBC">To be determined</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* Right Column: Hiring Manager */}
                  <div>
                    <h2 className="text-2xl font-light leading-6 text-gray-900 mb-4">
                      Hiring Manager Details
                    </h2>
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="hiring_lead_name"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="hiring_lead_name"
                          id="hiring_lead_name"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. John Smith"
                          value={formData.hiring_lead_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="hiring_lead_email"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Email Address
                        </label>
                        <input
                          type="hiring_lead_email"
                          name="hiring_lead_email"
                          id="hiring_lead_email"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. john@company.com"
                          value={formData.hiring_lead_email}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="hiring_lead_linkedin"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          LinkedIn
                        </label>
                        <input
                          type="text"
                          name="hiring_lead_linkedin"
                          id="hiring_lead_linkedin"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-[#02B491]"
                          placeholder="https://www.linkedin.com/in/john-smith/"
                          value={formData.hiring_lead_linkedin}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="hiring_lead_phone_number"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Phone number
                        </label>
                        <input
                          type="text"
                          name="hiring_lead_phone_number"
                          id="hiring_lead_phone_number"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. +44 7700 900000"
                          value={formData.hiring_lead_phone_number}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="additionalTeamEmails"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Additional hiring team emails (optional)
                        </label>
                        <input
                          type="text"
                          name="additionalTeamEmails"
                          id="additionalTeamEmails"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-[#02B491]"
                          placeholder="e.g. sarah@company.com, mike@company.com"
                          value={formData.additionalTeamEmails}
                          onChange={handleChange}
                        />
                        <p className="text-xs text-gray-500 mt-1">
                          Separate multiple emails with commas.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------------------------------------- */}
                {/* 2) Job Description                     */}
                {/* --------------------------------------- */}
                <div className="mb-10">
                  <h2 className="block text-2xl font-light leading-6 text-gray-900 mb-2">
                    Job Description
                  </h2>
                  <p className="flex text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m11.25 11.25.041-.02a.75.75 
                          0 0 1 1.063.852l-.708 2.836a.75.75 
                          0 0 0 1.063.853l.041-.021M21 
                          12a9 9 0 1 1-18 0 9 9 0 0 1 18 
                          0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    For tips on writing a JD click{" "}
                    <span
                      onClick={() =>
                        window.open(
                          "https://jumpstart-alumni.notion.site/Preparing-a-Job-Description-33504887841241d0a52f5a2a9e23e245?pvs=4"
                        )
                      }
                      className="pl-1 text-blue-500 cursor-pointer hover:underline"
                    >
                      here
                    </span>
                    .
                  </p>
                  <div className="mt-4">
                    <label
                      htmlFor="job-description"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Link
                    </label>
                    <div className="mt-1">
                      <div className="relative max-w-4xl flex items-center">
                        <input
                          type="text"
                          name="job_description_link"
                          id="job-description"
                          className="block max-w-[500px] pl-2 pr-2 w-full rounded-md border-0 py-1.5 text-gray-900
                            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="https://www.jumpstart-uk.com/"
                          value={formData.job_description_link}
                          onChange={handleChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 cursor-pointer ml-2"
                          onClick={handleIconClick}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 
                              0L9 9M3.75 20.25v-4.5m0 
                              4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5
                              0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5
                              0v-4.5m0 4.5L15 15"
                          />
                        </svg>
                        <div className="ml-2">
                          <button
                            type="button"
                            onClick={handleJobDescriptionButtonClick}
                            className="px-4 py-2 text-sm hover:bg-[#02B491] bg-white text-[#02B491] border-2 border-[#02B491] hover:text-white rounded-lg"
                          >
                            Upload Job Description
                          </button>
                        </div>
                      </div>
                    </div>
                    {isModalOpen && (
                      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded shadow-lg max-w-3xl w-full h-3/4">
                          <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-bold">Job Description Content</h2>
                            <button
                              onClick={closeModal}
                              className="px-4 py-2 text-black rounded"
                              style={{ backgroundColor: "#02B491" }}
                            >
                              Close
                            </button>
                          </div>
                          <iframe
                            src={formData.job_description_link}
                            title="Job Description Content"
                            className="w-full h-4/5 border rounded"
                            width="80%"
                            height="80%"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* --------------------------------------- */}
                {/* 3) Role Details                         */}
                {/* --------------------------------------- */}
                <div className="mb-10">
                  <h2 className="text-2xl font-light leading-6 text-gray-900 mb-4">
                    Role Details
                  </h2>
                  <div className="space-y-4">
                    <div>
                      <label
                        htmlFor="key_experience"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Key skills or experience
                      </label>
                      <textarea
                        id="key_experience"
                        name="key_experience"
                        rows="5"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                          ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                          focus:ring-inset focus:ring-[#02B491]"
                        placeholder="e.g. - 5+ years of experience with React&#10;- Strong understanding of web accessibility&#10;- Experience leading technical projects"
                        value={formData.key_experience}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div>
                      <label
                        htmlFor="interview_process"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Interview Process
                      </label>
                      <textarea
                        id="interview_process"
                        name="interview_process"
                        rows="5"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                          ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                          focus:ring-2 focus:ring-inset focus:ring-[#02B491]"
                        placeholder="e.g. 1. Initial phone screen&#10;2. Technical interview&#10;3. Final interview"
                        value={formData.interview_process}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div>
                      <label
                        htmlFor="interviewLink"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Interview Link (Calendly or other)
                      </label>
                      <input
                        id="interviewLink"
                        name="interviewLink"
                        type="text"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900
                          ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                          focus:ring-2 focus:ring-inset focus:ring-[#02B491]"
                        placeholder="e.g. calendly.com/johnsmith/interview"
                        value={formData.interviewLink}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                {/* --------------------------------------- */}
                {/* 4) Spotlights Recording Section         */}
                {/* --------------------------------------- */}
                <div className="border rounded-lg bg-white shadow-sm mb-10">
                  {/* Header */}
                  <div className="border-b px-6 py-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-2xl font-light text-gray-900">Spotlight Videos</h2>
                      <a
                        href="https://www.notion.so/jumpstart-alumni/Startup-Spotlight-Best-Practice-18ab8f57dd84806794bdff140e4b4d39?pvs=4"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-[#02B491] hover:underline flex items-center"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-4 w-4 mr-1" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        View spotlight tips
                      </a>
                    </div>
                    <p className="text-gray-600 text-sm mt-2">
                      Roles with spotlight videos receive <span className="font-semibold">50% more engagement</span> and help candidates 
                      self-select—saving everyone time. 
                    </p>
                    <p className="text-gray-600 text-sm mt-1">
                      <a href="https://calendly.com/d/cmhy-wb9-823/record-a-video-with-jumpstart" target="_blank" rel="noopener noreferrer" className="text-[#02B491] hover:underline font-medium">
                        <span className="inline-flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                          </svg>
                          Need help? Book a spotlight call with Jumpstart →
                        </span>
                      </a>
                    </p>
                  </div>

                  {/* Content */}
                  <div className="p-6">
                    {/* Company Spotlight */}
                    <div className="mb-8 border-b pb-8">
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-lg font-medium text-gray-900">Company spotlight</h3>
                            {companySpotlightInUse && companySpotlightIsSelected && (
                              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Selected
                              </span>
                            )}
                            {companySpotlightInUse && !companySpotlightIsSelected && (
                              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                Not Selected
                              </span>
                            )}
                          </div>
                          <p className="text-sm text-gray-600 mt-1">
                            A one-time video showcasing your mission, team, and culture — reusable across all roles.
                          </p>
                        </div>
                      </div>

                      {companySpotlightInUse ? (
                        <>
                          {companySpotlightInUse?.Type === "Company and Role" && (
                            <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-800 text-sm flex items-start">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              <span>
                                <strong>Legacy spotlight:</strong> This video covers both company and role information. For best results, consider creating separate company and role videos.
                              </span>
                            </div>
                          )}
                          <div className="flex flex-col md:flex-row items-center md:items-start mt-4 space-y-4 md:space-y-0 md:space-x-4">
                            <div className="w-full md:w-72">
                              <div className="relative group">
                                <CompactSpotlightCard
                                  spotlight={companySpotlightInUse}
                                  selected={companySpotlightIsSelected}
                                  onSelect={handleCompanyCardClick}
                                  onDelete={() => handleSpotlightDelete(companySpotlightInUse.id)}
                                  setModalType={setModalType}
                                  setSpotlightToEdit={setSpotlightToEdit}
                                  setShowAddSpotlightModal={setShowAddSpotlightModal}
                                  setShowPreviewModal={setShowPreviewModal}
                                  setRoleSpotlightName={setRoleSpotlightName}
                                  setRoleSpotlightUrl={setRoleSpotlightUrl}
                                  setShowSelectOrUploadRole={setShowSelectOrUploadRole}
                                  setCompanySpotlightName={setCompanySpotlightName}
                                  setCompanySpotlightUrl={setCompanySpotlightUrl}
                                  setShowSelectOrUploadCompany={setShowSelectOrUploadCompany}
                                />
                                <div className="mt-2 flex space-x-2">
                                  <button
                                    onClick={() => {
                                      setModalType("company");
                                      setSpotlightToEdit(companySpotlightInUse);
                                      setShowPreviewModal(true);
                                    }}
                                    className="flex-1 px-3 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center justify-center space-x-2 transition-colors"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5 text-gray-700"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 
                                        2.943 9.542 7c-1.274 4.057-5.064 7-9.542 
                                        7S1.732 14.057.458 10zM14 10a4 4 0 11-8 
                                        0 4 4 0 018 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <span className="font-medium text-gray-700">Preview</span>
                                  </button>
                                  <button
                                    onClick={() => {
                                      setCompanySpotlightIsSelected(!companySpotlightIsSelected);
                                    }}
                                    className={`flex-1 px-3 py-2 border rounded-lg flex items-center justify-center space-x-2 transition-colors ${
                                      companySpotlightIsSelected 
                                        ? "bg-[#02B491] text-white border-[#02B491] hover:bg-[#029478]" 
                                        : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                                    }`}
                                  >
                                    {companySpotlightIsSelected ? (
                                      <>
                                        <svg 
                                          xmlns="http://www.w3.org/2000/svg" 
                                          className="h-5 w-5" 
                                          fill="none" 
                                          viewBox="0 0 24 24" 
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                          />
                                        </svg>
                                        <span className="font-medium">Selected</span>
                                      </>
                                    ) : (
                                      <>
                                        <svg 
                                          xmlns="http://www.w3.org/2000/svg" 
                                          className="h-5 w-5" 
                                          fill="none" 
                                          viewBox="0 0 24 24" 
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                          />
                                        </svg>
                                        <span className="font-medium">Select</span>
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={openCompanySelectOrUpload}
                              className="w-full md:w-72 aspect-video rounded-lg border-2 border-dashed border-[#02B491] 
                                flex flex-col items-center justify-center cursor-pointer bg-white 
                                hover:bg-gray-50 transition-colors duration-200"
                            >
                              <div className="flex flex-col items-center text-[#02B491] p-4">
                                <span className="text-4xl font-light mb-2">+</span>
                                <span className="text-sm font-medium text-center">Browse or Upload New Company Video</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="mt-4">
                          <div className="w-full p-8 rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 flex flex-col items-center justify-center">
                

                        
            
             
                            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                              <button
                                onClick={() => handleSelectSpotlight("company")}
                                className="px-5 py-2.5 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center justify-center space-x-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 
                                    16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 
                                    2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>Browse Existing Videos</span>
                              </button>
                              <button
                                onClick={() => handleUploadSpotlight("company")}
                                className="px-5 py-2.5 bg-[#02B491] text-white rounded-lg hover:bg-[#029478] flex items-center justify-center space-x-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M3 17a1 1 0 011-1h12a1 1 0 
                                    110 2H4a1 1 0 01-1-1zM6.293 
                                    6.707a1 1 0 010-1.414l3-3a1 
                                    1 0 011.414 0l3 3a1 1 0 
                                    01-1.414 1.414L11 5.414V13a1 
                                    1 0 11-2 0V5.414L7.707 6.707a1 
                                    1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>Upload New Video</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Role Spotlight */}
                    <div className="pt-2">
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-lg font-medium text-gray-900">Role spotlight</h3>
                            {roleSpotlightInUse && roleSpotlightIsSelected && (
                              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Selected
                              </span>
                            )}
                            {roleSpotlightInUse && !roleSpotlightIsSelected && (
                              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                Not Selected
                              </span>
                            )}
                          </div>
                          <p className="text-sm text-gray-600 mt-1">
                            A short video specifically about this role, highlighting key responsibilities and what makes it exciting.
                          </p>
                        </div>
                      </div>

                      {roleSpotlightInUse ? (
                        <div className="flex flex-col md:flex-row items-center md:items-start mt-4 space-y-4 md:space-y-0 md:space-x-4">
                          <div className="w-full md:w-72">
                            <div className="relative group">
                              <CompactSpotlightCard
                                spotlight={roleSpotlightInUse}
                                selected={roleSpotlightIsSelected}
                                onSelect={handleRoleCardClick}
                                onDelete={() => handleSpotlightDelete(roleSpotlightInUse.id)}
                                setModalType={setModalType}
                                setSpotlightToEdit={setSpotlightToEdit}
                                setShowAddSpotlightModal={setShowAddSpotlightModal}
                                setShowPreviewModal={setShowPreviewModal}
                                setRoleSpotlightName={setRoleSpotlightName}
                                setRoleSpotlightUrl={setRoleSpotlightUrl}
                                setShowSelectOrUploadRole={setShowSelectOrUploadRole}
                                setCompanySpotlightName={setCompanySpotlightName}
                                setCompanySpotlightUrl={setCompanySpotlightUrl}
                                setShowSelectOrUploadCompany={setShowSelectOrUploadCompany}
                              />
                              <div className="mt-2 flex space-x-2">
                                <button
                                  onClick={() => {
                                    setModalType("role");
                                    setSpotlightToEdit(roleSpotlightInUse);
                                    setShowPreviewModal(true);
                                  }}
                                  className="flex-1 px-3 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center justify-center space-x-2 transition-colors"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 text-gray-700"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M.458 10C1.732 5.943 5.522 3 
                                      10 3s8.268 2.943 9.542 7c-1.274 
                                      4.057-5.064 7-9.542 7S1.732 
                                      14.057.458 10zM14 10a4 4 0 
                                      11-8 0 4 4 0 018 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="font-medium text-gray-700">Preview</span>
                                </button>
                                <button
                                  onClick={() => {
                                    setRoleSpotlightIsSelected(!roleSpotlightIsSelected);
                                  }}
                                  className={`flex-1 px-3 py-2 border rounded-lg flex items-center justify-center space-x-2 transition-colors ${
                                    roleSpotlightIsSelected 
                                      ? "bg-[#02B491] text-white border-[#02B491] hover:bg-[#029478]" 
                                      : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                                  }`}
                                >
                                  {roleSpotlightIsSelected ? (
                                    <>
                                      <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      <span className="font-medium">Selected</span>
                                    </>
                                  ) : (
                                    <>
                                      <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-5 w-5" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                      </svg>
                                      <span className="font-medium">Select</span>
                                    </>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={openRoleSelectOrUpload}
                            className="w-full md:w-72 aspect-video rounded-lg border-2 border-dashed border-[#02B491] 
                              flex flex-col items-center justify-center cursor-pointer bg-white 
                              hover:bg-gray-50 transition-colors duration-200"
                          >
                            <div className="flex flex-col items-center text-[#02B491] p-4">
                              <span className="text-4xl font-light mb-2">+</span>
                              <span className="text-sm font-medium text-center">Browse or Upload New Role Video</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4">
                          <div className="w-full p-8 rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 flex flex-col items-center justify-center">
                            <div className="mb-4">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="h-12 w-12 text-gray-400" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1.5}
                                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                />
                              </svg>
                            </div>
                            <p className="text-gray-700 font-medium mb-2 text-center">
                              Tell candidates about this role
                            </p>
               
                            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                              <button
                                onClick={() => handleSelectSpotlight("role")}
                                className="px-5 py-2.5 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center justify-center space-x-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 
                                    16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 
                                    2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>Browse Existing Videos</span>
                              </button>
                              <button
                                onClick={() => handleUploadSpotlight("role")}
                                className="px-5 py-2.5 bg-[#02B491] text-white rounded-lg hover:bg-[#029478] flex items-center justify-center space-x-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M3 17a1 1 0 011-1h12a1 1 0 
                                    110 2H4a1 1 0 01-1-1zM6.293 
                                    6.707a1 1 0 010-1.414l3-3a1 
                                    1 0 011.414 0l3 3a1 1 0 
                                    01-1.414 1.414L11 5.414V13a1 
                                    1 0 11-2 0V5.414L7.707 6.707a1 
                                    1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>Upload New Video</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Additional context if at least 1 is in use */}
                    {(companySpotlightInUse || roleSpotlightInUse) && (
                      <div className="mt-8 pt-8 border-t">
                        <label
                          htmlFor="spotlight_context"
                          className="block text-md font-medium text-gray-900 mb-2"
                        >
                          Additional context for candidates
                        </label>
                        <textarea
                          id="spotlight_context"
                          name="spotlight_context"
                          rows="2"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 
                            text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                            focus:ring-2 focus:ring-inset focus:ring-[#02B491] text-sm"
                          placeholder="e.g. This recording was made for our product team, but gives a good overview of our company culture. Skip to 2:45 to see our office."
                          value={formData.spotlight_context}
                          onChange={handleChange}
                        ></textarea>
                        <p className="text-xs text-gray-500 mt-2">
                          This note will appear below the spotlight videos to provide additional context to candidates.
                        </p>
                      </div>
                    )}

                    {/* Quick guidance */}


                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex items-center justify-start gap-x-6">
              <button
                type="submit"
                disabled={isSaving}
                className={`relative px-6 py-2 mb-4 text-md bg-white hover:bg-gray-100 text-[#02B491] border-2 border-[#02B491] rounded-lg font-medium ${isSaving ? 'cursor-not-allowed opacity-75' : ''}`}
              >
                <span className={`${isSaving ? 'invisible' : ''}`}>Save</span>
                {isSaving && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-[#02B491]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                )}
              </button>
              {showSaveSuccess && (
                <div className="fixed bottom-8 right-8 bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded-lg shadow-lg flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 
                        000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 
                        10.586 7.707 9.293a1 1 0 
                        00-1.414 1.414l2 2a1 1 0 
                        001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Changes saved successfully
                </div>
              )}
              <button
                type="button"
                onClick={() => {
                  if (urlId) {
                    window.open(`/role?roleId=${urlId}`, "_blank");
                  } else {
                    console.error("URL ID not found for role");
                  }
                }}
                className="px-6 py-2 mb-4 text-md bg-white hover:bg-gray-100 text-gray-700 border-2 border-gray-300 rounded-lg font-medium"
              >
                Preview
              </button>
              <button
                type="button"
                onClick={() => {
                  if (isFormComplete()) {
                    setSelectedRoleToggle(true);
                  }
                }}
                className={`relative px-6 py-2 mb-4 text-md text-white rounded-lg transition-all ${
                  isFormComplete()
                  ? "bg-[#02B491] hover:bg-[#029478] cursor-pointer border-2 border-[#02B491] group"
                  : "bg-gray-300 cursor-not-allowed"
                }`}
              >
                <div className="flex items-center justify-center gap-2">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="w-5 h-5 group-active:animate-rocket"
                  >
                    <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path>
                    <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path>
                    <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path>
                    <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path>
                  </svg>
                  <span>Publish</span>
                </div>
              </button>
            </div>
          </form>
        )}
      </div>

      {/* Job Description Modal */}
      {isJobDescriptionModalOpen && (
        <EditJobDescriptionModal
          onClose={closeJobDescriptionModal}
          formData={formData}
          setFormData={setFormData}
          setFile={setFile}
          file={file}
          onFileUpload={(url) => {
            setFormData({ ...formData, job_description_link: url });
            closeJobDescriptionModal();
          }}
        />
      )}

      {/* "Select or Upload" popup for Company */}
      {showSelectOrUploadCompany && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
          onClick={closeAllSelectOrUploadModals}
        >
          <div
            className="bg-white p-6 rounded-lg max-w-lg w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeAllSelectOrUploadModals}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-6 w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M6 18L18 6M6 6l12 12" 
                />
              </svg>
            </button>
            <h3 className="text-xl font-semibold mb-5">Add Company Spotlight</h3>
            
            <div className="space-y-4">
              {/* Browse Button */}
              <button
                className="w-full p-3 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center"
                onClick={() => handleSelectSpotlight("company")}
              >
                <div className="rounded-full bg-[#02B491]/10 p-1 mr-2 flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#02B491]"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                    />
                  </svg>
                </div>
                <span className="font-medium">Browse existing videos</span>
              </button>
              
              {/* OR Separator */}
              <div className="flex items-center justify-center my-2">
                <div className="border-t border-gray-200 flex-grow"></div>
                <span className="px-3 text-sm text-gray-500 font-medium">OR</span>
                <div className="border-t border-gray-200 flex-grow"></div>
              </div>
              
              {/* Upload new title */}
              <h4 className="text-md font-bold text-gray-700 mb-2">Upload new</h4>
              
              {/* Video Title field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Video Title</label>
                <input
                  type="text"
                  value={companySpotlightName || ""}
                  onChange={(e) => setCompanySpotlightName(e.target.value)}
                  placeholder="e.g. About Our Company Culture"
                  className="block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent"
                />
              </div>
              
              {/* URL Input field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Video URL (Loom or Dropbox)
                </label>
                <input
                  type="url"
                  value={companySpotlightUrl || ""}
                  onChange={(e) => setCompanySpotlightUrl(e.target.value)}
                  placeholder="https://www.loom.com/share/... or https://www.dropbox.com/s/..."
                  className="block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent"
                />
              </div>
              
              {/* Recording Tips Link */}
              <div className="text-center">
                <a
                  href="https://www.notion.so/jumpstart-alumni/Startup-Spotlight-Best-Practice-18ab8f57dd84806794bdff140e4b4d39?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-[#02B491] hover:underline inline-block"
                >
                  View recording tips →
                </a>
              </div>
              
              {/* Submit button */}
              <div>
                <button
                  onClick={handleAddCompanySpotlight}
                  disabled={!companySpotlightName || !companySpotlightUrl}
                  className={`w-full py-3 bg-[#02B491] text-white rounded-lg hover:bg-[#029478] flex items-center justify-center font-medium 
                    ${(!companySpotlightName || !companySpotlightUrl) ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Add Spotlight
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* "Select or Upload" popup for Role */}
      {showSelectOrUploadRole && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
          onClick={closeAllSelectOrUploadModals}
        >
          <div
            className="bg-white p-6 rounded-lg max-w-lg w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeAllSelectOrUploadModals}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-6 w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M6 18L18 6M6 6l12 12" 
                />
              </svg>
            </button>
            <h3 className="text-xl font-semibold mb-5">Add Role Spotlight</h3>
            
            <div className="space-y-4">
              {/* Browse Button */}
              <button
                className="w-full p-3 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center"
                onClick={() => handleSelectSpotlight("role")}
              >
                <div className="rounded-full bg-[#02B491]/10 p-1 mr-2 flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#02B491]"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                    />
                  </svg>
                </div>
                <span className="font-medium">Browse existing videos</span>
              </button>
              
              {/* OR Separator */}
              <div className="flex items-center justify-center my-2">
                <div className="border-t border-gray-200 flex-grow"></div>
                <span className="px-3 text-sm text-gray-500 font-medium">OR</span>
                <div className="border-t border-gray-200 flex-grow"></div>
              </div>
              
              {/* Upload new title */}
              <h4 className="text-md font-bold text-gray-700 mb-2">Upload new</h4>
              
              {/* Video Title field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Video Title</label>
                <input
                  type="text"
                  value={roleSpotlightName || ""}
                  onChange={(e) => setRoleSpotlightName(e.target.value)}
                  placeholder="e.g. Software Engineer Role Overview"
                  className="block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent"
                />
              </div>
              
              {/* URL Input field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Video URL (Loom or Dropbox)
                </label>
                <input
                  type="url"
                  value={roleSpotlightUrl || ""}
                  onChange={(e) => setRoleSpotlightUrl(e.target.value)}
                  placeholder="https://www.loom.com/share/... or https://www.dropbox.com/s/..."
                  className="block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#02B491] focus:border-transparent"
                />
              </div>
              
              {/* Recording Tips Link */}
              <div className="text-center">
                <a
                  href="https://www.notion.so/jumpstart-alumni/Startup-Spotlight-Best-Practice-18ab8f57dd84806794bdff140e4b4d39?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-[#02B491] hover:underline inline-block"
                >
                  View recording tips →
                </a>
              </div>
              
              {/* Submit button */}
              <div>
                <button
                  onClick={handleAddRoleSpotlight}
                  disabled={!roleSpotlightName || !roleSpotlightUrl}
                  className={`w-full p-3 rounded-lg font-medium ${
                    roleSpotlightName && roleSpotlightUrl
                      ? "bg-[#02B491] text-white hover:bg-[#029478]"
                      : "bg-gray-200 text-gray-500 cursor-not-allowed"
                  }`}
                >
                  Add Spotlight
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Select Existing Spotlight Modal */}
      <SelectExistingSpotlightModal
        isOpen={showSelectSpotlightModal}
        onClose={closeAllSelectOrUploadModals}
        spotlights={
          selectSpotlightType === "company"
            ? companySpotlights
            : selectSpotlightType === "role"
            ? roleSpotlights
            : []
        }
        currentlySelectedId={
          selectSpotlightType === "company"
            ? companySpotlightInUse?.id
            : roleSpotlightInUse?.id
        }
        onSelect={onSelectExistingSpotlight}
        setModalType={setModalType}
        setSpotlightToEdit={setSpotlightToEdit}
        setShowAddSpotlightModal={setShowAddSpotlightModal}
      />

      {/* Add/Edit Spotlight Modal */}
      <AddSpotlightModal
        isOpen={showAddSpotlightModal}
        onClose={() => {
          setShowAddSpotlightModal(false);
          setSpotlightToEdit(null);
          setAddSpotlightType(null);
        }}
        type={addSpotlightType}
        onAddSpotlight={handleAddSpotlight}
        spotlightToEdit={spotlightToEdit}
      />

      {/* Preview Spotlight Modal */}
      <PreviewSpotlightModal
        isOpen={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        spotlight={spotlightToEdit}
        onEdit={() => {
          setShowPreviewModal(false);
          // Determine the spotlight type
          const type = spotlightToEdit.Type === "Role information" ? "role" : "company";
          setModalType(type);
          
          // Set up the name and URL fields based on the spotlight type
          if (type === "company") {
            setCompanySpotlightName(spotlightToEdit.Name || "");
            setCompanySpotlightUrl(spotlightToEdit["URL Loom"] || spotlightToEdit["URL Dropbox"] || "");
          } else {
            setRoleSpotlightName(spotlightToEdit.Name || "");
            setRoleSpotlightUrl(spotlightToEdit["URL Loom"] || spotlightToEdit["URL Dropbox"] || "");
          }
          
          // Open the appropriate modal directly
          if (type === "company") {
            setShowSelectOrUploadCompany(true);
          } else {
            setShowSelectOrUploadRole(true);
          }
        }}
        onDelete={() => {
          setShowPreviewModal(false);
          setDeleteSpotlight(spotlightToEdit);
        }}
      />

      {/* Publish Confirmation Modal */}
      {selectedRoleToggle && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          <div className="fixed inset-0 flex justify-center items-center z-50">
            <div className="relative p-4 w-full max-w-3xl">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  onClick={() => setSelectedRoleToggle(null)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent 
                    hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex 
                    justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-4 md:p-5 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mx-auto mb-4 text-gray-400 w-12 h-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21.75 6.75v10.5a2.25 2.25 
                        0 0 1-2.25 2.25h-15a2.25 
                        2.25 0 0 1-2.25-2.25V6.75m19.5 
                        0A2.25 2.25 0 0 0 19.5 
                        4.5h-15a2.25 2.25 0 0 0-2.25 
                        2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 
                        1.916l-7.5 4.615a2.25 
                        2.25 
                        0 0 1-2.36 0L3.32 8.91a2.25 
                        2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure? Once published, we'll share applicants with you on {getNextFortnightlyMonday()}.
                  </h3>
                  <button
                    onClick={async () => {
                      await handlePublish();
                      setSelectedRoleToggle(null);
                    }}
                    type="button"
                    className="text-white bg-green-500 hover:bg-green-700 
                      focus:ring-4 focus:outline-none focus:ring-green-300 font-medium 
                      rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={() => setSelectedRoleToggle(null)}
                    type="button"
                    className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 
                      focus:outline-none bg-white rounded-lg border border-gray-200 
                      hover:bg-gray-100 hover:text-gray-700"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EditRole;
