import linkedin from "../../images/linkedin-logo.png";
import React, { useState } from "react";
import axios from "axios";
import Loading from "../../images/loading-black.svg";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import Office from "../../images/office-icon.png";
import Languages from "../../images/languages-icon.png";
import VideoIntro from "../../images/video-icon-green.png";

function CandidateCard({
  candidate,
  setInterviewNeedUpdate,
  setShowPopup,
  setShowFinalPopup,
  candidatesRequest,
  setCandidatesRequest,
}) {
  const languageLogos = {
    JavaScript: "/images/coding_logo/javascript.png",
    Python: "/images/coding_logo/python.png",
    C: "/images/coding_logo/c.png",
    Swift: "/images/coding_logo/swift.png",
    "C#": "/images/coding_logo/csharp.png",
    "C++": "/images/coding_logo/cplusplus.png", // For C++
    Java: "/images/coding_logo/java.svg",
    Go: "/images/coding_logo/go.png",
    PHP: "/images/coding_logo/php.svg",
    Ruby: "/images/coding_logo/ruby.webp",
    Kotlin: "/images/coding_logo/kotlin.png",
    Rust: "/images/coding_logo/rust.png",

    // Newly added languages
    Clojure: "/images/coding_logo/clojure.png",
    Haskell: "/images/coding_logo/haskell.png",
    Dart: "/images/coding_logo/dart.png",
    Elixir: "/images/coding_logo/elixir.webp",
    "Objective-C": "/images/coding_logo/objc.svg",
    Perl: "/images/coding_logo/perl.png",
    Scala: "/images/coding_logo/scala.png",
    Typescript: "/images/coding_logo/typescript.png",
    React: "/images/coding_logo/react.png",
    Vue: "/images/coding_logo/vue.png",
    Angular: "/images/coding_logo/angular.png",
    "Next.js": "/images/coding_logo/next-js.svg",
    MATLAB: "/images/coding_logo/matlab.png"

  };

  // Define the color classes based on the stage
  const getColorClasses = (stage) => {
    switch (stage) {
      case "Match requested":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
        };
      case "Application pending":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
        };
      case "Being interviewed":
        return {
          bgColor: "bg-green-100",
          textColor: "text-green-700",
          workBgColor: "bg-green-50",
          fillColor: "fill-green-700",
        };
      case "Interviewing":
        return {
          bgColor: "bg-green-100",
          textColor: "text-green-700",
          workBgColor: "bg-green-50",
          fillColor: "fill-green-700",
        };
      case "Offer made":
        return {
          bgColor: "bg-green-100",
          textColor: "text-green-700",
          workBgColor: "bg-green-50",
          fillColor: "fill-green-700",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
        };
    }
  };

  const { bgColor, textColor, workBgColor, fillColor } = getColorClasses(
    candidate.stage
  );

  const [withdrawnMenuVisible, setWithdrawnMenuVisible] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (stage) => {
    setWithdrawnMenuVisible(false); // Hide dropdown

    const updatedData = {
      fields: {
        Stage: stage,
        "Updated by startup": true,
      },
      interviewId: candidate.interviewId,
    };
    setError(""); 
    setLoading(true);
    try {
      const response = await axios.patch(
        `/api/interviews/updateInterviewRequest`,
        updatedData
      );

      console.log(response);

      setInterviewNeedUpdate(true);
      if (stage === "Interviewing (application)") {
        setShowPopup(true); // Show popup if you want
      }

      // Reduce candidatesRequest by one
      setCandidatesRequest((prev) => {
        const updatedCount = prev - 1;
        if (updatedCount === 0) {
          setShowFinalPopup(true);
        }
        return updatedCount;
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const previewText =
    candidate.rationale?.split(" ").slice(0, 10).join(" ") + "...";

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";

  const workingStyleMapping = Array.isArray(candidate.working_style)
    ? candidate.working_style.map((style) => {
        if (style === "5 days a week in office") {
          return "Fully in office";
        } else if (style === "Hybrid (1-4 days in office)") {
          return "Hybrid";
        } else if (style === "Fully remote") {
          return "Fully remote";
        }
        return style;
      })
    : [];

  // Add formatDuration helper function
  const formatDuration = (duration) => {
    if (!duration) return "";
    
    const years = parseInt(duration.years) || 0;
    const months = parseInt(duration.months) || 0;
    
    const yearText = years === 1 ? "yr" : "yrs";
    const monthText = months === 1 ? "mo" : "mos";
    
    if (years && months) {
      return `${years} ${yearText}, ${months} ${monthText}`;
    } else if (years) {
      return `${years} ${yearText}`;
    } else if (months) {
      return `${months} ${monthText}`;
    }
    return "";
  };

  return (
    <div className="relative max-w-xl mx-auto mt-5 pt-4 pr-4 pl-4 bg-white rounded-lg overflow-hidden flex flex-col h-full hover:drop-shadow-lg transition duration-400">
      <a
        href={`candidate/${candidate.url_id}`}
        onClick={(e) => {
          e.preventDefault();
          // Store the current page before opening the candidate details in a new tab
          sessionStorage.setItem("lastVisitedPage", window.location.pathname);
          window.open(`candidate/${candidate.url_id}`, "_blank");
        }}
      >
        <div className="relative">
          <img
            className="w-full h-72 object-cover rounded-lg"
            src={candidate.image || fallbackImageUrl}
            alt="Candidate"
            onError={(e) => {
              e.target.src = fallbackImageUrl;
            }}
          />
          <span
            className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            {candidate.stage === "Interviewing" ? (
              <>
                Interviewing for <strong>{candidate.role_title}</strong>
              </>
            ) : candidate.stage === "Application pending" ? (
              <>
                Applied to <strong>{candidate.role_title}</strong>
              </>
            ) : (
              candidate.stage
            )}
          </span>
        </div>
      </a>
      <div className="pt-2 flex flex-col flex-grow">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900">
              {candidate.name}
            </h5>
            {candidate.linkedin && (
              <img
                src={linkedin}
                alt={candidate.name}
                title="Linkedin icon"
                className="h-5 w-5 ml-2 rounded-sm"
                onClick={(e) => {
                  e.preventDefault();
                  let url = candidate.linkedin;
                  if (!url.startsWith("https://")) {
                    url = "https://" + url;
                  }
                  window.open(url, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.cv && (
              <img
                src={cv}
                alt={candidate.cv}
                title="CV icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.cv, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.video && (
              <img
                src={VideoIntro}
                alt={candidate.video}
                title="Video Intro icon"
                className="h-6 w-6 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.video, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.github && (
              <img
                src={github}
                alt={candidate.github}
                title="Github icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.github, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />         
                 )}
              {candidate.portfolio && (
                <svg
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(candidate.portfolio, "_blank");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 cursor-pointer ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                  />
                </svg>
            )}
                      </div>
          <div className="flex flex-col text-right text-sm">
            Expected Salary Range:
            {candidate.minimum_salary && (
              <h5 className="text-sm font-semibold tracking-tight text-gray-900">
                {candidate.minimum_salary} - {candidate.ideal_salary}
                {candidate.commission_expectation &&
                  ` + ${candidate.commission_expectation} commission`}
              </h5>
            )}
          </div>
        </div>

        <div className="mt-2">
          {candidate.roles &&
            candidate.roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-sm font-medium ${bgColor} ${textColor}`}
              >
                {role}
              </span>
            ))}
          {candidate.sales && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.sales}
            </span>
          )}
          {candidate.swe_roles &&
            candidate.swe_roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-sm font-medium ${bgColor} ${textColor}`}
              >
                {role}
              </span>
            ))}
          {candidate.time_coding && (
            <span
              className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.time_coding} of coding exp
            </span>
          )}       
           </div>

        <div className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md text-black text-sm md:text-md font-medium flex gap-x-2">
          {candidate.coding_languages &&
            candidate.coding_languages.map((language, index) => (
              <img
                key={index}
                src={
                  languageLogos[language.trim()] || "path/to/default-logo.png"
                }
                alt={language}
                className="h-6 w-6"
              />
            ))}
        </div>

        <div className="flex pb-2 pt-1">
          {candidate.working_style &&
            Array.isArray(candidate.working_style) &&
            workingStyleMapping.length > 0 && (
              <p className="pr-5 font-normal flex text-sm text-black items-center">
                <img className="h-6 pr-1" src={Office} alt="Office icon" />
                {workingStyleMapping.join(" or ")}
              </p>
            )}
          {candidate.languages &&
            Array.isArray(candidate.languages) &&
            candidate.languages.some((language) => language !== "N/A") && (
              <p className="font-normal flex text-sm text-black items-center">
                <img
                  className="h-6 pr-2"
                  src={Languages}
                  alt="Languages icon"
                />
                {candidate.languages
                  .filter((language) => language !== "N/A")
                  .join(", ")}
              </p>
            )}
        </div>

        {candidate.undergrad_degree_type && (
          <p className="text-sm">
            <span className="font-semibold">
              {candidate.undergrad_degree_type}{" "}
              {candidate.undergrad_degree_subject}
            </span>
            {candidate.undergrad_university && " @ "}
            {candidate.undergrad_university} ({candidate.undergrad_result}
            {candidate.undergrad_result &&
              candidate.undergrad_graduation_year &&
              " - "}
            {candidate.undergrad_graduation_year})
          </p>
        )}

        {candidate.postgrad_degree_type && (
          <p className="text-sm pt-2">
            <span className="font-semibold">
              {candidate.postgrad_degree_type}{" "}
              {candidate.postgrad_degree_subject}
            </span>
            {candidate.postgrad_university && " @ "}
            {candidate.postgrad_university} ({candidate.postgrad_result}
            {candidate.postgrad_result &&
              candidate.postgrad_graduation_year &&
              " - "}
            {candidate.postgrad_graduation_year})
          </p>
        )}

        <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
          {candidate.recentTarget && (
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Recent Target Achieved:
              </span>{" "}
              {candidate.recentTarget}
            </div>
          )}
        {candidate.work_experience_1_company && (
          <>
            <p className="text-sm pt-2">
              <span className="font-semibold">
                {candidate.work_experience_1_role}
              </span>{" "}
              @ {candidate.work_experience_1_company}
              {candidate.work_experience_1_duration && 
                ` (${formatDuration(candidate.work_experience_1_duration)})`}
            </p>
            <p className="text-gray-500 text-sm">
              {candidate.work_experience_1_description}
            </p>
          </>
        )}
        {candidate.work_experience_2_company && (
          <>
            <p className="text-sm pt-2">
              <span className="font-semibold">
                {candidate.work_experience_2_role}
              </span>{" "}
              @ {candidate.work_experience_2_company}
              {candidate.work_experience_2_duration && 
                ` (${formatDuration(candidate.work_experience_2_duration)})`}
            </p>
            <p className="text-gray-500 text-sm">
              {candidate.work_experience_2_description}
            </p>
          </>
        )}
      </div>
        {candidate.description && (
          <p className="mt-2 font-normal text-sm text-gray-700">
            <span className="font-semibold text-black">Most proud of: </span>
            {candidate.description}
          </p>
        )}

        {candidate.rationale && (
          <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Application Rationale:
              </span>{" "}
              {isExpanded ? candidate.rationale : previewText}
              <button
                onClick={handleToggle}
                className="text-blue-500 ml-2 underline"
              >
                {isExpanded ? "Show Less" : "Read More"}
              </button>
            </div>
          </div>
        )}

        {candidate.stage === "Application pending" && (
          <div className="flex mt-auto mb-4 w-full">
            {loading ? (
              <div className="flex justify-center items-center w-full h-12 bg-gray-100 rounded-lg">
                <img src={Loading} className="h-6" alt="Loading" />
              </div>
            ) : (
              <>
                <button
                  onClick={() => handleSubmit("Interviewing")}
                  className="px-2 py-2 w-full flex-grow text-sm mr-1 text-black flex rounded-lg font-medium transform hover:bg-white bg-[#02B491] hover:text-black text-white transition duration-400 border-2 border-[#02B491]"
                >
                  Accept Interview Request
                </button>

                <button
                  onClick={() => handleSubmit("Rejected @ CV")}
                  className="px-4 py-2 w-full flex-grow text-sm ml-1 text-black flex rounded-lg font-medium transform hover:bg-white bg-gray-500 hover:text-black text-white transition duration-400 border-2 border-gray-500"
                >
                  Decline Interview Request
                </button>
              </>
            )}
          </div>
        )}

        {candidate.stage === "Interviewing" && (
          <div className="w-full mt-auto mb-4 flex gap-2">
            <button
              onClick={() => setWithdrawnMenuVisible(!withdrawnMenuVisible)}
              className="flex-grow rounded-md py-2 px-auto hover:bg-gray-500 border-2 border-gray-500 text-black hover:text-white"
            >
              Withdrawn
            </button>
            <button
              onClick={(e) => handleSubmit("Rejected @ Interview")}
              className="flex-grow rounded-md py-2 px-auto hover:bg-red-500 border-2 border-red-500 text-black hover:text-white"
            >
              Rejected
            </button>
            <button
              onClick={(e) => handleSubmit("Offer made")}
              className="flex-grow rounded-md py-2 px-auto bg-[#02B491] border-2 border-[#02B491] text-white hover:bg-white hover:text-black"
            >
              Offer made!
            </button>
          </div>
        )}
        {candidate.stage === "Interviewing" && (
          <p className="flex mb-4 -mt-2 text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6 pr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 
                  1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
            No emails will be sent to the candidate
          </p>
        )}
        {withdrawnMenuVisible && (
          <div className="absolute bottom-16 bg-white shadow-md rounded-md mt-2 z-10">
            <button
              onClick={() => handleSubmit("Candidate withdrawn")}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              Candidate withdrawn
            </button>
            <button
              onClick={() => handleSubmit("Startup withdrawn")}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              Startup withdrawn
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CandidateCard;
