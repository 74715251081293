// frontend/src/pages/roles2/roles2.js

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import CalendlyWidget from "../../components/calendly/calendly";
import RoleDropCountdown from "../../components/countdown";

function Roles2() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [spotlights, setSpotlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null); // For delete confirmation modal
  const [hoveredRoleId, setHoveredRoleId] = useState(null);
  const [hoveredEditIconId, setHoveredEditIconId] = useState(null);
  const [selectedRoleToggle, setSelectedRoleToggle] = useState(null);
  const [isTogglingOpen, setIsTogglingOpen] = useState(false); // Track if the toggle is to open or close

  // Function to handle toggle confirmation
  const confirmToggleStatus = () => {
    if (selectedRoleToggle) {
      toggleStatus(selectedRoleToggle.id, selectedRoleToggle.status); // Call toggle function with role ID and current status
      setSelectedRoleToggle(null); // Close modal
    }
  };
  const [nextDate, setNextDate] = useState("");

  useEffect(() => {
    const getNextFortnightlyMonday = () => {
      // Starting date: Monday, March 10th, 2025
      const startDate = new Date(2025, 2, 10); // Month is 0-indexed (2 = March)
      const today = new Date();
      
      // If today is before our start date, just return the start date
      if (today < startDate) {
        return formatDate(startDate);
      }
      
      // Calculate days since start date
      const daysSinceStart = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
      // Calculate how many complete fortnights have passed
      const completedFortnights = Math.floor(daysSinceStart / 14);
      // Calculate the next fortnightly Monday
      const nextMonday = new Date(startDate);
      nextMonday.setDate(startDate.getDate() + (completedFortnights + 1) * 14);
      
      return formatDate(nextMonday);
    };
  
    // Helper function to format the date with proper ordinal suffix
  // Helper function to format the date with a shorter format
const formatDate = (date) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  const dayName = days[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  
  return `${dayName}, ${month} ${day}`;
};
  
    setNextDate(getNextFortnightlyMonday());
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;
        const transformedData = roles.map((role) => {
          const createdDate = new Date(role["Role creation date"]);
          const createdMonthYear = createdDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          return {
            id: role["id"],
            createdTime: role["Role creation date"],
            createdMonthYear: createdMonthYear,
            equity: role["Equity"],
            role_title: role["Role Title"],
            hiring_lead_name: role["Hiring Lead Name"],
            hiring_lead_email: role["Hiring Lead Email Address"],
            hiring_lead_linkedin: role["Hiring Lead Linkedin"],
            key_experience: role["Specific Experience"],
            job_description_link: role["Job Description PDF"],
            startup: role["Startup"],
            compensation: role["Compensation"],
            interviewProcess: role["Interview Process"],
            working_style: role["Working Style"],
            spotlight: role["Spotlight"],
            url_id: role["URL ID"],
            status:
              role["Open to applications"] === "Open to apps" ? true : false,
          };
        });

        setData(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchSpotlights = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const spotlights = response.data.spotlights;
        const transformedData = spotlights.map((spotlight) => ({
          id: spotlight["id"],
          url: spotlight["URL"],
          name: spotlight["Name"],
          comment: spotlight["Comment"],
        }));

        console.log(transformedData);

        setSpotlights(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
    fetchSpotlights();
  }, []);

  const handleDelete = async (roleId) => {
    try {
      const updatedData = {
        fields: { Deleted: true },
        roleId: roleId,
      };
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter((role) => role.id !== roleId));
      setSelectedRole(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleStatus = async (itemId, currentStatus) => {
    try {
      const newStatus = currentStatus ? "Not open to apps" : "Open to apps";

      setData((prevData) =>
        prevData.map((role) =>
          role.id === itemId
            ? { ...role, status: newStatus === "Open to apps" }
            : role
        )
      );
      const updatedData = {
        fields: { "Open to applications": newStatus },
        roleId: itemId,
      };

      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    } catch (err) {
      setError(err.message);
    }
  };

  const renderLoadingState = () => {
    return (
      <div className="relative max-w-[1400px] overflow-x-auto border-2 sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-3">
                Role Title
              </th>
              <th scope="col" className="px-4 py-3">
                Completed
              </th>
              <th scope="col" className="px-4 py-3">
                Published to candidates
              </th>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3"></th>
              <th scope="col" className="px-4 py-3"></th>
              <th scope="col" className="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(3)].map((_, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-64"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-28"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-40"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-64"></div>
                </td>
                <th scope="col" className="px-4 py-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 text-black"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </th>
                <td className="px-4 py-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 text-black cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                    />
                  </svg>
                </td>
                <td className="px-4 py-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 text-black cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const calculateCompletion = (role) => {
    const mandatoryFields = [
      role.role_title,
      role.compensation,
      role.working_style,
      role.hiring_lead_name,
      role.hiring_lead_email,
    ];

    const importantFields = [role.job_description_link, role.spotlight];

    const additionalFields = [
      role.equity,
      role.key_experience,
      role.hiring_lead_linkedin,
      role.interviewProcess,
    ];

    const filledMandatoryFields = mandatoryFields.filter(Boolean).length;
    const filledAdditionalFields = additionalFields.filter(Boolean).length;
    const filledImportantFields = importantFields.filter(Boolean).length;
    const totalFilledFields =
      filledMandatoryFields + filledAdditionalFields + filledImportantFields;
    const totalFields = mandatoryFields.length + additionalFields.length;

    const completionPercentage = (totalFilledFields / 11) * 100;

    let colorClass = "text-gray-500"; // Default
    let missingField = null;

    // Red: Missing any mandatory field or any important field
    if (
      filledMandatoryFields < mandatoryFields.length ||
      filledImportantFields === 0
    ) {
      colorClass = "text-red-500";
    }
    // Orange: All mandatory fields are filled but missing one important field
    else if (
      filledMandatoryFields === mandatoryFields.length &&
      filledImportantFields === 1
    ) {
      colorClass = "text-orange-400";
      // Determine which important field is missing
      if (!role.job_description_link) {
        missingField = "Job Description";
      } else if (!role.spotlight) {
        missingField = "Video Recording";
      }
    }
    // Green: All mandatory and important fields are filled
    else if (
      filledMandatoryFields === mandatoryFields.length &&
      filledImportantFields === importantFields.length
    ) {
      colorClass = "text-green-400";
    }

    return { completionPercentage, colorClass, missingField };
  };

  const sortedData = data
    .map((role) => {
      const { completionPercentage, colorClass, missingField } =
        calculateCompletion(role);
      return { ...role, completionPercentage, colorClass, missingField };
    })
    .sort((a, b) => {
      const colorPriority = {
        "text-red-500": 1, // Highest priority
        "text-orange-400": 2,
        "text-green-400": 3, // Lower priority
      };

      // Compare the color priority first
      if (colorPriority[a.colorClass] !== colorPriority[b.colorClass]) {
        return colorPriority[a.colorClass] - colorPriority[b.colorClass];
      }

      // If both have the same color class, sort by creation date (newer first)
      const dateA = new Date(a.createdTime).getTime();
      const dateB = new Date(b.createdTime).getTime();
      return dateB - dateA; // Newer first
    });

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [rolesPerPage] = useState(8); // Number of roles per page
  const [isPaginated, setIsPaginated] = useState(false);
  const tableRef = useRef(null);

  // Calculate total pages
  const totalPages = Math.ceil(sortedData.length / rolesPerPage);

  // Get current roles for the current page
  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = sortedData.slice(indexOfFirstRole, indexOfLastRole);

  // Check if the table height exceeds 2/3 of the screen height
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const tableHeight = tableRef.current.offsetHeight;
        const screenHeight = window.innerHeight;
        setIsPaginated(tableHeight > (screenHeight * 2) / 3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, [tableRef]);

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const targetDate = new Date("2024-10-31T00:00:00");

    const updateCountdown = () => {
      const now = new Date();
      const timeDifference = targetDate - now;

      if (timeDifference >= 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Pad with leading zeros if necessary
        const formattedDays = String(days).padStart(2, "0");
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        setCountdown({
          days: formattedDays,
          hours: formattedHours,
          minutes: formattedMinutes,
          seconds: formattedSeconds,
        });
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  // Create a new role with the given title
  const createRole = async () => {
    try {
      const data = {};
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.newRoleData.id; 
    } catch (error) {
      console.error("Error creating role:", error);
      return null;
    }
  };

  const [loadingForm, setLoadingForm] = useState(false);

  const handleCreateRole = async () => {
    setLoadingForm(true);
    try {
      // Create a new role and get its ID
      const roleId = await createRole();
      if (roleId) {
        // Redirect to the first step of the new form
        window.location.href = `/create-role/basic-info?roleId=${roleId}`;
      } else {
        alert("Failed to create the role.");
      }
    } catch (error) {
      console.error("Error creating a new role:", error);
      alert("An error occurred while creating the role.");
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <div className="flex flex-col items-center bg-white pt-10 pl-56 pb-2 px-4">
      <div className="max-h-[2/3]">
        <div>
          {/* Top container for next role-drop countdown */}
          <RoleDropCountdown
            days={countdown.days}
            hours={countdown.hours}
            minutes={countdown.minutes}
            seconds={countdown.seconds}
            targetDate="Thu 31st Oct 2024"
            nextMonday="Mon 13th November"
          />

          <h2 className="text-3xl pt-10">Roles</h2>
          <div className="pb-4">
            {/* Count Finished Roles */}
            You have <span className="font-bold">{sortedData.length}</span>{" "}
            roles, including{" "}
            <span className="font-bold">
              {sortedData.filter((role) => role.status).length}
            </span>{" "}
            open to applicants.
          </div>
        </div>
        {loading ? (
          renderLoadingState()
        ) : (
          <div
            ref={tableRef}
            className="relative max-w-[1400px]  overflow-x-auto border-2 sm:rounded-lg"
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-md text-gray-700 bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Role Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Completed
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Publish to our job board
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3"></th>
                  <th scope="col" className="px-4 py-3"></th>
                  <th scope="col" className="px-4 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {currentRoles.map((role, index) => {
                  const { completionPercentage, colorClass } =
                    calculateCompletion(role);
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50 "
                    >
                      <th
                        scope="row"
                        className="px-4 py-4 font-medium text-gray-900"
                      >
                        {role.role_title}{" "}
                        <span className="font-normal">
                          ({role.createdMonthYear})
                        </span>
                      </th>

                      <td className={`px-4 py-4 font-medium ${colorClass}`}>
                        {completionPercentage.toFixed(0)}% completed
                      </td>

                      <td className="flex flex-col items-center pt-6">
                        <label
                          className={`inline-flex items-center  ${
                            role.colorClass === "text-red-500"
                              ? "cursor-no-drop"
                              : "cursor-pointer"
                          }`}
                        >
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            checked={
                              role.status && role.colorClass !== "text-red-500"
                            } // Dynamically set checked state
                            onChange={() => {
                              if (role.colorClass !== "text-red-500") {
                                setSelectedRoleToggle(role);
                                setIsTogglingOpen(!role.status); 
                              }
                            }}
                            disabled={role.colorClass === "text-red-500"} 
                          />
                          <div
                            className={`relative w-11 h-6 bg-gray-200 rounded-full peer transition-all ${
                              role.status && role.colorClass !== "text-red-500"
                                ? "peer-checked:bg-green-300"
                                : role.colorClass === "text-red-500"
                                ? "bg-gray-300 cursor-no-drop"
                                : "peer-checked:bg-orange-200"
                            }`}
                          >
                            <div
                              className={`after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all ${
                                role.status &&
                                role.colorClass !== "text-red-500"
                                  ? "after:translate-x-full bg-blue-600"
                                  : role.colorClass === "text-red-500"
                                  ? "after:translate-x-0 bg-gray-500 cursor-no-drop"
                                  : "after:translate-x-0"
                              }`}
                            ></div>
                          </div>
                        </label>
                      </td>

                      <td className="px-4 py-4">
                        <div
                          className={`py-2 text-sm whitespace-nowrap justify-center w-[280px] items-center flex rounded-md ${
                            role.colorClass === "text-red-500"
                              ? "bg-gray-100 text-gray-400"
                              : role.status
                              ? "bg-green-100 text-green-600"
                              : "bg-orange-100 text-orange-600"
                          }`}
                        >
                          <svg
                            className={`h-1.5 w-1.5 mr-2 ${
                              role.colorClass === "text-red-500"
                                ? "fill-gray-400"
                                : role.status
                                ? "fill-green-600"
                                : "fill-orange-600"
                            }`}
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {role.colorClass === "text-red-500" ? (
                            <span>Draft</span>
                          ) : role.status ? (
                            <span>
                              Receiving applications{" "}
                              <span className="font-semibold">{nextDate}</span>
                            </span>
                          ) : (
                            <span>Not open to applicants</span>
                          )}
                        </div>
                      </td>

                      <td className="px-4 py-4">
                        <svg
                          onClick={() => window.open(`/role?roleId=${role.url_id}`)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 text-black cursor-pointer hover:text-green-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </td>

                      <td className="px-4 py-4">
                        <svg
                          onClick={() => navigate(`/edit-role?roleId=${role.id}`)}
                          onMouseEnter={() => setHoveredEditIconId(role.id)}
                          onMouseLeave={() => setHoveredEditIconId(null)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke={
                            hoveredEditIconId === role.id
                              ? "#10B981"
                              : "currentColor"
                          }
                          className="size-6 text-black cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                      </td>

                      <td className="px-4 py-4">
                        <svg
                          onClick={() => setSelectedRole(role.id)}
                          onMouseEnter={() => setHoveredRoleId(role.id)}
                          onMouseLeave={() => setHoveredRoleId(null)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke={
                            hoveredRoleId === role.id ? "#EF4444" : "currentColor"
                          }
                          className="size-6 text-black cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="flex justify-end ">
          <div className="w-full max-w-[1400px] flex mt-5 space-x-2">
            <button
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
              className="flex justify-center items-center px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
              Previous
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              className="flex justify-center items-center px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-500"
            >
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>

          <div className="w-full max-w-[1400px] flex justify-end mt-5">
            <button
              type="button"
              className="px-6 flex py-2 text-md border-2 border-[#02B491] text-white hover:text-black rounded-lg font-medium transform hover:bg-white bg-[#02B491]"
              onClick={handleCreateRole}
            >
              {loadingForm ? (
                <div className="flex justify-center items-center w-full rounded-lg">
                  <img src={LoadingBlack} className="h-6" alt="Loading" />
                </div>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  Create a new role
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {selectedRoleToggle && (
        <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Publish / Unpublish Confirmation Modal */}
          <div className="fixed inset-0 flex justify-center items-center z-50">
            <div className="relative w-full max-w-lg mx-3">
              <div className="relative bg-white rounded-lg shadow">
                {/* Close button */}
                <button
                  type="button"
                  onClick={() => setSelectedRoleToggle(null)}
                  className="absolute top-2 right-2 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center"
                >
                  <svg className="w-3 h-3" viewBox="0 0 14 14">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>

                <div className="p-3 text-center">
                  {/* Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mx-auto mb-2 text-gray-400 w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>

                  {/* Content */}
                  <div className="mb-2 text-sm text-gray-600">
                    {selectedRoleToggle.colorClass === "text-orange-400" &&
                    isTogglingOpen ? (
                      <div className="space-y-1">
                        <p className="text-sm">
                          Are you sure you want to publish your role without a{" "}
                          <strong>{selectedRoleToggle.missingField}</strong>?
                        </p>
                        <p className="text-xs text-gray-500 italic">
                          If you do, you'll receive applications on {nextDate}.
                        </p>
                        {selectedRoleToggle.missingField === "Video Recording" && (
                          <div className="mt-2">
                            <CalendlyWidget />
                          </div>
                        )}
                      </div>
                    ) : isTogglingOpen ? (
                      <p className="text-sm">
                        Are you sure? If you publish the role, we'll share
                        applicants with you on {nextDate}.
                      </p>
                    ) : (
                      "Are you sure you want to close this role to applicants?"
                    )}
                  </div>

                  {/* Buttons */}
                  <div className="flex justify-center gap-2 mt-2">
                    <button
                      onClick={confirmToggleStatus}
                      type="button"
                      className={`text-white ${
                        isTogglingOpen
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-red-500 hover:bg-red-700"
                      } px-3 py-1.5 text-sm font-medium rounded-md`}
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      onClick={() => setSelectedRoleToggle(null)}
                      type="button"
                      className="px-3 py-1.5 text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-200 hover:bg-gray-100"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {selectedRole && (
        <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Delete Confirmation Modal */}
          <div className="fixed inset-0 flex justify-center items-center z-50">
            <div className="relative w-full max-w-xs mx-3">
              <div className="relative bg-white rounded-lg shadow">
                {/* Close button */}
                <button
                  type="button"
                  onClick={() => setSelectedRole(null)}
                  className="absolute top-2 right-2 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center"
                >
                  <svg className="w-3 h-3" viewBox="0 0 14 14">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>

                <div className="p-6 text-center">
                  {/* Trash Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mx-auto mb-4 text-gray-400 w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                  <h3 className="mb-2 text-lg font-normal text-gray-500">
                    Are you sure you want to delete this role?
                  </h3>
                  <p className="text-sm text-gray-400 mb-5">
                    This action cannot be undone.
                  </p>

                  <button
                    onClick={() => handleDelete(selectedRole)}
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-md text-sm inline-flex items-center px-5 py-2.5 mr-2"
                  >
                    Yes, delete
                  </button>
                  <button
                    onClick={() => setSelectedRole(null)}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-md border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Roles2;
