"use client"

import { useState, useEffect } from "react"

export default function RoleDropBanner() {
  const [timeLeft, setTimeLeft] = useState("")
  const [isVisible, setIsVisible] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      // Only update if the banner hasn't been dismissed
      if (!dismissed) {
        const now = new Date()
        const target = new Date("2025-03-07T12:00:00")
        const difference = target - now

        // If the deadline has passed, hide the banner
        if (difference <= 0) {
          setIsVisible(false)
          clearInterval(timer)
          return
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)

        setTimeLeft(`${days}d ${hours}h`)
        setIsVisible(days <= 20) // Show banner within 3 days of deadline
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [dismissed])

  // If not visible or dismissed, render nothing
  if (!isVisible) return null

  return (
    <div className="fixed top-0 left-0 right-0 w-full bg-gradient-to-r from-[#02B491] to-[#018f73] z-50 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-11 text-white">
          <div className="flex items-center space-x-4">
            <div className="flex items-center bg-white bg-opacity-20 rounded-full px-3 py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-yellow-300 animate-pulse"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="ml-2 font-bold text-sm">{timeLeft}</span>
            </div>
            <p className="font-medium text-base">
              Let candidates know you’re hiring - post a role by{" "}
              <span className="font-bold underline">Friday 7th March</span> to receive applicants!
            </p>
          </div>
          <div className="flex items-center space-x-3 ml-auto mr-4">
            <button
              onClick={() => (window.location.href = "/roles")}
              className="inline-flex items-center text-sm font-semibold text-[#02B491] bg-white px-3 py-1.5 rounded-full hover:bg-opacity-90 transition-colors duration-200 ease-in-out"
            >
              Post a role
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 ml-1.5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setDismissed(true)
                setIsVisible(false)
              }}
              className="text-white hover:text-gray-200 transition-colors duration-200 pr-2"
              aria-label="Close banner"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
