import { useEffect, useState, useCallback, useRef, useMemo } from "react"
import axios from "axios"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import GeneralistCard from "../../../components/candidates/generalist_card.js"
import Candidate from "../../../components/candidates/candidate.js"
import InterviewRequest from "../../../components/interview_request/progress_bar.js"
import ActiveFilters from "../../../components/active_filters.js"
import CandidatesLoading from "../../../components/candidates/candidates_loading.js"
import FiltersModal from "../../../components/filters.js"
import MultiSelect from "../../../components/multiselect_filter.js"
import RoleDropBanner from "../../../components/role-drop-banner.jsx"
import RecommendationModal from "./RecommendationModal.js"
import TagReviewModal from "./TagReviewModal.js"
import './shimmer.css'; // Import CSS for the shimmer effect

export default function Candidates() {
  const navigate = useNavigate();
  const location = useLocation();
  const { roleId: urlRoleId } = useParams();
  const [data, setData] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStream, setFilterStream] = useState([]);
  const [filterYearsOfExp, setFilterYearsOfExp] = useState([]);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [filterWorkingStyle, setFilterWorkingStyle] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState({});
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [recommendedCandidates, setRecommendedCandidates] = useState([]);
  const [isInterviewRequest, setIsInterviewRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newRoleTitle, setNewRoleTitle] = useState("");
  const [newRoleForm, setNewRoleForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [candidatesStatus, setCandidatesStatus] = useState([]);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageData, setNextPageData] = useState(null);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [prefetchController, setPrefetchController] = useState(null);
  const CANDIDATES_PER_PAGE = 50;
  const prefetchTimeoutRef = useRef(null);

  // Recommendation states
  const [recommendModalOpen, setRecommendModalOpen] = useState(false);
  const [isRecommendationLoading, setIsRecommendationLoading] = useState(false);
  const [isGPTAnalysisLoading, setIsGPTAnalysisLoading] = useState(false);
  const [recommendationResults, setRecommendationResults] = useState(null);
  const [candidateRecommendationMap, setCandidateRecommendationMap] = useState({});
  const [showingRecommendations, setShowingRecommendations] = useState(false);
  const [recommendedRoleTitle, setRecommendedRoleTitle] = useState("");
  const [currentRecommendationPhase, setCurrentRecommendationPhase] = useState(1);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [recommendationProgress, setRecommendationProgress] = useState(0);
  const [shimmerAnimation, setShimmerAnimation] = useState(true);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [smoothProgressActive, setSmoothProgressActive] = useState(false);
  const [loadingFromAirtable, setLoadingFromAirtable] = useState(false);
  const smoothProgressRef = useRef(null);
  const smoothProgressTargetRef = useRef(5);
  const smoothProgressSpeedRef = useRef(0.3); // Default speed - will adjust dynamically
  const [progressLabel, setProgressLabel] = useState('Starting...');
  const progressTimersRef = useRef([]); // Add a ref to store progress timers
  const recommendationInProgressRef = useRef(false); // Tracks if a recommendation process is in progress - used in getAIRecommendations function

  // Tag review states
  const [tagReviewModalOpen, setTagReviewModalOpen] = useState(false);
  const [isEditingCriteria, setIsEditingCriteria] = useState(false);
  const [analyzedJD, setAnalyzedJD] = useState(null);
  const [selectedRoleForTags, setSelectedRoleForTags] = useState(null);
  const [showTagsModal, setShowTagsModal] = useState(false);

  // Custom CSS for animations
  const progressBarAnimation = `
    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
    
    .skeleton-shimmer {
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: shimmer 1.5s infinite;
    }
    
    @keyframes progressPulse {
      0% {
        opacity: 0.8;
        box-shadow: 0 0 0 0 rgba(2, 180, 145, 0.3);
      }
      70% {
        opacity: 1;
        box-shadow: 0 0 0 6px rgba(2, 180, 145, 0);
      }
      100% {
        opacity: 0.8;
        box-shadow: 0 0 0 0 rgba(2, 180, 145, 0);
      }
    }
    
    .progress-pulse {
      animation: progressPulse 2s infinite;
    }
    
    @keyframes gradientMove {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    
    .progress-animated {
      background: linear-gradient(90deg, #02B491, #03d6ab, #02B491);
      background-size: 200% 100%;
      animation: gradientMove 3s ease infinite;
      transition: width 0.5s ease-out;
    }
  `;

  // Smooth progress animation function
  const startSmoothProgress = useCallback((targetValue, initialSpeed = 0.3) => {
    // Clear any existing animation
    if (smoothProgressRef.current) {
      cancelAnimationFrame(smoothProgressRef.current);
      smoothProgressRef.current = null;
    }

    // Set the target and speed (ensure values are numbers)
    smoothProgressTargetRef.current = Number(targetValue) || 5;
    smoothProgressSpeedRef.current = Number(initialSpeed) || 0.3;
    setSmoothProgressActive(true);

    // Animation function
    const animate = () => {
      setRecommendationProgress(prev => {
        // Parse the previous value to ensure it's a number
        const prevValue = Number(prev) || 0;
        
        // Already reached or exceeded target
        if (prevValue >= smoothProgressTargetRef.current) {
          return smoothProgressTargetRef.current;
        }

        // Calculate new value with dynamic speed adjustments
        // Move faster when far from target, slower when approaching target
        const distance = smoothProgressTargetRef.current - prevValue;
        
        // Use more appropriate speeds based on distance to target
        // This ensures continuous movement even during long operations
        let speed;
        if (distance > 40) speed = 0.5;        // Move faster when far away
        else if (distance > 20) speed = 0.3;   // Medium speed at medium distance
        else if (distance > 10) speed = 0.15;  // Slow down when approaching
        else speed = 0.08;                     // Very slow near target

        smoothProgressSpeedRef.current = speed;
        
        // Calculate increment, but ensure we don't exceed target
        const increment = Math.min(speed, distance);
        
        // Return a rounded number to avoid floating point issues
        return Math.round((prevValue + increment) * 10) / 10;
      });

      // Continue animation until target reached or animation stopped
      if (smoothProgressActive) {
        smoothProgressRef.current = requestAnimationFrame(animate);
      }
    };

    // Start animation
    smoothProgressRef.current = requestAnimationFrame(animate);
  }, [smoothProgressActive]);

  // Stop smooth progress animation
  const stopSmoothProgress = useCallback(() => {
    setSmoothProgressActive(false);
    if (smoothProgressRef.current) {
      cancelAnimationFrame(smoothProgressRef.current);
      smoothProgressRef.current = null;
    }
  }, []);

  // Create a helper function to finalize the progress to 100%
  const finalizeProgress = useCallback(() => {
    if (smoothProgressActive) {
      smoothProgressTargetRef.current = 100;
      smoothProgressSpeedRef.current = 1;
      setProgressLabel('Recommendations complete!');
      
      // Stop the animation after a brief delay
      setTimeout(() => {
        stopSmoothProgress();
        setRecommendationProgress(100);
      }, 1000);
    }
  }, [smoothProgressActive, stopSmoothProgress, recommendedRoleTitle]);

  // Set progress with smooth animation and optional auto-complete
  const setSmoothProgress = useCallback((target, autoComplete = false, label = null) => {
    // Ensure target is a valid number
    const validTarget = Number(target) || 0;
    smoothProgressTargetRef.current = validTarget;
    
    // Update the label if provided
    if (label) {
      setProgressLabel(label);
    }
    
    if (!smoothProgressActive) {
      startSmoothProgress(validTarget);
    }
    
    // If autoComplete is true, automatically finish to 100% after reaching target
    if (autoComplete && validTarget >= 95) {
      setTimeout(() => {
        finalizeProgress();
        setProgressLabel('Completed! Loading results...');
      }, 1000);
    }
  }, [startSmoothProgress, smoothProgressActive, finalizeProgress]);

  // Helper function to update both progress percentage and label
  const updateProgress = useCallback((percent, label) => {
    setSmoothProgress(percent, false, label);
  }, [setSmoothProgress]);

  // (We do not need to rely on this to pass to GPT, but you can still store it if needed)
  const [candidatesWithEmbeddings, setCandidatesWithEmbeddings] = useState([]);

  const [processedRoles, setProcessedRoles] = useState(() => {
    // Try to load processed roles from localStorage
    const savedProcessedRoles = localStorage.getItem("processedRoles");
    return savedProcessedRoles ? JSON.parse(savedProcessedRoles) : {};
  });

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [savedCandidates, setSavedCandidates] = useState(() => {
    const savedCandidates = localStorage.getItem("candidates");
    return savedCandidates ? JSON.parse(savedCandidates) : [];
  });

  const streams = ["Generalist", "Sales", "Software Engineering", "Marketing"];

  // ----- Toast Handling -----
  const addToast = (message, type = "shortlist") => {
    setToasts((prevToasts) => [...prevToasts, { message, type }]);
  };

  useEffect(() => {
    if (toasts.length > 0) {
      const timers = toasts.map((toast, index) =>
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
        }, 5000)
      );
      return () => timers.forEach((timer) => clearTimeout(timer));
    }
  }, [toasts]);

  // ----- Local Storage -----
  const saveFiltersToLocalStorage = () => {
    const filters = {
      filterStream,
      filterYearsOfExp,
      minSalary,
      maxSalary,
      filterWorkingStyle,
      filterIndustry,
      timestamp: Date.now(),
    };
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const loadFiltersFromLocalStorage = () => {
    const filters = JSON.parse(localStorage.getItem("filters"));
    if (filters) {
      const currentTime = Date.now();
      const twoHours = 2 * 60 * 60 * 1000;

      if (currentTime - filters.timestamp < twoHours) {
        setFilterStream(filters.filterStream);
        setFilterYearsOfExp(filters.filterYearsOfExp);
        setMinSalary(filters.minSalary);
        setMaxSalary(filters.maxSalary);
        setFilterWorkingStyle(filters.filterWorkingStyle);
        setFilterIndustry(filters.filterIndustry);
      } else {
        localStorage.removeItem("filters");
      }
    }
  };

  useEffect(() => {
    loadFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  // ----- Transform & Parse Helpers -----
  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (roleSet.has("Front End Developer") && roleSet.has("Back End Developer")) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = Number.parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? Number.parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  // Helper function to format salary range for display
  const formatSalaryRange = (minimum, ideal) => {
    if (!minimum && !ideal) return null;
    
    // Helper to clean and parse salary values
    const cleanSalary = (val) => {
      if (!val) return null;
      // Remove any non-numeric characters and convert to number
      const num = val.toString().replace(/[^0-9.]/g, '');
      return num ? parseInt(num, 10) : null;
    };
    
    const min = cleanSalary(minimum);
    const max = cleanSalary(ideal);
    
    if (min && max) {
      if (min === max) {
        return `£${min}k`;
      } else {
        return `£${min}k - £${max}k`;
      }
    } else if (min) {
      return `£${min}k+`;
    } else if (max) {
      return `Up to £${max}k`;
    }
    
    return null;
  };

  //---------------------------------------------
  //  HELPER FUNCTION: transformCandidate
  //---------------------------------------------
  const transformCandidate = (candidate) => {
    // Safeguard if candidate or candidate.fields is missing
    if (!candidate || !candidate.fields) {
      return {
        id: candidate?.id || "unknown-id",
        name: candidate?.name || "No data",
        stream: candidate?.stream || "",
        // ...any other fallback properties you need
      };
    }

    // Use the full transformation for complete data consistency
    return transformCandidateFull(candidate);
  };

  //---------------------------------------------
  //  HELPER FUNCTION: transformCandidateFull
  //  This function handles the complete transformation of candidate data
  //  to ensure consistent field mapping regardless of how the data is loaded.
  //  ALL candidate data should be processed through this function to avoid
  //  missing fields when navigating to the page from different entry points.
  //---------------------------------------------
//---------------------------------------------
//  HELPER FUNCTION: transformCandidateFull
//  This function handles the complete transformation of candidate data
//  to ensure consistent field mapping regardless of how the data is loaded.
//---------------------------------------------
const transformCandidateFull = (candidate) => {
  if (!candidate || !candidate.fields) {
    return {
      id: candidate?.id || "unknown-id",
      name: candidate?.name || "No data",
      stream: candidate?.stream || "",
    };
  }

  const fields = candidate.fields || {};

  // Safely handle SWE roles
  let sweRoles = [];
  try {
    const rawSweRoles = fields["SWE relevant roles"] || "";
    if (rawSweRoles) {
      sweRoles = Array.isArray(rawSweRoles)
        ? rawSweRoles[0].split(",").map(role => role.trim())
        : typeof rawSweRoles === 'string'
          ? rawSweRoles.split(",").map(role => role.trim())
          : [];
      sweRoles = transformRoles(sweRoles);
    }
  } catch (error) {
    console.error("Error processing SWE roles:", error);
  }

  // Safely extract stream
  let stream = "";
  try {
    if (fields["Stream (from CRM)"]) {
      stream = Array.isArray(fields["Stream (from CRM)"])
        ? fields["Stream (from CRM)"][0]
        : fields["Stream (from CRM)"];
    }
  } catch (error) {
    console.error("Error processing stream:", error);
  }

  // Safely determine salary
  let salary = "";
  try {
    if (stream === "Generalist" || stream === "Marketing") {
      salary = fields["Salary - cleaned"] || "";
    } else if (stream === "Sales") {
      salary = fields["Base range + commission"] || "";
    } else if (stream === "Software Engineering") {
      salary = fields["SWE Salary - cleaned"] || "";
    }
  } catch (error) {
    console.error("Error determining salary:", error);
  }

  const { minSalary, maxSalary } = parseSalaryRange(salary);

  // Get image URL safely
  const imageUrl = fields.Photo && fields.Photo[0] ? fields.Photo[0].url : null;

  return {
    id: candidate.id || "",
    url_id: fields["ID"] || "",
    minimum_salary: fields["Minimum salary"] || "",
    ideal_salary: fields["Ideal salary"] || "",
    salary_minimum: fields["Minimum salary"] || "",
    salary_ideal: fields["Ideal salary"] || "",
    expectedSalaryRange: formatSalaryRange(fields["Minimum salary"], fields["Ideal salary"]),

    createdTime: candidate.createdTime || "",
    years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
    cv: fields["CV RAW"] || "",
    video: fields["Raw video intro"] || "",
    linkedin: fields["LinkedIn RAW"] || "",
    github: fields["Github RAW"] || "",
    
    name: fields["Name"] || "",
    lastName: fields["Surname"] || "",
    email: fields["Email"] || "",
    
    undergrad_degree_type: fields["Undergrad degree type"] || "",
    undergrad_degree_subject: fields["Undergrad subject"] || "",
    undergrad_university: fields["Undergrad university"] || "",
    undergrad_graduation_year: fields["Undergrad graduation year"] || "",
    undergrad_result: fields["Undergrad result"] || "",
    postgrad_degree_type: fields["Postgrad degree type"] || "",
    postgrad_degree_subject: fields["Postgrad subject"] || "",
    postgrad_university: fields["Postgrad university"] || "",
    postgrad_graduation_year: fields["Postgrad graduation year"] || "",
    postgrad_result: fields["Postgrad result"] || "",
    
    roles: fields["Generalist roles suitable for copy"] || "",
    industry_exp: fields["Industry experience new"] || [],
    stream: stream,
    search_fields: fields["Search Aggregate Fields"] || "",

    // Additional fields used by the card
    description: fields["Description"] || fields["Top achievement"] || "",
    most_impressive_swe: fields["Most impressive swe"] || "",
    right_to_work: fields["Right to work"] || "",
    recentTarget: fields["Recent target achieved"] || "",
    mostRecentCohort: fields["Most recent cohort"] || "",
    
    working_style: fields["Preferred working style copy"] || "",
    languages: fields["Languages copy"] || "",
    time_coding: fields["Time spent coding"] || "",
    coding_languages: fields["Coding languages new"] || "",
    work_experience_1_role: fields["(1) Work Experience Role"] || "",
work_experience_1_company: fields["(1) Work Experience Company"] || "",
work_experience_1_description: fields["(1) Work Experience Description"] || "",
work_experience_1_duration: {
  years: fields["(1) Time spent in work experience YRS"] || 0,
  months: fields["(1) Time spent in work experience MOS"] || 0,
},
work_experience_2_role: fields["(2) Work Experience Role"] || "",
work_experience_2_company: fields["(2) Work Experience Company"] || "",
work_experience_2_description: fields["(2) Work Experience Description"] || "",
work_experience_2_duration: {
  years: fields["(2) Time spent in work experience YRS"] || 0,
  months: fields["(2) Time spent in work experience MOS"] || 0,
},

    swe_roles: sweRoles,
    sales: fields["Proven Sales Experience / High Potential for Sales"] || "",
    exp_swe: fields["Level of exp SWE"] || "",
    
    // Important: match the exact property names the card expects
    portfolio_swe: fields["SWE Portfolio"] || "",
    marketing_portfolio: fields["Marketing portfolio RAW"] || "",
    
    image: imageUrl,
  };
};


  // ----- Fetch Data -----
  const fetchShortlistedCandidates = useCallback(async () => {
    if (!rolesNeedUpdate) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;
      const shortlistedCandidatesByRole = {};
      const rolesList = [];
      
      // Process AI recommendations from Airtable
      roles.forEach((role) => {
        const roleTitle = role["Role Title"];
        const roleUrlId = role["URL ID"];
        const roleId = role["id"];
        const aiRecommendations = role["Recommended AI"] || [];
        // Get the saved recommendation data (scores and reasons) if available
        let recommendationData = null;
        try {
          if (role["RecommendationData"]) {
            recommendationData = JSON.parse(role["RecommendationData"]);
            console.log(`Loaded recommendation data for role ${roleTitle} with ${recommendationData.length} entries`);
          }
        } catch (err) {
          console.error(`Error parsing recommendation data for role ${roleId}:`, err);
        }

        rolesList.push(roleTitle);

        // If this role has AI recommendations saved in Airtable and we don't have them cached already
        if (aiRecommendations.length > 0 && (!processedRoles[roleId] || !processedRoles[roleId].candidateIds || processedRoles[roleId].candidateIds.length === 0)) {
          console.log(`Found ${aiRecommendations.length} AI recommendations for role ${roleTitle} (${roleId})`);
          
          // Create recommendation map from the saved data if available
          const recommendationMap = {};
          if (recommendationData && Array.isArray(recommendationData)) {
            // Convert array of recommendation objects to a map keyed by candidate ID
            recommendationData.forEach(item => {
              if (item && item.id) {
                recommendationMap[item.id] = {
                  score: item.score || 80, // Default to 80 if no score
                  reason: item.reason || "AI-recommended candidate",
                  isTopGPTCandidate: true // All saved recommendations are considered top candidates
                };
              }
            });
            console.log(`Created recommendation map with ${Object.keys(recommendationMap).length} entries`);
          }
          
          // Update the processed roles with the recommendations from Airtable
          setProcessedRoles(prev => ({
            ...prev,
            [roleId]: {
              candidateIds: aiRecommendations,
              recommendationMap: recommendationMap, // Use the map created from saved data
              timestamp: Date.now(),
              notificationShown: false, // Show notification when viewing
              fromAirtable: true // Flag to indicate this came from Airtable
            }
          }));
        }

        if (role["Shortlisted Candidates"]) {
          if (!shortlistedCandidatesByRole[roleTitle]) {
            shortlistedCandidatesByRole[roleTitle] = {
              roleUrlId: roleUrlId,
              roleId: roleId,
              candidates: [],
            };
          }
          shortlistedCandidatesByRole[roleTitle].candidates =
            shortlistedCandidatesByRole[roleTitle].candidates.concat(
              role["Shortlisted Candidates"]
            );
        }
      });

      setShortlistedCandidates(shortlistedCandidatesByRole);
      setRoles(roles);
      setRolesNeedUpdate(false);
    } catch (err) {
      setError(err.message);
    }
  }, [rolesNeedUpdate, processedRoles]);

  const fetchRecommendedCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const company = response.data.companyData;
      const recommendation = company["recommended_candidates"] || [];

      setRecommendedCandidates(recommendation);
    } catch (err) {
      setError(err.message);
    }
  }, []);
  
  // ------------------ PHASE 2: GPT Analysis (direct array param) ------------------
  const getGPTRecommendations = useCallback(
    async (candidateEmbeddingsArr) => {
      try {
        // ...existing code...
      } catch (err) {
        console.error("Error in GPT analysis:", err);
        addToast(
          "Error performing GPT candidate analysis. Please try again.",
          "error"
        );
        return [];
      }
    },
    [addToast]
  );

  // ------------------ Job Description Analysis ------------------
  const analyzeJobDescription = useCallback(async (roleId) => {
    try {
      setIsRecommendationLoading(true);
      
      // Find the role object immediately
      const role = roles.find(r => r.id === roleId);
      if (!role) {
        throw new Error("Role not found");
      }
      
      // Set the role title and IDs
      setSelectedRoleId(roleId);
      setRecommendedRoleTitle(role["Role Title"]);
      
      // Start smooth progress animation in the background
      setRecommendationProgress(5);
      setSmoothProgress(15, false, 'Analyzing job description...');
      
      // Pre-set the role for tag editing
      setSelectedRoleForTags({
        ...role,
        processedJDText: "" // Will be populated with API response
      });
      
      // Skip showing the RecommendationModal - go directly to analysis
      // [REMOVED: setRecommendModalOpen(true);]
      
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/candidates/analyzeJobDescription",
        { roleId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: 60000 // 60 seconds timeout
        }
      );
      
      // Move progress to show completion of analysis
      setSmoothProgress(25, true, 'Job description analyzed! Identifying key requirements...');
      
      const { roleDetails, jdAnalysis } = response.data;
      
      // Update selected role with full processed text
      setSelectedRoleForTags({
        ...role,
        processedJDText: roleDetails.processedJDText
      });
      setAnalyzedJD(jdAnalysis);
      
      // Open the tag review modal
      setTagReviewModalOpen(true);
      
      // Return the data for potential use by the calling function
      return { roleDetails, jdAnalysis };
    } catch (err) {
      console.error("Error analyzing job description:", err);
      addToast("Error analyzing job description. Please try again.", "error");
      setIsRecommendationLoading(false);
      stopSmoothProgress();
      // [REMOVED: setRecommendModalOpen(false);]
      return null;
    }
  }, [roles, addToast, setSmoothProgress, stopSmoothProgress]);

  // ------------------ PHASE 1: AI Embedding/Filtering + immediate GPT call ------------------
  const getAIRecommendations = useCallback(async (roleId, customJDAnalysis = null) => {
    // CRITICAL FIX: Prevent multiple simultaneous recommendation processes
    if (recommendationInProgressRef.current && !customJDAnalysis) {
      console.log("Recommendation already in progress, ignoring new request");
      return;
    }
    
    // Set the in-progress flag
    recommendationInProgressRef.current = true;

    // If already in the middle of a recommendation process, don't start another one
    if (isRecommendationLoading && !customJDAnalysis) {
      console.log("Recommendation already in progress, aborting new request");
      recommendationInProgressRef.current = false;
      return;
    }
    
    // If no roleId is provided, we should just open the role selection modal
    if (!roleId) {
      setRecommendModalOpen(true);
      recommendationInProgressRef.current = false;
      return;
    }
    
    // Check if we're coming from a "View matches for:" click by looking at the URL
    const queryParams = new URLSearchParams(window.location.search);
    const isFromProcessed = queryParams.get('fromProcessed') === 'true';
    
    // If we're coming from a processed role's button click, use loadRecommendationsFromAirtable instead
    if (isFromProcessed && !customJDAnalysis) {
      console.log("Coming from processed role button, loading from Airtable instead of starting tag review");
      recommendationInProgressRef.current = false;
      loadRecommendationsFromAirtable(roleId);
      return;
    }
    
    // Reset states - but only if this is a new request (no customJDAnalysis)
    if (!customJDAnalysis) {
      setIsRecommendationLoading(true);
      setSelectedRoleId(roleId);
      setRecommendationProgress(5);
      setSmoothProgress(15, false, 'Starting analysis...');
      setCandidatesWithEmbeddings([]); // Reset embeddings array
    } else {
      // Even in edit mode, we still need to show loading UI
      setIsRecommendationLoading(true);
      // When re-running after edit, start at a higher progress value
      setRecommendationProgress(20);
      setSmoothProgress(25, false, 'Re-analyzing with updated criteria...');
    }
    
    // Schedule future progress updates to ensure continuous movement
    const progressSchedule = [
      { progress: 10, delay: 1000, label: 'Analyzing job description...' },      // 1 second: 10%
      { progress: 20, delay: 3000, label: 'Extracting key skills and requirements...' },  // 3 seconds: 20%
      { progress: 30, delay: 5000, label: 'Searching for matching candidates...' },   // 5 seconds: 30%
      { progress: 45, delay: 15000, label: 'Evaluating candidate profiles...' },  // 15 seconds: 45%
      { progress: 60, delay: 30000, label: 'Comparing candidates to requirements...' },  // 30 seconds: 60%
      { progress: 75, delay: 50000, label: 'Ranking candidates by qualifications...' },  // 50 seconds: 75%
      { progress: 85, delay: 70000, label: 'Generating detailed assessments...' },  // 70 seconds: 85%
      { progress: 95, delay: 90000, label: 'Finalizing recommendations...' },  // 90 seconds: 95%
    ];
    
    // Clear any existing progress timers
    progressTimersRef.current.forEach(timer => clearTimeout(timer));
    progressTimersRef.current = [];
    
    // Set up new timers for smooth progress tracking
    progressSchedule.forEach(({ progress, delay, label }) => {
      const timer = setTimeout(() => {
        updateProgress(progress, label);
      }, delay);
      progressTimersRef.current.push(timer);
    });
    
    // Check if we have a cached version
    if (processedRoles[roleId] && !customJDAnalysis) {
      try {
        // Skip tag review for cached recommendations
        const isFromAirtable = processedRoles[roleId].fromAirtable || false;
        console.log(`Loading ${isFromAirtable ? 'Airtable' : 'cached'} recommendations for role ${roleId}`);
        
        // Move to 40% when starting to load cached data - faster for cached data
        setSmoothProgress(40);
        
        const role = roles.find(r => r.id === roleId);
        if (!role) {
          throw new Error("Role not found in roles[]");
        }
        setRecommendedRoleTitle(role["Role Title"]);
        
        const candidateIds = processedRoles[roleId].candidateIds;
        if (!candidateIds || candidateIds.length === 0) {
          throw new Error("No cached candidates found");
        }
        
        // Move to 60% when fetching candidate details
        setSmoothProgress(70);
        
        const token = localStorage.getItem("token");
        const candidatesResponse = await axios.get(
          `/api/candidates/fetchCandidates?ids=${candidateIds.join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        // Move to 80% when processing data
        setSmoothProgress(90);
        
        // ...rest of cached loading...
      } catch (err) {
        console.error("Error loading cached recommendations:", err);
        // Fall through to the normal (non-cached) flow
      }
    }
    
    try {
      // If we don't have custom JD analysis from tag review modal, we need to analyze first
      if (!customJDAnalysis) {
        // This function will set the loading states and show the tag review modal
        await analyzeJobDescription(roleId);
        
        // Return here - the user will need to confirm tags in the modal before proceeding
        // The modal's onProceed callback will call getAIRecommendations again with customJDAnalysis
        return;
      }
      
      console.log(`Starting recommendation process for role ${roleId} with custom tags:`, customJDAnalysis);
      
      const token = localStorage.getItem("token");
      
      // Ensure loading UI is visible and progress is updated
      setIsRecommendationLoading(true);
      setShowingRecommendations(true);
      
      // Start progress at 30% since tag review is already done
      setSmoothProgress(30, true, 'Processing updated criteria...');
      
      // Use a more continuous progress animation during API calls
      const phase1Interval = setInterval(() => {
        // Get current progress and increment by small amounts
        setRecommendationProgress(prev => {
          const currentProgress = Number(prev) || 30;
          // Only increment if below the next milestone to prevent overshooting
          if (currentProgress < 40) {
            return currentProgress + 0.5;
          }
          return currentProgress;
        });
      }, 1000); // Update every second
      
      // Make Phase 1 API call with custom JD analysis
      console.log("Making Phase 1 API call with custom JD analysis");
      const response = await axios.post(
        "/api/candidates/getRecommendations",
        { 
          roleId, 
          phase: 1,
          customJDAnalysis  // Pass the custom tags to the API
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: 600000
        }
      );
      
      // Clean up phase 1 interval and move to next phase
      clearInterval(phase1Interval);
      
      // Update progress to 50% after phase 1 completes
      setSmoothProgress(50);
      
      // Process Phase 1 response
      const { topCandidatesByEmbedding, roleDetails, candidateCount } = response.data;
      if (!topCandidatesByEmbedding || topCandidatesByEmbedding.length === 0) {
        throw new Error("No candidates found in phase 1");
      }
      
      console.log(`Phase 1 found ${topCandidatesByEmbedding.length} candidates by embedding`);
      setCandidatesWithEmbeddings(topCandidatesByEmbedding);
      setRecommendedRoleTitle(roleDetails["Role Title"]);
      console.log("Role details:", roleDetails);
      
      // Create a new recommendation map for scoring
      const recMap = {};
      
      // More continuous progress movement during phase 2
      const phase2Interval = setInterval(() => {
        // Increment progress more frequently but in smaller steps
        setRecommendationProgress(prev => {
          const currentProgress = Number(prev) || 50;
          // Increase the upper limit to 85 to prevent getting stuck at 70%
          if (currentProgress < 85) {
            return currentProgress + 0.3;
          }
          return currentProgress;
        });
      }, 800); // Update slightly faster
      
      // Additionally, set up a progress safeguard that ensures progress keeps moving
      // even if the API call takes longer than expected
      const progressGuard = [
        { progress: 60, delay: 10000 },  // If still waiting after 10s, move to 60%
        { progress: 70, delay: 20000 },  // If still waiting after 20s, move to 70%
        { progress: 80, delay: 40000 },  // If still waiting after 40s, move to 80%
        { progress: 90, delay: 80000 },  // If still waiting after 80s, move to 90%
      ];
      
      const guardTimers = progressGuard.map(({ progress, delay }) => {
        return setTimeout(() => {
          // Only update if still loading (phase 2 API hasn't returned yet)
          if (isRecommendationLoading) {
            setSmoothProgress(progress);
          }
        }, delay);
      });
      
      // Make Phase 2 API call with embedding results and custom JD analysis
      console.log("Making Phase 2 API call with custom JD analysis");
      const phase2Response = await axios.post(
        "/api/candidates/getRecommendations",
        { 
          roleId, 
          phase: 2,
          candidateEmbeddings: topCandidatesByEmbedding,
          customJDAnalysis
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          timeout: 600000
        }
      );
      
      // Clean up phase 2 interval and guard timers
      clearInterval(phase2Interval);
      guardTimers.forEach(timer => clearTimeout(timer));
      setSmoothProgress(80); // Increased from 75 to 80 to show more progress
      
      // Process Phase 2 response
      const recommendedCandidates = phase2Response.data.recommendedCandidates || [];
      const allRankedCandidates = phase2Response.data.allRankedCandidates || [];
      
      if (recommendedCandidates.length === 0) {
        console.warn("No recommended candidates from phase 2");
      }
      
      setRecommendationResults(recommendedCandidates);
      console.log("GPT analysis results:", recommendedCandidates);
      
      // Build a recommendation map that maps candidate IDs to their scores and reasons
      if (allRankedCandidates && allRankedCandidates.length > 0) {
        allRankedCandidates.forEach(candidate => {
          recMap[candidate.id] = {
            score: candidate.score,
            reason: candidate.reason,
            // Mark candidates that were in the top GPT recommendations
            isTopGPTCandidate: recommendedCandidates.some(rc => rc.id === candidate.id)
          };
        });
      } else {
        // Fallback to the old method if allRankedCandidates isn't available
        console.log("Falling back to manual ranking method");
        
        // First populate ALL candidates with their embedding scores
        topCandidatesByEmbedding.forEach(item => {
          const candidateId = item?.candidate?.id;
          if (candidateId) {
            recMap[candidateId] = {
              score: Math.round(item.similarity * 100),
              reason: "Matched based on CV content and required skills."
            };
          }
        });
        
        // Then update scores for candidates analyzed by GPT
        recommendedCandidates.forEach(rec => {
          if (rec.id in recMap) {
            recMap[rec.id] = {
              score: rec.score,
              reason: rec.reason,
              isTopGPTCandidate: true // Mark the ones that were analyzed by GPT
            };
          }
        });
      }
      
      setCandidateRecommendationMap(recMap);
      
      // Update progress to 80% when fetching candidate details
      setSmoothProgress(85);
      
      // Start final details countdown with more frequent updates
      const detailsInterval = setInterval(() => {
        // Use even smaller increments for the final stretch
        setRecommendationProgress(prev => {
          const currentProgress = Number(prev) || 85;
          // Only increment if below the next milestone
          if (currentProgress < 95) {
            return currentProgress + 0.2;
          }
          return currentProgress;
        });
      }, 500); // Update even faster during final stage
      
      // Fetch details for ALL candidates from phase 1
      const candidateIds = topCandidatesByEmbedding.map(item => item.candidate.id);
      
      try {
        // If we have too many candidates, split them into batches
        const MAX_BATCH_SIZE = 20; // Should match the limit in fetchCandidates.js
        let fetchedCandidates = [];
        
        if (candidateIds.length > MAX_BATCH_SIZE) {
          // Process in batches
          console.log(`Fetching ${candidateIds.length} candidates in batches of ${MAX_BATCH_SIZE}`);
          
          // Break candidates into batches
          const batches = [];
          for (let i = 0; i < candidateIds.length; i += MAX_BATCH_SIZE) {
            batches.push(candidateIds.slice(i, i + MAX_BATCH_SIZE));
          }
          
          // Fetch each batch sequentially to avoid overwhelming the server
          for (let i = 0; i < batches.length; i++) {
            const batchIds = batches[i];
            console.log(`Fetching batch ${i+1}/${batches.length} with ${batchIds.length} candidates`);
            
            const batchResponse = await axios.get(
              `/api/candidates/fetchCandidates?ids=${batchIds.join(",")}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            
            if (batchResponse.data && batchResponse.data.candidates) {
              fetchedCandidates = [...fetchedCandidates, ...batchResponse.data.candidates];
            }
            
            // Update progress during the batching process
            setSmoothProgress(85 + (i+1) * (10 / batches.length));
          }
        } else {
          // Fetch all at once if under the limit
          const candidatesResponse = await axios.get(
            `/api/candidates/fetchCandidates?ids=${candidateIds.join(",")}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          if (candidatesResponse.data && candidatesResponse.data.candidates) {
            fetchedCandidates = candidatesResponse.data.candidates;
          }
        }
        
        clearInterval(detailsInterval);
        
        // Create a map for quick lookups
        const candidateMap = {};
        fetchedCandidates.forEach(candidate => {
          candidateMap[candidate.id] = candidate;
        });
        
        // Combine with recommendation data
        const candidatesWithScores = [];
        candidateIds.forEach(id => {
          if (candidateMap[id] && recMap[id]) {
            candidatesWithScores.push({
              ...candidateMap[id],
              recommendationScore: recMap[id].score,
              recommendationReason: recMap[id].reason,
              isTopGPTCandidate: recMap[id].isTopGPTCandidate || false,
              isFiltered: recMap[id].filtered || false // Add the filtered flag
            });
          }
        });
        
        // If we didn't get all the candidates, show a message
        if (fetchedCandidates.length < candidateIds.length) {
          addToast(`Showing ${fetchedCandidates.length} out of ${candidateIds.length} recommended candidates due to system limits.`, "info");
        }
        
        // Check if we have any non-filtered candidates
        const validCandidates = candidatesWithScores.filter(c => !c.isFiltered);
        
        // Always show all candidates, but sort them appropriately
        // Instead of conditionally selecting which candidates to show
        const candidatesToDisplay = candidatesWithScores;
        
        // Show a message if all candidates were filtered out for not meeting requirements
        if (validCandidates.length === 0 && candidatesToDisplay.length > 0) {
          addToast(`No candidates met all the must-have requirements for this role. Displaying all candidates sorted by score.`, "warning");
        } else if (validCandidates.length > 0) {
          addToast(`Found ${validCandidates.length} candidates that meet all the must-have requirements.`, "success");
        }
        
        // Set final results - always include ALL candidates, just make sure they're sorted correctly
        setRecommendedCandidates(candidatesWithScores
          // First filter out candidates with low scores or failing must-have requirements
          .filter(candidate => {
            const score = candidate.recommendationScore || 0;
            const isFiltered = candidate.isFiltered || false;
            // Keep only candidates with score >= 60 and not filtered
            return score >= 60 && !isFiltered;
          })
          // Then sort the passing candidates
          .sort((a, b) => {
            // First priority: Top GPT candidates
            if (a.isTopGPTCandidate && !b.isTopGPTCandidate) return -1;
            if (!a.isTopGPTCandidate && b.isTopGPTCandidate) return 1;
            
            // Second priority: recommendation score
            return b.recommendationScore - a.recommendationScore;
          })
        );
        
        // Save recommendations to Airtable before completing
        console.log("Saving recommendations to Airtable...");
        const savedData = await saveRecommendationsToAirtable(roleId, candidateIds, recMap);
        
        if (savedData && savedData.recommendationUrl) {
          // Update URL without reloading the page
          window.history.pushState(
            {}, 
            `Recommendations for ${recommendedRoleTitle}`, 
            savedData.recommendationUrl
          );
        }
        
        // Set processed flag for this role to prevent re-processing
        setProcessedRoles(prev => ({
          ...prev,
          [roleId]: {
            ...(prev[roleId] || {}),
            candidateIds: candidateIds,
            notificationShown: true
          }
        }));
        
        // Set showing recommendations to true so user sees the recommendation view
        setShowingRecommendations(true);
        
        // Finally, clear loading state and set progress to 100%
        setIsRecommendationLoading(false);
        setSmoothProgress(100);
        
        // Clear all progress timers
        progressTimersRef.current.forEach(timer => clearTimeout(timer));
        progressTimersRef.current = [];

        // CRITICAL FIX: Reset tag review state to prevent loops
        setTagReviewModalOpen(false);
        setSelectedRoleForTags(null);
        
      } catch (error) {
        console.error("Error fetching candidate details:", error);
        clearInterval(detailsInterval);
        addToast("Error loading candidate details. Please try again.", "error");
      }
      
      // Reset the in-progress flag
      recommendationInProgressRef.current = false;
      
    } catch (err) {
      console.error("Error in recommendations:", err);
      setIsRecommendationLoading(false);
      stopSmoothProgress();
      setShowingRecommendations(false);
      
      // Clear progress timers
      progressTimersRef.current.forEach(timer => clearTimeout(timer));
      progressTimersRef.current = [];
      
      // Reset the in-progress flag
      recommendationInProgressRef.current = false;
      
      addToast(
        `Error getting recommendations: ${err.message || "Unknown error"}`,
        "error"
      );
    }
  }, [roles, addToast, processedRoles, setSmoothProgress, stopSmoothProgress, analyzeJobDescription]);

  const fetchInterviews = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/interviews/fetchInterviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const interviews = response.data.interviews;
      const candidateStatusTuples = interviews.map((interview) => ({
        id: interview["Candidate"][0],
        status: interview["Stage"],
        role: interview["Role Title (from Role)"],
      }));

      setCandidatesStatus(candidateStatusTuples);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const controller = new AbortController();
      
      setLoading(true);
      const response = await axios.get("/api/candidates/fetchCandidates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: controller.signal
      }).catch(error => {
        console.error("Error fetching candidates:", error);
        setError("Failed to load candidates. Please try again later.");
        setLoading(false);
        return { data: { candidates: [] } }; // Return empty data on error
      });

      const candidates = response.data.candidates || [];
      
      // Transform candidates using our full transformation function for consistency
      const transformedData = candidates.map(candidate => {
        try {
          return transformCandidateFull(candidate);
        } catch (error) {
          console.error("Error transforming candidate:", error, candidate);
          // Return a minimal valid candidate object to prevent crashes
          return {
            id: candidate.id || "unknown-id",
            name: "Error loading candidate",
            stream: "",
            industry_exp: [],
          };
        }
      });

      setData(transformedData);
      setCandidates(transformedData);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setLoading(false);
    }
  }, []);

  // Sorting & Setting Data
  useEffect(() => {
    const redStatuses = [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ];

    const getStatusPriority = (status) => {
      if (status === "Application pending") return 1;
      if (status === "Interviewing") return 3;
      if (status === "Match requested") return 4;
      if (redStatuses.includes(status)) return 5;
      return 2; // No status
    };

    const allCandidatesWithStatus = candidates.map((candidate) => {
      const candidateStatus = candidatesStatus.find(
        (status) => status.id === candidate.id
      );
      return {
        ...candidate,
        status: candidateStatus ? candidateStatus.status : null,
        isRecommended: recommendedCandidates.includes(candidate.id),
      };
    });

    const sortedCandidates = allCandidatesWithStatus.sort((a, b) => {
      const aPriority = getStatusPriority(a.status);
      const bPriority = getStatusPriority(b.status);

      if (aPriority !== bPriority) return aPriority - bPriority;
      if (a.isRecommended && !b.isRecommended) return -1;
      if (!a.isRecommended && b.isRecommended) return 1;

      return new Date(b.createdTime) - new Date(a.createdTime);
    });

    setData(sortedCandidates);
  }, [candidates, candidatesStatus, recommendedCandidates]);

  // ----- Apply Filters -----
  const applyFilters = useCallback((candidatesList) => {
    console.log(`Applying filters: showingRecommendations=${showingRecommendations}, recommendationMap size=${Object.keys(candidateRecommendationMap).length}, candidates=${candidatesList.length}`);
    
    // If we're showing recommendations and have recommendation data but no candidates,
    // log an error but don't crash
    if (showingRecommendations && Object.keys(candidateRecommendationMap).length > 0 && candidatesList.length === 0) {
      console.error("ERROR: Have recommendations but no candidate data to display! This shouldn't happen.");
      return [];
    }
    
    // Apply filters to the candidates
    const filteredCandidates = candidatesList.filter((candidate) => {
      // Basic check for valid candidate data
      if (!candidate || !candidate.id) {
        console.warn("Invalid candidate in filter:", candidate);
        return false;
      }
      
      try {
        // Debug trace for a sample candidate to verify all properties exist
        if (candidate.id && candidatesList.indexOf(candidate) === 0) {
          console.log('Sample candidate field values for filtering:', {
            id: candidate.id,
            stream: candidate.stream,
            years_of_exp: candidate.years_of_exp,
            working_style: candidate.working_style,
            industry_exp: candidate.industry_exp,
            search_fields: candidate.search_fields
          });
        }
        
        const matchesStream = filterStream.length
          ? filterStream.some((streamFilter) => {
              const candidateStream = candidate.stream;
              if (candidateStream === undefined || candidateStream === null) return false;
              
              // Convert to string in case it's another type
              const streamStr = String(candidateStream);
              return streamStr.toLowerCase().includes(streamFilter.toLowerCase());
            })
          : true;
    
        const matchesYearsOfExp = filterYearsOfExp.length
          ? filterYearsOfExp.some((yearsFilter) => {
              // Handle years_of_exp which might be a number or string
              const candidateYears = candidate.years_of_exp;
              if (candidateYears === undefined || candidateYears === null) return false;
              
              const yearsStr = candidateYears.toString().toLowerCase();
              return yearsStr.includes(yearsFilter.toLowerCase());
            })
          : true;
    
        const matchesSalary = true;
    
        const matchesWorkingStyle = filterWorkingStyle.length
          ? filterWorkingStyle.some((styleFilter) => {
              // Handle working_style
              const workStyle = candidate.working_style;
              if (workStyle === undefined || workStyle === null) return false;
              
              // Convert to string in case it's another type
              const workStyleStr = String(workStyle);
              return workStyleStr.toLowerCase().includes(styleFilter.toLowerCase());
            })
          : true;
    
        const matchesIndustry = filterIndustry.length
          ? filterIndustry.some((industryFilter) => {
              // Handle industry_exp
              const industryExp = candidate.industry_exp;
              
              // If no industry experience, return false
              if (!industryExp || !Array.isArray(industryExp) || industryExp.length === 0) return false;
              
              // Check if any of the candidate's industries match the filter
              return industryExp.some(industry => {
                // Map UI filter options to database values
                switch(industryFilter) {
                  case "Banking":
                    return industry === "Banking" || industry === "Banking / Finance" || industry === "bankii";
                  case "Consulting":
                    return industry === "Consulting" || industry === "Consu";
                  case "Startup experience":
                    return industry === "Startup experience" || industry === "Startup";
                  case "Founder experience":
                    return industry === "Founder experience" || industry === "Founder Experience";
                  default:
                    return industry.toLowerCase().includes(industryFilter.toLowerCase());
                }
              });
            })
          : true;
    
        const matchesSearchQuery = searchKeywords.length
          ? searchKeywords.every((keyword) => {
              const searchFields = candidate.search_fields;
              if (searchFields === undefined || searchFields === null) return false;
              
              // Convert to string in case it's another type
              const searchFieldsStr = String(searchFields);
              return searchFieldsStr.toLowerCase().includes(keyword.toLowerCase());
            })
          : true;
    
        return (
          matchesStream &&
          matchesYearsOfExp &&
          matchesSalary &&
          matchesWorkingStyle &&
          matchesIndustry &&
          matchesSearchQuery
        );
      } catch (error) {
        console.error(`Error filtering candidate ${candidate.id}:`, error, candidate);
        return false; // Skip candidates that cause errors
      }
    });

    // Enhanced handling for recommendations
    if (showingRecommendations && Object.keys(candidateRecommendationMap).length > 0) {
      console.log(`Handling recommendation view: ${filteredCandidates.length} filtered candidates, ${Object.keys(candidateRecommendationMap).length} in recommendation map`);
      
      // Apply recommendation criteria on the already filtered candidates list
      const recommendedAndFilteredCandidates = filteredCandidates.filter(candidate => {
        if (!candidate || !candidate.id) return false;
        
        // Only include candidates with recommendation data
        const hasRecommendationData = candidateRecommendationMap[candidate.id] !== undefined;
        
        if (hasRecommendationData) {
          // Filter out candidates with scores less than 60
          const score = candidateRecommendationMap[candidate.id]?.score || candidate.recommendationScore || 0;
          const isFiltered = candidateRecommendationMap[candidate.id]?.filtered || candidate.isFiltered || false;
          
          // Keep candidates that have score >= 60 AND are not filtered (not failing more than 1 must-have)
          return score >= 60 && !isFiltered;
        }
        
        return false;
      });
      
      console.log(`Found ${recommendedAndFilteredCandidates.length} candidates with good recommendation data (score >= 60 and meeting requirements) that also match user filters`);
      
      if (recommendedAndFilteredCandidates.length === 0) {
        console.warn("WARNING: No candidates match both filters and recommendation requirements!");
        return [];
      }
      
      // Sort candidates by score (highest first)
      return recommendedAndFilteredCandidates.sort((a, b) => {
        const scoreA = candidateRecommendationMap[a.id]?.score || 0;
        const scoreB = candidateRecommendationMap[b.id]?.score || 0;
        return scoreB - scoreA;
      });
    }

    return filteredCandidates;
  }, [
    filterStream,
    filterYearsOfExp,
    minSalary,
    maxSalary,
    filterWorkingStyle,
    filterIndustry,
    searchKeywords,
    candidateRecommendationMap,
    showingRecommendations
  ]);

  const allFilteredCandidates = applyFilters(data);
  const totalPages = Math.ceil(allFilteredCandidates.length / CANDIDATES_PER_PAGE);

  const prefetchNextPage = useCallback(() => {
    const calculatedTotalPages = Math.ceil(allFilteredCandidates.length / CANDIDATES_PER_PAGE);
    
    if (currentPage < calculatedTotalPages && !isNextPageLoading && !nextPageData) {
      setIsNextPageLoading(true);
      
      if (prefetchTimeoutRef.current) {
        clearTimeout(prefetchTimeoutRef.current);
      }
      
      if (prefetchController) {
        prefetchController.abort();
      }
      
      const controller = new AbortController();
      setPrefetchController(controller);
      
      prefetchTimeoutRef.current = setTimeout(() => {
        const startIndex = currentPage * CANDIDATES_PER_PAGE;
        const endIndex = startIndex + CANDIDATES_PER_PAGE;
        const nextPageCandidates = allFilteredCandidates.slice(startIndex, endIndex);
        setNextPageData(nextPageCandidates);
        setIsNextPageLoading(false);
      }, 100);
    }
  }, [currentPage, isNextPageLoading, nextPageData, CANDIDATES_PER_PAGE, prefetchController, allFilteredCandidates]);

  const getPaginatedData = useCallback(() => {
    const startIndex = (currentPage - 1) * CANDIDATES_PER_PAGE;
    const endIndex = startIndex + CANDIDATES_PER_PAGE;
    return allFilteredCandidates.slice(startIndex, endIndex);
  }, [allFilteredCandidates, currentPage]);

  const filteredData = getPaginatedData();

  useEffect(() => {
    prefetchNextPage();
    return () => {
      if (prefetchTimeoutRef.current) {
        clearTimeout(prefetchTimeoutRef.current);
      }
      if (prefetchController) {
        prefetchController.abort();
      }
    };
  }, [prefetchNextPage, prefetchController]);

  useEffect(() => {
    setCurrentPage(1);
    setNextPageData(null);
  }, [
    filterStream,
    filterYearsOfExp,
    minSalary,
    maxSalary,
    filterWorkingStyle,
    filterIndustry,
    searchKeywords,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchRecommendedCandidates();
    fetchData();
    fetchInterviews();
    
    return () => {
      abortController.abort();
    };
  }, [fetchRecommendedCandidates, fetchData, fetchInterviews]);

  useEffect(() => {
    fetchShortlistedCandidates();
  }, [fetchShortlistedCandidates]);

  useEffect(() => {
    localStorage.setItem("candidates", JSON.stringify(savedCandidates));
  }, [savedCandidates]);

  useEffect(() => {
    saveFiltersToLocalStorage();
  }, [
    filterStream,
    filterYearsOfExp,
    minSalary,
    maxSalary,
    filterWorkingStyle,
    filterIndustry,
  ]);

  // ----- Manage Saved Candidates -----
  const addCandidate = (candidate) => {
    if (!savedCandidates.some((c) => c.id === candidate.id)) {
      setSavedCandidates([...savedCandidates, candidate]);
    }
  };

  const removeCandidate = (candidate) => {
    setSavedCandidates(savedCandidates.filter((c) => c.id !== candidate.id));
  };

  const clear = () => {
    setSavedCandidates([]);
  };

  const toggleCandidate = (candidate) => {
    if (savedCandidates.some((c) => c.id === candidate.id)) {
      removeCandidate(candidate);
    } else {
      addCandidate(candidate);
    }
  };

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleStreamFilterChange = (selectedOptions) => {
    setFilterStream(selectedOptions);
  };

  const handleStreamChange = (option) => {
    if (filterStream.includes(option)) {
      handleStreamFilterChange(filterStream.filter((item) => item !== option));
    } else {
      handleStreamFilterChange([...filterStream, option]);
    }
  };

  const handleYearsOfExpFilterChange = (selectedOptions) => {
    setFilterYearsOfExp(selectedOptions);
  };

  const handleWorkingStyleFilterChange = (selectedOptions) => {
    setFilterWorkingStyle(selectedOptions);
  };

  // ----- Simple Search -----
  const handleSearch = () => {
    if (searchQuery.trim()) {
      setSearchKeywords((prevKeywords) => [
        ...prevKeywords,
        searchQuery.trim(),
      ]);
      setSearchQuery("");
    }
  };

  // ----- Create Role Actions -----
  const handleNewRoleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      fields: {
        "Role Title": newRoleTitle,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      handleRoleSelect(response.data.newRoleData, selectedCandidate?.id || "");
      setRolesNeedUpdate(true);
      setNewRoleForm(false);
      setNewRoleTitle("");
    } catch (err) {
      console.error(err);
    }
  };

  // Handle when user confirms tag review and wants to proceed with recommendations
  const handleTagReviewProceed = (updatedJDAnalysis) => {
    // Store the updated analysis
    setAnalyzedJD(updatedJDAnalysis);
    
    // Close the tag review modal immediately to prevent it from reopening
    setTagReviewModalOpen(false);
    
    // When re-running, make sure to reset progress properly
    if (isEditingCriteria) {
      setRecommendationProgress(0); // Reset progress
      stopSmoothProgress(); // Stop any ongoing progress animations
    }
    
    // Give a small delay before continuing with recommendations to ensure the state is settled
    setTimeout(() => {
      // Continue with the recommendation process using the updated tags
      if (selectedRoleForTags) {
        // Call getAIRecommendations with the updated analysis
        getAIRecommendations(selectedRoleForTags.id, updatedJDAnalysis);
        // Reset the editing flag
        setIsEditingCriteria(false);
      }
    }, 100);
  };

  const handleRoleSelect = async (role, candidateId) => {
    if (!candidateId) return;
    const updatedData = {
      fields: {
        "Shortlisted Candidates": [candidateId],
      },
      roleId: role["id"],
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => {
        const updatedRoles = prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
        return updatedRoles;
      });

      setRolesNeedUpdate(true);
    } catch (err) {
      console.error(err);
    }
  };

  // ----- Clear & Update Filters -----
  const clearFilters = () => {
    handleStreamFilterChange([]);
    handleWorkingStyleFilterChange([]);
    setFilterIndustry([]);
    setSearchKeywords([]);
    handleYearsOfExpFilterChange([]);
    setMaxSalary("");
    setFilterYearsOfExp([]);
  };
  
  // ----- Recommendation Handling -----
  const clearRecommendations = () => {
    setShowingRecommendations(false);
    setCandidateRecommendationMap({});
    setRecommendedRoleTitle("");
    setSelectedRoleId(null);
    
    // Clear the URL parameter to avoid triggering recommendation loading again
    navigate('/browse-candidates', { replace: true });
    
    // Clear any recommendation errors to allow retrying
    setProcessedRoles(prev => {
      const newProcessedRoles = { ...prev };
      // Remove entries that have loadError flag
      Object.keys(newProcessedRoles).forEach(key => {
        if (newProcessedRoles[key].loadError) {
          delete newProcessedRoles[key];
        }
      });
      return newProcessedRoles;
    });
  };

  const onChange = ({
    filterStream: updatedFilterStream,
    filterYearsOfExp: updatedFilterYearsOfExp,
    filterWorkingStyle: updatedFilterWorkingStyle,
    filterIndustry: updatedFilterIndustry,
    searchKeywords: updatedSearchKeywords,
  }) => {
    setFilterStream(updatedFilterStream);
    setFilterYearsOfExp(updatedFilterYearsOfExp);
    setFilterWorkingStyle(updatedFilterWorkingStyle);
    setFilterIndustry(updatedFilterIndustry);
    setSearchKeywords(updatedSearchKeywords);
  };

  useEffect(() => {
    localStorage.setItem("processedRoles", JSON.stringify(processedRoles));
  }, [processedRoles]);
  
  useEffect(() => {
    const clearOldProcessedRoles = () => {
      const now = Date.now();
      const oneDayMs = 24 * 60 * 60 * 1000;
      
      setProcessedRoles(prev => {
        const updated = { ...prev };
        let hasChanges = false;
        
        Object.keys(updated).forEach(roleId => {
          if (now - updated[roleId].timestamp > oneDayMs) {
            delete updated[roleId];
            hasChanges = true;
          }
        });
        
        return hasChanges ? updated : prev;
      });
    };
    
    clearOldProcessedRoles();
    const interval = setInterval(clearOldProcessedRoles, 24 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Optionally handle URL parameters for auto-run
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isRecommendation = queryParams.get('recommendation') === 'true';
    const roleId = queryParams.get('roleId');
    const pendingRoleId = localStorage.getItem("pendingRecommendationRoleId");
    
    if ((isRecommendation && roleId) || pendingRoleId) {
      const targetRoleId = roleId || pendingRoleId;
      localStorage.removeItem("pendingRecommendationRoleId");
      
      // Add safety check to ensure roles is an array before using find
      if (Array.isArray(roles) && roles.length > 0) {
        const role = roles.find(r => r.id === targetRoleId);
        if (role) {
          setIsRecommendationLoading(true);
          getAIRecommendations(targetRoleId);
          navigate('/browse-candidates', { replace: true });
        } else {
          addToast(`Role not found. Please select from the list.`, "error");
        }
      }
    }
  }, [roles, navigate, getAIRecommendations, addToast]);

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        if (selectedRoleId) {
          // Don't set loading again if already loading
          if (!isRecommendationLoading) {
            setIsRecommendationLoading(true);
            setRecommendationProgress(5);
            setSmoothProgress(15);
            
            // We no longer need the full interval-based progress simulation
            // since we have continuous progress with our smoothProgress system
            // Just make sure to keep progress moving during backend operations
            const keepAliveTimer = setInterval(() => {
              setSmoothProgress(prev => {
                // If we're near 100%, don't increment further
                if (prev >= 95) return prev;
                // Otherwise, slowly increment to keep progress moving
                return Math.min(prev + 0.5, 95);
              });
            }, 1000);
            
            // Don't pass the toast notification here - getAIRecommendations will handle it
            await getAIRecommendations(selectedRoleId);
            
            // Clean up timer
            clearInterval(keepAliveTimer);
            
            // Ensure we set progress to 100% at the end
            setSmoothProgress(100);
          }
        }
      } catch (error) {
        console.error("Error fetching candidates:", error);
        setIsRecommendationLoading(false);
        stopSmoothProgress();
        setSmoothProgress(0);
      }
    };

    // Only fetch if we have a role ID and are not already loading
    // And add a check to prevent redundant calls for the same roleId
    if (selectedRoleId && !isRecommendationLoading) {
      // Check if this is a new selection to prevent duplicate processing
      const hasProcessedRole = processedRoles[selectedRoleId] && processedRoles[selectedRoleId].notificationShown;
      
      // Only process if it's a new selection or hasn't been fully processed yet
      if (!hasProcessedRole) {
        // Use a one-time execution to avoid loop
        const onlyOnce = { current: true };
        if (onlyOnce.current) {
          onlyOnce.current = false;
          fetchCandidates();
        }
      }
    }
  }, [selectedRoleId, getAIRecommendations, isRecommendationLoading, processedRoles, setSmoothProgress, stopSmoothProgress]);

  useEffect(() => {
    // Clean up any animations on unmount
    return () => {
      stopSmoothProgress();
    };
  }, [stopSmoothProgress]);

  // Add a cleanup effect for the progress animation
  useEffect(() => {
    // This effect ensures that any requestAnimationFrame is canceled when the component unmounts
    return () => {
      if (smoothProgressRef.current) {
        cancelAnimationFrame(smoothProgressRef.current);
        smoothProgressRef.current = null;
      }
      // Also clear any progress-related intervals
      const allIntervals = [];
      document.querySelectorAll('*').forEach(node => {
        if (node._progressInterval) {
          clearInterval(node._progressInterval);
          allIntervals.push(node._progressInterval);
          delete node._progressInterval;
        }
      });
      console.log(`Cleaned up ${allIntervals.length} progress intervals`);
    };
  }, []);

  // Add additional protection for the progress display by wrapping it in a useMemo
  const displayProgress = useMemo(() => {
    // Ensure we always have a valid number
    const progress = isNaN(recommendationProgress) ? 0 : recommendationProgress;
    return {
      percent: progress,
      text: progressLabel
    };
  }, [recommendationProgress, progressLabel]);

  // Make sure to set initial recommendationProgress to a valid number
  useEffect(() => {
    if (isRecommendationLoading && isNaN(recommendationProgress)) {
      setRecommendationProgress(0);
    }
  }, [isRecommendationLoading, recommendationProgress]);

  // New function to load recommendations from Airtable
  const loadRecommendationsFromAirtable = async (roleId) => {
    if (!roleId) {
      console.log("No roleId provided to loadRecommendationsFromAirtable");
      return;
    }
    if (loadingFromAirtable) {
      console.log("Already loading recommendations - skipping duplicate request");
      return;
    }

    setLoadingFromAirtable(true);
    setIsRecommendationLoading(true);
    setSelectedRoleId(roleId);
    setSmoothProgress(15, false, 'Loading recommendation data...');

    try {
      const token = localStorage.getItem("token");
      console.log(`Fetching role data for ${roleId}`);

      // 1) Make sure roles are loaded
      if (!roles || roles.length === 0) {
        console.log("Roles not loaded yet, fetching roles first");
        try {
          const rolesResponse = await axios.get("/api/roles/fetchRoles", {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (rolesResponse.data && rolesResponse.data.roles) {
            setRoles(rolesResponse.data.roles);
            setRolesNeedUpdate(false);
          }
        } catch (err) {
          console.error("Error loading roles:", err);
          throw new Error("Failed to load roles. Please refresh the page and try again.");
        }
      }

      // 2) Fetch recommendations from Airtable for this role
      console.log(`Fetching recommendations for role ID: ${roleId}`);
      setSmoothProgress(30, false, 'Retrieving candidate recommendations...');
      
      const recommendationsResponse = await axios.get(
        `/api/candidates/fetchRecommendations?roleId=${roleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { candidateIds, recommendationMap, roleTitle } = recommendationsResponse.data;
      console.log(`Received API response for role ID ${roleId} with data:`, {
        candidateCount: candidateIds?.length || 0,
        recommendationMapCount: recommendationMap?.length || 0, 
        roleTitle: roleTitle || 'Not provided'
      });

      if (!candidateIds || candidateIds.length === 0) {
        throw new Error("No recommendations found for this role");
      }

      // If we have a role title from the RecommendationDetails table, use it immediately
      if (roleTitle) {
        console.log(`Setting role title to "${roleTitle}" from RecommendationDetails table`);
        setRecommendedRoleTitle(roleTitle);
      } else {
        console.log(`No role title provided in API response for role ID ${roleId}`);
      }

      // Update progress
      setSmoothProgress(60, false, 'Processing candidate profiles...');

      // 3) Fetch the actual candidate records using those IDs
      const candidatesResponse = await axios.get(
        `/api/candidates/fetchCandidates?ids=${candidateIds.join(",")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!candidatesResponse.data || !candidatesResponse.data.candidates) {
        throw new Error("Failed to fetch candidate details");
      }

      // 4) Transform the returned candidates using the comprehensive transformation function
      const fetchedCandidates = candidatesResponse.data.candidates;
      console.log(`Transforming ${fetchedCandidates.length} candidates with transformCandidateFull`);
      const transformedCandidates = fetchedCandidates.map(candidate => {
        try {
          return transformCandidateFull(candidate);
        } catch (error) {
          console.error(`Error transforming candidate ${candidate.id}:`, error);
          // Return a minimal valid object with the ID preserved
          return {
            id: candidate.id,
            name: candidate.fields?.Name || "Error transforming candidate",
            stream: candidate.fields?.["Stream (from CRM)"] || "",
          };
        }
      });
      
      console.log(`Successfully transformed ${transformedCandidates.length} candidates`);

      // 5) Build local recommendation map from the server's recommendationMap
      const recMap = {};
      recommendationMap.forEach(rec => {
        recMap[rec.candidateId] = {
          score: rec.score || 80,
          reason: rec.reason || "AI recommended candidate",
          isTopGPTCandidate: true,
        };
      });
      
      // 6) Enrich them with recommendation data and make sure salary info is complete
      const enrichedCandidates = transformedCandidates.map(candidate => {
        const rec = recMap[candidate.id] || {};
        
        // Make sure we have all required salary fields
        if (!candidate.expectedSalaryRange) {
          candidate.expectedSalaryRange = formatSalaryRange(
            candidate.salary_minimum || candidate.minimum_salary,
            candidate.salary_ideal || candidate.ideal_salary
          );
        }
        
        return {
          ...candidate,
          recommendationScore: rec.score || 0,
          recommendationReason: rec.reason || "",
          isFiltered: rec.filtered || false,
        };
      });

      // 7) Update all state in one go
      console.log(`Setting ${enrichedCandidates.length} candidates and ${Object.keys(recMap).length} recommendations`);
      
      // First set the recommendation map
      setCandidateRecommendationMap(recMap);

      // Filter out candidates with score < 60% or who don't meet requirements
      const filteredEnrichedCandidates = enrichedCandidates.filter(candidate => {
        const score = candidate.recommendationScore || 0;
        const isFiltered = candidate.isFiltered || false;
        return score >= 60 && !isFiltered;
      });
      
      if (filteredEnrichedCandidates.length === 0) {
        console.warn("No candidates with score >= 60% or meeting requirements for this role");
        addToast("No quality candidates were found for this role. Try different requirements.", "warning");
      } else {
        console.log(`Filtered to ${filteredEnrichedCandidates.length} quality candidates with scores >= 60%`);
      }

      // 8) Update the main data state with filtered enriched candidates
      setData(prevData => {
        // Create a set of existing IDs for faster lookup
        const existingIds = new Set(prevData.map(c => c.id));
        
        // Filter out duplicates
        const newCandidates = filteredEnrichedCandidates.filter(c => !existingIds.has(c.id));
        
        // Update existing candidates with recommendation data and enrichment
        const updatedExistingCandidates = prevData.map(candidate => {
          if (recMap[candidate.id]) {
            // Check if this candidate passes our quality filters
            const score = recMap[candidate.id].score || 0;
            const isFiltered = recMap[candidate.id].filtered || false;
            const passesQualityFilter = score >= 60 && !isFiltered;
            
            if (!passesQualityFilter) {
              // Skip candidates that don't meet our quality criteria
              return candidate;
            }
            
            // Important: Find the enriched version of this candidate
            const enrichedVersion = enrichedCandidates.find(ec => ec.id === candidate.id);
            // If found, use that entire enriched object instead of just adding properties
            if (enrichedVersion) {
              return enrichedVersion;
            }
            // Fallback if not found (shouldn't happen) - use transformCandidateFull to ensure consistency
            const fetchedCandidate = fetchedCandidates.find(fc => fc.id === candidate.id);
            if (fetchedCandidate) {
              const transformed = transformCandidateFull(fetchedCandidate);
              return {
                ...transformed,
                recommendationScore: recMap[candidate.id].score,
                recommendationReason: recMap[candidate.id].reason,
                isFiltered: recMap[candidate.id].filtered || false
              };
            }
            // Last resort fallback
            return {
              ...candidate,
              recommendationScore: recMap[candidate.id].score,
              recommendationReason: recMap[candidate.id].reason,
              isFiltered: recMap[candidate.id].filtered || false
            };
          }
          return candidate;
        });
        
        // Debug log
        console.log(`Data update: ${newCandidates.length} new candidates, ${updatedExistingCandidates.length} existing candidates updated`);
        
        // Combine updated existing candidates and new candidates
        return [
          ...updatedExistingCandidates.filter(c => !recMap[c.id]),
          ...filteredEnrichedCandidates
        ];
      });

      // Explicitly set the candidates state as well
      setCandidates(filteredEnrichedCandidates);

      // Show recommendation view
      setShowingRecommendations(true);

      // Improved role title setting with better logging and handling
      // Only try to set this if we don't already have a title from the recommendations API
      if (!roleTitle) {
        const matchedRole = roles.find((r) => r.id === roleId);
        if (matchedRole && matchedRole.fields && matchedRole.fields["Role Title"]) {
          console.log(`Setting role title to "${matchedRole.fields["Role Title"]}" from matched role ID ${roleId}`);
          setRecommendedRoleTitle(matchedRole.fields["Role Title"]);
        } else {
          console.warn(`Role ID ${roleId} not found in loaded roles or missing title. Roles length: ${roles.length}`);
          // If we're setting "Unknown Role" here, we'll try to recover it later in the useEffect we added
          setRecommendedRoleTitle("Unknown Role");
          
          // This is a good place to trigger a roles refresh if needed
          if (roles.length === 0 || rolesNeedUpdate) {
            console.log("Triggering roles refresh due to missing role data");
            setRolesNeedUpdate(true);
          }
        }
      }

      // Mark as processed to avoid reprocessing
      setProcessedRoles(prev => ({
        ...prev,
        [roleId]: {
          candidateIds,
          timestamp: Date.now(),
          notificationShown: true,
          fromAirtable: true
        }
      }));

      // Complete the progress
      setSmoothProgress(100, false, 'Completed! Displaying candidates...');
      console.log(`✅ Successfully loaded ${filteredEnrichedCandidates.length} recommended candidates`);
      
      setLoadingFromAirtable(false);
      setIsRecommendationLoading(false);
    } catch (error) {
      console.error("Error loading recommendations from Airtable:", error);
      setError(`Error: ${error.message || "Failed to load recommendations"}`);
      setLoadingFromAirtable(false);
      setIsRecommendationLoading(false);
      setSmoothProgress(0);
    }
  };

  // Add function to save recommendations to Airtable
  const saveRecommendationsToAirtable = async (roleId, candidateIds, recommendationMap) => {
    if (!roleId || !candidateIds || candidateIds.length === 0) {
      console.error("Cannot save recommendations: missing required data");
      return null;
    }
    
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/candidates/saveRecommendationsToNewTables",
        {
          roleId,
          candidates: candidateIds,
          recommendationData: recommendationMap
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      
      console.log("Recommendations saved to Airtable:", response.data);
      return response.data;
    } catch (err) {
      console.error("Error saving recommendations to Airtable:", err);
      return null;
    }
  };

  // Add useEffect to check for URL parameters on initial load
  useEffect(() => {
    // Only proceed if we have a roleId in the URL
    if (!urlRoleId) return;
    
    // Get the processed roles from local storage or state
    const processedRole = processedRoles[urlRoleId] || {};
    
    // Make sure roles are loaded before attempting to load recommendations
    if (rolesNeedUpdate && urlRoleId) {
      // Fetch roles first
      const fetchRolesFirst = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get("/api/roles/fetchRoles", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.data && response.data.roles) {
            setRoles(response.data.roles);
            setRolesNeedUpdate(false);
            
            // Now that roles are loaded, proceed with recommendations if not already loading
            if (!isRecommendationLoading && 
                !showingRecommendations && 
                !loadingFromAirtable && 
                !processedRole.loadError) {
              console.log(`URL contains roleId: ${urlRoleId}, loading recommendations after roles fetch`);
              loadRecommendationsFromAirtable(urlRoleId);
            }
          }
        } catch (error) {
          console.error("Error fetching roles:", error);
          addToast("Failed to load roles. Please refresh the page and try again.", "error");
        }
      };
      
      fetchRolesFirst();
    } else if (!isRecommendationLoading && 
        !showingRecommendations && 
        !loadingFromAirtable && 
        !processedRole.loadError) {
      console.log(`URL contains roleId: ${urlRoleId}, loading recommendations from Airtable`);
      loadRecommendationsFromAirtable(urlRoleId);
    }
  }, [urlRoleId, isRecommendationLoading, showingRecommendations, loadingFromAirtable, processedRoles, rolesNeedUpdate]);
  // Ensure recommendations are shown if we have recommendation data
  useEffect(() => {
    const hasRecommendations = Object.keys(candidateRecommendationMap).length > 0;
    
    if (hasRecommendations && !showingRecommendations) {
      console.log(`Found ${Object.keys(candidateRecommendationMap).length} recommendations but showingRecommendations=false - enabling recommendations view`);
      setShowingRecommendations(true);
    }
  }, [candidateRecommendationMap, showingRecommendations]);

  // Log URL parameters for debugging
  useEffect(() => {
    if (urlRoleId) {
      console.log(`URL contains roleId: ${urlRoleId}, showingRecommendations=${showingRecommendations}, recommendations=${Object.keys(candidateRecommendationMap).length}`);
    }
  }, [urlRoleId, showingRecommendations, candidateRecommendationMap]);

  // Special loading mechanism specifically for URL-based roleId
  useEffect(() => {
    if (!urlRoleId) return;
    
    console.log(`Initial load with roleId in URL: ${urlRoleId}`);

    // Check for recommendation data from API if we still don't have a valid role title
    const checkForRecommendationData = async () => {
      if (recommendedRoleTitle === "" || recommendedRoleTitle === "Unknown Role") {
        try {
          const token = localStorage.getItem("token");
          if (!token) return;
          
          // Make a direct API call to see if we can get the role title
          console.log("Trying to fetch role title from API directly");
          const response = await axios.get(
            `/api/candidates/fetchRecommendations?roleId=${urlRoleId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          if (response.data && response.data.roleTitle) {
            console.log(`Setting role title to "${response.data.roleTitle}" from direct API call`);
            setRecommendedRoleTitle(response.data.roleTitle);
          }
        } catch (err) {
          console.log("Error fetching role title from API:", err);
        }
      }
    };

    // Try to set role title from roles data if available
    if (roles.length > 0 && (recommendedRoleTitle === "" || recommendedRoleTitle === "Unknown Role")) {
      const matchedRole = roles.find(r => r.id === urlRoleId);
      if (matchedRole && matchedRole.fields && matchedRole.fields["Role Title"]) {
        console.log(`Setting role title to "${matchedRole.fields["Role Title"]}" from URL roleId ${urlRoleId}`);
        setRecommendedRoleTitle(matchedRole.fields["Role Title"]);
      } else {
        // If we can't find the role in the roles array, try the API
        checkForRecommendationData();
      }
    } else if (recommendedRoleTitle === "" || recommendedRoleTitle === "Unknown Role") {
      // If roles aren't loaded yet, try the API
      checkForRecommendationData();
    }

    // Check if we're coming from a "View matches for:" click by looking at the URL
    const queryParams = new URLSearchParams(window.location.search);
    const isFromProcessed = queryParams.get('fromProcessed') === 'true';
    
    // Check if recommendations are already loaded for this role
    if (showingRecommendations && Object.keys(candidateRecommendationMap).length > 0) {
      console.log('Already showing recommendations with data, not reloading');
      return;
    }
    
    // Only reload if not currently loading
    if (isRecommendationLoading || loadingFromAirtable) {
      console.log('Loading already in progress, not starting a new load');
      return;
    }
    
    // If we're coming directly to a processed role URL, we want to preserve processed roles
    if (!isFromProcessed) {
      // Clear previous errors or state for this role ONLY if not coming from a "View matches for:" click
      setProcessedRoles(prev => {
        const newProcessedRoles = { ...prev };
        // Always remove this role from processed roles to allow a fresh attempt when directly accessing via URL
        if (newProcessedRoles[urlRoleId]) {
          console.log(`Clearing previous state for role ${urlRoleId} to allow fresh load from URL`);
          delete newProcessedRoles[urlRoleId];
        }
        return newProcessedRoles;
      });
    }
    
    // Use the loadRecommendationsFromAirtable function for consistent data transformation,
    // instead of the forceLoadRecommendations function
    console.log(`Loading recommendations for direct URL navigation to ${urlRoleId}`);
    loadRecommendationsFromAirtable(urlRoleId);
    
    // No cleanup needed
    return () => {};
  }, [urlRoleId, isRecommendationLoading, showingRecommendations, loadingFromAirtable, candidateRecommendationMap, loadRecommendationsFromAirtable, roles, recommendedRoleTitle]);

  // Add this debug function to help with testing - add it anywhere in the component body
  const resetRecommendationErrors = () => {
    console.log("Resetting all recommendation errors");
    setProcessedRoles({});
    window.location.reload();
  };

  // Add this debug test function right after the resetRecommendationErrors function
  const testRecommendationAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No authentication token found");
        addToast("No authentication token found", "error");
        return;
      }
      
      console.log(`Testing recommendation API for roleId: ${urlRoleId}`);
      addToast(`Testing API for role ${urlRoleId}...`, "info");
      
      // Make direct API call to test
      const response = await axios.get(
        `/api/candidates/fetchRecommendations?roleId=${urlRoleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      console.log("API Response:", response.data);
      
      if (response.data && response.data.candidateIds) {
        addToast(`Success! Found ${response.data.candidateIds.length} recommendations`, "success");
        // Start a full reload of recommendations
        resetRecommendationErrors();
      } else {
        addToast("API returned success but no candidates found", "error");
      }
    } catch (error) {
      console.error("Error testing API:", error);
      addToast(`API Error: ${error.response?.status} ${error.response?.data?.message || error.message}`, "error");
    }
  };

  // Add this debug test function right after the resetRecommendationErrors function
  const debugRecommendations = () => {
    console.log("=== RECOMMENDATION DEBUG INFO ===");
    console.log("URL Role ID:", urlRoleId);
    console.log("Selected Role ID:", selectedRoleId);
    console.log("Role Title:", recommendedRoleTitle);
    console.log("Recommendation Map Size:", Object.keys(candidateRecommendationMap).length);
    console.log("Showing Recommendations:", showingRecommendations);
    console.log("Is Loading:", isRecommendationLoading || loadingFromAirtable);
    console.log("Processed Roles:", processedRoles);
    
    // Check sample recommendations
    console.log("First 3 Recommendation Examples:", 
      Object.entries(candidateRecommendationMap).slice(0, 3).map(([id, data]) => ({
        candidateId: id,
        score: data.score,
        reason: data.reason?.substring(0, 30) + "..."
      }))
    );
    
    // Check data in the filtered candidates
    if (filteredData.length > 0) {
      console.log("Filtered Data Sample:", 
        filteredData.slice(0, 3).map(c => ({ 
          id: c.id, 
          name: c.name || "NO NAME", 
          hasImage: !!c.image,
          hasRecommendation: !!candidateRecommendationMap[c.id],
          directRecommendationScore: c.recommendationScore || "None",
          directRecommendationReason: c.recommendationReason ? "Has reason" : "No reason"
        }))
      );
    } else {
      console.log("No filtered data available");
    }
    
    console.log("================================");
    
    // Return helper functions that can be called from console
    return {
      reloadRecommendations: () => {
        console.log("Manually triggering recommendation reload for role:", urlRoleId);
        setProcessedRoles({});
        setShowingRecommendations(false);
        setCandidateRecommendationMap({});
        setTimeout(() => window.location.reload(), 500);
      },
      fixMissingData: () => {
        if (!urlRoleId) {
          console.log("No role ID in URL, cannot fix data");
          return;
        }
        
        console.log("Attempting to fix missing data for role:", urlRoleId);
        const token = localStorage.getItem("token");
        
        // Force a direct API call to get recommendations
        axios.get(`/api/candidates/fetchRecommendations?roleId=${urlRoleId}`, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
          console.log("API response:", response.data);
          // You can add more code here to manually update the state if needed
        }).catch(err => {
          console.error("Error fetching recommendations:", err);
        });
      }
    };
  };

  // Add to window object so we can call from console
  window.debugRecommendations = debugRecommendations;

  // Add this useEffect to ensure role title is updated when roles are loaded
  useEffect(() => {
    // Only run this effect if we have a role ID in the URL and roles are loaded
    if (urlRoleId && roles.length > 0 && (recommendedRoleTitle === "" || recommendedRoleTitle === "Unknown Role")) {
      const matchedRole = roles.find(r => r.id === urlRoleId);
      if (matchedRole && matchedRole.fields && matchedRole.fields["Role Title"]) {
        console.log(`Setting role title to ${matchedRole.fields["Role Title"]} from roles data`);
        setRecommendedRoleTitle(matchedRole.fields["Role Title"]);
      }
    }
  }, [urlRoleId, roles, recommendedRoleTitle]);

  // Add state to track if we're coming from a processed recommendation
  const [isFromProcessed, setIsFromProcessed] = useState(false);

  // Add useEffect to check for fromProcessed parameter when component mounts or URL changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setIsFromProcessed(queryParams.get('fromProcessed') === 'true');
  }, [location.search]); // Re-run when URL search parameters change

  // Determine if we should show the edit button:
  // - Show if we're coming from a processed recommendation (fromProcessed=true)
  // - Show if we're in an active generation session (we have JD analysis data in the current state)
  const shouldShowEditButton = () => {
    // Case 1: If we have the fromProcessed URL parameter, show the button
    if (isFromProcessed) return true;
    
    // Case 2: If we have an active generation session, show the button
    // We check by seeing if:
    // 1. We have recommendation data
    // 2. We're NOT navigating directly to a URL (which would be the case if !isFromProcessed && urlRoleId)
    const hasRecommendations = Object.keys(candidateRecommendationMap).length > 0;
    const isDirectUrlNavigation = !isFromProcessed && urlRoleId;
    
    return hasRecommendations && !isDirectUrlNavigation;
  };

  return (
    <div className="relative md:pl-56 bg-white min-h-screen">
      <style>{progressBarAnimation}</style>
      
      {/* Floating Beta Indicator */}
      <div className="fixed bottom-4 right-4 z-50 bg-[#02B491] text-white px-3 py-2 rounded-full shadow-lg hover:scale-105 transition-transform">
        <div className="flex items-center gap-2">
          <span className="font-bold">BETA</span>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-5 h-5"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09z" 
            />
          </svg>
        </div>
      </div>
      
      {filtersModalOpen && (
        <FiltersModal
          setFiltersModalOpen={setFiltersModalOpen}
          filterYearsOfExp={filterYearsOfExp}
          handleYearsOfExpFilterChange={handleYearsOfExpFilterChange}
          filterWorkingStyle={filterWorkingStyle}
          handleWorkingStyleFilterChange={handleWorkingStyleFilterChange}
          filterIndustry={filterIndustry}
          setFilterIndustry={setFilterIndustry}
          filterStream={filterStream}
          handleStreamFilterChange={handleStreamFilterChange}
          length={allFilteredCandidates.length}
          maxSalary={maxSalary}
          setMaxSalary={setMaxSalary}
          clearFilters={clearFilters}
        />
      )}

      {selectedCandidate ? (
        <Candidate
          candidate={selectedCandidate}
          isInList={savedCandidates.some((c) => c.id === selectedCandidate.id)}
          onToggleCandidate={toggleCandidate}
          onSelectCandidate={handleSelectCandidate}
        />
      ) : (
        <>
          <div className="sticky top-0 z-50">
            <RoleDropBanner />
          </div>

          {/* Beta banner */}
          <div className="bg-gradient-to-r from-[#02B491] to-[#03d6ab] text-white py-2 px-4 text-center relative">
            <div className="flex items-center justify-center gap-2">
              <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-white text-[#02B491] animate-pulse">
                BETA
              </span>
              <p className="font-medium">You're exploring our new AI-powered candidate matching system. We'd love your feedback!</p>

            </div>
          </div>

          <div className="bg-white px-5 pt-7 sticky top-0 pb-1 z-40 w-full border-b">
            <div className="ml-4 mb-2">
              <div className="flex mt-4 mb-2 items-center gap-8">
                <h2 className="text-3xl">
                  {showingRecommendations 
                    ? (
                        <span className="flex items-center">
                          <span className="text-[#02B491] mr-2">AI Recommendations</span>
                          <span className="text-2xl">for {recommendedRoleTitle}</span>
                          <span className="inline-flex items-center px-2 py-1 ml-2 rounded-md text-xs font-bold bg-[#02B49115] text-[#02B491] border border-[#02B49130]">
                            BETA
                          </span>
                        </span>
                      )
                    : (
                        <span className="flex items-center">
                          Candidates
        
                        </span>
                      )
                  }
                </h2>
                {showingRecommendations ? (
                  <div className="flex flex-col">
                    <button
                      onClick={clearRecommendations}
                      className="flex items-center text-[#02B491] hover:text-[#018c70] mt-2"
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5} 
                        stroke="currentColor" 
                        className="w-5 h-5 mr-1"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" 
                        />
                      </svg>
                      Return to all candidates
                    </button>
                    {/* Show the "Edit and review criteria" button if we're either:
                        1. Coming from a normal flow with the fromProcessed parameter, OR
                        2. In an active recommendation generation session */}
                    {shouldShowEditButton() && (
                      <button
                        onClick={() => {
                          setIsEditingCriteria(true);
                          // Set selectedRoleForTags to ensure we're editing the current role
                          const currentRole = roles.find(r => r.id === selectedRoleId);
                          if (currentRole) {
                            setSelectedRoleForTags(currentRole);
                          }
                          setTagReviewModalOpen(true);
                        }}
                        className="flex items-center text-[#02B491] hover:text-[#018c70] mt-2"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          strokeWidth={1.5} 
                          stroke="currentColor" 
                          className="w-5 h-5 mr-1"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" 
                          />
                        </svg>
                        Review and Edit Criteria
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="flex mt-2 gap-2">
                    <div>
                      <span className="font-bold">{allFilteredCandidates.length} </span>
                      candidates matching your search.
                    </div>
                    
                  </div>
                )}
              </div>
              <div className="mb-1">
                <div className="flex pt-2">
                  <div className="relative w-[500px]">
                    <input
                      type="text"
                      id="voice-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-md focus:ring-[#02B491] focus:border-[#02B491] block w-full p-2.5"
                      placeholder="Search through CVs and profiles"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      required
                    />
                    <button
                      type="button"
                      className="absolute rounded-md bg-[#02B491] text-white m-1 py-2 px-4 inset-y-0 end-0 flex justify-center items-center"
                      onClick={handleSearch}
                    >
                      <svg
                        className="w-4 h-4 me-2 mr-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      Search
                    </button>
                  </div>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setFiltersModalOpen(true);
                    }}
                    className="py-2 px-4 ml-2 flex rounded-lg border items-center justify-center border-gray-500 hover:border-black hover:bg-black/5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                      />
                    </svg>
                    Filters
                  </button>

                  <button
                    onClick={() => getAIRecommendations(null)}
                    className="bg-[#e6f7f4] hover:bg-[#d0f0ea] active:bg-[#b3e6db] text-[#02B491] hover:text-[#028d72] flex items-center px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02B491] focus:ring-opacity-50 transition-all duration-200 hover:shadow-sm ml-4"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      strokeWidth={1.5} 
                      stroke="currentColor" 
                      className="w-5 h-5 mr-2"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" 
                      />
                    </svg>
                    Get AI Recommendations
                    <span className="inline-flex items-center px-1.5 py-0.5 ml-2 rounded text-xs font-medium bg-[#02B49115] text-[#02B491] border border-[#02B49130]">
                      BETA
                    </span>
                  </button>
                </div>

                {/* Already-processed roles */}
                {Object.keys(processedRoles).length > 0 && (
                  <div className="mt-4">
                    <div className="flex flex-wrap gap-2 items-center">
                      <span className="text-sm font-medium text-gray-700">
                        View matches for:
                      </span>
              
                      {Object.keys(processedRoles).map((rId) => {
                        // Add safety check before using array methods
                        const role = Array.isArray(roles) ? roles.find(r => r.id === rId) : null;
                        if (!role) return null;
                        
                        return (
                          <button 
                            key={rId} 
                            onClick={() => navigate(`/browse-candidates-recommendation/${rId}`)}
                            className="px-3 py-1.5 bg-white border border-[#02B49130] rounded-md text-sm text-gray-700 hover:border-[#02B491] hover:text-[#02B491] transition-colors hover:bg-[#02B49110] flex items-center gap-1 shadow-sm"
                          >
                            <svg 
                              xmlns="http://www.w3.org/2000/svg" 
                              fill="none" 
                              viewBox="0 0 24 24" 
                              strokeWidth={1.5} 
                              stroke="currentColor" 
                              className="w-4 h-4 text-[#02B491]"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09z" 
                              />
                            </svg>
                            {role["Role Title"]}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <ActiveFilters
                filterStream={filterStream}
                filterWorkingStyle={filterWorkingStyle}
                filterIndustry={filterIndustry}
                filterYearsOfExp={filterYearsOfExp}
                searchKeywords={searchKeywords}
                clearFilters={clearFilters}
                onChange={onChange}
                maxSalary={maxSalary}
                setMaxSalary={setMaxSalary}
                setSearchKeywords={setSearchKeywords}
                setFilterYearsOfExp={setFilterYearsOfExp}
              />
            </div>
          </div>

          {isInterviewRequest && (
            <InterviewRequest
              setIsInterviewRequest={setIsInterviewRequest}
              clear={clear}
              setSuccess={setSuccess}
            />
          )}

          <div className="flex ml-auto h-full pb-20 justify-center items-center bg-white">
            <div className="max-w-[1500px] w-full">
              {isRecommendationLoading && (
                <div className="w-full max-w-[1500px] px-4 mb-8">
                  <div className="my-6">
                    <div className="flex items-center gap-3">
                      <h2 className="text-xl font-semibold text-gray-900">We're finding your perfect matches! 🔍</h2>
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-[#02B49115] text-[#02B491] border border-[#02B49130]">
                        BETA
                      </span>
                    </div>
                    <p className="text-gray-600 mt-1">Sit tight—it usually takes about 3 minutes.</p>
                    <p className="text-gray-600 mt-1">Feel free to explore candidates below — we'll have your tailored recommendations ready shortly.</p>
                    
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4 overflow-hidden">
                      <div 
                        className="bg-emerald-500 h-2.5 rounded-full transition-all duration-700 ease-out"
                        style={{ width: `${isNaN(recommendationProgress) ? 0 : Math.round(recommendationProgress)}%` }}
                      ></div>
                    </div>
                    <div className="mt-2 flex justify-between">
                      <span className="text-xs text-gray-500">
                        {progressLabel}
                      </span>
                      <span className="text-sm font-medium text-gray-700">
                        {isNaN(recommendationProgress) ? 0 : Math.round(recommendationProgress)}%
                      </span>
                    </div>
                  </div>
                  
                  
                  {/* Improved skeleton cards - smaller with more animation */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-4 mt-8">
                    {/* Card 1 - with staggered animation timing */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-gray-200 transition-all duration-300 transform animate-float">
                      {/* Image placeholder with enhanced shimmer effect */}
                      <div className="h-48 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 relative overflow-hidden">
                        <div className="absolute inset-0 animate-shimmer"></div>
                      </div>
                      
                      {/* Content area */}
                      <div className="p-4">
                        {/* Name placeholder */}
                        <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md w-2/3 mb-3 animate-pulse-soft"></div>
                        
                        {/* Tags placeholder with staggered pulse */}
                        <div className="flex gap-2 mb-4">
                          <div className="h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded-md w-16 animate-pulse-soft"></div>
                          <div className="h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded-md w-20 animate-pulse-soft delay-75"></div>
                        </div>
                        
                        {/* Text line placeholders with varying widths */}
                        <div className="space-y-2 mb-4">
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-full animate-pulse-soft"></div>
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-5/6 animate-pulse-soft delay-100"></div>
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-4/6 animate-pulse-soft delay-200"></div>
                        </div>
                        
                        {/* Button placeholder */}
                        <div className="h-8 bg-gradient-to-r from-emerald-100 via-emerald-50 to-emerald-100 rounded-md w-full animate-pulse-soft"></div>
                      </div>
                    </div>
                    
                    {/* Card 2 - with different animation timing */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-gray-200 transition-all duration-300 transform animate-float delay-150">
                      {/* Image placeholder with enhanced shimmer effect */}
                      <div className="h-48 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 relative overflow-hidden">
                        <div className="absolute inset-0 animate-shimmer delay-150"></div>
                      </div>
                      
                      {/* Content area */}
                      <div className="p-4">
                        {/* Name placeholder */}
                        <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md w-3/4 mb-3 animate-pulse-soft delay-50"></div>
                        
                        {/* Tags placeholder with staggered pulse */}
                        <div className="flex gap-2 mb-4">
                          <div className="h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded-md w-24 animate-pulse-soft delay-100"></div>
                          <div className="h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded-md w-16 animate-pulse-soft delay-175"></div>
                        </div>
                        
                        {/* Text line placeholders with varying widths */}
                        <div className="space-y-2 mb-4">
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-5/6 animate-pulse-soft delay-75"></div>
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-full animate-pulse-soft delay-125"></div>
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-3/4 animate-pulse-soft delay-175"></div>
                        </div>
                        
                        {/* Button placeholder */}
                        <div className="h-8 bg-gradient-to-r from-emerald-100 via-emerald-50 to-emerald-100 rounded-md w-full animate-pulse-soft delay-100"></div>
                      </div>
                    </div>
                    
                    {/* Card 3 - with different animation timing */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-gray-200 transition-all duration-300 transform animate-float delay-300">
                      {/* Image placeholder with enhanced shimmer effect */}
                      <div className="h-48 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 relative overflow-hidden">
                        <div className="absolute inset-0 animate-shimmer delay-300"></div>
                      </div>
                      
                      {/* Content area */}
                      <div className="p-4">
                        {/* Name placeholder */}
                        <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md w-1/2 mb-3 animate-pulse-soft delay-100"></div>
                        
                        {/* Tags placeholder with staggered pulse */}
                        <div className="flex gap-2 mb-4">
                          <div className="h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded-md w-20 animate-pulse-soft delay-200"></div>
                          <div className="h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded-md w-28 animate-pulse-soft delay-275"></div>
                        </div>
                        
                        {/* Text line placeholders with varying widths */}
                        <div className="space-y-2 mb-4">
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-full animate-pulse-soft delay-150"></div>
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-4/5 animate-pulse-soft delay-225"></div>
                          <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded w-5/6 animate-pulse-soft delay-300"></div>
                        </div>
                        
                        {/* Button placeholder */}
                        <div className="h-8 bg-gradient-to-r from-emerald-100 via-emerald-50 to-emerald-100 rounded-md w-full animate-pulse-soft delay-200"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {loading ? (
                <div className="pt-20 px-5 pl-2">
                  <CandidatesLoading />
                </div>
              ) : (
                <div>
                  <div className="space-y-4">
                    {/* Add recommendation status banner for debugging and user clarity */}
 
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-4">
                      {filteredData.map((candidate, index) => (
                        <GeneralistCard
                          key={index}
                          candidate={candidate}
                          isInList={savedCandidates.some(
                            (c) => c.id === candidate.id
                          )}
                          onToggleCandidate={toggleCandidate}
                          onSelectCandidate={handleSelectCandidate}
                          recommendedCandidates={recommendedCandidates}
                          roles={roles}
                          setRoles={setRoles}
                          shortlistedCandidates={shortlistedCandidates}
                          setRolesNeedUpdate={setRolesNeedUpdate}
                          setNewRoleForm={setNewRoleForm}
                          // Only pass recommendation data if we're showing recommendations, and only include reason once
                          recommendationData={showingRecommendations ? {
                            score: candidateRecommendationMap[candidate.id]?.score || candidate.recommendationScore || 0,
                            reason: candidateRecommendationMap[candidate.id]?.reason || candidate.recommendationReason || "",
                            filtered: candidateRecommendationMap[candidate.id]?.filtered || candidate.isFiltered || false
                          } : null}
                          showRecommendationInfo={showingRecommendations}
                          candidatesStatus={candidatesStatus}
                          addToast={addToast}
                          handleSelectCandidate={handleSelectCandidate}
                        />
                      ))}
                    </div>
                    
                    {totalPages > 1 && (
                      <div className="flex justify-center items-center space-x-4 py-4 mt-4">
                        <button
                          onClick={() => {
                            setCurrentPage(prev => Math.max(prev - 1, 1));
                            setTimeout(() => {
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }, 10);
                          }}
                          disabled={currentPage === 1}
                          className={`
                            px-4 py-2 rounded-md text-sm font-medium transition-colors
                            ${currentPage === 1 
                              ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                              : 'bg-white border border-gray-200 text-gray-700 hover:bg-gray-50'}
                          `}
                        >
                          Previous
                        </button>
                        
                        <div className="text-sm text-gray-500">
                          Page {currentPage} of {totalPages}
                        </div>

                        <button
                          onClick={() => {
                            const calculatedTotalPages = Math.ceil(allFilteredCandidates.length / CANDIDATES_PER_PAGE);
                            
                            if (nextPageData && currentPage < calculatedTotalPages) {
                              setCurrentPage(prev => prev + 1);
                              setNextPageData(null);
                            } else {
                              setCurrentPage(prev => Math.min(prev + 1, calculatedTotalPages));
                            }
                            
                            setTimeout(() => {
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }, 10);
                          }}
                          disabled={currentPage === totalPages}
                          className={`
                            px-4 py-2 rounded-md text-sm font-medium transition-colors
                            ${currentPage === totalPages 
                              ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                              : 'bg-[#02B491] text-white hover:bg-[#029e80]'}
                          `}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {newRoleForm && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                    <h2 className="text-lg font-semibold mb-4">Add New Role</h2>
                    <form onSubmit={handleNewRoleSubmit}>
                      <label
                        htmlFor="title"
                        className="block text-md font-normal leading-6 text-gray-900"
                      >
                        Role Title
                      </label>
                      <input
                        type="text"
                        value={newRoleTitle}
                        onChange={(e) => setNewRoleTitle(e.target.value)}
                        placeholder="Sales"
                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                        required
                      />
                      <button
                        type="submit"
                        className="block w-full px-4 py-2 bg-[#02B491] text-sm text-white rounded hover:bg-[#00f2c2] hover:text-black"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={() => setNewRoleForm(false)}
                        className="block w-full px-4 py-2 mt-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              )}
              
              {/* Toast notifications */}
              {toasts.length > 0 && (
                <>
                  <div className="fixed inset-0 z-40 bg-black opacity-10 pointer-events-none"></div>
                  <div className="relative z-50">
                    <div className="fixed top-0 right-0 flex flex-col items-end p-6 space-y-3">
                      {toasts.map((toast, index) => {
                        let iconContent = null;
                        let messageContent = null;
                        let actionButton = null;
                        
                        if (toast.type === "success") {
                          iconContent = (
                            <svg className="w-5 h-5 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                            </svg>
                          );
                          messageContent = <span className="font-normal">{toast.message}</span>;
                          actionButton = null;
                        } else if (toast.type === "error") {
                          iconContent = (
                            <svg className="w-5 h-5 text-red-500 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                          );
                          messageContent = <span className="font-normal">{toast.message}</span>;
                          actionButton = null;
                        } else if (toast.type === "shortlist") {
                          iconContent = (
                            <svg
                              className="text-green-500 mr-1 h-5 w-5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                          );
                          messageContent = (
                            <>
                              <span className="font-bold">{toast.message}</span>&nbsp;successfully added to shortlist.
                            </>
                          );
                          actionButton = (
                            <button
                              onClick={() => navigate("/shortlists")}
                              className="text-sm flex font-medium text-[#02B491] hover:bg-gray-100 rounded-lg p-1.5"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-5 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                                />
                              </svg>
                              View shortlists
                            </button>
                          );
                        }
                        
                        return (
                          <div
                            key={index}
                            className="fixed divide-x flex right-10 border z-50 justify-between items-center w-full max-w-[500px] p-4 bg-white rounded-lg shadow-lg mb-4"
                            role="alert"
                            style={{ top: `${10 + index * 70}px` }}
                          >
                            <div className="text-sm font-normal text-gray-500 flex">
                              {iconContent}
                              {messageContent}
                            </div>
                            {actionButton && (
                              <div className="flex items-center space-x-2 pl-2">
                                {actionButton}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      
      {/* Tag Review Modal */}
      <TagReviewModal
        open={tagReviewModalOpen}
        onClose={() => {
          setTagReviewModalOpen(false);
          setIsEditingCriteria(false);  // Reset the editing flag when closing
          setIsRecommendationLoading(false); // Restore the original code
          stopSmoothProgress(); // Restore the original code
        }}
        onProceed={handleTagReviewProceed}
        jdAnalysis={analyzedJD}
        roleTitle={selectedRoleForTags?.["Role Title"] || ""}
        isEditMode={isEditingCriteria}
      />
      
      {/* Recommendation Modal (phase=1 role selection only) */}
      {recommendModalOpen && (
        <RecommendationModal
          isOpen={recommendModalOpen}
          onClose={() => {
            setRecommendModalOpen(false);
            setIsRecommendationLoading(false);
            stopSmoothProgress();
          }}
          roles={roles}
          onGetRecommendations={getAIRecommendations}
          isLoading={isRecommendationLoading}
          isGPTLoading={isGPTAnalysisLoading}
          phase={recommendationProgress > 85 ? 3 : recommendationProgress > 50 ? 2 : recommendationProgress > 20 ? 1 : 0}
          candidatesFound={candidatesWithEmbeddings?.length || 0}
          roleTitle={recommendedRoleTitle}
        />
      )}
    </div>
  );
}

