import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Role() {
  const query = useQuery();
  const roleId = query.get("roleId");

  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // We'll store the assigned company and role spotlights here:
  const [companySpotlight, setCompanySpotlight] = useState(null);
  const [roleSpotlight, setRoleSpotlight] = useState(null);

  // For tab switching (company vs. role):
  const [activeTab, setActiveTab] = useState("company");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1) Fetch the role
        const roleResponse = await axios.get(`/api/roles/fetchRole?id=${roleId}`);
        const r = roleResponse.data.role;
        if (!r) {
          throw new Error("No role data found");
        }

        // Transform fields as before
        const transformedData = {
          id: r["id"] || "",
          job_description_pdf: r.fields["Job Description PDF"] ?? "",
          working_style: r.fields["Working Style"] ?? "",
          hiring_lead_email: r.fields["Hiring Lead Email Address"] ?? "",
          interview_process: r.fields["Interview Process"] ?? "",
          hiring_lead_name: r.fields["Hiring Lead Name"] ?? "",
          image:
            r.fields["Role Cover Image (from Startup)"]?.[0]?.url ??
            "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg",
          spotlight: r.fields["URL Cleaned (from Spotlight)"] ?? "",  // (unused if we do the new approach)
          compensation: r.fields["Compensation"] ?? "",
          startup_description: r.fields["Startup Description (from Startup)"] ?? "",
          website: r.fields["Website (from Startup)"] ?? "",
          team_size: r.fields["Team Size (from Startup)"] ?? "",
          funding: r.fields["Funding Stage (from Startup)"] ?? "",
          startup_name: r.fields["Name (from Startup)"]?.[0] ?? "",
          linkedin: r.fields["Startup Linkedin (from Startup)"] ?? "",
          title: r.fields["Role Title"] ?? "",
          key_experience: r.fields["Specific Experience"] ?? "",
          additional_info: r.fields["Additional Information"] ?? "",
          location: r.fields["Location (from Startup)"] ?? "",
          // We used to store single dropbox/loom in these:
          dropbox_url: r.fields["URL Dropbox cleaned (from Spotlight)"]?.[0] ?? null,
          loom_url: r.fields["URL Loom cleaned (from Spotlight)"]?.[0] ?? null,
          hiring_lead_linkedin: r.fields["Hiring Lead Linkedin"] ?? "",
          spotlight_context: r.fields["Spotlight Context"] ?? "",
          // The main difference: there's also "Spotlight" array (IDs) in Airtable
          spotlightIds: r.fields["Spotlight"] || [],
        };

        setRole(transformedData);

        // 2) If there are assigned Spotlights, fetch them
        const assignedIDs = transformedData.spotlightIds || [];
        if (assignedIDs.length > 0) {
          // If your spotlights are publicly accessible:
          // (If protected, you may need a token from localStorage, as in edit_role.js)
          let spsResp = await axios.get("/api/spotlights/fetchSpotlights");
          let allSps = spsResp.data.spotlights || [];

          // Filter out deleted or unassigned
          allSps = allSps.filter(
            (sp) => !sp.Deleted && assignedIDs.includes(sp.id)
          );

          // Find the relevant Company vs. Role spotlight
          const assignedCompany = allSps.find(
            (sp) =>
              sp.Type === "Company information" || sp.Type === "Company and Role"
          );
          const assignedRole = allSps.find(
            (sp) => sp.Type === "Role information" || sp.Type === "Company and Role"
          );

          // Save them in state
          setCompanySpotlight(assignedCompany || null);
          setRoleSpotlight(assignedRole || null);
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message || "Failed to load role data");
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId]);

  if (loading) {
    return (
      <div className="flex flex-col items-center bg-white pb-20">
        <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
          <div className="flex items-center mb-6">
            <img
              src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
              className="h-6 my-2 px-4 sm:block hidden"
              alt="Jumpstart Logo"
            />
          </div>
        </div>
        <div className="max-w-[700px] flex flex-col h-full mt-20 bg-white animate-pulse">
          <div className="h-[400px] w-[700px] bg-gray-300 rounded-xl"></div>
          <div className="h-6 w-64 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="flex h-6 mt-2">
            <div className="h-6 w-36 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-32 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-20 mr-2 bg-gray-300 rounded"></div>
          </div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="h-40 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-60 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-60 bg-gray-300 rounded mt-2"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <p className="text-red-600">Error: {error}</p>;
  }

  if (!role) {
    return <p>No role data found</p>;
  }

  // Utility for rendering a single spotlight
  const renderSpotlightVideo = (spot) => {
    if (!spot) {
      return (
        <div className="flex items-center justify-center h-[400px] w-[700px] bg-gray-200 text-gray-500">
          No spotlight assigned
        </div>
      );
    }

    const loomUrl = spot["URL Loom cleaned"];
    const dropboxUrl = spot["URL Dropbox cleaned"];

    if (dropboxUrl) {
      // If dropbox link ends with "?dl=0", we can replace with "?raw=1" to get direct video
      const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
      return (
        <video
          src={directVideoUrl}
          className="h-[400px] w-[700px] object-cover rounded-xl bg-[var(--overlay-2)]"
          controls
          onError={(e) => {
            console.error("Failed to load video at", e.target.src);
          }}
        />
      );
    } else if (loomUrl) {
      // Loom embed
      // The "ID" is typically after the last slash in the Loom URL, but we can just embed the full link if needed
      return (
        <iframe
          src={loomUrl}
          className="h-[400px] w-[700px] object-cover rounded-xl bg-[var(--overlay-2)]"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      // Fallback if no video URL
      return (
        <div className="flex items-center justify-center h-[400px] w-[700px] bg-gray-200 text-gray-500">
          No video available for this spotlight
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center bg-white pb-20">
      {/* Top-left Jumpstart logo */}
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>

      {/* Main container */}
      <div className="max-w-[700px] flex flex-col h-full mt-20 bg-white gap-2">
        {/* ------------- SPOTLIGHT SECTION AT TOP ------------- */}
        <div className="mb-6">
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveTab("company")}
              className={`px-4 py-2 rounded-lg ${
                activeTab === "company"
                  ? "bg-green-200 text-green-900 font-semibold"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              Company
            </button>
            <button
              onClick={() => setActiveTab("role")}
              className={`px-4 py-2 rounded-lg ${
                activeTab === "role"
                  ? "bg-green-200 text-green-900 font-semibold"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              Role
            </button>
          </div>

          <div className="mt-4">{/* Render the selected tab's spotlight video */}</div>
          {activeTab === "company"
            ? renderSpotlightVideo(companySpotlight)
            : renderSpotlightVideo(roleSpotlight)}
        </div>

        {role.spotlight_context && (
          <p className="mb-4 font-normal italic text-md text-gray-500">
            {role.spotlight_context}
          </p>
        )}

        {/* ------------- ROLE / STARTUP DETAILS BELOW ------------- */}
        <div className="font-normal pt-2 text-2xl text-gray-500">
          <p className="text-black">
            <span className="text-2xl font-semibold">{role.startup_name}</span> -{" "}
            {role.title}
          </p>
        </div>

        <div className="mt-2">
          {role.compensation && (
            <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-4 py-1 text-md md:text-md font-medium text-purple-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 
                     1.453-.342 1.453-1.096V18.75M3.75 
                     4.5v.75A.75.75 0 0 1 3 
                     6h-.75m0 0v-.375c0-.621.504-1.125 
                     1.125-1.125H20.25M2.25 
                     6v9m18-10.5v.75c0 
                     .414.336.75.75.75h.75m-1.5-1.5h.375c.621 
                     0 1.125.504 1.125 
                     1.125v9.75c0 .621-.504 1.125-1.125 
                     1.125h-.375m1.5-1.5H21a.75.75 0 
                     0 0-.75.75v.75m0 
                     0H3.75m0 
                     0h-.375a1.125 1.125 0 0 
                     1-1.125-1.125V15m1.5 
                     1.5v-.75A.75.75 0 0 0 3 
                     15h-.75M15 10.5a3 3 0 
                     1 1-6 0 3 3 0 0 1 6 
                     0Zm3 
                     0h.008v.008H18V10.5Zm-12 
                     0h.008v.008H6V10.5Z"
                />
              </svg>
              Salary range: {role.compensation}
            </span>
          )}
          {role.team_size && (
            <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-green-100 px-4 py-1 text-md md:text-md font-medium text-green-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 
                     0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 
                     3.198.001.031c0 .225-.012.447-.037.666A11.944 
                     11.944 0 0 1 12 21c-2.17 
                     0-4.207-.576-5.963-1.584A6.062 6.062 
                     0 0 1 6 18.719m12 
                     0a5.971 5.971 0 0 
                     0-.941-3.197m0 
                     0A5.995 5.995 0 0 0 12 
                     12.75a5.995 5.995 0 0 0-5.058 
                     2.772m0 0a3 3 0 0 0-4.681 
                     2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 
                     5.971 0 0 0-.94 3.197M15 
                     6.75a3 3 0 1 1-6 
                     0 3 3 0 0 1 6 
                     0Zm6 
                     3a2.25 2.25 0 1 1-4.5 
                     0 2.25 2.25 0 0 1 
                     4.5 0Zm-13.5 
                     0a2.25 2.25 0 1 1-4.5 
                     0 2.25 2.25 0 0 1 
                     4.5 0Z"
                />
              </svg>
              {role.team_size} people in the team
            </span>
          )}
          {role.funding && (
            <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-red-100 px-4 py-1 text-md md:text-md font-medium text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 
                     6a7.5 7.5 0 1 0 7.5 
                     7.5h-7.5V6Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 
                     10.5H21A7.5 7.5 0 0 0 13.5 
                     3v7.5Z"
                />
              </svg>
              {role.funding}
            </span>
          )}
          {role.location && (
            <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-blue-100 px-4 py-1 text-md md:text-md font-medium text-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 
                     10.5a3 3 0 1 1-6 
                     0 3 3 0 0 1 6 0Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 
                     10.5c0 7.142-7.5 11.25-7.5 
                     11.25S4.5 17.642 4.5 
                     10.5a7.5 7.5 0 1 1 15 
                     0Z"
                />
              </svg>
              {role.location}
            </span>
          )}
        </div>

        {role.startup_description && (
          <>
            <h2 className="mt-2 font-semibold text-md text-black">
              Company Description
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.startup_description}
            </p>
          </>
        )}

        {role.website && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Company Website
            </h2>
            <a
              onClick={() =>
                window.open(
                  role.website.startsWith("http")
                    ? role.website
                    : `https://${role.website}`,
                  "_blank"
                )
              }
              className="-mt-2 font-normal text-md text-blue-500 hover:underline cursor-pointer"
            >
              {role.website}
            </a>
          </>
        )}

        {role.job_description_pdf && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Job Description
            </h2>
            <a
              href={role.job_description_pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="-mt-2 font-normal text-md text-blue-500 hover:underline"
            >
              {role.job_description_pdf}
            </a>
          </>
        )}

        {role.key_experience && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Specific Experience
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.key_experience}
            </p>
          </>
        )}

        {role.interview_process && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Interview Process
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.interview_process}
            </p>
          </>
        )}

        {role.working_style && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Team Working Style
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.working_style}
            </p>
          </>
        )}

        {role.additional_info && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Additional Info
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.additional_info}
            </p>
          </>
        )}

        {role.hiring_lead_name && (
          <>
            <h2 className="mt-4 font-semibold text-md text-black">
              Hiring Lead
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.hiring_lead_linkedin ? (
                <a
                  href={role.hiring_lead_linkedin}
                  className="text-blue-500 hover:underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {role.hiring_lead_name}
                </a>
              ) : (
                <span>{role.hiring_lead_name}</span>
              )}
              {role.hiring_lead_email && <> - {role.hiring_lead_email}</>}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Role;
