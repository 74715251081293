import React, { useState, useEffect, useCallback } from "react";
import CompanyDetails from "../../../components/new_interview_request/company_details";
import RoleDetails from "../../../components/new_interview_request/role_details";
import Message from "../../../components/interview_request2/message";
import ShortlistRecap from "../../../components/interview_request2/shortlist_recap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import RolePreview from "../../../components/new_interview_request/role_preview";
import { Edit2, Trash2 } from "lucide-react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AddSpotlightModal({ isOpen, onClose, type, onAddSpotlight, spotlightToEdit }) {
  const isEditing = !!spotlightToEdit;
  const [name, setName] = useState("");
  const [loomUrl, setLoomUrl] = useState("");
  const [dropboxUrl, setDropboxUrl] = useState("");
  const [selectedSource, setSelectedSource] = useState("loom");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    if (isEditing) {
      setName(spotlightToEdit.Name || "");
      const loom = spotlightToEdit["URL Loom cleaned"] || "";
      const dropbox = spotlightToEdit["URL Dropbox cleaned"] || "";
      setLoomUrl(loom);
      setDropboxUrl(dropbox);
      setSelectedSource(loom ? "loom" : dropbox ? "dropbox" : "loom");
    } else {
      setName("");
      setLoomUrl("");
      setDropboxUrl("");
      setSelectedSource("loom");
    }
  }, [isOpen, isEditing, spotlightToEdit]);

  if (!isOpen) return null;

  const handleSave = () => {
    setIsLoading(true);
    const updated = isEditing
      ? { ...spotlightToEdit }
      : {
          id: Date.now(),
          Type: type === "role" ? "Role information" : "Company information",
        };

    updated.Name = name.trim();
    if (selectedSource === "loom") {
      updated["URL Loom cleaned"] = loomUrl.trim();
      updated["URL Dropbox cleaned"] = "";
    } else {
      updated["URL Loom cleaned"] = "";
      updated["URL Dropbox cleaned"] = dropboxUrl.trim();
    }

    onAddSpotlight(updated);
    setIsLoading(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl my-8">
        <h2 className="text-xl font-semibold mb-6">
          {isEditing ? "Edit spotlight video" : "Add a new spotlight"}
        </h2>

        <div className="space-y-6">
          <div className="flex gap-4">
            <div
              onClick={() => setSelectedSource("loom")}
              className={`flex-1 p-4 border rounded-md cursor-pointer transition-colors ${
                selectedSource === "loom" ? "border-[#625DF5] bg-[#625DF5]/5" : "border-gray-300"
              }`}
            >
              <div className="flex justify-between items-center">
                <span className="font-semibold text-lg">Loom</span>
                <a
                  href="https://www.loom.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-[#625DF5] hover:underline"
                  onClick={(e) => e.stopPropagation()}
                >
                  Visit Loom
                </a>
              </div>
              <p className="text-sm text-gray-600 mt-2">
                Use Loom to record your video.
              </p>
            </div>
            <div
              onClick={() => setSelectedSource("dropbox")}
              className={`flex-1 p-4 border rounded-md cursor-pointer transition-colors ${
                selectedSource === "dropbox" ? "border-blue-500 bg-blue-50" : "border-gray-300"
              }`}
            >
              <div className="flex justify-between items-center">
                <span className="font-semibold text-lg">Dropbox</span>
                <a
                  href="https://www.dropbox.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 hover:underline"
                  onClick={(e) => e.stopPropagation()}
                >
                  Visit Dropbox
                </a>
              </div>
              <p className="text-sm text-gray-600 mt-2">
                Use Dropbox to upload your file.
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Recording Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="e.g. Jumpstart: Match Making"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {selectedSource === "loom" ? "Loom Video URL" : "Dropbox File URL"}
              </label>
              <input
                type="url"
                value={selectedSource === "loom" ? loomUrl : dropboxUrl}
                onChange={(e) =>
                  selectedSource === "loom"
                    ? setLoomUrl(e.target.value)
                    : setDropboxUrl(e.target.value)
                }
                placeholder={
                  selectedSource === "loom"
                    ? "https://www.loom.com/your-video-link"
                    : "https://www.dropbox.com/s/..."
                }
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-8">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-900">
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={isLoading}
            className="px-6 py-2 bg-[#02B491] text-white rounded-full hover:bg-[#029478] flex items-center justify-center gap-2"
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  />
                </svg>
                Saving...
              </>
            ) : isEditing ? (
              "Save"
            ) : (
              "Upload"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

function CompactSpotlightCard({ spotlight, selected, onSelect, onDelete }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const loomUrl = spotlight["URL Loom cleaned"];
  const dropboxUrl = spotlight["URL Dropbox cleaned"];

  let previewElement = null;
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop();
    const loomEmbed = `https://www.loom.com/embed/${loomId}`;
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg overflow-hidden">
        <iframe
          title="Loom Preview"
          src={loomEmbed}
          frameBorder="0"
          allowFullScreen
          className="w-full h-full"
          sandbox="allow-same-origin allow-scripts allow-popups"
        />
      </div>
    );
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg overflow-hidden">
        <video src={directVideoUrl} className="w-full h-full object-cover" />
      </div>
    );
  } else {
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg flex items-center justify-center">
        <div className="flex flex-col items-center justify-center text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 mb-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
            />
          </svg>
          <p className="text-sm">No preview</p>
        </div>
      </div>
    );
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      await axios.patch(
        "/api/spotlights/updateSpotlightData",
        {
          spotlightId: spotlight.id,
          fields: { Deleted: true },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      onDelete(spotlight.id);
      setShowDeleteModal(false);
    } catch (err) {
      console.error("Error deleting spotlight:", err);
    }
  };

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(spotlight);
  };

  return (
    <>
      <div
        onClick={handleSelect}
        className={`group bg-white rounded-lg transition-all duration-200 overflow-hidden cursor-pointer
          ${
            selected
              ? "ring-2 ring-[#02B491] shadow-md"
              : "border border-gray-200 hover:border-gray-300 hover:shadow-sm"
          }`}
      >
        <div className="cursor-pointer">{previewElement}</div>
        <div className="p-4 flex items-center justify-between border-t border-gray-100">
          <div className="flex-grow">
            <p className="text-sm font-medium text-gray-700 truncate">
              {spotlight.Name || "Untitled"}
            </p>
            {selected && <p className="text-xs text-[#02B491] mt-1">Selected</p>}
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowDeleteModal(true);
            }}
            className="p-1.5 rounded-full hover:bg-gray-100 transition-all duration-200"
          >
            <Trash2 className="w-4 h-4 text-red-500" />
          </button>
        </div>
      </div>

      {showDeleteModal && (
        <div
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <h2 className="text-xl font-semibold mb-4">Delete Spotlight</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this spotlight? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SelectExistingSpotlightModal({
  isOpen,
  onClose,
  spotlights = [],
  currentlySelectedId,
  onSelect,
}) {
  if (!isOpen) return null;

  const handleSelect = (spotlight) => {
    onSelect(spotlight);
    onClose();
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-4xl my-8"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-semibold mb-4">Select a spotlight</h2>
        {spotlights.length === 0 && (
          <p className="text-gray-600">No spotlights found yet for this category.</p>
        )}
        {spotlights.length > 0 && (
          <div className="grid grid-cols-3 gap-4">
            {spotlights.map((sp) => (
              <CompactSpotlightCard
                key={sp.id}
                spotlight={sp}
                selected={currentlySelectedId === sp.id}
                onSelect={handleSelect}
                onDelete={() => {}}
              />
            ))}
          </div>
        )}
        <div className="flex justify-end mt-6">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-900">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function RequestInterview() {
  const navigate = useNavigate();
  const query = useQuery();
  const roleRequestId = query.get("roleRequestId");
  
  // Basic state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [roles, setRoles] = useState([]);
  const [activeStep, setActiveStep] = useState("Company Information");
  const [companyNeedUpdate, setCompanyNeedUpdate] = useState(true);
  const [roleNeedUpdate, setRoleNeedUpdate] = useState(true);
  const [firstTime, setFirstTime] = useState(true);

  // Spotlight states
  const [spotlights, setSpotlights] = useState([]);
  const [companySpotlights, setCompanySpotlights] = useState([]);
  const [roleSpotlights, setRoleSpotlights] = useState([]);
  const [selectedCompanySpotlight, setSelectedCompanySpotlight] = useState(null);
  const [selectedRoleSpotlight, setSelectedRoleSpotlight] = useState(null);
  const [spotlightNeedUpdate, setSpotlightNeedUpdate] = useState(false);

  // Modal states
  const [showSelectOrUploadCompany, setShowSelectOrUploadCompany] = useState(false);
  const [showSelectOrUploadRole, setShowSelectOrUploadRole] = useState(false);
  const [showSelectSpotlightModal, setShowSelectSpotlightModal] = useState(false);
  const [selectSpotlightType, setSelectSpotlightType] = useState(null);
  const [showAddSpotlightModal, setShowAddSpotlightModal] = useState(false);
  const [addSpotlightType, setAddSpotlightType] = useState(null);
  const [spotlightToEdit, setSpotlightToEdit] = useState(null);

  // Token check
  const checkToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  // Fetch candidates
  const fetchCandidates = useCallback(async (candidateIds, roleName, roleId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/candidates/fetchCandidates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ids: candidateIds.join(","),
        },
      });

      const candidates = response.data.candidates;
      const transformedData = candidates
        .filter(candidate => candidate.fields?.["Featured status"]?.[0] === "Featured")
        .map((candidate) => {
          let stream = candidate.fields?.["Stream (from CRM)"]?.[0] || "";

          return {
            id: candidate.id || "",
            url_id: candidate.fields?.["ID"] || "",
            featured: candidate.fields?.["Featured status"]?.[0] || "",
            name: candidate.fields?.["Name"] || "Unknown",
            education: Array.isArray(candidate.fields?.["Degree details"])
              ? candidate.fields["Degree details"]
              : [candidate.fields?.["Degree details"] || "No details available"],
            image: candidate.fields?.Photo ? candidate.fields.Photo[0]?.url || null : null,
            stream: stream,
            role: roleName,
            roleId: roleId,
            most_recent_cohort: candidate.fields?.["Most recent cohort"] || "",
          };
        });

      setCandidates(transformedData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  // Fetch company data
  const fetchCompanyData = useCallback(async () => {
    if (!companyNeedUpdate) return;
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const companyData = response.data.companyData;
      setCompany(companyData);
      setLoading(false);
      setCompanyNeedUpdate(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [companyNeedUpdate]);

  // Fetch role data
  const fetchRole = useCallback(async () => {
    if (!roleNeedUpdate) return;
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`/api/roles/fetchRole?id=${roleRequestId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const role = response.data.role;
      const transformedData = {
        id: role["id"],
        url_id: role.fields["URL ID"],
        equity: role.fields["Equity"],
        job_description_link: role.fields["Job Description PDF"],
        working_style: role.fields["Working Style"],
        hiring_lead_email: role.fields["Hiring Lead Email Address"],
        interview_process: role.fields["Interview Process"],
        hiring_lead_name: role.fields["Hiring Lead Name"],
        hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"],
        image: role.fields["Logo (from Startup)"]
          ? role.fields["Logo (from Startup)"][0].url
          : null,
        spotlight: role.fields["Spotlight"],
        compensation: role.fields["Compensation"],
        startup_description: role.fields["Startup Description (from Startup)"],
        website: role.fields["Website (from Startup)"],
        team_size: role.fields["Team Size (from Startup)"],
        funding: role.fields["Funding Stage (from Startup)"],
        startup_name: role.fields["Name (from Startup)"][0],
        linkedin: role.fields["Startup Linkedin (from Startup)"],
        title: role.fields["Role Title"],
        key_experience: role.fields["Specific Experience"],
        additional_info: role.fields["Additional Information"],
        location: role.fields["Location (from Startup)"],
        eye_catching_information: role.fields["Eye catching information"],
        open_to_applications: role.fields["Open to applications"] === "Open to apps" ? true : false,
        spotlight_context: role.fields["Spotlight Context"],
        shortlist: role.fields["Shortlisted Candidates"]
      };
      setRole(transformedData);

      if (role.fields["Selected Candidates"]) {
        const candidateIds = role.fields["Selected Candidates"];
        const roleName = role.fields["Role Title"];
        const roleId = role["id"];
        await fetchCandidates(candidateIds, roleName, roleId);
      } else {
        setLoading(false);
      }
      setRoleNeedUpdate(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [roleNeedUpdate, roleRequestId, fetchCandidates]);

  // Initial data load
  useEffect(() => {
    checkToken();
    fetchCompanyData();
    fetchRole();
  }, [checkToken, fetchCompanyData, fetchRole]);

  // Fetch spotlights
  useEffect(() => {
    const fetchSpotlights = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;
        const resp = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: { Authorization: `Bearer ${token}` },
        });
        let sps = resp.data.spotlights || [];
        sps = sps.filter((s) => !s.Deleted);

        setSpotlights(sps);
        const comp = sps.filter((sp) =>
          ["Company information", "Company and Role"].includes(sp.Type)
        );
        const roleSps = sps.filter((sp) => sp.Type === "Role information");
        setCompanySpotlights(comp);
        setRoleSpotlights(roleSps);

        // Only set spotlights if they are assigned to the role
        if (role && role.spotlight && Array.isArray(role.spotlight)) {
          const assignedIDs = role.spotlight;
          const assigned = sps.filter((s) => assignedIDs.includes(s.id));
          const assignedCompany = assigned.find((sp) =>
            ["Company information", "Company and Role"].includes(sp.Type)
          );
          const assignedRole = assigned.find((sp) => sp.Type === "Role information");
          setSelectedCompanySpotlight(assignedCompany || null);
          setSelectedRoleSpotlight(assignedRole || null);
        } else {
          // If no spotlights are assigned, set both to null
          setSelectedCompanySpotlight(null);
          setSelectedRoleSpotlight(null);
        }
      } catch (err) {
        console.error("Failed to load spotlights. Continuing with none.", err);
        setSpotlights([]);
        setCompanySpotlights([]);
        setRoleSpotlights([]);
        setSelectedCompanySpotlight(null);
        setSelectedRoleSpotlight(null);
      }
    };
    fetchSpotlights();
  }, [role]);

  // Spotlight update effect
  useEffect(() => {
    if (!spotlightNeedUpdate) return;
    const fetchAgain = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;
        const resp = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: { Authorization: `Bearer ${token}` },
        });
        let sps = resp.data.spotlights || [];
        sps = sps.filter((s) => !s.Deleted);

        setSpotlights(sps);
        const comp = sps.filter((sp) =>
          ["Company information", "Company and Role"].includes(sp.Type)
        );
        const roleSps = sps.filter((sp) => sp.Type === "Role information");
        setCompanySpotlights(comp);
        setRoleSpotlights(roleSps);

        setSpotlightNeedUpdate(false);
      } catch (err) {
        console.error("Failed to reload spotlights", err);
        setSpotlights([]);
        setCompanySpotlights([]);
        setRoleSpotlights([]);
        setSpotlightNeedUpdate(false);
      }
    };
    fetchAgain();
  }, [spotlightNeedUpdate]);

  // Step completion check
  useEffect(() => {
    if (!loading && company && role && firstTime) {
      const companyRequiredFields = ["name", "description", "location", "website"];
      const roleRequiredFields = ["title", "compensation", "equity", "job_description_link"];
      
      const isCompanyComplete = companyRequiredFields.every((field) => company[field]);
      const isRoleComplete = roleRequiredFields.every((field) => role[field]);

      if (isCompanyComplete) {
        if (isRoleComplete) {
          setActiveStep("Message");
        } else {
          setActiveStep("Role Details");
        }
      } else {
        setActiveStep("Company Information");
      }
      setFirstTime(false);
    }
  }, [company, role, loading, firstTime]);

  // Spotlight handlers
  const handleAddSpotlight = async (updated) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      const isTemp = typeof updated.id === "number";
      let newSpotlight;

      if (isTemp) {
        // Create new spotlight
        const dataToCreate = {
          fields: {
            Name: updated.Name,
            Type: updated.Type,
            "URL Loom": updated["URL Loom cleaned"] || "",
            "URL Dropbox": updated["URL Dropbox cleaned"] || "",
            Deleted: false,
          },
        };
        const response = await axios.post("/api/spotlights/createSpotlight", dataToCreate, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const newFields = response?.data?.newSpotlightData?.fields;
        if (!newFields) {
          throw new Error("Invalid server response");
        }

        newSpotlight = {
          id: newFields.id,
          Name: newFields.Name,
          Type: newFields.Type || updated.Type,
          "URL Loom cleaned": newFields["URL Loom cleaned"] || "",
          "URL Dropbox cleaned": newFields["URL Dropbox cleaned"] || "",
          Deleted: false
        };
      } else {
        // Edit existing spotlight
        const dataToUpdate = {
          spotlightId: updated.id,
          fields: {
            Name: updated.Name,
            Type: updated.Type,
            "URL Loom": updated["URL Loom cleaned"] || "",
            "URL Dropbox": updated["URL Dropbox cleaned"] || "",
          },
        };
        const response = await axios.patch("/api/spotlights/updateSpotlightData", dataToUpdate, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const updatedFields = response?.data?.updatedSpotlight?.fields;
        if (!updatedFields) {
          throw new Error("Invalid server response");
        }

        newSpotlight = {
          id: updatedFields.id,
          Name: updatedFields.Name,
          Type: updatedFields.Type || updated.Type,
          "URL Loom cleaned": updatedFields["URL Loom cleaned"] || "",
          "URL Dropbox cleaned": updatedFields["URL Dropbox cleaned"] || "",
          Deleted: false
        };
      }

      if (!newSpotlight || !newSpotlight.id) {
        throw new Error("Failed to create/update spotlight");
      }

      // Update state
      setSpotlights(prev => {
        const filtered = prev.filter(s => s.id !== newSpotlight.id);
        return [...filtered, newSpotlight];
      });

      if (newSpotlight.Type === "Role information") {
        setRoleSpotlights(prev => {
          const filtered = prev.filter(s => s.id !== newSpotlight.id);
          return [...filtered, newSpotlight];
        });
        setSelectedRoleSpotlight(newSpotlight);
      } else if (["Company information", "Company and Role"].includes(newSpotlight.Type)) {
        setCompanySpotlights(prev => {
          const filtered = prev.filter(s => s.id !== newSpotlight.id);
          return [...filtered, newSpotlight];
        });
        setSelectedCompanySpotlight(newSpotlight);
      }

      // Close modal
      setShowAddSpotlightModal(false);
      setSpotlightToEdit(null);
      setAddSpotlightType(null);

    } catch (err) {
      console.error("Failed to add/edit spotlight:", err);
      alert("Failed to save spotlight. Please try again.");
    }
  };

  const handleSpotlightDelete = (spotlightId) => {
    if (selectedCompanySpotlight?.id === spotlightId) {
      setSelectedCompanySpotlight(null);
    }
    if (selectedRoleSpotlight?.id === spotlightId) {
      setSelectedRoleSpotlight(null);
    }
    setSpotlightNeedUpdate(true);
  };

  // Modal handlers
  const openCompanySelectOrUpload = () => {
    setShowSelectOrUploadCompany(true);
  };

  const openRoleSelectOrUpload = () => {
    setShowSelectOrUploadRole(true);
  };

  const closeAllSelectOrUploadModals = () => {
    setShowSelectOrUploadCompany(false);
    setShowSelectOrUploadRole(false);
    setShowSelectSpotlightModal(false);
    setAddSpotlightType(null);
    setSpotlightToEdit(null);
  };

  const handleSelectSpotlight = (type) => {
    setSelectSpotlightType(type);
    setShowSelectOrUploadCompany(false);
    setShowSelectOrUploadRole(false);
    setShowSelectSpotlightModal(true);
  };

  const handleUploadSpotlight = (type) => {
    setShowSelectOrUploadCompany(false);
    setShowSelectOrUploadRole(false);
    setAddSpotlightType(type);
    setSpotlightToEdit(null);
    setShowAddSpotlightModal(true);
  };

  const onSelectExistingSpotlight = (sp) => {
    if (selectSpotlightType === "company") {
      setSelectedCompanySpotlight(sp);
    } else {
      setSelectedRoleSpotlight(sp);
    }
    setShowSelectSpotlightModal(false);
    setSelectSpotlightType(null);
  };

  return (
    <div className="flex flex-col pt-20 items-center bg-white pl-60 pr-4 overflow-x-hidden">
      <div className="max-w-7xl w-full flex mb-20 h-full bg-white col-span">
        <div className="flex-initial w-2/3 divide-y-2 divide-gray-500">
          {/* Company Details Section */}
          <CompanyDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            company={company}
            setCompany={setCompany}
            setCompanyNeedUpdate={setCompanyNeedUpdate}
            loading={loading}
          />

          {/* Role Details Section */}
          <RoleDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            role={role}
            setRole={setRole}
            roles={roles}
            setRoleNeedUpdate={setRoleNeedUpdate}
            loading={loading}
            selectedCompanySpotlight={selectedCompanySpotlight}
            selectedRoleSpotlight={selectedRoleSpotlight}
            companySpotlights={companySpotlights}
            roleSpotlights={roleSpotlights}
            onCompanySpotlightSelect={setSelectedCompanySpotlight}
            onRoleSpotlightSelect={setSelectedRoleSpotlight}
            onSpotlightDelete={handleSpotlightDelete}
            onOpenCompanySelectOrUpload={openCompanySelectOrUpload}
            onOpenRoleSelectOrUpload={openRoleSelectOrUpload}
          />

          {/* Message Section */}
          <Message
            role={role}
            activeStep={activeStep}
            candidates={candidates}
          />
        </div>

        {/* Shortlist Recap Section */}
        <div className="flex-initial pl-20 w-1/3">
          <ShortlistRecap candidates={candidates} loading={loading} />
        </div>
      </div>

      {/* Modals */}
      {/* Company Spotlight Select/Upload Modal */}
      {showSelectOrUploadCompany && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
          onClick={closeAllSelectOrUploadModals}
        >
          <div
            className="bg-white p-6 rounded-lg max-w-sm w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeAllSelectOrUploadModals}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
            >
              ×
            </button>
            <h3 className="text-lg font-semibold mb-3">Company spotlight</h3>
            <p className="text-gray-600 mb-4">Select from existing or upload a new spotlight?</p>
            <div className="flex justify-between">
              <button
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded"
                onClick={() => handleSelectSpotlight("company")}
              >
                Select existing
              </button>
              <button
                className="px-4 py-2 bg-[#02B491] hover:bg-[#029478] text-white rounded"
                onClick={() => handleUploadSpotlight("company")}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Role Spotlight Select/Upload Modal */}
      {showSelectOrUploadRole && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
          onClick={closeAllSelectOrUploadModals}
        >
          <div
            className="bg-white p-6 rounded-lg max-w-sm w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeAllSelectOrUploadModals}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
            >
              ×
            </button>
            <h3 className="text-lg font-semibold mb-3">Role spotlight</h3>
            <p className="text-gray-600 mb-4">Select from existing or upload a new spotlight?</p>
            <div className="flex justify-between">
              <button
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded"
                onClick={() => handleSelectSpotlight("role")}
              >
                Select existing
              </button>
              <button
                className="px-4 py-2 bg-[#02B491] hover:bg-[#029478] text-white rounded"
                onClick={() => handleUploadSpotlight("role")}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Select Existing Spotlight Modal */}
      <SelectExistingSpotlightModal
        isOpen={showSelectSpotlightModal}
        onClose={closeAllSelectOrUploadModals}
        spotlights={
          selectSpotlightType === "company"
            ? companySpotlights
            : selectSpotlightType === "role"
            ? roleSpotlights
            : []
        }
        currentlySelectedId={
          selectSpotlightType === "company"
            ? selectedCompanySpotlight?.id
            : selectedRoleSpotlight?.id
        }
        onSelect={onSelectExistingSpotlight}
      />

      {/* Add/Edit Spotlight Modal */}
      <AddSpotlightModal
        isOpen={showAddSpotlightModal}
        onClose={() => {
          setShowAddSpotlightModal(false);
          setSpotlightToEdit(null);
          setAddSpotlightType(null);
        }}
        type={addSpotlightType}
        onAddSpotlight={handleAddSpotlight}
        spotlightToEdit={spotlightToEdit}
      />
    </div>
  );
}

export default RequestInterview;
