import React from 'react';

const RecommendationModal = ({
  isOpen,
  onClose,
  roles,
  onGetRecommendations,
  onRunGPTAnalysis,
  isLoading,
  isGPTLoading,
  phase,
  candidatesFound,
  roleTitle = ""
}) => {
  if (!isOpen) return null;

  // Handle backdrop click - close the modal when clicking outside
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const getLoadingMessage = () => {
    if (phase === 0) {
      return "Analysing job description...";
    } else if (phase === 1) {
      return "Finding candidates that match requirements...";
    } else if (phase === 2) {
      return `Processing ${candidatesFound || "candidate"} profiles...`;
    } else {
      return "Processing...";
    }
  };
  
  // Handle role selection - start recommendation and close modal
  const handleRoleSelect = (roleId) => {
    onGetRecommendations(roleId);
    onClose();
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300"
      onClick={handleBackdropClick}
    >
      <div className="bg-white p-6 rounded-xl shadow-xl w-[550px] max-h-[80vh] overflow-y-auto animate-slideUp" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold text-gray-800">
              {isLoading && roleTitle ? `Finding Matches for ${roleTitle}` : "Get AI Recommendations"}
            </h2>
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-[#02B49115] text-[#02B491] border border-[#02B49130]">
              BETA
            </span>
          </div>
          {/* Always show close button and make it work even during loading */}
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            aria-label="Close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="mb-6">
          {isLoading ? (
            <div className="text-center py-8 px-4">
              <div className="relative mx-auto mb-6 w-24 h-24">
                <div className="absolute top-0 left-0 w-full h-full border-4 border-gray-200 rounded-full"></div>
                <div className="absolute top-0 left-0 w-full h-full border-4 border-[#02B491] rounded-full animate-spin-slow border-t-transparent"></div>
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-[#02B491]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                  </svg>
                </div>
              </div>
              <p className="text-gray-700 text-lg font-medium mb-2">{getLoadingMessage()}</p>
              <p className="text-gray-500 text-sm max-w-xs mx-auto">
                Our AI is analysing candidate profiles to find the best matches for your role requirements.
              </p>
              
              <div className="mt-6 relative pt-1 w-full max-w-md mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                  <div 
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#02B491] transition-all duration-1000 ease-in-out"
                    style={{ width: `${phase * 33}%` }}
                  ></div>
                </div>
                <div className="flex mt-2 text-xs text-gray-500 justify-between">
                  <span>Analysis</span>
                  <span>Matching</span>
                  <span>Ranking</span>
                  <span>Complete</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <p className="text-gray-600 mb-5 leading-relaxed">
                Select a role to get AI-powered candidate recommendations based on the job requirements.
                You'll be able to review and customise the search criteria before we find matches.
              </p>
              
              <div className="space-y-3">
                {roles.filter(role => role["Role Title"]?.trim()).map((role) => (
                  <button
                    key={role.id}
                    onClick={() => handleRoleSelect(role.id)}
                    className="w-full text-left px-5 py-4 rounded-lg border border-gray-200 hover:border-[#02B491] hover:bg-gray-50 transition-all duration-200 group"
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="font-medium text-gray-800">{role["Role Title"]}</div>
                        {role["Company"] && (
                          <div className="text-sm text-gray-500 mt-1">
                            {role["Company"]}
                          </div>
                        )}
                      </div>
                      <div className="text-[#02B491] opacity-0 group-hover:opacity-100 transition-opacity">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                      </div>
                    </div>
                  </button>
                ))}
                {roles.length > 0 && roles.filter(role => role["Role Title"]?.trim()).length === 0 && (
                  <div className="text-center py-4 text-gray-500">
                    No roles with titles available. Please add titles to your roles to view them here.
                  </div>
                )}
              </div>
            </>
          )}
        </div>


      </div>
    </div>
  );
};

export default RecommendationModal;
