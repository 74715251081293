import React, { useEffect, useState, useRef } from "react";
import linkedin from "../../images/linkedin-logo.png";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import jumpstart from "../../images/jumpstart_logo.jpeg";
import { differenceInDays } from "date-fns";
import Office from "../../images/office-icon.png"; // Ensure you have the loading.svg in the correct path
import Languages from "../../images/languages-icon.png"; // Ensure you have the loading.svg in the correct path
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import axios from "axios";
import { useShortlisted } from "../../provider/ShortlistedContext";
import VideoIntro from "../../images/video-icon-green.png";
import { useAsyncError, useNavigate } from "react-router-dom";

const languageLogos = {
  JavaScript: "/images/coding_logo/javascript.png",
  Python: "/images/coding_logo/python.png",
  C: "/images/coding_logo/c.png",
  Swift: "/images/coding_logo/swift.png",
  "C#": "/images/coding_logo/csharp.png",
  "C++": "/images/coding_logo/cplusplus.png", // For C++
  Java: "/images/coding_logo/java.svg",
  Go: "/images/coding_logo/go.png",
  PHP: "/images/coding_logo/php.svg",
  Ruby: "/images/coding_logo/ruby.webp",
  Kotlin: "/images/coding_logo/kotlin.png",
  Rust: "/images/coding_logo/rust.png",

  // Newly added languages
  Clojure: "/images/coding_logo/clojure.png",
  Haskell: "/images/coding_logo/haskell.png",
  Dart: "/images/coding_logo/dart.png",
  Elixir: "/images/coding_logo/elixir.webp",
  "Objective-C": "/images/coding_logo/objc.svg",
  Perl: "/images/coding_logo/perl.png",
  Scala: "/images/coding_logo/scala.png",
  Typescript: "/images/coding_logo/typescript.png",
  React: "/images/coding_logo/react.png",
  Vue: "/images/coding_logo/vue.png",
  Angular: "/images/coding_logo/angular.png",
  "Next.js": "/images/coding_logo/next-js.svg",
  MATLAB: "/images/coding_logo/matlab.png"

};


const formatDuration = (duration) => {
  if (!duration) return "";
  
  const years = parseInt(duration.years) || 0;
  const months = parseInt(duration.months) || 0;
  
  const yearText = years === 1 ? "yr" : "yrs";
  const monthText = months === 1 ? "mo" : "mos";
  
  if (years && months) {
    return `${years} ${yearText}, ${months} ${monthText}`;
  } else if (years) {
    return `${years} ${yearText}`;
  } else if (months) {
    return `${months} ${monthText}`;
  }
  return "";
};

const formatSalary = (minimum, ideal) => {
  if (!minimum && !ideal) return null;
  
  // Helper to clean and parse salary values
  const cleanSalary = (val) => {
    if (!val) return null;
    // Remove any non-numeric characters and convert to number
    const num = val.toString().replace(/[^0-9.]/g, '');
    return num ? parseInt(num, 10) : null;
  };
  
  const min = cleanSalary(minimum);
  const max = cleanSalary(ideal);
  
  if (min && max) {
    if (min === max) {
      return `£${min}k`;
    } else {
      return `£${min}k - £${max}k`;
    }
  } else if (min) {
    return `£${min}k+`;
  } else if (max) {
    return `Up to £${max}k`;
  }
  
  return null;
};

function GeneralistCard({
  candidate,
  recommendedCandidates,
  roles,
  shortlistedCandidates,
  setRoles,
  setRolesNeedUpdate,
  role,
  setRoleNeedUpdate,
  setNewRoleForm,
  handleSelectCandidate,
  candidatesStatus = [],
  addToast,
  recommendationData, // New prop for recommendation data
  showRecommendationInfo = false, // Flag to control whether to show recommendation info
}) {
  const navigate = useNavigate();
  const { shortlistedCount, setShortlistedCount } = useShortlisted();
  const [showRoleMenu, setShowRoleMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const roleMenuRef = useRef();
  const [companyLink, setCompanyLink] = useState("");

  const handleAddToShortlist = () => {
    setShowRoleMenu(!showRoleMenu);
  };

  const isNewCandidate = (timecreated) => {
    const createdDate = new Date(timecreated);
    const currentDate = new Date();
    return differenceInDays(currentDate, createdDate) < 7;
  };

  const handleRoleSelect = async (role, candidateId) => {
    setLoading(true); // Set loading state to true
    const currentShortlistedCandidates = role["Shortlisted Candidates"] || [];
    const updatedShortlistedCandidates = [
      ...new Set([...currentShortlistedCandidates, candidateId]),
    ];

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => {
        const updatedRoles = prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
        return updatedRoles;
      });

      const newUpdatedRoles = roles.map((r) =>
        r.id === updatedRoleData.id ? updatedRoleData : r
      );

      // Recalculate the shortlisted count
      const newShortlistedCount = newUpdatedRoles.reduce(
        (acc, role) =>
          acc +
          (role["Shortlisted Candidates"]
            ? role["Shortlisted Candidates"].length
            : 0),
        0
      );
      setShortlistedCount(newShortlistedCount);
      setRolesNeedUpdate(true);
      addToast(candidate.name);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Set loading state to false
      setShowRoleMenu(false);
    }
  };

  const handleRemoveCandidate = async (roles, role, candidateId) => {
    setLoading(true); // Set loading state to true
    
    console.log("Remove candidate called with:", { 
      roles,
      roleId: role,
      candidateId
    });
    
    try {
      // Determine the structure of the roles data
      if (!roles) {
        console.error("Roles data is undefined or null");
        setLoading(false);
        return;
      }
      
      let roleObj;
      let currentShortlistedCandidates;
      let roleId;
      
      // Case 1: roles is an array of role objects
      if (Array.isArray(roles)) {
        console.log("Handling array format");
        roleObj = roles.find(r => r.id === role);
        if (!roleObj) {
          console.error(`Role ${role} not found in roles array`);
          setLoading(false);
          return;
        }
        currentShortlistedCandidates = roleObj["Shortlisted Candidates"] || [];
        roleId = roleObj.id;
      } 
      // Case 2: roles is an object with role names as keys
      else if (typeof roles === 'object' && roles[role] && typeof roles[role] === 'object') {
        console.log("Handling object format");
        roleObj = roles[role];
        currentShortlistedCandidates = roleObj.candidates || [];
        roleId = roleObj.roleId;
      }
      // Case 3: role itself is the role object (common in recommendation view)
      else if (typeof role === 'object' && role.id) {
        console.log("Handling role object format");
        roleObj = role;
        currentShortlistedCandidates = role["Shortlisted Candidates"] || [];
        roleId = role.id;
      }
      else {
        console.error("Unrecognized roles data structure:", { roles, role });
        setLoading(false);
        return;
      }
      
      console.log("Processing with:", { 
        roleObj, 
        currentShortlistedCandidates, 
        roleId 
      });
      
      const updatedShortlistedCandidates = currentShortlistedCandidates.filter(
        candidate => candidate !== candidateId
      );
      
      const updatedData = {
        fields: {
          "Shortlisted Candidates": updatedShortlistedCandidates,
        },
        roleId: roleId,
      };
      
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      
      const updatedRoleData = response.data.updatedRoleData.fields;
      
      // Update roles state based on the format we detected
      // Add safety checks before updating state
      if (setRoles) {
        if (Array.isArray(roles)) {
          setRoles(prevRoles => {
            if (!Array.isArray(prevRoles)) {
              console.error("Expected prevRoles to be an array but got:", prevRoles);
              return prevRoles; // Return unchanged if not an array
            }
            return prevRoles.map(r => 
              r.id === roleId 
                ? { ...r, "Shortlisted Candidates": updatedShortlistedCandidates } 
                : r
            );
          });
        } else if (typeof roles === 'object' && roles[role]) {
          setRoles(prevRoles => ({
            ...prevRoles,
            [role]: {
              ...prevRoles[role],
              candidates: updatedShortlistedCandidates,
            },
          }));
        }
      }
      
      // Update shortlisted count
      if (typeof shortlistedCount === 'number' && setShortlistedCount) {
        const newShortlistedCount = shortlistedCount - 1;
        setShortlistedCount(newShortlistedCount);
      }
      
      if (setRolesNeedUpdate) {
        setRolesNeedUpdate(true);
      }
      
    } catch (err) {
      console.error("Error removing candidate from shortlist:", err);
    } finally {
      setLoading(false);
      setShowRoleMenu(false);
    }
  };

  const isShortlistedForRole = (candidateId, roles) => {
    console.log("isShortlistedForRole called with:", { 
      candidateId,
      roles
    });
    
    if (!roles) {
      console.log("Roles are null or undefined");
      return null;
    }
    
    // Check if roles is empty
    if (
      (Array.isArray(roles) && roles.length === 0) || 
      (!Array.isArray(roles) && typeof roles === 'object' && Object.keys(roles).length === 0)
    ) {
      console.log("Roles are empty, returning null");
      return null;
    }

    try {
      // Case 1: roles is an array of role objects (common in recommendation view)
      if (Array.isArray(roles)) {
        console.log("Checking array-format roles");
        for (const role of roles) {
          if (!role) continue;
          
          const shortlistedCandidates = role["Shortlisted Candidates"];
          if (
            shortlistedCandidates && 
            Array.isArray(shortlistedCandidates) &&
            shortlistedCandidates.includes(candidateId)
          ) {
            console.log(`Candidate ${candidateId} is shortlisted for role ${role.id}`);
            return role.id; // Return the role ID
          }
        }
        console.log(`Candidate ${candidateId} is not shortlisted in any role in the array`);
        return null;
      }
      
      // Case 2: roles is an object with role names as keys (common in regular browse view)
      if (typeof roles === 'object') {
        console.log("Checking object-format roles");
        for (const roleName in roles) {
          const role = roles[roleName];
          if (!role) continue;
          
          // Check if the role has a candidates property that includes the candidateId
          if (
            role.candidates &&
            Array.isArray(role.candidates) &&
            role.candidates.includes(candidateId)
          ) {
            console.log(`Candidate ${candidateId} is shortlisted for role ${roleName}`);
            return roleName;
          }
          
          // Alternative format: role has a "Shortlisted Candidates" property
          if (
            role["Shortlisted Candidates"] &&
            Array.isArray(role["Shortlisted Candidates"]) &&
            role["Shortlisted Candidates"].includes(candidateId)
          ) {
            console.log(`Candidate ${candidateId} is shortlisted for role ${roleName}`);
            return roleName;
          }
        }
      }
      
      console.log(`Candidate ${candidateId} is not shortlisted in any role`);
      return null;
    } catch (err) {
      console.error("Error in isShortlistedForRole:", err);
      return null;
    }
  };

  const roleShortlistedFor = shortlistedCandidates
    ? isShortlistedForRole(candidate.id, shortlistedCandidates)
    : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (roleMenuRef.current && !roleMenuRef.current.contains(event.target)) {
        setShowRoleMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";
  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "hover:bg-emerald-400",
          borderColor: "border-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "hover:bg-blue-400",
          borderColor: "border-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "hover:bg-orange-400",
          borderColor: "border-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "hover:bg-purple-400",
          borderColor: "border-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "hover:bg-gray-400",
          borderColor: "border-gray-400",
        };
    }
  };

  const candidateStatus = candidatesStatus.find(
    (candidateStatus) => candidateStatus.id === candidate.id
  );
  const statusStyles = {
    "Application pending": {
      color: "text-green-800",
      borderColor: "border-green-300",
      bgColor: "bg-green-50",
      message: (candidate, role) => `${candidate.name} applied to ${role}`,
    },
    Interviewing: {
      color: "text-blue-800",
      borderColor: "border-blue-300",
      bgColor: "bg-blue-50",
      message: (candidate, role) =>
        `${candidate.name} is being interviewed for ${role}`,
    },
    "Match requested": {
      color: "text-orange-800",
      borderColor: "border-orange-300",
      bgColor: "bg-orange-50",
      message: (candidate, role) =>
        `You have sent a request to ${candidate.name} for ${role}`,
    },
    "Rejected @ CV": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) =>
        `You rejected ${candidate.name} for ${role}`,
    },
    "Rejected @ Interview": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) =>
        `You rejected ${candidate.name} for ${role}`,
    },
    "Rejected Interview Request": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) =>
        `${candidate.name} declined invite to ${role}`,
    },
    "Company withdrawn": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) => `You withdrew from ${role}`,
    },
    "Candidate withdrawn": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) => `${candidate.name} withdrew from ${role}`,
    },
  };

  const statusStyle = candidateStatus
    ? statusStyles[candidateStatus.status]
    : null;

  const { bgColor, textColor, workBgColor, fillColor, bgColor2, borderColor } =
    getColorClasses(candidate.stream);

  const newRole = () => {
    handleSelectCandidate(candidate);
    setNewRoleForm(true);
  };

  useEffect(() => {
    const linkFromStorage = localStorage.getItem("companyLink");
    
    setCompanyLink(linkFromStorage);
  }, []);

  const displayShortlistButton =
    !candidateStatus ||
    [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ].includes(candidateStatus.status);

  const handleSvgClick = () => {
    setSvgMovedUp(true);
    setShowCopiedText(true);
    setTimeout(() => {
      setSvgMovedUp(false);
      setShowCopiedText(false);
    }, 2000);
  };

  const [showCopiedText, setShowCopiedText] = useState(false);
  const [svgMovedUp, setSvgMovedUp] = useState(false);

  const copyToClipboard = () => {
    const link = `${companyLink}&page=candidate/${candidate.url_id}`;
    navigator.clipboard.writeText(link).then(
      () => {
        handleSvgClick();
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const validRightToWorkStatuses = [
    "No - I don't have the right to work in the UK",
    "No - Requires Visa sponsorship",
    "No - Looking for Visa sponsorship, but willing to self-sponsor if necessary",
    "No - Requires visa sponsorship - is well versed in the process + has a lawyer at hand if needed",
  ];

  const workingStyleMapping = Array.isArray(candidate.working_style)
    ? candidate.working_style.map((style) => {
        if (style === "5 days a week in office") {
          return "Fully in office";
        } else if (style === "Hybrid (1-4 days in office)") {
          return "Hybrid";
        } else if (style === "Fully remote") {
          return "Fully remote";
        }
        return style;
      })
    : [];

  // Get all the candidate data from the recommendationData prop
  const candidateRecommendationData = recommendationData || 
    (candidate.recommendationScore ? 
      { 
        score: candidate.recommendationScore || 0, 
        filtered: candidate.isFiltered || false, 
        reason: candidate.recommendationReason || "" 
      } : null);
  
  // Determine match quality level based on score
  const getMatchQuality = (score) => {
    if (score >= 80) return { label: "Excellent Match", color: "text-[#028d72]", bgColor: "bg-[#e6f7f4]" };
    if (score >= 60) return { label: "Good Match", color: "text-[#02B491]", bgColor: "bg-[#e6f7f4]" };
    return { label: "Match", color: "text-[#40c4ac]", bgColor: "bg-[#e6f7f4]" };
  };
  
  // Get match quality for this candidate
  const matchQuality = candidateRecommendationData?.score 
    ? getMatchQuality(candidateRecommendationData.score) 
    : candidateRecommendationData 
      ? getMatchQuality(0) // Default match if we have recommendation data but no score
      : null;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isRecommendation = queryParams.get('recommendation') === 'true';
    const roleId = queryParams.get('roleId');
    const pendingRoleId = localStorage.getItem("pendingRecommendationRoleId");
    
    if ((isRecommendation && roleId) || pendingRoleId) {
      const targetRoleId = roleId || pendingRoleId;
      localStorage.removeItem("pendingRecommendationRoleId");
      
      // Add defensive check to make sure roles is an array before using find
      if (roles && Array.isArray(roles) && roles.length > 0) {
        const role = roles.find(r => r.id === targetRoleId);
        if (role) {
          // Your original logic here...
        }
      }
    }
  }, []);

  return (
    <div 
      className={`relative max-w-lg mt-5 bg-white rounded-xl overflow-hidden flex flex-col h-full hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition duration-400 ${candidateRecommendationData?.filtered ? 'border-2 border-amber-400' : ''}`}
      onClick={(e) => {
        // Only trigger navigation if we're not clicking on an interactive element
        if (
          !e.target.closest('button') && 
          !e.target.closest('img[style*="cursor: pointer"]')
        ) {
          // Store the current page before opening the candidate details in a new tab
          sessionStorage.setItem("lastVisitedPage", window.location.pathname);
          
          // Navigate to candidate detail page - use full path to avoid navigation issues
          window.open(`/candidate/${candidate.url_id}`, "_blank");
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {/* TOP: Candidate image & "Recently added"/"Recommended" labels */}
      <div className="relative pt-4 px-4">
        <img
          className="w-full h-72 object-cover rounded-xl"
          src={candidate.image || fallbackImageUrl}
          alt="Candidate"
          onError={(e) => {
            console.log(`Image failed to load for candidate: ${candidate.name} (${candidate.id})`);
            e.target.src = fallbackImageUrl;
          }}
        />

        <span
          className={`absolute top-6 right-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
        >
          <svg
            className={`h-1.5 w-1.5 ${fillColor}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {candidate.stream}
        </span>

        {/* Add warning for filtered candidates */}
        {candidateRecommendationData?.filtered && (
          <span
            className="text-amber-800 bg-amber-100 absolute bottom-6 left-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium border border-amber-400"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              strokeWidth={1.5} 
              stroke="currentColor" 
              className="w-5 h-5"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" 
              />
            </svg>
            Doesn't meet requirements
          </span>
        )}

        {isNewCandidate(candidate.createdTime) && (
          <span
            className="text-black absolute top-6 left-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium"
            style={{
              background: "white",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Recently added
          </span>
        )}

        {recommendedCandidates && recommendedCandidates.includes(candidate.id) && (
          <span
            className="text-black absolute top-6 left-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium"
            style={{
              background: "white",
            }}
          >
            <img
              src={jumpstart}
              title="Jumpstart icon"
              className="h-6 w-6 rounded-sm"
            />
            Recommended for you
          </span>
        )}
        
        {/* AI Recommendation Score */}
        {candidateRecommendationData && matchQuality && (
          <div className="absolute top-[15rem] right-6 shadow-md">
            <div className={`flex items-center ${matchQuality.bgColor} ${matchQuality.color} px-3 py-1.5 rounded font-semibold border border-gray-200`}>
              <svg className="w-5 h-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              {matchQuality.label}
            </div>
          </div>
        )}
      </div>

      {/* Name and Salary Range - Adding this section */}
      <div className="mx-4 mt-4 mb-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900">
              {candidate.name}
            </h5>

            {/* Copy link icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={`size-6 ml-1 cursor-pointer transform ${
                svgMovedUp ? "hidden" : ""
              }`}
              onClick={copyToClipboard}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
              />
            </svg>

            {showCopiedText && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"
                />
              </svg>
            )}
            {candidate.linkedin && (
              <img
                src={linkedin}
                alt="LinkedIn"
                title="LinkedIn icon"
                className="h-5 w-5 ml-2 rounded-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  let url = candidate.linkedin;
                  if (!url.startsWith("https://")) {
                    url = "https://" + url;
                  }
                  window.open(url, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.cv && (
              <img
                src={cv}
                alt="CV"
                title="CV icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(candidate.cv, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {/* Video Intro icon */}
            {candidate.video && (
              <img
                src={VideoIntro}
                alt={candidate.video}
                title="Video Intro icon"
                className="h-6 w-6 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.video, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}

            {/* GitHub icon */}
            {candidate.github && (
              <img
                src={github}
                alt={candidate.github}
                title="Github icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.github, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}

            {/* Portfolio link */}
            {/* Portfolio link for Generalist/Marketing */}
            {(candidate.stream === "Generalist" || candidate.stream === "Marketing") && candidate.marketing_portfolio && (
              <svg
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.marketing_portfolio, "_blank");
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 cursor-pointer ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 
                    1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                />
              </svg>
            )}

            {/* Portfolio link for SWE */}
            {candidate.stream === "Software Engineering" && candidate.portfolio_swe && (
              <svg
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.portfolio_swe, "_blank");
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 cursor-pointer ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 
                    1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                />
              </svg>
            )}

          </div>
          {/* Expected salary */}
          <div className="flex flex-col text-right">
            <span className="text-sm text-gray-500">Expected Salary Range:</span>
            <span className="text-sm font-semibold">
              {formatSalary(candidate.salary_minimum || candidate.minimum_salary, candidate.salary_ideal || candidate.ideal_salary)}
            </span>
          </div>
        </div>
      </div>

      {/* Clean, single recommendation reason display */}
      {showRecommendationInfo && candidateRecommendationData && candidateRecommendationData.reason && (
        <div className="mx-4 mt-2 mb-3 border-l-4 border-[#02B491] bg-[#e6f7f4] rounded-md p-3 shadow-sm">
          <div className="flex items-start">
            <svg className="w-5 h-5 mr-2 text-[#02B491] flex-shrink-0 mt-0.5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <div>
              <p className="font-medium text-sm text-[#02B491]">
                Why it's {/^[aeiou]/i.test(matchQuality?.label.toLowerCase() || 'match') ? 'an' : 'a'} {matchQuality?.label.toLowerCase() || 'match'}:
              </p>
              <p className="text-sm text-gray-700">
                {candidateRecommendationData.reason}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* MIDDLE: flex-1 so it stretches; all details, status, etc. */}
      <div className="flex-1 pt-2 px-4 flex flex-col">
        {/* Roles, etc. */}
        <div className="flex">
          {candidate.roles &&
            candidate.roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-sm font-medium ${bgColor} ${textColor}`}
              >
                {role}
              </span>
            ))}
          {candidate.sales && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.sales}
            </span>
          )}
          {candidate.swe_roles &&
            candidate.swe_roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-sm font-medium ${bgColor} ${textColor}`}
              >
                {role}
              </span>
            ))}
          {candidate.exp_swe && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.exp_swe}
            </span>
          )}
          {candidate.time_coding && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.time_coding} yrs of coding exp
            </span>
          )}

          {/* Right to work checks */}
          {candidate.right_to_work &&
            validRightToWorkStatuses.includes(candidate.right_to_work) && (
              <span
                className={`inline-flex mb-2 mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {candidate.right_to_work}
              </span>
            )}
        </div>

        {/* Coding languages */}
        <div className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md text-black text-sm md:text-md font-medium flex gap-x-2">
          {candidate.coding_languages &&
            candidate.coding_languages.map((language, index) => (
              <img
                key={index}
                src={languageLogos[language.trim()] || "path/to/default-logo.png"}
                alt={language}
                className="h-6 w-6"
              />
            ))}
        </div>

        {/* Working style & languages */}
        <div className="flex pb-2 pt-1">
          {candidate.working_style &&
            Array.isArray(candidate.working_style) &&
            workingStyleMapping.length > 0 && (
              <p className="pr-5 font-normal flex text-sm text-black items-center">
                <img className="h-6 pr-1" src={Office} alt="Office icon" />
                {workingStyleMapping.join(" or ")}
              </p>
            )}
          {candidate.languages &&
            Array.isArray(candidate.languages) &&
            candidate.languages.some((language) => language !== "N/A") && (
              <p className="font-normal flex text-sm text-black items-center">
                <img className="h-6 pr-2" src={Languages} alt="Languages icon" />
                {candidate.languages
                  .filter((language) => language !== "N/A")
                  .join(", ")}
              </p>
            )}
        </div>

        {/* Degrees */}
        {candidate.undergrad_degree_type && (
          <p className="text-sm">
            <span className="font-semibold">
              {candidate.undergrad_degree_type}{" "}
              {candidate.undergrad_degree_subject}
            </span>
            {candidate.undergrad_university && " @ "}
            {candidate.undergrad_university} ({candidate.undergrad_result}
            {candidate.undergrad_result && candidate.undergrad_graduation_year && " - "}
            {candidate.undergrad_graduation_year})
          </p>
        )}

        {candidate.postgrad_degree_type && (
          <p className="text-sm pt-2">
            <span className="font-semibold">
              {candidate.postgrad_degree_type}{" "}
              {candidate.postgrad_degree_subject}
            </span>
            {candidate.postgrad_university && " @ "}
            {candidate.postgrad_university} ({candidate.postgrad_result}
            {candidate.postgrad_result && candidate.postgrad_graduation_year && " - "}
            {candidate.postgrad_graduation_year})
          </p>
        )}

        {/* Work experience summary */}
        <div className={`pb-2 mb-2 px-2 rounded-md ${workBgColor}`}>
          {candidate.recentTarget && (
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Recent Target Achieved:
              </span>{" "}
              {candidate.recentTarget}
            </div>
          )}

{candidate.work_experience_1_company && (
          <>
            <p className="text-sm pt-2">
              <span className="font-semibold">
                {candidate.work_experience_1_role}
              </span>{" "}
              @ {candidate.work_experience_1_company}
              {candidate.work_experience_1_duration && 
                ` (${formatDuration(candidate.work_experience_1_duration)})`}
            </p>
            <p className="text-gray-500 text-sm">
              {candidate.work_experience_1_description}
            </p>
          </>
        )}

        {candidate.work_experience_2_company && (
          <>
            <p className="text-sm pt-2">
              <span className="font-semibold">
                {candidate.work_experience_2_role}
              </span>{" "}
              @ {candidate.work_experience_2_company}
              {candidate.work_experience_2_duration && 
                ` (${formatDuration(candidate.work_experience_2_duration)})`}
            </p>
            <p className="text-gray-500 text-sm">
              {candidate.work_experience_2_description}
            </p>
          </>
        )}
      </div>
        {/* Candidate description */}
        {candidate.description && (
          <p className="mt-2 font-normal text-sm text-gray-700 flex">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.7"
                stroke="currentColor"
                className="size-5 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1-3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                />
              </svg>
            </div>
            {candidate.description}
          </p>
        )}

        {/* Most impressive swe */}
        {candidate.most_impressive_swe && (
          <div className="mt-2 mb-4 flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1-3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
              />
            </svg>
            <p className="font-normal pl-1 text-sm text-gray-700">
              {candidate.most_impressive_swe}
            </p>
          </div>
        )}
        
        {/* Status display (if any) */}
        {statusStyle ? (
          <div
            className={`flex justify-between items-center py-2 px-2 ${
              displayShortlistButton ? "mt-2" : "my-4"
            } text-sm ${statusStyle.color} border ${statusStyle.borderColor} rounded-lg ${statusStyle.bgColor}`}
            role="alert"
          >
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>{statusStyle.message(candidate, candidateStatus.role)}</div>
            </div>
            {candidateStatus.status === "Application pending" && (
              <button
                onClick={() => {
                  navigate("/applications");
                }}
                className="ml-2 p-2 bg-[#02B491] hover:text-black hover:bg-green-50 border-[#02B491] border text-white rounded-md flex"
              >
                View application
              </button>
            )}
          </div>
        ) : null}
      </div>

      {/* FOOTER: Shortlist buttons anchored at bottom */}
      {displayShortlistButton && (
        <div className="p-4">
          {roleShortlistedFor ? (
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click navigation
                handleRemoveCandidate(
                  shortlistedCandidates,
                  roleShortlistedFor,
                  candidate.id
                );
              }}
              className="w-full px-6 py-2 text-sm border-2 border-red-500 text-black rounded-lg font-medium transform hover:bg-red-500 hover:text-white"
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center items-center w-full rounded-lg">
                  <img
                    src={LoadingBlack}
                    className="h-6 fill-red-500"
                    alt="Loading"
                  />
                </div>
              ) : (
                `Remove from ${typeof roleShortlistedFor === 'object' && roleShortlistedFor.title ? roleShortlistedFor.title : roleShortlistedFor} shortlist`
              )}
            </button>
          ) : (
            <div className="flex gap-2">
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card click navigation
                  handleAddToShortlist();
                }}
                className={`w-full px-6 py-2 text-sm text-black flex rounded-lg font-medium justify-center ${bgColor2} hover:text-white border-2 ${borderColor} bg-white`}
              >
                {loading ? (
                  <div className="flex justify-center items-center w-full rounded-lg">
                    <img src={LoadingBlack} className="h-6" alt="Loading" />
                  </div>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-5 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                      />
                    </svg>
                    Add to shortlist
                  </>
                )}
              </button>

              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card click navigation
                  handleAddToShortlist();
                }}
                className={`hidden w-full px-6 py-2 text-sm text-black flex rounded-lg font-medium justify-center ${bgColor2} hover:text-white border-2 ${borderColor} bg-white`}
              >
                {loading ? (
                  <div className="flex justify-center items-center w-full rounded-lg">
                    <img src={LoadingBlack} className="h-6" alt="Loading" />
                  </div>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-5 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                      />
                    </svg>
                    Request interview
                  </>
                )}
              </button>

              {showRoleMenu && (
                <div
                  ref={roleMenuRef}
                  className="absolute mt-2 bottom-16 w-48 bg-white shadow-lg rounded-lg z-10"
                >
                  {Array.isArray(roles) ? (
                    roles.map((role, index) => (
                      <button
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRoleSelect(role, candidate.id);
                        }}
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      >
                        {role["Role Title"]}
                      </button>
                    ))
                  ) : (
                    <div className="px-4 py-2 text-sm text-gray-500">No roles available</div>
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      newRole();
                    }}
                    className="block w-full px-4 py-2 text-left bg-[#02B491] rounded-b-lg text-sm text-white hover:bg-[#00f2c2] hover:text-black"
                  >
                    + New Role
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GeneralistCard;
