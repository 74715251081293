import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';

const VideoRecording = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  // Get roleId from URL or localStorage
  const queryRoleId = searchParams.get('roleId') || localStorage.getItem('currentRoleId');

  const [formData, setFormData] = useState({
    companySpotlightUrl: '',
    roleSpotlightUrl: ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('videoRecordingData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
    // Also store the roleId if present
    if (queryRoleId) {
      localStorage.setItem('currentRoleId', queryRoleId);
    }
  }, [queryRoleId]);

  const handleNext = () => {
    // Potentially save the data or patch to Airtable here
    localStorage.setItem('videoRecordingData', JSON.stringify(formData));
    navigate(`/create-role/job-description?roleId=${queryRoleId}`);
  };

  const handleBack = () => {
    navigate(`/create-role/spotlight?roleId=${queryRoleId}`);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <div className="w-full max-w-5xl mx-auto pt-6 pb-8">
        <h1 className="text-[32px] font-medium text-center mb-12">Create role</h1>

        {/* Progress Steps */}
        <div className="flex gap-2 mb-12">
          {[1, 2, 3, 4].map((step) => (
            <div
              key={step}
              className={`h-2 flex-1 rounded-full ${
                step <= 2 ? 'bg-[#08f2c3]' : 'bg-[#cbcbcb]'
              }`}
            />
          ))}
        </div>
      </div>

      {/* Main Content */}
      <main className="w-full max-w-5xl mx-auto px-6">
        <div className="space-y-8">
          {/* Instructions */}
          <div className="rounded-lg border border-[#cbcbcb] p-6">
            <div className="flex gap-3">
              <div className="w-5 h-5 rounded-full bg-[#08f2c3] flex items-center justify-center text-white">
                i
              </div>
              <div>
                <h3 className="text-[16px] font-medium mb-2">How to record your spotlight:</h3>
                <ol className="space-y-1 text-[#3c3c3c]">
                  <li>1. Click the button below to open Loom or VideoAsk in a new tab</li>
                  <li>2. Record your video following the guidelines for each spotlight</li>
                  <li>3. Copy the share link for your video</li>
                  <li>4. Paste the link in the input field for the respective spotlight</li>
                </ol>
              </div>
            </div>
            <button 
              onClick={() => window.open('https://www.loom.com')}
              className="mt-6 w-full py-3 bg-[#6c5dd3] text-white rounded-lg flex items-center justify-center gap-2 hover:bg-[#6c5dd3]/90"
            >
              Go to Loom
              <ExternalLink className="w-4 h-4" />
            </button>
          </div>

          {/* Spotlights */}
          <div className="grid grid-cols-2 gap-8">
            {/* Company Spotlight */}
            <div>
              <h3 className="text-[16px] font-medium mb-2">Company Spotlight</h3>
              <p className="text-[14px] text-[#3c3c3c] mb-2">Remember to include:</p>
              <ul className="space-y-2 mb-4">
                {[
                  'Your company\'s mission and vision',
                  'Company culture and values',
                  'Your product/service overview'
                ].map((item, index) => (
                  <li key={index} className="flex items-center gap-2 text-[14px] text-[#3c3c3c]">
                    <div className="w-2 h-2 rounded-full bg-[#08f2c3]" />
                    {item}
                  </li>
                ))}
              </ul>
              <input
                type="text"
                placeholder="https://www.loom.com/share/videos"
                className="w-full p-4 border border-[#cbcbcb] rounded-lg text-[#3c3c3c] placeholder:text-[#b4b4b4] focus:outline-none focus:border-[#08f2c3]"
                value={formData.companySpotlightUrl}
                onChange={(e) => setFormData({
                  ...formData,
                  companySpotlightUrl: e.target.value
                })}
              />
            </div>

            {/* Role Spotlight */}
            <div>
              <h3 className="text-[16px] font-medium mb-2">Role Spotlight</h3>
              <p className="text-[14px] text-[#3c3c3c] mb-2">Remember to include:</p>
              <ul className="space-y-2 mb-4">
                {[
                  'Role responsibilities and expectations',
                  'Team structure and dynamics',
                  'Growth opportunities'
                ].map((item, index) => (
                  <li key={index} className="flex items-center gap-2 text-[14px] text-[#3c3c3c]">
                    <div className="w-2 h-2 rounded-full bg-[#08f2c3]" />
                    {item}
                  </li>
                ))}
              </ul>
              <input
                type="text"
                placeholder="https://www.loom.com/share/videos"
                className="w-full p-4 border border-[#cbcbcb] rounded-lg text-[#3c3c3c] placeholder:text-[#b4b4b4] focus:outline-none focus:border-[#08f2c3]"
                value={formData.roleSpotlightUrl}
                onChange={(e) => setFormData({
                  ...formData,
                  roleSpotlightUrl: e.target.value
                })}
              />
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between pt-6">
            <button
              onClick={handleBack}
              className="px-6 py-2 bg-[#f1f1f1] text-[#000000] rounded-full text-[16px]"
            >
              Back
            </button>
            <button
              onClick={handleNext}
              className="px-12 py-3 bg-[#08f2c3] text-[#000000] rounded-full text-[16px] font-medium"
            >
              Next
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default VideoRecording;