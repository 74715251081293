import React, { useEffect, useState } from "react";
import linkedin from "../../images/linkedin-logo.png";
import cv from "../../images/cv-icon.png";

function Candidate({
  candidate,
  isInList,
  onToggleCandidate,
  onSelectCandidate,
  isRequested,
}) {
  const formattedWork = candidate.work
    .split(/(\d\)\s)/)
    .filter((part) => part.trim())
    .map((part, index) => {
      if (index % 2 === 1) {
        // Add bold formatting to position titles
        const [position, rest] = part.split("@");
        const [company, details] = rest.split(/-\s/, 2); // Split on the first occurrence of "- "
        return (
          <div key={index} className="font-normal text-md text-gray-500 ">
            <p className="text-black">
              <span className="font-semibold">{position.trim()}</span> @{" "}
              {company.trim()}
            </p>
            {details ? details.trim() : ""}
          </div>
        );
      }
      return (
        <p key={index} className="mb-2 font-normal text-sm text-gray-400 ">
          {part.replace(/\d\)\s/, "")}
        </p>
      );
    });
  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "bg-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "bg-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "bg-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "bg-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "bg-gray-400",
        };
    }
  };

  function processSalary(salary) {
    if (!salary) return "";
    const regex = /£\d+k-\d+k/;
    const match = salary.match(regex);
    return match ? match[0] : salary.split(" ")[0];
  }

  const { bgColor, textColor, workBgColor, fillColor, bgColor2 } =
    getColorClasses(candidate.stream);
  return (
    <div className="flex flex-col -ml-20 items-start bg-white pt-2 pb-20">
      <button
        type="button"
        onClick={() => onSelectCandidate(null)}
        className="pt-2"
      >
        ← Back to all candidates
      </button>
      <div className="max-w-2xl pt-5 items-center flex flex-col h-full bg-white gap-2">
        <div className="relative">
          <img
            className=" h-96 w-[650px] object-cover rounded-lg"
            src={candidate.image}
            alt="Descriptive alt text"
          />
          <span
            className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            <svg
              className={`h-1.5 w-1.5 ${fillColor}`}
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {candidate.stream}
          </span>
        </div>
        <div className=" flex w-[650px] flex-col flex-grow justify-between">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                {candidate.name}
              </h5>
              <img
                src={linkedin}
                alt={candidate.name}
                title="Linkedin icon"
                className="h-5 w-5 ml-2 rounded-sm"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.linkedin, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
              <img
                src={cv}
                alt={candidate.cv}
                title="CV icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.cv, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="flex flex-col text-right">
              <h5 className="text-md tracking-tight text-gray-900 ">
                Expected Salary Range: {candidate.expectedSalaryRange}
              </h5>
              <h5 className="text-sm tracking-tight text-gray-900 ">
                {candidate.salary}
                <h5 className="text-sm tracking-tight text-gray-900">
                  {candidate.salary}
                  {processSalary(candidate.swe_salary)}
                </h5>
              </h5>
            </div>
          </div>
          <div className="mt-2">
            {candidate.roles &&
              typeof candidate.roles === "string" &&
              candidate.roles.split(",").map((role, index) => (
                <span
                  key={index}
                  className="inline-flex mr-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-2 py-1 text-md md:text-md font-medium text-purple-700"
                >
                  {role.trim()}
                </span>
              ))}
            {candidate.sales && (
              <span
                className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {candidate.sales}
              </span>
            )}
            {candidate.swe_roles &&
              typeof candidate.swe_roles === "string" &&
              candidate.swe_roles.split(",").map((role, index) => (
                <span
                  key={index}
                  className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
                >
                  {role.trim()}
                </span>
              ))}
            {candidate.time_coding && (
              <span
                className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {candidate.time_coding} of coding exp
              </span>
            )}
          </div>

          <p className="text-md pt-2">
            {(() => {
              const educationText = candidate.education[0];
              console.log("Education Text:", educationText);
              if (educationText.includes("; ")) {
                const splitText = educationText.split("; ")[1];
                console.log("After Semicolon:", splitText);
                const [beforeAt, afterAt] = splitText.split(" @ ");
                const [firstPart, ...restParts] = beforeAt.split("&");
                return (
                  <>
                    <span className="font-semibold">{firstPart.trim()}</span>
                    {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                    {afterAt ? ` @ ${afterAt}` : ""}
                  </>
                );
              }
              const [beforeAt, afterAt] = educationText.split(" @ ");
              const [firstPart, ...restParts] = beforeAt.split("&");
              return (
                <>
                  <span className="font-semibold">{firstPart.trim()}</span>
                  {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                  {afterAt ? ` @ ${afterAt}` : ""}
                </>
              );
            })()}
          </p>
          <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
            {candidate.recentTarget && (
              <div className="font-normal text-md text-gray-700 pt-2">
                <span className="font-semibold text-black">
                  Recent Target Achieved:
                </span>{" "}
                {candidate.recentTarget}
              </div>
            )}

            {formattedWork}
          </div>

          {candidate.description && (
            <p className="mt-2 font-normal text-sm text-gray-700">
              <span className="font-semibold text-black">Most proud of: </span>
              {candidate.description}
            </p>
          )}

          {candidate.languages !== "N/A" && (
            <p className="mt-2 font-normal text-sm text-black ">
              <span className="font-semibold">Additional languages:</span>{" "}
              {candidate.languages}
            </p>
          )}

          {candidate.working_style && (
            <p className="mt-2 font-normal text-sm text-black">
              <span className="font-semibold">Preferred working style:</span>{" "}
              {candidate.working_style}
            </p>
          )}

          {!isRequested && (
            <div className="mt-5">
              {isInList ? (
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => onToggleCandidate(candidate)}
                    className="px-6 py-2 mb-4 text-sm bg-red-500 text-white rounded-lg font-medium transform hover:-translate-y-1 transition duration-400"
                  >
                    Remove from the list
                  </button>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => onToggleCandidate(candidate)}
                    className={`px-6 py-2 mb-4 text-sm bg-black text-black rounded-lg font-medium transform hover:-translate-y-1 transition duration-400 ${bgColor2}`}
                  >
                    Add to shortlist
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Candidate;
