import React, { useState } from 'react';
import axios from 'axios';
import LoadingBlack from "../../images/loading-black.svg"; // Make sure this path matches your project structure

function NewRolePage() {
  const [loadingForm, setLoadingForm] = useState(false);

  // Create a new role with the given title
  const createRole = async () => {
    try {
      const data = {};
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.newRoleData.id;
    } catch (error) {
      console.error("Error creating role:", error);
      return null;
    }
  };

  const handleCreateRole = async () => {
    setLoadingForm(true);
  
    try {
      // Create a new role and get its ID
      const roleId = await createRole();
  
      if (roleId) {
        // Redirect to the first step of the new form
        window.location.href = `/create-role/basic-info?roleId=${roleId}`;
      } else {
        alert("Failed to create the role.");
      }
    } catch (error) {
      console.error("Error creating a new role:", error);
      alert("An error occurred while creating the role.");
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <div className="flex flex-col items-center bg-white pt-10 px-4">
      <div className="text-2xl mb-6">Test New Role</div>
      <button
        type="button"
        className="px-6 flex py-2 text-md border-2 border-[#02B491] text-white hover:text-black rounded-lg font-medium transform hover:bg-white bg-[#02B491]"
        onClick={handleCreateRole}
      >
        {loadingForm ? (
          <div className="flex justify-center items-center w-full rounded-lg">
            <img src={LoadingBlack} className="h-6" alt="Loading" />
          </div>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Create a new role
          </>
        )}
      </button>
    </div>
  );
}

export default NewRolePage;