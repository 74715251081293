import React, { useState, useEffect } from "react";
import { Chip, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';

const TagReviewModal = ({
  open,
  onClose,
  onProceed,
  jdAnalysis,
  roleTitle,
  isEditMode = false,
}) => {
  // Combine must-have and nice-to-have tags into a single array
  const [requirementTags, setRequirementTags] = useState([]);
  
  // Keep the experience and industry tags (hidden but used in backend)
  const [experienceTags, setExperienceTags] = useState([]);
  const [industryTags, setIndustryTags] = useState([]);
  
  // State for new tag input
  const [newRequirementTag, setNewRequirementTag] = useState("");
  
  // Track active editing
  const [isEditing, setIsEditing] = useState(true); // Start in editing mode by default
  
  // Animation states
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  
  // Handle click outside
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  
  // Parse tags from comma-separated strings
  useEffect(() => {
    if (jdAnalysis) {
      // Simulate loading for a smoother UX
      setIsLoading(true);
      
      setTimeout(() => {
        // Combine must-have and nice-to-have into a single requirements list
        const mustHaves = jdAnalysis.must_have ? jdAnalysis.must_have.split(',').map(tag => ({
          text: tag.trim(),
          type: 'must-have'
        })).filter(tag => tag.text) : [];
        
        const niceToHaves = jdAnalysis.nice_to_have ? jdAnalysis.nice_to_have.split(',').map(tag => ({
          text: tag.trim(),
          type: 'nice-to-have'
        })).filter(tag => tag.text) : [];
        
        // Always add the industry as a nice-to-have tag
        const industryFocusList = jdAnalysis.industryFocus ? jdAnalysis.industryFocus.split(',').map(industry => industry.trim()).filter(industry => industry) : [];
        
        // Create industry nice-to-have tags with the specified format
        const industryNiceToHaves = [];
        
        // Check for duplicates before adding industry tags
        industryFocusList.forEach(industry => {
          // Skip empty industry values
          if (!industry) return;
          
          // Create the industry tag with the specified format
          const industryTag = `interest or experience in ${industry} industry`;
          
          // Check if this industry is already mentioned in the nice-to-have tags
          const isDuplicate = niceToHaves.some(tag => 
            tag.text.toLowerCase().includes(industry.toLowerCase()) && 
            (tag.text.toLowerCase().includes('industry') || 
             tag.text.toLowerCase().includes('experience'))
          );
          
          // Only add if not a duplicate
          if (!isDuplicate) {
            industryNiceToHaves.push({
              text: industryTag,
              type: 'nice-to-have'
            });
          }
        });
        
        // Log the industry tags that were added
        if (industryNiceToHaves.length > 0) {
          console.log(`Added ${industryNiceToHaves.length} industry tags as nice-to-have requirements`);
        }
        
        // Combine all tags
        setRequirementTags([...mustHaves, ...niceToHaves, ...industryNiceToHaves]);
        
        // Keep experience and industry for backend
        setExperienceTags(jdAnalysis.experience ? jdAnalysis.experience.split(',').map(tag => tag.trim()).filter(tag => tag) : []);
        setIndustryTags(jdAnalysis.industryFocus ? jdAnalysis.industryFocus.split(',').map(tag => tag.trim()).filter(tag => tag) : []);
        
        setIsLoading(false);
        setTimeout(() => setIsReady(true), 300);
      }, 600); // Reduced loading time
    }
  }, [jdAnalysis]);

  // Add a new tag to requirements
  const addTag = (newTag) => {
    if (!newTag.trim()) return;
    setRequirementTags([...requirementTags, { text: newTag.trim(), type: 'must-have' }]);
    setNewRequirementTag("");
  };

  // Remove a tag from requirements
  const removeTag = (indexToRemove) => {
    setRequirementTags(requirementTags.filter((_, index) => index !== indexToRemove));
  };

  // Toggle tag type between must-have and nice-to-have
  const toggleTagType = (index) => {
    const updatedTags = [...requirementTags];
    updatedTags[index].type = updatedTags[index].type === 'must-have' ? 'nice-to-have' : 'must-have';
    setRequirementTags(updatedTags);
  };

  // Convert tag arrays back to comma-separated strings
  const getUpdatedAnalysis = () => {
    return {
      must_have: requirementTags.filter(tag => tag.type === 'must-have').map(tag => tag.text).join(', '),
      nice_to_have: requirementTags.filter(tag => tag.type === 'nice-to-have').map(tag => tag.text).join(', '),
      experience: experienceTags.join(', '),
      industryFocus: industryTags.join(', ')
    };
  };

  // Handle the proceed button click
  const handleProceed = () => {
    // Make a local copy of the updated analysis
    const updatedAnalysis = getUpdatedAnalysis();
    
    // Call onProceed with the updated analysis
    // This will update the parent component's state with the new tags
    if (onProceed) {
      onProceed(updatedAnalysis);
    }
    
    // No need to call onClose here - the parent will handle this
    // The parent's handleTagReviewProceed will call setTagReviewModalOpen(false)
  };

  // Render loading skeleton
  const renderLoading = () => (
    <div className="flex flex-col items-center justify-center py-10">
      <div className="relative mb-6 w-16 h-16">
        <div className="absolute top-0 left-0 w-full h-full border-4 border-gray-200 rounded-full"></div>
        <div className="absolute top-0 left-0 w-full h-full border-4 border-[#02B491] rounded-full animate-spin-slow border-t-transparent"></div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#02B491]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
          </svg>
        </div>
      </div>
      <p className="text-gray-700 text-lg font-medium mb-2">Analysing job description...</p>
      <p className="text-gray-500 text-sm max-w-xs mx-auto text-center">
        Our AI is extracting key requirements from the job description.
      </p>
    </div>
  );

  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300" onClick={handleBackdropClick}>
      <div className="bg-white p-6 rounded-xl shadow-xl w-[800px] max-h-[90vh] overflow-y-auto animate-slideUp" onClick={(e) => e.stopPropagation()}>
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            {isLoading ? `Analysing ${roleTitle}` : `Key Requirements for ${roleTitle}`}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            aria-label="Close"
          >
            <CloseIcon className="w-6 h-6" />
          </button>
        </div>
        
        {isLoading ? (
          renderLoading()
        ) : (
          <div className={`opacity-0 transition-opacity duration-500 ${isReady ? 'opacity-100' : ''}`}>
            <div className="mb-6">
              <p className="text-gray-800 font-bold mb-1">
                Our AI will semantically search through candidates' CVs for the below. Please review & edit.
              </p>
              <p className="text-gray-500 text-sm">
                It works best with generalisations (e.g. 2yrs in consulting / banking / law) but will struggle with intangible attributes (e.g. creativity, ownership).
              </p>
            </div>
            
            {/* Requirements Input Section */}
            <div className="mb-5 flex gap-3">
              <div className="flex-grow relative">
                <LocalOfferIcon className="absolute left-4 top-3 text-gray-400 w-5 h-5" />
                <input
                  className="w-full pl-12 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#02B491] focus:border-transparent transition-all duration-200"
                  value={newRequirementTag}
                  onChange={(e) => setNewRequirementTag(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addTag(newRequirementTag);
                      e.preventDefault();
                    }
                  }}
                  placeholder="Type a skill, qualification or experience..."
                />
              </div>
              <button 
                className="px-6 py-3 bg-[#02B491] hover:bg-[#029f7d] text-white rounded-lg transition-colors duration-200 flex items-center"
                onClick={() => addTag(newRequirementTag)}
              >
                <AddIcon className="mr-1 w-5 h-5" />
                Add
              </button>
            </div>
            
            {/* Requirements Tags Section */}
            <div className="p-4 rounded-lg border border-gray-200 bg-white mb-3 min-h-[140px]">
              <div className="mb-3 flex justify-between items-center">
                <h3 className="text-lg font-medium text-gray-700">Requirements</h3>
                
                {/* Simple Legend */}
                <div className="flex items-center space-x-5">
                  <div className="flex items-center">
                    <div className="w-4 h-4 rounded-full bg-[#02B491] mr-2"></div>
                    <span className="text-sm text-gray-600">Must have</span>
                  </div>
                  <div className="flex items-center">
                    <div className="w-4 h-4 rounded-full bg-[#ff9800] mr-2"></div>
                    <span className="text-sm text-gray-600">Nice to have</span>
                  </div>
                </div>
              </div>
              
              <div className="flex flex-wrap gap-2">
                {requirementTags.length > 0 ? (
                  requirementTags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.text}
                      onClick={() => toggleTagType(index)}
                      onDelete={() => removeTag(index)}
                      sx={{ 
                        margin: '0.25rem', 
                        transition: 'all 0.2s ease',
                        backgroundColor: tag.type === 'must-have' ? '#02B491' : '#ff9800',
                        color: 'white',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                        }
                      }}
                    />
                  ))
                ) : (
                  <p className="text-gray-500 text-sm p-2 italic">
                    Add requirements above to find matching candidates
                  </p>
                )}
              </div>
            </div>
            
            {/* Small note about clicking tags */}
            <p className="text-xs text-gray-500 mb-6 text-center">
              Click on any tag to toggle between "must have" and "nice to have"
            </p>
          </div>
        )}
        
        {/* Footer actions */}
        <div className="flex justify-end pt-4 border-t border-gray-200">
          <button 
            onClick={onClose} 
            className="px-6 py-3 text-gray-600 hover:text-gray-800 mr-4 transition-colors duration-200"
          >
            Cancel
          </button>
          <button 
            onClick={handleProceed} 
            className={`px-8 py-3 rounded-lg font-medium transition-all duration-200 ${isLoading || requirementTags.length === 0 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-[#02B491] hover:bg-[#029f7d] text-white'}`}
            disabled={isLoading || requirementTags.length === 0}
          >
            {isEditMode ? 'Re-run Recommendations' : 'Find Matching Candidates'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagReviewModal; 