import { useParams, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import CongratsScreen from "./in_between_screen";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NewOnboarding() {
  const query = useQuery();
  const startupId = query.get("companyId");
  const navigate = useNavigate();

  const [animationClass, setAnimationClass] = useState("animate-fadeInUp");

  const [file, setFile] = useState(null); // For holding the selected file
  const [isUploading, setIsUploading] = useState(false); // For handling the upload state
  const [message, setMessage] = useState(""); // For displaying success/error messages
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const [step, setStep] = useState(1);
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const totalSteps = 12;
  let intervalId = null; // Variable to track the interval ID

  // State to store selected answers for questions 4, 5, and 6
  const [selectedAnswers, setSelectedAnswers] = useState({
    q10: "", // Will store "yes" or "no" for equity
    q11: "", // Will store "yes" or "no" for equity
    q4: "", // Will store funding stage
  });

  const [company, setCompany] = useState({
    name: "",
    website: "",
    working_style: "",
    location: "",
    funding: "",
    team_size: "",
    profile_picture_link: "",
    description: "",
    fees: "",
    short_interview_process: "",
    initial_contact_name: "",
    initial_contact_email: "",
    initial_contact_phone_number: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLoading(false); // Optionally you can stop loading after a certain time
    }, 2000); // Duration for one cycle + delay before restarting

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const [prevCompany, setPrevCompany] = useState(company); // Store previous company state to detect changes

  // Function to check if any company field has changed
  const hasCompanyChanged = () => {
    return Object.keys(company).some(
      (key) => company[key] !== prevCompany[key]
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, []);

  const handleUpload = async (file) => {
    if (!file) {
      setMessage("Please select a file to upload.");
      return;
    }

    // Start the loading animation
    setIsUploading(true);

    const CLOUDINARY_CLOUD_NAME = "dkjsnpplv";
    const CLOUDINARY_UPLOAD_PRESET = "ml_default";
    const CLOUDINARY_API_KEY = "815772339983468";
    const CLOUDINARY_FOLDER = "ProfilePicture";

    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`;

    try {
      const {
        data: { signature, timestamp },
      } = await axios.get("/api/cloudinarySignaturePP", {
        params: { upload_preset: CLOUDINARY_UPLOAD_PRESET },
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
      formData.append("timestamp", timestamp);
      formData.append("api_key", CLOUDINARY_API_KEY);
      formData.append("signature", signature);
      formData.append("folder", CLOUDINARY_FOLDER);

      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setMessage("File uploaded successfully!");
      setMessage("File uploaded successfully!");
      setCompany((prevFormData) => ({
        ...prevFormData,
        profile_picture_link: response.data.secure_url,
      }));
    } catch (error) {
      // Handle the error
      setMessage(
        `Error: ${error.response ? error.response.data.message : error.message}`
      );
    } finally {
      // End the loading animation
      setIsUploading(false);
    }
  };

  // Function to send company data to the backend

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/companies/fetchCompanyData", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const companyData = response.data.companyData;

        setSelectedAnswers({
          q4: companyData.funding,
          q10: companyData.fees,
          q11: companyData.short_interview_process,
        });
        console.log(companyData);
        setCompany(companyData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to send company data to the backend
  const sendCompanyUpdate = async () => {
    if (hasCompanyChanged()) {
      // Ensure this function checks if the company data has changed
      // Map the company data to match the field names used in the backend
      const updatedData = {
        fields: {
          "Startup Name": company.name,
          Website: company.website,
          "Startup Description": company.description,
          "Team Size": company.team_size,
          "Aware of fees": company.fees,
          "2-3 weeks interview process": company.short_interview_process,
          "Initial Contact Name": company.initial_contact_name,
          "Initial Contact Email": company.initial_contact_email,
          "Initial Contact Phone Number": company.initial_contact_phone_number,
          Location: company.location,
          ...(company.funding && company.funding !== "Choose an option"
            ? { "Funding Stage": company.funding }
            : {}),
        },
      };

      // Add "Logo" to updatedData if it exists (assuming it's stored in company.profile_picture_link)
      if (company.profile_picture_link) {
        updatedData.fields["Logo"] = [{ url: company.profile_picture_link }];
      }

      const token = localStorage.getItem("token");

      try {
        if (!token) {
          throw new Error("Authorization token is missing");
        }

        // Send a PATCH request to update company data
        await axios.patch("/api/companies/updateCompanyData", updatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        setPrevCompany(company); // Update previous company state after a successful update
      } catch (error) {
        console.error("Error updating company data:", error);
        setError("Failed to update company data: " + error.message);
      }
    }
  };

  // useEffect to trigger on step change or role field change
  useEffect(() => {
    sendCompanyUpdate();
  }, [step]);

  const nextStep = () => {
    if (validateStep() && step !== 12) {
      setAnimationClass("animate-fadeOutDown");
      setTimeout(() => {
        setStep((prev) => prev + 1);
        setAnimationClass("animate-fadeInUp");
      }, 500); // Match the animation duration
    }
  };

  const previousStep = () => {
    if (step != 1) {
      setAnimationClass("animate-fadeOutDown");
      setTimeout(() => {
        setStep(step - 1);
        setAnimationClass("animate-fadeInUp");
      }, 500); // Match the animation duration
    }
  };

  const animatePercentage = (start, end) => {
    if (end < 0 || end > 100) {
      return; // Exit if the percentage is out of bounds
    }

    const duration = 500; // Animation duration in milliseconds
    const steps = Math.abs(end - start); // Total steps required
    if (steps === 0) return; // No need to animate if start and end are the same

    const stepTime = Math.floor(duration / steps); // Calculate time per step
    let current = start;
    const increment = end > start ? 1 : -1;

    clearInterval(intervalId); // Clear any previous intervals

    intervalId = setInterval(() => {
      current += increment;
      setPercentageCompleted(current);

      // Stop the interval when the target percentage is reached
      if (current === end || current < 0 || current > 100) {
        clearInterval(intervalId);
      }
    }, stepTime);
  };

  useEffect(() => {
    const newPercentage = Math.round(((step - 1) / (totalSteps - 1)) * 100);
    const boundedPercentage = Math.max(0, Math.min(newPercentage, 100)); // Ensure percentage is between 0 and 100
    animatePercentage(percentageCompleted, boundedPercentage); // Animate to the new percentage
  }, [step]); // Update when the step changes

  const updateCompanyField = (field, value) => {
    setCompany((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isControlOrCommand = event.ctrlKey || event.metaKey; // Check if Ctrl or Command key is pressed

      if (isControlOrCommand && event.key.toLowerCase() === "ArrowRight") {
        nextStep(); // Move to next step if Ctrl/Cmd + N is pressed
      } else if (
        isControlOrCommand &&
        event.key.toLowerCase() === "ArrowLeft"
      ) {
        previousStep(); // Move to previous step if Ctrl/Cmd + P is pressed
      }
    };

    // Add event listener for keydown events
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [step]);

  // Function to handle answer selection for questions 4, 5, and 6
  const handleSelectAnswer = (question, answer) => {
    setSelectedAnswers((prevAnswers) => {
      if (question === "q4") {
        updateCompanyField("funding", answer);
      }
      if (question === "q10") {
        updateCompanyField("fees", answer);
      }
      if (question === "q11") {
        updateCompanyField("short_interview_process", answer);
      }

      let updatedAnswers = { ...prevAnswers };

      // Single choice questions (q4, q10, q11)
      const wasPreviouslySelected = prevAnswers[question] === answer;
      updatedAnswers[question] = wasPreviouslySelected ? "" : answer;

      // Automatically go to the next step if a new answer is selected
      if (!wasPreviouslySelected && (question === "q4" || question === "q10")) {
        setTimeout(() => {
          nextStep();
        }, 300); // Add a slight delay before moving to the next step
      }

      return updatedAnswers;
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key.toLowerCase(); // Normalize the key
      // Question 4 (Funding Stage - Pre-Seed, Seed, Series A, Series B, Series C+)
      if (step === 4) {
        if (key === "1") {
          handleSelectAnswer("q4", "Pre-Seed");
        } else if (key === "2") {
          handleSelectAnswer("q4", "Seed");
        } else if (key === "3") {
          handleSelectAnswer("q4", "Series A");
        } else if (key === "4") {
          handleSelectAnswer("q4", "Series B");
        } else if (key === "5") {
          handleSelectAnswer("q4", "Series C+");
        } else if (key === "6") {
          handleSelectAnswer("q4", "Other");
        }
      }

      // Question 10 (Aware of fees - Yes or No)
      if (step === 10) {
        if (key === "y") {
          handleSelectAnswer("q10", "Yes");
        } else if (key === "n") {
          handleSelectAnswer("q10", "No");
        }
      }

      // Question 11 (Able to commit to 2-3 week interview process - Yes or No)
      if (step === 11) {
        if (key === "y") {
          handleSelectAnswer("q11", "Yes");
        } else if (key === "n") {
          handleSelectAnswer("q11", "No");
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [step, selectedAnswers]);

  const validateStep = () => {
    switch (step) {
      case 1:
        return company?.name && company?.name?.trim() !== "";
      case 2:
        return company?.website && company?.website?.trim() !== "";
      case 3:
        return company?.description && company?.description?.trim() !== "";
      case 4:
        return selectedAnswers.q4 && selectedAnswers?.q4 !== "";
      case 5:
        return company?.location && company?.location?.trim() !== "";
      case 6:
        return company?.team_size && company?.team_size?.trim() !== "";
      case 7:
        return (
          company?.initial_contact_name &&
          company?.initial_contact_name?.trim() !== ""
        );
      case 8:
        return (
          company?.initial_contact_email &&
          company?.initial_contact_email?.trim() !== ""
        );
      case 10:
        return selectedAnswers.q10 && selectedAnswers?.q10 !== "";
      case 11:
        return selectedAnswers.q11 && selectedAnswers?.q11 !== "";
      default:
        return true;
    }
  };

  const [loadingEndFlow, setLoadingEndFlow] = useState();

  const handleSaveAccount = async () => {
    // Set loading to true to show the animation
    setLoadingEndFlow(true);

    // Call the function to update the role data
    await sendCompanyUpdate();

    // Once the update is done, navigate to the /browse-candidates page
    navigate(`/account-created`);
  };

  if (loadingEndFlow) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <h2 className={`text-xl pb-2 font-bold ${animationClass}`}>
          Creating your account
        </h2>
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center  items-center h-screen bg-white">
        <h2 className={`text-xl pb-2 font-bold ${animationClass}`}>
          Logging you in
        </h2>
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-y-hidden items-center bg-white">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>

      <h className="text-lg fixed top-10 right-10">
        {percentageCompleted}% completed
      </h>

      <div className="fixed pb-10 pt-4 bg-white z-50 bottom-0 w-full flex flex-col space-y-4">
        {/* Progress bar section */}
        <div className="w-full flex justify-between items-center gap-0 space-x-0">
          <div className="w-full h-2 bg-gray-300">
            {/* Progress fill */}
            <div
              className="h-2 bg-[#02B491] transition-all duration-500 ease-out"
              style={{ width: `${((step - 1) / 11) * 100}%` }} // Calculate progress based on completed steps
            ></div>
          </div>
        </div>

        {/* Next and Previous text section */}

        <div className="w-full flex justify-between items-center pt-2">
          {/* Previous text on the far left */}
          {step !== 1 ? (
            <div
              onClick={() => previousStep()}
              className="text-left flex px-4 ml-10 cursor-pointer rounded-md hover:bg-black/10 py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>

              <p className="text-gray-700 font-medium ">Previous</p>
            </div>
          ) : (
            <div></div>
          )}

          {step !== 11 ? (
            <div
              onClick={() => nextStep()}
              disabled={!validateStep()}
              className={`text-right px-4 mr-10 flex cursor-pointer py-2 rounded-md ${
                validateStep()
                  ? "hover:bg-black/10"
                  : "opacity-50 cursor-not-allowed"
              }`}
            >
              <p className="text-gray-700 font-medium ">Next</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 ml-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          ) : (
            <div
              onClick={() => handleSaveAccount()}
              className="text-right px-4 mr-10 flex cursor-pointer hover:bg-black/10 py-2 rounded-md"
            >
              <p className="text-gray-700 font-medium ">Create account</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 ml-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          )}

          {/* Next text on the far right */}
        </div>
      </div>

      <div className="flex justify-left pl-[25%] items-center h-screen w-full bg-white">
        <div className="flex flex-col items-left  mb-40 max-w-[800px]">
          {step === 1 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                1. What is your company's name?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-8 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="Jumpstart"
                value={company?.name || ""}
                onChange={(e) =>
                  setCompany({ ...company, name: e.target.value })
                }
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>

                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                2. Please share your company website
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="https://www.jumpstart-uk.com/"
                autoFocus
                value={company?.website || ""}
                onChange={(e) =>
                  setCompany({ ...company, website: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>

                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <div className="flex">
                <h2 className="text-4xl pr-1 font-semibold">3. </h2>
                <h2 className="text-4xl pb-8 font-semibold">
                  Please share a few summary lines about your business
                  <span className="text-black">*</span>
                </h2>
              </div>

              <div className="ml-8">
                <div className="mb-2 flex space-x-2 items-center">
                  <button
                    onClick={() => {
                      const textarea = document.getElementById('company-description');
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;
                      const text = textarea.value;
                      const before = text.substring(0, start);
                      const selection = text.substring(start, end);
                      const after = text.substring(end);
                      const newText = before + '**' + selection + '**' + after;
                      setCompany({ ...company, description: newText });
                      
                      // Set cursor position after the inserted text
                      setTimeout(() => {
                        textarea.focus();
                        const newPosition = end + 4; // 4 is the length of the added '**' tags
                        textarea.setSelectionRange(newPosition, newPosition);
                      }, 0);
                    }}
                    className="p-1 rounded hover:bg-gray-100 text-gray-700 border border-gray-300"
                    title="Bold"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
                      <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      const textarea = document.getElementById('company-description');
                      const start = textarea.selectionStart;
                      const text = textarea.value;
                      const before = text.substring(0, start);
                      const after = text.substring(start);
                      
                      // Add a bullet point at the beginning of the line
                      let newText;
                      if (before.endsWith('\n') || before === '') {
                        newText = before + '• ' + after;
                      } else {
                        newText = before + '\n• ' + after;
                      }
                      
                      setCompany({ ...company, description: newText });
                      
                      // Set cursor position after the bullet point
                      setTimeout(() => {
                        textarea.focus();
                        const newPosition = start + (before.endsWith('\n') || before === '' ? 2 : 3);
                        textarea.setSelectionRange(newPosition, newPosition);
                      }, 0);
                    }}
                    className="p-1 rounded hover:bg-gray-100 text-gray-700 border border-gray-300"
                    title="Bullet Point"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="8" y1="6" x2="21" y2="6"></line>
                      <line x1="8" y1="12" x2="21" y2="12"></line>
                      <line x1="8" y1="18" x2="21" y2="18"></line>
                      <line x1="3" y1="6" x2="3.01" y2="6"></line>
                      <line x1="3" y1="12" x2="3.01" y2="12"></line>
                      <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                  </button>
                  <span className="text-sm text-gray-500">Add basic formatting to your description</span>
                </div>

                <textarea
                  id="company-description"
                  className="border rounded-md font-normal w-[700px] border-gray-200 text-xl placeholder:text-gray-400 outline-none transition-colors duration-200 p-3"
                  placeholder="At Jumpstart, we dramatically streamline the hiring process for early career talent. We go through 1000s of applications every month to handpick the top 1% of candidates. We then matchmake those candidates with startups by getting startups to pitch their companies & roles to the handpicked few."
                  autoFocus
                  value={company?.description || ""}
                  onChange={(e) =>
                    setCompany({ ...company, description: e.target.value })
                  }
                  rows={8}
                />
                <div className="mt-1 text-sm text-gray-500">
                  Use line breaks for paragraphs. You can add <span className="font-mono">**text**</span> for <strong>bold text</strong> and bullet points for lists.
                </div>
              </div>
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                4. What is your funding stage?
                <span className="text-black">*</span>
              </h2>
              <div className="ml-10 sm:col-span-8 flex space-x-2">
                <div className="flex-1">
                  <button
                    className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border border-green-400 bg-green-400/10 hover:bg-green-400/20 ${
                      selectedAnswers.q4 === "Pre-Seed" ? "border-2" : ""
                    }`}
                    onClick={(e) => {
                      console.log("no");
                      e.preventDefault();
                      handleSelectAnswer("q4", "Pre-Seed");
                    }}
                  >
                    <span
                      className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-400 ${
                        selectedAnswers.q4 === "Pre-Seed"
                          ? "bg-green-400 text-white"
                          : "bg-white/50"
                      }`}
                    >
                      1
                    </span>
                    Pre-Seed
                    {selectedAnswers.q4 === "Pre-Seed" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="ml-auto w-5 h-5 text-green-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </button>

                  <button
                    className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border border-green-400 bg-green-400/10 hover:bg-green-400/20 ${
                      selectedAnswers.q4 === "Seed" ? "border-2" : ""
                    }`}
                    onClick={() => handleSelectAnswer("q4", "Seed")}
                  >
                    <span
                      className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-400 ${
                        selectedAnswers.q4 === "Seed"
                          ? "bg-green-400 text-white"
                          : "bg-white/50"
                      }`}
                    >
                      2
                    </span>
                    Seed
                    {selectedAnswers.q4 === "Seed" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="ml-auto w-5 h-5 text-green-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </button>

                  <button
                    className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border border-green-400 bg-green-400/10 hover:bg-green-400/20 ${
                      selectedAnswers.q4 === "Series A" ? "border-2" : ""
                    }`}
                    onClick={() => handleSelectAnswer("q4", "Series A")}
                  >
                    <span
                      className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-400 ${
                        selectedAnswers.q4 === "Series A"
                          ? "bg-green-400 text-white"
                          : "bg-white/50"
                      }`}
                    >
                      3
                    </span>
                    Series A
                    {selectedAnswers.q4 === "Series A" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="ml-auto w-5 h-5 text-green-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </button>
                </div>

                <div className="flex-1">
                  <button
                    className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border border-green-400 bg-green-400/10 hover:bg-green-400/20 ${
                      selectedAnswers.q4 === "Series B" ? "border-2" : ""
                    }`}
                    onClick={() => handleSelectAnswer("q4", "Series B")}
                  >
                    <span
                      className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-400 ${
                        selectedAnswers.q4 === "Series B"
                          ? "bg-green-400 text-white"
                          : "bg-white/50"
                      }`}
                    >
                      4
                    </span>
                    Series B
                    {selectedAnswers.q4 === "Series B" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="ml-auto w-5 h-5 text-green-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </button>

                  <button
                    className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border border-green-400 bg-green-400/10 hover:bg-green-400/20 ${
                      selectedAnswers.q4 === "Series C+" ? "border-2" : ""
                    }`}
                    onClick={() => handleSelectAnswer("q4", "Series C+")}
                  >
                    <span
                      className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-400 ${
                        selectedAnswers.q4 === "Series C+"
                          ? "bg-green-400 text-white"
                          : "bg-white/50"
                      }`}
                    >
                      5
                    </span>
                    Series C+
                    {selectedAnswers.q4 === "Series C+" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="ml-auto w-5 h-5 text-green-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </button>

                  <button
                    className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border border-green-400 bg-green-400/10 hover:bg-green-400/20 ${
                      selectedAnswers.q4 === "Other" ? "border-2" : ""
                    }`}
                    onClick={() => handleSelectAnswer("q4", "Other")}
                  >
                    <span
                      className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-400 ${
                        selectedAnswers.q4 === "Other"
                          ? "bg-green-400 text-white"
                          : "bg-white/50"
                      }`}
                    >
                      6
                    </span>
                    Other
                    {selectedAnswers.q4 === "Other" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="ml-auto w-5 h-5 text-green-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
              </div>
            </div>
          )}

          {step === 5 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                5. Where are you based?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="London"
                autoFocus
                value={company?.location || ""}
                onChange={(e) =>
                  setCompany({ ...company, location: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />

              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}

          {step === 6 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                6. What is your team size?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="6"
                autoFocus
                value={company?.team_size || ""}
                onChange={(e) =>
                  setCompany({ ...company, team_size: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />

              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-xs ml-2 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 7 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                7. What is your name?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-8 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="John Smith"
                value={company?.initial_contact_name || ""}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    initial_contact_name: e.target.value,
                  })
                }
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 8 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                8. What is your email address?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-8 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="john@jumpstart-uk.com"
                value={company?.initial_contact_email || ""}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    initial_contact_email: e.target.value,
                  })
                }
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 9 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                9. What is your phone number?
              </h2>
              <input
                className="border-b-2 ml-8 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="+44 749 938 401"
                value={company?.initial_contact_phone_number || ""}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    initial_contact_phone_number: e.target.value,
                  })
                }
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => nextStep()}
                  className="mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  OK
                </button>{" "}
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 15 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                7. What is your normal working style?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-8 font-normal w-[500px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="Hybrid - 2/3 days in office"
                autoFocus
                value={company?.working_style || ""}
                onChange={(e) =>
                  setCompany({ ...company, working_style: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => nextStep()}
                  className="mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  OK
                </button>{" "}
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 10 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <div className="flex">
                <h2 className="text-4xl pr-1 font-semibold">10.</h2>
                <h2 className="text-4xl pb-8 font-semibold">
                  Please confirm you agree to the terms associated with hiring
                  via Jumpstart?<span className="text-black">*</span>
                </h2>
              </div>

              <div className="ml-14">
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[200px] py-2 rounded-md border hover:bg-[#02B491]/20 border-[#02B491] bg-[#02B491]/10 ${
                    selectedAnswers.q10 === "Yes" ? "border-2" : ""
                  }`}
                  onClick={() => handleSelectAnswer("q10", "Yes")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-[#02B491] ${
                      selectedAnswers.q10 === "Yes"
                        ? "bg-[#02B491] text-white"
                        : "bg-white/50"
                    }`}
                  >
                    Y
                  </span>
                  Yes
                  {selectedAnswers.q10 === "Yes" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`px-2 text-left flex items-center text-2xl w-[200px] hover:bg-gray-600/20 py-2 rounded-md border border-gray-600 bg-gray-600/10 ${
                    selectedAnswers.q10 === "No"
                      ? "border-2 "
                      : "border-gray-600"
                  }`}
                  onClick={() => handleSelectAnswer("q10", "No")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-gray-600  ${
                      selectedAnswers.q10 === "No"
                        ? "bg-gray-600 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    N
                  </span>
                  No
                  {selectedAnswers.q10 === "No" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="black"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
              </div>

              {/* New text with hyperlink */}
              <p className="ml-14 mt-4">
                For more information regarding our terms{" "}
                <a
                  href="https://jumpstart-alumni.notion.site/Terms-for-Startup-Partners-127b8f57dd84802ca447cfd81a65ff90"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  click here
                </a>
                .
              </p>

              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only call nextStep if validateStep returns true
                  className={`mt-4 ml-14 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
              </div>
            </div>
          )}

          {step === 11 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <div className="flex">
                <h2 className="text-4xl pr-2 font-semibold">11.</h2>{" "}
                <h2 className="text-4xl pb-8 font-semibold">
                  Are you able to keep to a 2-3 week interview process once
                  you've been introduced to candidates?
                  <span className="text-black">*</span>
                </h2>
              </div>

              <div className="ml-14">
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[200px] py-2 rounded-md border hover:bg-[#02B491]/20 border-[#02B491] bg-[#02B491]/10 ${
                    selectedAnswers.q11 === "Yes" ? "border-2" : ""
                  }`}
                  onClick={() => handleSelectAnswer("q11", "Yes")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-[#02B491] ${
                      selectedAnswers.q11 === "Yes"
                        ? "bg-[#02B491] text-white"
                        : "bg-white/50"
                    }`}
                  >
                    Y
                  </span>
                  Yes
                  {selectedAnswers.q11 === "Yes" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`px-2 text-left flex items-center text-2xl w-[200px] hover:bg-gray-600/20 py-2 rounded-md border border-gray-600 bg-gray-600/10 ${
                    selectedAnswers.q11 === "No"
                      ? "border-2 "
                      : "border-gray-600"
                  }`}
                  onClick={() => handleSelectAnswer("q11", "No")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-gray-600  ${
                      selectedAnswers.q11 === "No"
                        ? "bg-gray-600 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    N
                  </span>
                  No
                  {selectedAnswers.q11 === "No" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="black"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="flex items-center ">
                <button
                  onClick={() => handleSaveAccount()}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                  className={`mt-4 ml-14 text-xl flex justify-center items-center font-medium py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                >
                  Create account
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="size-6 ml-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewOnboarding;
