import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ExternalLink, AlertCircle, ArrowLeft } from "lucide-react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Role() {
  const query = useQuery();
  const navigate = useNavigate();
  const roleId = query.get("roleId");

  const [role, setRole] = useState(null);
  const [companySpotlight, setCompanySpotlight] = useState(null);
  const [roleSpotlight, setRoleSpotlight] = useState(null);

  // Loading / Error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Tab for Spotlights
  const [activeTab, setActiveTab] = useState("company");

  // For controlling video play (so only one plays at a time)
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    // If you need token-based auth, you could check localStorage here
    // const token = localStorage.getItem("token");
    // if (!token) {
    //   navigate("/login");
    // }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // 1) Fetch the role from /api/roles/fetchRole
        const roleResponse = await axios.get(`/api/roles/fetchRole?id=${roleId}`);
        const r = roleResponse.data.role;
        if (!r) {
          throw new Error("No role data found");
        }

        // Transform fields (this is your existing mapping logic)
        const transformedData = {
          id: r["id"] || "",
          job_description_pdf: r.fields["Job Description PDF"] ?? "",
          working_style: r.fields["Working Style"] ?? "",
          hiring_lead_email: r.fields["Hiring Lead Email Address"] ?? "",
          interview_process: r.fields["Interview Process"] ?? "",
          hiring_lead_name: r.fields["Hiring Lead Name"] ?? "",
          image:
            r.fields["Role Cover Image (from Startup)"]?.[0]?.url ??
            "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg",
          spotlight: r.fields["URL Cleaned (from Spotlight)"] ?? "",
          compensation: r.fields["Compensation"] ?? "",
          startup_description: r.fields["Startup Description (from Startup)"] ?? "",
          website: r.fields["Website (from Startup)"] ?? "",
          team_size: r.fields["Team Size (from Startup)"] ?? "",
          funding: r.fields["Funding Stage (from Startup)"] ?? "",
          startup_name: r.fields["Name (from Startup)"]?.[0] ?? "",
          linkedin: r.fields["Startup Linkedin (from Startup)"] ?? "",
          title: r.fields["Role Title"] ?? "",
          key_experience: r.fields["Specific Experience"] ?? "",
          additional_info: r.fields["Additional Information"] ?? "",
          location: r.fields["Location (from Startup)"] ?? "",
          dropbox_url: r.fields["URL Dropbox cleaned (from Spotlight)"]?.[0] ?? null,
          loom_url: r.fields["URL Loom cleaned (from Spotlight)"]?.[0] ?? null,
          hiring_lead_linkedin: r.fields["Hiring Lead Linkedin"] ?? "",
          spotlight_context: r.fields["Spotlight Context"] ?? "",
          // IDs of assigned spotlights
          spotlightIds: r.fields["Spotlight"] || [],
        };

        setRole(transformedData);

        // 2) Fetch assigned Spotlights (if any)
        const assignedIDs = transformedData.spotlightIds || [];
        if (assignedIDs.length > 0) {
          const token = localStorage.getItem("token");
          let spsResp;
          try {
            spsResp = await axios.get("/api/spotlights/fetchSpotlights", {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            });
          } catch (err) {
            console.error("Failed to fetch spotlights:", err);
            spsResp = { data: { spotlights: [] } };
          }

          let allSps = spsResp.data.spotlights || [];
          // Filter out deleted or unassigned
          allSps = allSps.filter(
            (sp) => !sp.Deleted && assignedIDs.includes(sp.id)
          );

          // Identify a single "company" vs. "role" spotlight
          const assignedCompany = allSps.find(
            (sp) =>
              sp.Type === "Company information" || sp.Type === "Company and Role"
          );
          const assignedRole = allSps.find(
            (sp) =>
              sp.Type === "Role information" || sp.Type === "Company and Role"
          );

          setCompanySpotlight(assignedCompany || null);
          setRoleSpotlight(assignedRole || null);
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message || "Failed to load role data");
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId]);

  // Helper to only allow one video playing at a time
  const handlePlay = () => {
    if (currentlyPlaying && currentlyPlaying !== videoRef.current) {
      currentlyPlaying.pause();
    }
    setCurrentlyPlaying(videoRef.current);
  };

  // Renders a single spotlight video
  const renderSpotlightVideo = (spot) => {
    if (!spot) return null;

    const loomUrl = spot["URL Loom cleaned"];
    const dropboxUrl = spot["URL Dropbox cleaned"];

    // If we have a Dropbox link, embed as a video
    if (dropboxUrl) {
      return (
        <video
          ref={videoRef}
          src={dropboxUrl}
          className="w-full h-[400px] border object-cover rounded-lg bg-gray-100 mb-4 shadow-sm transition-transform hover:scale-[1.01]"
          controls
          onPlay={handlePlay}
        />
      );
    }
    // If we have a Loom link, embed an iframe
    if (loomUrl) {
      return (
        <iframe
          src={loomUrl}
          className="w-full h-[400px] object-cover rounded-lg bg-gray-100 mb-4 shadow-sm transition-transform hover:scale-[1.01]"
          title="Loom Spotlight"
        />
      );
    }
    // Otherwise, no video
    return null;
  };

  // Quick utility to handle line breaks in text fields
  const formatTextWithLineBreaks = (text) => {
    // Handle null, undefined, or non-string values
    if (!text) return "";
    
    // Ensure text is a string
    const textStr = String(text);
    
    // Process bold text wrapped in ** markers
    const processBoldText = (line) => {
      return line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    };
    
    // Process bullet points
    const processBulletPoints = (line) => {
      if (line.startsWith('• ')) {
        return `<li>${line.substring(2)}</li>`;
      }
      return line;
    };
    
    // Split by line breaks and process each line
    const lines = textStr.split('\n');
    let inList = false;
    let formattedText = [];
    
    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];
      
      // Process bold text
      line = processBoldText(line);
      
      // Check if this line is a bullet point
      const isBullet = line.startsWith('• ') || line.startsWith('<li>');
      
      // Handle list beginning and ending
      if (isBullet && !inList) {
        formattedText.push('<ul class="list-disc ml-5 my-2">');
        inList = true;
      } else if (!isBullet && inList) {
        formattedText.push('</ul>');
        inList = false;
      }
      
      // Process and add the line
      if (isBullet) {
        formattedText.push(processBulletPoints(line));
      } else if (line.trim() === '') {
        // Add paragraph spacing for empty lines
        formattedText.push('<br />');
      } else {
        formattedText.push(line);
      }
    }
    
    // Close any open list
    if (inList) {
      formattedText.push('</ul>');
    }
    
    return (
      <div dangerouslySetInnerHTML={{ __html: formattedText.join('') }} />
    );
  };

  // ------------------ LOADING / ERROR STATES -----------------
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="text-gray-600 animate-pulse">Loading role data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!role) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="text-gray-600">No role found.</div>
      </div>
    );
  }

  // ------------------ MAIN RENDER -----------------
  return (
    <div className="bg-gradient-to-b from-white to-gray-50 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 py-8">

        {/* SPOTLIGHT SECTION */}
        {(companySpotlight || roleSpotlight) && (
          <div className="mt-6 mb-8">
            {/* Show tabs only if we have separate company and role spotlights */}
            {companySpotlight?.Type !== "Company and Role" && roleSpotlight?.Type !== "Company and Role" ? (
              <div className="flex border-b border-gray-200">
                <button
                  className={`py-2 px-4 text-sm font-medium transition-colors duration-200 ${
                    activeTab === "company"
                      ? "border-b-2 border-[#08f2c3] text-[#08f2c3]"
                      : "text-gray-600 hover:text-gray-900"
                  }`}
                  onClick={() => setActiveTab("company")}
                >
                  Company Spotlight
                </button>
                <button
                  className={`py-2 px-4 text-sm font-medium transition-colors duration-200 ${
                    activeTab === "role"
                      ? "border-b-2 border-[#08f2c3] text-[#08f2c3]"
                      : "text-gray-600 hover:text-gray-900"
                  }`}
                  onClick={() => setActiveTab("role")}
                >
                  Role Spotlight
                </button>
              </div>
            ) : (
              <div className="border-b border-gray-200">
                <div className="py-2 px-4 text-sm font-medium text-[#08f2c3] border-b-2 border-[#08f2c3]">
                  Company and Role Spotlight
                </div>
              </div>
            )}
            
            <div className="mt-4">
              {/* For "Company and Role" type, show the spotlight regardless of active tab */}
              {(companySpotlight?.Type === "Company and Role" || roleSpotlight?.Type === "Company and Role") ? (
                renderSpotlightVideo(companySpotlight || roleSpotlight)
              ) : (
                <>
                  {activeTab === "company" && companySpotlight
                    ? renderSpotlightVideo(companySpotlight)
                    : null}
                  {activeTab === "company" && !companySpotlight && (
                    <p className="text-sm text-gray-500 py-3">
                      No company spotlight available.
                    </p>
                  )}
                  {activeTab === "role" && roleSpotlight
                    ? renderSpotlightVideo(roleSpotlight)
                    : null}
                  {activeTab === "role" && !roleSpotlight && (
                    <p className="text-sm text-gray-500 py-3">
                      No role spotlight available.
                    </p>
                  )}
                </>
              )}
            </div>
            
            {/* Spotlight Context */}
            {role.spotlight_context && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <p className="text-sm text-gray-600 leading-relaxed">
                  {formatTextWithLineBreaks(role.spotlight_context)}
                </p>
              </div>
            )}
          </div>
        )}

        {/* ROLE TITLE / COMPANY NAME */}
        <div className="mb-4">
          <h1 className="text-3xl font-bold tracking-tight">
            {role.title || "Untitled Role"}
          </h1>
          <p className="text-lg text-gray-600 mt-1">
            {role.startup_name || "Unnamed Company"}
          </p>
        </div>

        {/* TAGS SECTION */}
        <div className="flex flex-wrap gap-2 mb-6">
          {role.compensation && (
            <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-purple-100 px-4 py-1 text-md font-medium text-purple-700 ring-1 ring-inset ring-purple-200">
              {role.compensation}
            </span>
          )}
          {role.team_size && (
              <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-green-100 px-4 py-1 text-md md:text-md font-medium text-green-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
              {role.team_size} in the team
            </span>
          )}
          {role.funding && (
 <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-red-100 px-4 py-1 text-md md:text-md font-medium text-red-500">
 <svg
   xmlns="http://www.w3.org/2000/svg"
   fill="none"
   viewBox="0 0 24 24"
   stroke-width="1.5"
   stroke="currentColor"
   class="size-5"
 >
   <path
     stroke-linecap="round"
     stroke-linejoin="round"
     d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
   />
   <path
     stroke-linecap="round"
     stroke-linejoin="round"
     d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
   />
 </svg>              {role.funding}
            </span>
          )}
          {role.location && (
           <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-blue-100 px-4 py-1 text-md md:text-md font-medium text-blue-500">
           <svg
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
             stroke-width="2"
             stroke="currentColor"
             class="size-5"
           >
             <path
               stroke-linecap="round"
               stroke-linejoin="round"
               d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
             />
             <path
               stroke-linecap="round"
               stroke-linejoin="round"
               d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
             />
           </svg>
              {role.location}
            </span>
          )}
        </div>

        <hr className="my-6 border-gray-200" />


{/* COMPANY DESCRIPTION */}
{role.startup_description && (
          <div className={`mb-${role.startup_description.length < 100 ? '4' : '8'}`}>
            <h2 className="text-xl font-bold mb-2">Company Description</h2>
            <div className="flex flex-col space-y-2">
              <div className="text-base text-gray-700 leading-relaxed">
                {formatTextWithLineBreaks(role.startup_description)}
              </div>
              {role.website && (
                <a
                  href={role.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-base text-[#08f2c3] font-medium hover:text-[#06d1a7] transition-colors duration-200"
                >
                  Visit Website
                  <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              )}
            </div>
          </div>
        )}

        {/* KEY SKILLS (Specific Experience) */}
        {role.key_experience && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-3">Key Skills</h2>
            <p className="text-base text-gray-700 whitespace-pre-line leading-relaxed">
              {formatTextWithLineBreaks(role.key_experience)}
            </p>
          </div>
        )}

        {/* INTERVIEW PROCESS */}
        {role.interview_process && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-3">Interview Process</h2>
            <p className="text-base text-gray-700 whitespace-pre-line leading-relaxed">
              {formatTextWithLineBreaks(role.interview_process)}
            </p>
          </div>
        )}

        {/* WORKING STYLE (Optional if you want to show it) */}
        {role.working_style && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-3">Team Working Style</h2>
            <p className="text-base text-gray-700 whitespace-pre-line leading-relaxed">
              {role.working_style}
            </p>
          </div>
        )}

        {/* ADDITIONAL INFO */}
        {role.additional_info && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-3">Additional Information</h2>
            <p className="text-base text-gray-700 whitespace-pre-line leading-relaxed">
              {role.additional_info}
            </p>
          </div>
        )}

        {/* HIRING LEAD (Optional) */}
        {role.hiring_lead_name && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-3">Hiring Lead</h2>
            <p className="text-base text-gray-700 leading-relaxed">
              {role.hiring_lead_linkedin ? (
                <a
                  href={role.hiring_lead_linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {role.hiring_lead_name}
                </a>
              ) : (
                <span>{role.hiring_lead_name}</span>
              )}
              {role.hiring_lead_email && <> - {role.hiring_lead_email}</>}
            </p>
          </div>
        )}

        {/* JOB DESCRIPTION PDF */}
        {role.job_description_pdf ? (
          <div className="mb-6">
            <a
              href={role.job_description_pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-black bg-[#08f2c3] rounded-md hover:bg-[#06d1a7] transition-colors duration-200"
            >
              View full job description
              <ExternalLink className="w-4 h-4 ml-1" />
            </a>
          </div>
        ) : (
          <div className="flex items-center mb-6 p-3 bg-yellow-50 rounded-md">
            <AlertCircle className="w-4 h-4 mr-2 text-yellow-500" />
            <span className="text-sm text-yellow-700 font-medium">
              Job description not available
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Role;
