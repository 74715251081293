import { useState, useEffect } from "react";

function FiltersModal({
  setFiltersModalOpen,
  filterWorkingStyle,
  handleWorkingStyleFilterChange,
  filterIndustry,
  filterYearsOfExp,
  handleYearsOfExpFilterChange,
  setFilterIndustry,
  filterStream,
  handleStreamFilterChange,
  length,
  clearFilters,
  maxSalary,
  setMaxSalary,
}) {
  const [minSelected, setMinSelected] = useState(null);
  const [maxSelected, setMaxSelected] = useState(null);
  const [isSelectingMax, setIsSelectingMax] = useState(false);

  const year_of_exp = ["0-1", "1-2", "2-3", "3-4", "4+"];
  const salaryOptions = [30, 35, 40, 45, 50, "50k+"];

  const handleSalaryClick = (index) => {
    const selectedSalary =
      salaryOptions[index] === "50k+" ? "50k+" : salaryOptions[index];
    setMaxSalary(maxSalary === selectedSalary ? null : selectedSalary);
  };

  const handleTileClick = (index) => {
    if (!isSelectingMax) {
      setMinSelected(index);
      setMaxSelected(index);
      setIsSelectingMax(true);
    } else {
      setMaxSelected(index);
      setIsSelectingMax(false);
    }
  };

  useEffect(() => {
    if (minSelected !== null && maxSelected !== null) {
      const selectedFilters = year_of_exp.slice(minSelected, maxSelected + 1);
      handleYearsOfExpFilterChange(selectedFilters);
    }
  }, [minSelected, maxSelected]);

  useEffect(() => {
    if (filterYearsOfExp && filterYearsOfExp.length > 0) {
      const minIndex = year_of_exp.findIndex(
        (exp) => exp === filterYearsOfExp[0]
      );
      const maxIndex = year_of_exp.findIndex(
        (exp) => exp === filterYearsOfExp[filterYearsOfExp.length - 1]
      );

      setMinSelected(minIndex);
      setMaxSelected(maxIndex);
    } else {
      setMinSelected(null);
      setMaxSelected(null);
    }
  }, [filterYearsOfExp]);

  const handleOptionChange = (selectedOptions, option, onChange) => {
    if (selectedOptions.includes(option)) {
      onChange(selectedOptions.filter((item) => item !== option));
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const clearAllFilters = () => {
    clearFilters();
    setMinSelected(null);
    setMaxSelected(null);
    setIsSelectingMax(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[60] transition-opacity duration-300"
        onClick={() => setFiltersModalOpen(false)}
      />

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center z-[70]">
        <div className="relative w-full max-w-[700px] max-h-[90vh] bg-white rounded-xl shadow-2xl overflow-hidden transform transition-all duration-300 ease-out">
          {/* Header */}
          <div className="sticky top-0 bg-white px-6 py-4 border-b flex justify-between items-center">
            <h3 className="text-xl font-semibold">Filters</h3>
            <button
              onClick={() => setFiltersModalOpen(false)}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Content */}
          <div className="overflow-y-auto px-6 py-4" style={{ maxHeight: "calc(90vh - 140px)" }}>
            {/* Type of candidate */}
            <div className="space-y-4 mb-8">
              <h2 className="text-lg font-medium">Type of candidate</h2>
              <div className="flex flex-wrap gap-2">
                {[
                  {
                    label: "Generalist",
                    value: "Generalist",
                    color: "green",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                      </svg>
                    ),
                  },
                  {
                    label: "Sales",
                    value: "Sales",
                    color: "blue",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                      </svg>
                    ),
                  },
                  {
                    label: "Marketing",
                    value: "Marketing",
                    color: "purple",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
                      </svg>
                    ),
                  },
                  {
                    label: "Software Engineering",
                    value: "Software Engineering",
                    color: "orange",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
                      </svg>
                    ),
                  },
                ].map((option) => (
                  <button
                    key={option.value}
                    onClick={() =>
                      handleOptionChange(
                        filterStream,
                        option.value,
                        handleStreamFilterChange
                      )
                    }
                    className={`py-2 px-4 rounded-lg border transition-all inline-flex items-center space-x-2 ${
                      filterStream.includes(option.value)
                        ? `bg-${option.color}-50 border-${option.color}-500 text-${option.color}-700`
                        : "border-gray-300 hover:border-gray-400"
                    }`}
                  >
                    {option.icon}
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Maximum salary */}
            <div className="space-y-4 mb-8">
              <div className="flex items-baseline gap-2">
                <h2 className="text-lg font-medium">Maximum salary</h2>
                <span className="text-sm text-gray-500">Select one</span>
              </div>
              <div className="flex">
                {salaryOptions.map((label, index) => (
                  <button
                    key={index}
                    onClick={() => handleSalaryClick(index)}
                    className={`flex-1 py-2 transition-colors ${
                      maxSalary === (typeof label === "number" ? label : label)
                        ? "bg-[#02B491] text-white"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    } ${index === 0 ? "rounded-l-lg" : ""} ${
                      index === salaryOptions.length - 1 ? "rounded-r-lg" : ""
                    }`}
                  >
                    {typeof label === "number" ? `${label}k` : label}
                  </button>
                ))}
              </div>
            </div>

            {/* Years of experience */}
            <div className="space-y-4 mb-8">
              <div className="flex items-baseline gap-2">
                <h2 className="text-lg font-medium">Years of experience</h2>
                <span className="text-sm text-gray-500">Select a range</span>
              </div>
              <div className="flex">
                {[0, 1, 2, 3, "4+"].map((label, index) => (
                  <button
                    key={index}
                    onClick={() => handleTileClick(index)}
                    className={`flex-1 py-2 transition-colors ${
                      minSelected !== null &&
                      maxSelected !== null &&
                      index >= minSelected &&
                      index <= maxSelected
                        ? "bg-[#02B491] text-white"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    } ${index === 0 ? "rounded-l-lg" : ""} ${
                      index === 4 ? "rounded-r-lg" : ""
                    }`}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>

            {/* Working style */}
            <div className="space-y-4 mb-8">
              <h2 className="text-lg font-medium">Working style</h2>
              <div className="flex flex-wrap gap-2">
                {[
                  {
                    label: "In person",
                    value: "Fully in person",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                      </svg>
                    ),
                  },
                  {
                    label: "Hybrid",
                    value: "Hybrid",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
                      </svg>
                    ),
                  },
                  {
                    label: "Remote",
                    value: "Fully remote",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525" />
                      </svg>
                    ),
                  },
                ].map((option) => (
                  <button
                    key={option.value}
                    onClick={() =>
                      handleOptionChange(
                        filterWorkingStyle,
                        option.value,
                        handleWorkingStyleFilterChange
                      )
                    }
                    className={`py-2 px-4 rounded-lg border transition-all inline-flex items-center space-x-2 ${
                      filterWorkingStyle.includes(option.value)
                        ? "bg-gray-900 text-white border-gray-900"
                        : "border-gray-300 hover:border-gray-400"
                    }`}
                  >
                    {option.icon}
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Industry experience */}
            <div className="space-y-4">
              <h2 className="text-lg font-medium">Industry experience</h2>
              <div className="flex flex-wrap gap-2">
                {[
                  {
                    label: "Banking",
                    value: "Banking",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
                      </svg>
                    ),
                  },
                  {
                    label: "Consulting",
                    value: "Consulting",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                      </svg>
                    ),
                  },
                  {
                    label: "Startup experience",
                    value: "Startup experience",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                      </svg>
                    ),
                  },
                  {
                    label: "Founder experience",
                    value: "Founder experience",
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008Z" />
                      </svg>
                    ),
                  },
                ].map((option) => (
                  <button
                    key={option.value}
                    onClick={() =>
                      handleOptionChange(
                        filterIndustry,
                        option.value,
                        setFilterIndustry
                      )
                    }
                    className={`py-2 px-4 rounded-lg border transition-all inline-flex items-center space-x-2 ${
                      filterIndustry.includes(option.value)
                        ? "bg-gray-900 text-white border-gray-900"
                        : "border-gray-300 hover:border-gray-400"
                    }`}
                  >
                    {option.icon}
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="sticky bottom-0 bg-white px-6 py-4 border-t flex justify-between items-center">
            <button
              onClick={clearAllFilters}
              className="text-gray-600 hover:text-gray-900 font-medium"
            >
              Clear filters
            </button>
            <button
              onClick={() => setFiltersModalOpen(false)}
              className="bg-[#02B491] text-white px-6 py-2 rounded-lg hover:bg-[#02a082] transition-colors"
            >
              View {length} candidates
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FiltersModal;
