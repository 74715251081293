import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  Pencil,
  ExternalLink,
  AlertCircle,
  Info,
  Calendar,
  Settings,
  Users,
  PoundSterling,
  Briefcase
} from "lucide-react"
import axios from "axios"

/**
 * Publish requirements:
 * 1. Basic Info fields (all required):
 *    - Role title
 *    - Working style
 *    - Compensation
 *    - Hiring lead name
 *    - Hiring lead email
 *    - Hiring lead phone
 *    - Hiring lead LinkedIn
 * 2. Either a Role Spotlight (video) OR a Job Description
 */

const ReviewRole = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queryRoleId = searchParams.get("roleId") || localStorage.getItem("currentRoleId")
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [publishSuccessScenario, setPublishSuccessScenario] = useState(null)

  const [token, setToken] = useState("")
  const [roleId, setRoleId] = useState(null)
  const [roleFields, setRoleFields] = useState({})
  const [loading, setLoading] = useState(true)
  const [publishError, setPublishError] = useState(null)

  const [activeTab, setActiveTab] = useState("company")
  const [isNewStartup, setIsNewStartup] = useState(true)
  const [hasBookedCall] = useState(() => localStorage.getItem("hasBookedCall") === "true")

  // Heading changes depending on whether the role is fully ready or not
  const [headingText, setHeadingText] = useState("It's time to publish!")
  const [isPublishDisabled, setIsPublishDisabled] = useState(false)

  // "What's next?" is only visible if no fields are missing
  const [showWhatsNext, setShowWhatsNext] = useState(false)

  // For truncating Key Skills / Interview Process
  const [showFullKeySkills, setShowFullKeySkills] = useState(false)
  const [showFullInterview, setShowFullInterview] = useState(false)

  // For card auto-scaling
  const cardRef = useRef(null)
  const [scale, setScale] = useState(1)

  // Helper to check if everything is loaded and then scale the card
  const scaleCardToFit = () => {
    if (!cardRef.current) return

    // Some margin to ensure headings and modals fit too
    const paddingFromHeaderFooter = 160

    const cardHeight = cardRef.current.offsetHeight
    const windowHeight = window.innerHeight - paddingFromHeaderFooter

    // If the card is taller than the available viewport, shrink it
    if (cardHeight > windowHeight) {
      const newScale = windowHeight / cardHeight
      setScale(newScale < 0.5 ? 0.5 : newScale) // Don't shrink below 50%, if you like
    } else {
      setScale(1)
    }
  }

  // Re-scale whenever the window resizes, or data changes
  useEffect(() => {
    window.addEventListener("resize", scaleCardToFit)
    return () => window.removeEventListener("resize", scaleCardToFit)
  }, [])

  // On load, fetch data
  useEffect(() => {
    const storedToken = localStorage.getItem("token")
    setToken(storedToken || "")
    setRoleId(queryRoleId || null)

    // Clear hasBookedCall if this is a new role
    if (!queryRoleId) {
      localStorage.removeItem("hasBookedCall")
    }

    const companySpotlight = JSON.parse(localStorage.getItem("selectedCompanySpotlight") || "null")
    const roleSpotlight = JSON.parse(localStorage.getItem("selectedRoleSpotlight") || "null")

    if (storedToken && queryRoleId) {
      localStorage.setItem("currentRoleId", queryRoleId)
      fetchRole(queryRoleId, storedToken, companySpotlight, roleSpotlight)

      const hasExistingSpotlights = companySpotlight || roleSpotlight
      setIsNewStartup(!hasExistingSpotlights)
    } else {
      setLoading(false)
    }
  }, [queryRoleId])

  // After roleFields changes or after we finish loading, try to scale
  useEffect(() => {
    if (!loading) {
      scaleCardToFit()
    }
    // eslint-disable-next-line
  }, [roleFields, loading])

  const fetchRole = async (id, authToken, companySpotlight, roleSpotlight) => {
    try {
      const response = await axios.get("/api/roles/fetchRoleById", {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { id },
      })

      if (response.data.role) {
        const role = response.data.role
        const fields = role.fields || {}

        // Transform data for easier checks
        const transformedData = {
          id: role.id || "",
          // Basic info
          role_title: fields["Role Title"] || "",
          working_style: fields["Working Style"] || "",
          compensation: fields["Compensation"] || "",
          hiring_lead_name: fields["Hiring Lead Name"] || "",
          hiring_lead_email: fields["Hiring Lead Email Address"] || "",
          hiring_lead_phone: fields["Hiring Lead Phone Number"] || "",
          hiring_lead_linkedin: fields["Hiring Lead Linkedin"] || "",
          // Company data
          company_name: fields["Name (from Startup)"]?.[0] || "",
          company_description: fields["Startup Description (from Startup)"] || "",
          company_website: fields["Website (from Startup)"] || "",
          team_size: fields["Team Size (from Startup)"] || "",
          location: fields["Location (from Startup)"] || "",
          // Extended job details
          key_skills: fields["Specific Experience"] || "",
          interview_process: fields["Interview Process"] || "",
          interview_link: fields["Hiring Lead Calendly Link"] || "",
          job_description_url: fields["Job Description PDF"] || "",
          spotlight_context: fields["Spotlight Context"] || "",
          // Spotlights
          company_dropbox_url: companySpotlight?.["URL Dropbox cleaned"] || null,
          company_loom_url: companySpotlight?.["URL Loom cleaned"] || null,
          role_dropbox_url: roleSpotlight?.["URL Dropbox cleaned"] || null,
          role_loom_url: roleSpotlight?.["URL Loom cleaned"] || null,
        }
        setRoleFields(transformedData)
      }
    } catch (err) {
      console.error("Error fetching role data:", err)
      setPublishError("Failed to load role data.")
    } finally {
      setLoading(false)
    }
  }

  // Basic "ready to publish" checks
  const hasRoleSpotlight = !!(roleFields.role_loom_url || roleFields.role_dropbox_url)
  const hasJobDescription = !!roleFields.job_description_url

  const requiredMissing = () => {
    const missing = []
    // Basic Info
    if (!roleFields.role_title) missing.push("Role Title")
    if (!roleFields.working_style) missing.push("Working Style")
    if (!roleFields.compensation) missing.push("Compensation")
    if (!roleFields.hiring_lead_name) missing.push("Hiring Lead Name")
    if (!roleFields.hiring_lead_email) missing.push("Hiring Lead Email")
    if (!roleFields.hiring_lead_phone) missing.push("Hiring Lead Phone")
    if (!roleFields.hiring_lead_linkedin) missing.push("Hiring Lead LinkedIn")

    // Must have EITHER Role spotlight or JD
    if (!hasRoleSpotlight && !hasJobDescription) {
      missing.push("Role Spotlight or Job Description")
    }
    return missing
  }

  // Switch heading and disable Publish if anything's missing
  useEffect(() => {
    if (!loading) {
      const missing = requiredMissing()
      const anythingMissing = missing.length > 0

      setIsPublishDisabled(anythingMissing)
      setHeadingText(anythingMissing ? "Almost there!" : "It's time to publish!")
      setShowWhatsNext(!anythingMissing) // If nothing missing, show "What's next?"
    }
    // eslint-disable-next-line
  }, [roleFields, loading])

  const handlePublish = async (confirmedPublish = false) => {
    if (!roleId || !token) return

    const missing = requiredMissing()
    if (missing.length > 0) {
      // can't publish if basic info is missing or both JD & spotlight missing
      setPublishError("Some required fields are still missing.")
      return
    }

    // If no JD but user didn't confirm, show the "publish without JD" confirm
    if (!hasJobDescription && !confirmedPublish) {
      setShowPublishModal(true)
      return
    }

    // If user confirmed or if we do have JD, proceed
    const chosenIDs = []
    const sc = localStorage.getItem("selectedCompanySpotlight")
    const sr = localStorage.getItem("selectedRoleSpotlight")
    if (sc) {
      const parsed = JSON.parse(sc)
      if (parsed?.id) chosenIDs.push(parsed.id)
    }
    if (sr) {
      const parsed = JSON.parse(sr)
      if (parsed?.id) chosenIDs.push(parsed.id)
    }

    try {
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: {
            "Open to applications": "Open to apps",
            Spotlight: chosenIDs,
          },
          roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )

      // Determine which success scenario to show:
      let scenario = "nothingMissing"
      if (!hasJobDescription && hasRoleSpotlight) scenario = "jdMissing"
      if (hasJobDescription && !hasRoleSpotlight) scenario = "spotlightMissing"
      
      setPublishSuccessScenario(scenario)

    } catch (error) {
      console.error("Error publishing role:", error)
      setPublishError("Failed to publish role.")
    }
  }

  const handleSaveDraft = async () => {
    if (!roleId || !token) return

    const chosenIDs = []
    const sc = localStorage.getItem("selectedCompanySpotlight")
    const sr = localStorage.getItem("selectedRoleSpotlight")
    if (sc) {
      const parsed = JSON.parse(sc)
      if (parsed?.id) chosenIDs.push(parsed.id)
    }
    if (sr) {
      const parsed = JSON.parse(sr)
      if (parsed?.id) chosenIDs.push(parsed.id)
    }

    try {
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: {
            "First saved": true,
            Spotlight: chosenIDs,
          },
          roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      navigate("/roles")
    } catch (err) {
      console.error("Error saving draft:", err)
      setPublishError("Failed to save draft.")
    }
  }

  // If there's no "company" or "role" video, show a red "i" next to the tab
  const companyHasVideo = !!(roleFields.company_loom_url || roleFields.company_dropbox_url)
  const roleHasVideo = !!(roleFields.role_loom_url || roleFields.role_dropbox_url)

  const renderTabTitle = (tabName) => {
    let label = tabName === "company" ? "Company" : "Role"
    let hasVideo = tabName === "company" ? companyHasVideo : roleHasVideo

    return (
      <>
        {label}
        {!hasVideo && (
          <Info
            className="w-4 h-4 ml-1 text-red-500"
            title="No spotlight video attached"
          />
        )}
      </>
    )
  }

  // Show the actual video or fallback
  const renderSpotlightVideo = (type) => {
    const loomUrl = type === "company" ? roleFields.company_loom_url : roleFields.role_loom_url
    const dropboxUrl = type === "company" ? roleFields.company_dropbox_url : roleFields.role_dropbox_url

    if (loomUrl) {
      const loomId = loomUrl.split("/").pop()
      return (
        <iframe
          src={`https://www.loom.com/embed/${loomId}`}
          className="w-full h-[150px] object-cover rounded-lg bg-gray-100"
          frameBorder="0"
          allowFullScreen
        />
      )
    } else if (dropboxUrl) {
      const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1")
      return (
        <video
          src={directVideoUrl}
          className="w-full h-[150px] object-cover rounded-lg bg-gray-100"
          controls
        />
      )
    }

    // Show special message if they've booked a call
    if (hasBookedCall) {
      return (
        <div className="w-full h-[150px] flex flex-col items-center justify-center bg-[#08f2c3]/5 rounded-lg border-2 border-dashed border-[#08f2c3]">
          <Calendar className="w-6 h-6 text-[#08f2c3] mb-2" />
          <p className="text-sm text-gray-600 text-center px-4">
            Call booked! We'll help you record your spotlights during the session.
          </p>
        </div>
      )
    }

    return (
      <div className="w-full h-[150px] flex items-center justify-center bg-gray-100 rounded-lg">
        <p className="text-xs text-gray-500">
          No {type} spotlight video available
        </p>
      </div>
    )
  }

  // Build a list of clickable "missing fields"
  // Each item navigates to the correct page for editing
  const getMissingFields = () => {
    const missing = []
    const fields = requiredMissing()

    fields.forEach((item) => {
      switch (item) {
        case "Role Title":
        case "Working Style":
        case "Compensation":
        case "Hiring Lead Name":
        case "Hiring Lead Email":
        case "Hiring Lead Phone":
        case "Hiring Lead LinkedIn":
          missing.push({
            label: item,
            onClick: () => navigate(`/create-role/basic-info?roleId=${roleId}`),
          })
          break
        case "Role Spotlight or Job Description":
          // They can add either job description or spotlight
          missing.push({
            label: "Add a Job Description",
            onClick: () => navigate(`/create-role/job-description?roleId=${roleId}`),
          })
          missing.push({
            label: "Add a Role Spotlight",
            onClick: () => {
              if (isNewStartup) {
                navigate(`/create-role/spotlight?roleId=${roleId}`)
              } else {
                navigate(`/create-role/select-spotlight?roleId=${roleId}`)
              }
            },
          })
          break
        default:
          break
      }
    })
    return missing
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <p>Loading role data...</p>
      </div>
    )
  }

  const missingFieldsList = getMissingFields()

  return (
    <div className="min-h-screen bg-white transition-all duration-300">
      {/* Heading */}
      <div className="pt-20 pb-16">
        <h1 className="text-4xl font-semibold text-center">{headingText}</h1>
      </div>

      <div className="max-w-6xl mx-auto px-4 md:px-8">
        {/* Changed items-center to items-start for top alignment */}
        <div className="flex flex-col md:flex-row items-start gap-16">
          {/* Preview Card (auto-scaling wrapper) */}
          <div
            className="w-full md:w-[448px]"
            style={{
              transform: `scale(${scale})`,
              transformOrigin: "top center",
              transition: "transform 0.25s ease",
            }}
          >
            <div ref={cardRef} className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200">
              <div className="p-4">
                {/* Spotlight Section */}
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex gap-4">
                      <button
                        onClick={() => setActiveTab("company")}
                        className={`text-xs font-medium pb-1 ${
                          activeTab === "company"
                            ? "text-[#08f2c3] border-b-2 border-[#08f2c3]"
                            : "text-gray-500"
                        }`}
                      >
                        {renderTabTitle("company")}
                      </button>
                      <button
                        onClick={() => setActiveTab("role")}
                        className={`text-xs font-medium pb-1 ${
                          activeTab === "role"
                            ? "text-[#08f2c3] border-b-2 border-[#08f2c3]"
                            : "text-gray-500"
                        }`}
                      >
                        {renderTabTitle("role")}
                      </button>
                    </div>
                    <button
                      onClick={() => {
                        if (isNewStartup) {
                          navigate("/create-role/spotlight?roleId=" + roleId)
                        } else {
                          navigate("/create-role/select-spotlight?roleId=" + roleId)
                        }
                      }}
                      className="p-1 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                    >
                      <Pencil className="w-4 h-4" />
                    </button>
                  </div>
                  {renderSpotlightVideo(activeTab)}

                  {roleFields.spotlight_context && (
                    <p className="mt-2 text-xs text-gray-600">
                      {roleFields.spotlight_context}
                    </p>
                  )}
                </div>

                {/* Role and Company Info */}
                <div className="mb-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h2 className="text-sm font-semibold">
                        {roleFields.role_title || "(No role title)"}
                      </h2>
                      <p className="text-xs text-gray-600">
                        {roleFields.company_name || "Unnamed Company"}
                      </p>
                    </div>
                    <button
                      onClick={() => navigate(`/create-role/basic-info?roleId=${roleId}`)}
                      className="p-1 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                    >
                      <Pencil className="w-4 h-4" />
                    </button>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 mb-4">
                  {/* Compensation tag with PoundSterling icon */}
                  {roleFields.compensation ? (
                    <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-4 py-1 text-sm md:text-md font-medium text-purple-700">
                      <PoundSterling className="w-4 h-4" />
                      Salary range: {roleFields.compensation}
                    </span>
                  ) : (
                    <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-orange-100 px-4 py-1 text-sm md:text-md font-medium text-orange-700">
                      <AlertCircle className="size-5" />
                      Compensation missing
                    </span>
                  )}
                  
                  {/* Working style tag with Briefcase icon */}
                  {roleFields.working_style ? (
                    <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-blue-100 px-4 py-1 text-sm md:text-md font-medium text-blue-700">
                      <Briefcase className="w-4 h-4" />
                      {roleFields.working_style}
                    </span>
                  ) : (
                    <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-orange-100 px-4 py-1 text-sm md:text-md font-medium text-orange-700">
                      <AlertCircle className="size-5" />
                      Working style missing
                    </span>
                  )}

                  {/* Team size tag */}
                  {roleFields.team_size && (
                    <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-green-100 px-4 py-1 text-sm md:text-md font-medium text-green-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>
                      {roleFields.team_size} people in the team
                    </span>
                  )}

                  {/* Location tag */}
                  {roleFields.location && (
                    <span className="flex inline-flex mr-2 items-center gap-x-1 rounded-md bg-blue-100 px-4 py-1 text-sm md:text-md font-medium text-blue-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>
                      {roleFields.location}
                    </span>
                  )}
                </div>

                {/* Company Description */}
                <div className="mb-4">
                  <div className="flex justify-between items-start mb-1">
                    <h3 className="text-xs font-semibold">About the company</h3>
                  </div>
                  <p className="text-xs text-gray-600 line-clamp-2 mb-1">
                    {roleFields.company_description || "No company description provided."}
                  </p>
                  {roleFields.company_website && (
                    <a
                      href={roleFields.company_website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-xs text-[#08f2c3] hover:underline"
                    >
                      Visit website
                      <ExternalLink className="w-3 h-3 ml-1" />
                    </a>
                  )}
                </div>

                {/* Job Details Section */}
                <div className="mb-4">
                  <div className="flex justify-between items-start mb-1">
                    <h3 className="text-xs font-semibold">Job details</h3>
                    <button
                      onClick={() => navigate(`/create-role/job-description?roleId=${roleId}`)}
                      className="p-1 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                    >
                      <Pencil className="w-3 h-3" />
                    </button>
                  </div>
                  {/* Key Skills (with toggle) */}
                  {roleFields.key_skills && (
                    <div className="mb-2">
                      <h4 className="text-xs font-medium text-gray-700">Key skills</h4>
                      <p
                        className={`
                          text-xs text-gray-600 whitespace-pre-line 
                          ${showFullKeySkills ? '' : 'line-clamp-1'} 
                          overflow-hidden
                        `}
                      >
                        {roleFields.key_skills}
                      </p>
                      <button
                        onClick={() => setShowFullKeySkills(!showFullKeySkills)}
                        className="mt-1 text-xs text-[#08f2c3] hover:underline"
                      >
                        {showFullKeySkills ? 'Show less' : 'Show more'}
                      </button>
                    </div>
                  )}

                  {/* Interview Process (with toggle) */}
                  {roleFields.interview_process && (
                    <div className="mb-2">
                      <h4 className="text-xs font-medium text-gray-700">Interview process</h4>
                      <p
                        className={`
                          text-xs text-gray-600 whitespace-pre-line 
                          ${showFullInterview ? '' : 'line-clamp-1'} 
                          overflow-hidden
                        `}
                      >
                        {roleFields.interview_process}
                      </p>
                      <button
                        onClick={() => setShowFullInterview(!showFullInterview)}
                        className="mt-1 text-xs text-[#08f2c3] hover:underline"
                      >
                        {showFullInterview ? 'Show less' : 'Show more'}
                      </button>
                    </div>
                  )}

                  {/* Job Description Link */}
                  {roleFields.job_description_url ? (
                    <a
                      href={roleFields.job_description_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-xs text-[#08f2c3] hover:underline mt-1"
                    >
                      View full job description
                      <ExternalLink className="w-3 h-3 ml-1" />
                    </a>
                  ) : (
                    <div className="flex items-center mt-1">
                      <AlertCircle className="w-3 h-3 mr-1 text-yellow-500" />
                      <span className="text-xs text-yellow-700">Job description missing</span>
                    </div>
                  )}
                </div>

                {/* Error message if any */}
                {publishError && (
                  <p className="text-xs text-red-500 mb-2">{publishError}</p>
                )}

                {/* Action Buttons */}
                <div className="space-y-3 mt-4">
                  <button
                    onClick={handleSaveDraft}
                    className="w-full px-4 py-3 bg-gray-100 text-gray-900 rounded-full text-sm font-semibold hover:bg-gray-200 transition-colors"
                  >
                    Save draft
                  </button>
                  <button
                    onClick={() => handlePublish(false)}
                    disabled={isPublishDisabled}
                    className="w-full px-4 py-3 bg-[#08f2c3] text-gray-900 rounded-full text-sm font-semibold
                      hover:bg-[#06c19c] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <div className="flex flex-col items-center leading-tight">
                      <span>Publish role</span>
                      {/* Changed 10th to 24th here */}
                      <span className="text-[10px] text-gray-900 mt-1">
                        (you will start receiving applicants on Monday 24th Mar)
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* MISSING FIELDS BOX if something is blocking publish */}
          {isPublishDisabled && (
            <div className="flex-1 max-w-md">
              <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                <h2 className="text-lg font-semibold text-yellow-900 mb-2 flex items-center">
                  <AlertCircle className="w-5 h-5 mr-1" />
                  Missing fields
                </h2>
                <p className="text-sm text-yellow-900 mb-3">
                  {missingFieldsList.length === 2 && missingFieldsList[0].label.includes("Job Description") ? 
                    "You need to fill one of the following before publishing:" :
                    "You need to fill in the following before publishing:"
                  }
                </p>
                <ul className="space-y-2">
                  {missingFieldsList.map((item, idx) => (
                    <li key={idx}>
                      <button
                        onClick={item.onClick}
                        className="inline-flex items-center gap-1 px-3 py-2 text-sm font-medium
                          text-yellow-900 bg-yellow-100 rounded-md hover:bg-yellow-200
                          transition-colors"
                      >
                        <AlertCircle className="w-4 h-4" />
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* WHAT'S NEXT if fully ready to publish (no missing fields) */}
          {!isPublishDisabled && showWhatsNext && (
            <div className="flex-1 max-w-md md:py-12">
              <h2 className="text-2xl font-semibold mb-8">What's next?</h2>
              <div className="space-y-8">
                <div className="flex gap-6">
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#08f2c3]/10 flex items-center justify-center">
                    <Calendar className="w-5 h-5 text-[#08f2c3]" />
                  </div>
                  <div>
                    <h3 className="text-base font-medium mb-1">Start receiving applications</h3>
                    <p className="text-gray-600">
                      We share roles with candidates every fortnight. We'll be in touch when your role is live!
                    </p>
                  </div>
                </div>

                <div className="flex gap-6">
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#08f2c3]/10 flex items-center justify-center">
                    <Settings className="w-5 h-5 text-[#08f2c3]" />
                  </div>
                  <div>
                    <h3 className="text-base font-medium mb-1">Browse and match</h3>
                    <p className="text-gray-600">
                      Review applicant profiles and send match requests to potential candidates.
                    </p>
                  </div>
                </div>

                <div className="flex gap-6">
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#08f2c3]/10 flex items-center justify-center">
                    <Users className="w-5 h-5 text-[#08f2c3]" />
                  </div>
                  <div>
                    <h3 className="text-base font-medium mb-1">Connect with candidates</h3>
                    <p className="text-gray-600">
                      Schedule interviews and engage with matched candidates through our platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* End of "What's next?" */}
        </div>
      </div>

      {/* Publish Confirmation Modal (if no JD) */}
      <PublishConfirmModal 
        isOpen={showPublishModal}
        onClose={() => setShowPublishModal(false)}
        onConfirm={() => {
          setShowPublishModal(false)
          handlePublish(true)
        }}
        hasJobDescription={!!roleFields.job_description_url}
        roleId={roleId}
      />

      {/* Publish Success Modal (replaced with the updated version) */}
      <PublishSuccessModal 
        scenario={publishSuccessScenario} 
        onClose={() => {
          setPublishSuccessScenario(null)
          navigate("/roles")
        }}
        roleId={roleId}
        isNewStartup={isNewStartup}
      />
    </div>
  )
}

/* PublishConfirmModal: Shown if user tries to publish but is missing JD (yet has a spotlight).
   It warns "Publish without job description?" */
const PublishConfirmModal = ({ isOpen, onClose, onConfirm, hasJobDescription, roleId }) => {
  const navigate = useNavigate()

  if (!isOpen) return null

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-2">Publish without job description?</h3>
        <p className="text-sm text-gray-600 mb-6">
          Are you sure you want to publish this role without a job description? You can still add one later.
        </p>
        <div className="flex justify-center gap-3">
          {!hasJobDescription && (
            <button
              onClick={() => {
                onClose()
                navigate(`/create-role/job-description?roleId=${roleId}`)
              }}
              className="px-4 py-2 text-sm font-medium text-[#08f2c3] hover:text-[#06c19c] border border-[#08f2c3] rounded-full"
            >
              Add job description
            </button>
          )}
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c]"
          >
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
  )
}

/* PublishSuccessModal: replaced with your updated version that includes:
   - new text referencing 24th Feb
   - "Add a reminder to your calendar" button
*/
const PublishSuccessModal = ({ scenario, onClose, roleId, isNewStartup }) => {
  const navigate = useNavigate()

  // Create Google Calendar link
  const createCalendarLink = () => {
    const startDate = "2025-02-24T09:00:00" // February 24th, 2025 at 9 AM
    const endDate = "2025-02-24T10:00:00"   // 1 hour duration
    const text = "Review Jumpstart Applications"
    const details = "Check Jumpstart platform for new candidate applications that have come in."
    
    const googleCalendarUrl = new URL('https://calendar.google.com/calendar/render')
    googleCalendarUrl.searchParams.append('action', 'TEMPLATE')
    googleCalendarUrl.searchParams.append('text', text)
    googleCalendarUrl.searchParams.append('details', details)
    googleCalendarUrl.searchParams.append('dates', `${startDate.replace(/[-:]/g, '')}/${endDate.replace(/[-:]/g, '')}`)
    
    return googleCalendarUrl.toString()
  }

  if (!scenario) return null

  let title = "Congratulations!"
  let body = "Your role has been published! You will receive applicants on "
  body += "<strong>Monday 24th March</strong>"
  body += ". In the meantime, you can start requesting individual candidates via the browse page."
  let buttonText = "Browse candidates"
  let buttonAction = () => {
    onClose()
    navigate("/browse-candidates")
  }

  if (scenario === "jdMissing") {
    title = "Congratulations!"
    body = "Your role has been published, but you're still missing a job description. You can add one anytime by going to the edit page."
  } else if (scenario === "spotlightMissing") {
    title = "Congratulations!"
    body = "Your role has been published! To increase engagement by 50%, consider adding a quick video spotlight explaining the role."
    buttonText = "Add role spotlight"
    buttonAction = () => {
      onClose()
      if (isNewStartup) {
        navigate(`/create-role/spotlight?roleId=${roleId}`)
      } else {
        navigate(`/create-role/select-spotlight?roleId=${roleId}`)
      }
    }
  }

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        {/* Centered emoji and title */}
        <div className="text-center mb-6">
          <span className="text-4xl mb-2 block">🎉</span>
          <h3 className="text-2xl font-semibold">{title}</h3>
        </div>

        {/* Left-aligned body text with bold date */}
        <p 
          className="text-sm text-gray-600 mb-8 text-left"
          dangerouslySetInnerHTML={{ __html: body }}
        />

        <div className="flex flex-col md:flex-row justify-center gap-4">
          <button
            onClick={buttonAction}
            className="px-6 py-2.5 text-sm font-medium bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c] transition-colors duration-200 whitespace-nowrap"
          >
            {buttonText}
          </button>

          {scenario === 'nothingMissing' && (
            <a
              href={createCalendarLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-2.5 text-sm font-medium text-[#08f2c3] hover:text-[#06c19c] border border-[#08f2c3] rounded-full flex items-center justify-center transition-colors duration-200 whitespace-nowrap group"
            >
              <Calendar className="w-4 h-4 mr-2 group-hover:scale-110 transition-transform duration-200" />
              Add reminder
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReviewRole
