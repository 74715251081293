import { useEffect, useState, useCallback, useRef } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import CandidateCard from "../../../components/candidates/generalist_card.js"
import InterviewRequest from "../../../components/interview_request/progress_bar.js"
import ActiveFilters from "../../../components/active_filters.js"
import CandidatesLoading from "../../../components/candidates/candidates_loading.js"
import FiltersModal from "../../../components/filters.js"
import MultiSelect from "../../../components/multiselect_filter.js"
import RoleDropBanner from "../../../components/role-drop-banner.jsx"

export default function Candidates() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStream, setFilterStream] = useState([]);
  const [filterYearsOfExp, setFilterYearsOfExp] = useState([]);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [filterWorkingStyle, setFilterWorkingStyle] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [recommendedCandidates, setRecommendedCandidates] = useState([]);
  const [isInterviewRequest, setIsInterviewRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newRoleTitle, setNewRoleTitle] = useState("");
  const [newRoleForm, setNewRoleForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [candidatesStatus, setCandidatesStatus] = useState([]);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageData, setNextPageData] = useState(null);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [prefetchController, setPrefetchController] = useState(null);
  const CANDIDATES_PER_PAGE = 50;
  const prefetchTimeoutRef = useRef(null);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [savedCandidates, setSavedCandidates] = useState(() => {
    const savedCandidates = localStorage.getItem("candidates");
    return savedCandidates ? JSON.parse(savedCandidates) : [];
  });

  const streams = ["Generalist", "Sales", "Software Engineering", "Marketing"];

  // ----- Local Storage -----
  const saveFiltersToLocalStorage = () => {
    const filters = {
      filterStream,
      filterYearsOfExp,
      minSalary,
      maxSalary,
      filterWorkingStyle,
      filterIndustry,
      timestamp: Date.now(),
    };
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const loadFiltersFromLocalStorage = () => {
    const filters = JSON.parse(localStorage.getItem("filters"));
    if (filters) {
      const currentTime = Date.now();
      const twoHours = 2 * 60 * 60 * 1000;

      if (currentTime - filters.timestamp < twoHours) {
        setFilterStream(filters.filterStream);
        setFilterYearsOfExp(filters.filterYearsOfExp);
        setMinSalary(filters.minSalary);
        setMaxSalary(filters.maxSalary);
        setFilterWorkingStyle(filters.filterWorkingStyle);
        setFilterIndustry(filters.filterIndustry);
      } else {
        localStorage.removeItem("filters");
      }
    }
  };

  useEffect(() => {
    loadFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  // ----- Transform & Parse Helpers -----
  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = Number.parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? Number.parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  // ----- Fetch Data -----
  const fetchShortlistedCandidates = useCallback(async () => {
    if (!rolesNeedUpdate) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;
      const shortlistedCandidatesByRole = {};
      const rolesList = [];

      roles.forEach((role) => {
        const roleTitle = role["Role Title"];
        const roleUrlId = role["URL ID"];
        const roleId = role["id"];

        rolesList.push(roleTitle);

        if (role["Shortlisted Candidates"]) {
          if (!shortlistedCandidatesByRole[roleTitle]) {
            shortlistedCandidatesByRole[roleTitle] = {
              roleUrlId: roleUrlId,
              roleId: roleId,
              candidates: [],
            };
          }
          shortlistedCandidatesByRole[roleTitle].candidates =
            shortlistedCandidatesByRole[roleTitle].candidates.concat(
              role["Shortlisted Candidates"]
            );
        }
      });

      setShortlistedCandidates(shortlistedCandidatesByRole);
      setRoles(roles);
      setRolesNeedUpdate(false);
    } catch (err) {
      setError(err.message);
    }
  }, [rolesNeedUpdate]);

  const fetchRecommendedCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const company = response.data.companyData;
      const recommendation = company["recommended_candidates"] || [];

      setRecommendedCandidates(recommendation);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchInterviews = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/interviews/fetchInterviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const interviews = response.data.interviews;
      const candidateStatusTuples = interviews.map((interview) => ({
        id: interview["Candidate"][0],
        status: interview["Stage"],
        role: interview["Role Title (from Role)"],
      }));

      setCandidatesStatus(candidateStatusTuples);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      
      // Create a new AbortController for this request
      const controller = new AbortController();
      
      console.log("Fetching candidates data...");
      const response = await axios.get("/api/candidates/fetchCandidates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: controller.signal
      });

      const candidates = response.data.candidates;
      console.log(`Received ${candidates.length} candidates from API`);

      const transformedData = candidates.map((candidate, index) => {
        try {
          const fields = candidate.fields || {};

          // Log the first few candidates for debugging
          if (index < 3) {
            console.log(`Processing candidate ${candidate.id} with industry_exp:`, fields["Industry experience new"]);
          }

          let sweRoles = fields["SWE relevant roles"] || "";
          if (sweRoles) {
            try {
              sweRoles = Array.isArray(sweRoles)
                ? sweRoles[0].split(",").map((role) => role.trim())
                : sweRoles.split(",").map((role) => role.trim());
              sweRoles = transformRoles(sweRoles);
            } catch (err) {
              console.error(`Error processing SWE roles for candidate ${candidate.id}:`, err);
              sweRoles = [];
            }
          } else {
            sweRoles = [];
          }

          // Safely access stream data with error handling
          let stream = "";
          try {
            if (fields["Stream (from CRM)"] && Array.isArray(fields["Stream (from CRM)"]) && fields["Stream (from CRM)"].length > 0) {
              stream = fields["Stream (from CRM)"][0];
            } else if (fields["Stream (from CRM)"]) {
              stream = String(fields["Stream (from CRM)"]);
            }
          } catch (err) {
            console.error(`Error accessing stream for candidate ${candidate.id}:`, err);
          }

          let salary = "";
          try {
            if (stream === "Generalist" || stream === "Marketing") {
              salary = fields["Salary - cleaned"] || "";
            } else if (stream === "Sales") {
              salary = fields["Base range + commission"] || "";
            } else if (stream === "Software Engineering") {
              salary = fields["SWE Salary - cleaned"] || "";
            }
          } catch (err) {
            console.error(`Error determining salary for candidate ${candidate.id}:`, err);
          }

          const { minSalary, maxSalary } = parseSalaryRange(salary);

          // Safely access image data
          let imageUrl = null;
          try {
            imageUrl = fields.Photo && fields.Photo[0] ? fields.Photo[0].url : null;
          } catch (err) {
            console.error(`Error accessing image for candidate ${candidate.id}:`, err);
          }

          return {
            id: candidate["id"] || "",
            url_id: fields["ID"] || "",
            minimum_salary: fields["Minimum salary"] || "",
            ideal_salary: fields["Ideal salary"] || "",
            createdTime: candidate["createdTime"] || "",
            years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
            cv: fields["CV RAW"] || "",
            video: fields["Raw video intro"] || "",
            linkedin: fields["LinkedIn RAW"] || "",
            industry_exp: Array.isArray(fields["Industry experience new"]) ? fields["Industry experience new"] : [],
            name: fields["Name"] || "",
            lastName: fields["Surname"] || "",
            undergrad_degree_type: fields["Undergrad degree type"] || "",
            undergrad_degree_subject: fields["Undergrad subject"] || "",
            undergrad_university: fields["Undergrad university"] || "",
            undergrad_graduation_year: fields["Undergrad graduation year"] || "",
            undergrad_result: fields["Undergrad result"] || "",
            postgrad_degree_type: fields["Postgrad degree type"] || "",
            postgrad_degree_subject: fields["Postgrad subject"] || "",
            postgrad_university: fields["Postgrad university"] || "",
            postgrad_graduation_year: fields["Postgrad graduation year"] || "",
            postgrad_result: fields["Postgrad result"] || "",
            roles: fields["Generalist roles suitable for copy"] || "",
            email: fields["Email"] || "",
            image: imageUrl,
            work_experience_1_role: fields["(1) Work Experience Role"] || "",
            work_experience_1_company: fields["(1) Work Experience Company"] || "",
            work_experience_1_description:
              fields["(1) Work Experience Description"] || "",
            work_experience_1_duration: {
              years: fields["(1) Time spent in work experience YRS"] || 0,
              months: fields["(1) Time spent in work experience MOS"] || 0,
            },
            work_experience_2_role: fields["(2) Work Experience Role"] || "",
            work_experience_2_company: fields["(2) Work Experience Company"] || "",
            work_experience_2_description:
              fields["(2) Work Experience Description"] || "",
            work_experience_2_duration: {
              years: fields["(2) Time spent in work experience YRS"] || 0,
              months: fields["(2) Time spent in work experience MOS"] || 0,
            },
            working_style: fields["Preferred working style copy"] || "",
            github: fields["Github RAW"] || "",
            swe_roles: sweRoles,
            languages: fields["Languages copy"] || "",
            time_coding: fields["Time spent coding"] || "",
            coding_languages: fields["Coding languages new"] || "",
            description: fields["Top achievement"] || "",
            mostRecentCohort: fields["Most recent cohort"] || "",
            recentTarget: fields["Recent target achieved"] || "",
            stream: stream,
            search_fields: fields["Search Aggregate Fields"] || "",
            sales: fields["Proven Sales Experience / High Potential for Sales"] || "",
            exp_swe: fields["Level of exp SWE"] || "",
            portfolio: fields["SWE Portfolio"] || "",
            marketing_portfolio: fields["Marketing portfolio RAW"] || "",
          };
        } catch (err) {
          console.error(`Error transforming candidate ${candidate.id}:`, err);
          // Return a minimal valid object to prevent breaking the whole list
          return { 
            id: candidate.id || "unknown-id", 
            error: true,
            name: "Error processing candidate",
            stream: "",
            industry_exp: []
          };
        }
      });

      console.log(`Successfully transformed ${transformedData.length} candidates`);
      setCandidates(transformedData);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching candidates:", err);
      setError(err.message);
      setLoading(false);
    }
  }, []);

  // Sorting & Setting Data
  useEffect(() => {
    const redStatuses = [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ];

    const getStatusPriority = (status) => {
      if (status === "Application pending") return 1;
      if (status === "Interviewing") return 3;
      if (status === "Match requested") return 4;
      if (redStatuses.includes(status)) return 5;
      return 2; // No status
    };

    const allCandidatesWithStatus = candidates.map((candidate) => {
      const candidateStatus = candidatesStatus.find(
        (status) => status.id === candidate.id
      );
      return {
        ...candidate,
        status: candidateStatus ? candidateStatus.status : null,
        isRecommended: recommendedCandidates.includes(candidate.id),
      };
    });

    const sortedCandidates = allCandidatesWithStatus.sort((a, b) => {
      const aPriority = getStatusPriority(a.status);
      const bPriority = getStatusPriority(b.status);

      if (aPriority !== bPriority) return aPriority - bPriority;

      // When priorities are equal, sort recommended candidates first
      if (a.isRecommended && !b.isRecommended) return -1;
      if (!a.isRecommended && b.isRecommended) return 1;

      return new Date(b.createdTime) - new Date(a.createdTime);
    });

    setData(sortedCandidates);
  }, [candidates, candidatesStatus, recommendedCandidates]);

  // ----- Apply Filters -----
  const applyFilters = useCallback((candidatesList) => {
    console.log(`Applying filters to ${candidatesList.length} candidates`);
    
    return candidatesList.filter((candidate) => {
      try {
        // Skip invalid candidates
        if (!candidate || !candidate.id) {
          console.warn("Invalid candidate object in filter:", candidate);
          return false;
        }
        
        // Check if candidate matches the selected stream filter
        const matchesStream = filterStream.length
          ? filterStream.includes(candidate.stream)
          : true;
    
        // Check if candidate matches the years of experience filter
        const matchesYearsOfExp = filterYearsOfExp.length
          ? filterYearsOfExp.includes(candidate.years_of_exp)
          : true;
    
        // Utility function to parse salary string and convert to integer
        function parseSalary(salary) {
          if (!salary) return null; 
          try {
            return Number.parseInt(salary.replace(/[^\d]/g, ""), 10) * 1000;
          } catch (err) {
            console.warn(`Error parsing salary for candidate ${candidate.id}:`, err);
            return null;
          }
        }
    
        // Parse candidate salaries
        const candidateIdealSalaryK = parseSalary(candidate.ideal_salary);
        const candidateMinimumSalaryK = parseSalary(candidate.minimum_salary);
    
        // Calculate salary in thousands for comparison
        const minSalaryK = minSalary ? minSalary * 1000 : null;
        const maxSalaryK = maxSalary ? maxSalary * 1000 : null;
    
        // Check if candidate salary is within range
        const matchesSalary =
          (!minSalaryK || candidateIdealSalaryK >= minSalaryK) &&
          (!maxSalaryK || candidateMinimumSalaryK <= maxSalaryK);
    
        // Check if candidate matches the working style filter
        const matchesWorkingStyle = filterWorkingStyle.length
          ? (candidate.working_style && Array.isArray(candidate.working_style)
            ? filterWorkingStyle.some((style) => {
                if (style === "Hybrid") {
                  return candidate.working_style.some((candidateStyle) =>
                    candidateStyle.includes("Hybrid")
                  );
                }
                if (style === "Fully in person") {
                  return candidate.working_style.some((candidateStyle) =>
                    candidateStyle.includes("5 days a week in office")
                  );
                }
                if (style === "Fully remote") {
                  return candidate.working_style.some((candidateStyle) =>
                    candidateStyle.includes("Fully remote")
                  );
                }
                return false;
              })
            : typeof candidate.working_style === 'string'
              ? filterWorkingStyle.some(style => candidate.working_style.includes(style))
              : false)
          : true;
    
        // Ensure industry_exp is an array
        const candidateIndustryArray = Array.isArray(candidate.industry_exp) 
          ? candidate.industry_exp 
          : [];
    
        // Check if candidate matches the selected industries
        const matchesIndustry = filterIndustry.length
          ? candidateIndustryArray.some((industry) => {
              if (!industry) return false;
              
              // For each filter option, check if it's included in any of the candidate's industries
              return filterIndustry.some(filter => {
                try {
                  // Map UI filter options to database values
                  switch(filter) {
                    case "Banking":
                      return industry === "Banking" || industry === "Banking / Finance" || industry === "bankii";
                    case "Consulting":
                      return industry === "Consulting" || industry === "Consu";
                    case "Startup experience":
                      return industry === "Startup experience" || industry === "Startup";
                    case "Founder experience":
                      return industry === "Founder experience" || industry === "Founder Experience";
                    default:
                      return industry === filter;
                  }
                } catch (err) {
                  console.error(`Error matching industry for candidate ${candidate.id}:`, err);
                  return false;
                }
              });
            })
          : true;
    
        // Check if candidate matches all search keywords
        const matchesSearchQuery = searchKeywords.length
          ? searchKeywords.every((keyword) => {
              if (!candidate.search_fields) return false;
              try {
                return candidate.search_fields
                  .toLowerCase()
                  .includes(keyword.toLowerCase());
              } catch (err) {
                console.error(`Error matching search query for candidate ${candidate.id}:`, err);
                return false;
              }
            })
          : true;
    
        // Return true if the candidate satisfies all filter conditions
        return (
          matchesStream &&
          matchesYearsOfExp &&
          matchesSalary &&
          matchesWorkingStyle &&
          matchesIndustry &&
          matchesSearchQuery
        );
      } catch (err) {
        console.error(`Error filtering candidate ${candidate?.id || 'unknown'}:`, err);
        return false; // Skip candidates that cause errors
      }
    });
  }, [filterStream, filterYearsOfExp, minSalary, maxSalary, filterWorkingStyle, filterIndustry, searchKeywords]);

  // ----- Get all filtered data -----
  const allFilteredCandidates = applyFilters(data);
  
  // Calculate total pages
  const totalPages = Math.ceil(allFilteredCandidates.length / CANDIDATES_PER_PAGE);

  // ----- Prefetch next page data -----
  const prefetchNextPage = useCallback(() => {
    // Check if we have a valid totalPages value
    const calculatedTotalPages = Math.ceil(allFilteredCandidates.length / CANDIDATES_PER_PAGE);
    
    if (currentPage < calculatedTotalPages && !isNextPageLoading && !nextPageData) {
      setIsNextPageLoading(true);
      
      // Clear any existing timeout
      if (prefetchTimeoutRef.current) {
        clearTimeout(prefetchTimeoutRef.current);
      }
      
      // Cancel any ongoing prefetch request
      if (prefetchController) {
        prefetchController.abort();
      }
      
      // Create a new controller for this prefetch
      const controller = new AbortController();
      setPrefetchController(controller);
      
      // Use setTimeout to delay prefetching slightly
      prefetchTimeoutRef.current = setTimeout(() => {
        const startIndex = currentPage * CANDIDATES_PER_PAGE;
        const endIndex = startIndex + CANDIDATES_PER_PAGE;
        const nextPageCandidates = allFilteredCandidates.slice(startIndex, endIndex);
        setNextPageData(nextPageCandidates);
        setIsNextPageLoading(false);
      }, 100);
    }
  }, [currentPage, isNextPageLoading, nextPageData, CANDIDATES_PER_PAGE, prefetchController, allFilteredCandidates]);
  
  // ----- Get paginated data for current view -----
  const getPaginatedData = useCallback(() => {
    const startIndex = (currentPage - 1) * CANDIDATES_PER_PAGE;
    const endIndex = startIndex + CANDIDATES_PER_PAGE;
    return allFilteredCandidates.slice(startIndex, endIndex);
  }, [allFilteredCandidates, currentPage, CANDIDATES_PER_PAGE]);
  
  // Current page data
  const filteredData = getPaginatedData();

  // Prefetch next page data when current page changes
  useEffect(() => {
    prefetchNextPage();
    
    // Cleanup function to clear timeout if component unmounts
    return () => {
      if (prefetchTimeoutRef.current) {
        clearTimeout(prefetchTimeoutRef.current);
      }
      if (prefetchController) {
        prefetchController.abort();
      }
    };
  }, [prefetchNextPage, prefetchController]);
  
  // Reset pagination when filters change
  useEffect(() => {
    setCurrentPage(1);
    setNextPageData(null);
  }, [filterStream, filterYearsOfExp, minSalary, maxSalary, filterWorkingStyle, filterIndustry, searchKeywords]);

  // Initialize data
  useEffect(() => {
    const abortController = new AbortController();
    
    fetchRecommendedCandidates();
    fetchData();
    fetchInterviews();
    
    // Cleanup function to cancel pending requests when component unmounts
    return () => {
      abortController.abort();
    };
  }, [fetchRecommendedCandidates, fetchData, fetchInterviews]);

  useEffect(() => {
    fetchShortlistedCandidates();
  }, [fetchShortlistedCandidates]);

  useEffect(() => {
    localStorage.setItem("candidates", JSON.stringify(savedCandidates));
  }, [savedCandidates]);

  useEffect(() => {
    saveFiltersToLocalStorage();
  }, [
    filterStream,
    filterYearsOfExp,
    minSalary,
    maxSalary,
    filterWorkingStyle,
    filterIndustry,
  ]);

  // ----- Manage Saved Candidates -----
  const addCandidate = (candidate) => {
    if (!savedCandidates.some((c) => c.id === candidate.id)) {
      setSavedCandidates([...savedCandidates, candidate]);
    }
  };

  const removeCandidate = (candidate) => {
    setSavedCandidates(savedCandidates.filter((c) => c.id !== candidate.id));
  };

  const clear = () => {
    setSavedCandidates([]);
  };

  const toggleCandidate = (candidate) => {
    if (savedCandidates.some((c) => c.id === candidate.id)) {
      removeCandidate(candidate);
    } else {
      addCandidate(candidate);
    }
  };

  // ----- Candidate / Role Actions -----
  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleStreamFilterChange = (selectedOptions) => {
    setFilterStream(selectedOptions);
  };

  const handleStreamChange = (option) => {
    if (filterStream.includes(option)) {
      handleStreamFilterChange(filterStream.filter((item) => item !== option));
    } else {
      handleStreamFilterChange([...filterStream, option]);
    }
  };

  const handleYearsOfExpFilterChange = (selectedOptions) => {
    setFilterYearsOfExp(selectedOptions);
  };

  const handleWorkingStyleFilterChange = (selectedOptions) => {
    setFilterWorkingStyle(selectedOptions);
  };

  // ----- Search (No Suggestions) -----
  const handleSearch = () => {
    if (searchQuery.trim()) {
      setSearchKeywords((prevKeywords) => [
        ...prevKeywords,
        searchQuery.trim(),
      ]);
      setSearchQuery("");
    }
  };

  // ----- Create Role Actions -----
  const handleNewRoleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      fields: {
        "Role Title": newRoleTitle,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      handleRoleSelect(response.data.newRoleData, selectedCandidate.id);
      setRolesNeedUpdate(true);
      setNewRoleForm(false);
      setNewRoleTitle("");
    } catch (err) {
      console.error(err);
    }
  };

  const handleRoleSelect = async (role, candidateId) => {
    const updatedData = {
      fields: {
        "Shortlisted Candidates": [candidateId],
      },
      roleId: role["id"],
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => {
        const updatedRoles = prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
        return updatedRoles;
      });

      setRolesNeedUpdate(true);
    } catch (err) {
      console.error(err);
    }
  };

  // ----- Toast Handling -----
  const addToast = (message) => {
    setToasts((prevToasts) => [...prevToasts, message]);
  };

  useEffect(() => {
    if (toasts.length > 0) {
      const timers = toasts.map((toast, index) =>
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
        }, 5000)
      );
      return () => timers.forEach((timer) => clearTimeout(timer));
    }
  }, [toasts]);

  // ----- Clear & Update Filters -----
  const clearFilters = () => {
    handleStreamFilterChange([]);
    handleWorkingStyleFilterChange([]);
    setFilterIndustry([]);
    setSearchKeywords([]);
    handleYearsOfExpFilterChange([]);
    setMaxSalary("");
    setFilterYearsOfExp([]);
  };

  const onChange = ({
    filterStream: updatedFilterStream,
    filterYearsOfExp: updatedFilterYearsOfExp,
    filterWorkingStyle: updatedFilterWorkingStyle,
    filterIndustry: updatedFilterIndustry,
    searchKeywords: updatedSearchKeywords,
  }) => {
    setFilterStream(updatedFilterStream);
    setFilterYearsOfExp(updatedFilterYearsOfExp);
    setFilterWorkingStyle(updatedFilterWorkingStyle);
    setFilterIndustry(updatedFilterIndustry);
    setSearchKeywords(updatedSearchKeywords);
  };

  return (
    <div className="relative md:pl-56 bg-white min-h-screen">
      {filtersModalOpen && (
        <FiltersModal
          setFiltersModalOpen={setFiltersModalOpen}
          filterYearsOfExp={filterYearsOfExp}
          handleYearsOfExpFilterChange={handleYearsOfExpFilterChange}
          filterWorkingStyle={filterWorkingStyle}
          handleWorkingStyleFilterChange={handleWorkingStyleFilterChange}
          filterIndustry={filterIndustry}
          setFilterIndustry={setFilterIndustry}
          filterStream={filterStream}
          handleStreamFilterChange={handleStreamFilterChange}
          length={allFilteredCandidates.length}
          maxSalary={maxSalary}
          setMaxSalary={setMaxSalary}
          clearFilters={clearFilters}
        />
      )}

      <div className="sticky top-0 z-50">
        <RoleDropBanner />
      </div>

      <div className="bg-white px-5 pt-7 sticky top-0 pb-1 z-40 w-full border-b">
        <div className="ml-4 mb-2">
          <div className="flex mt-4 mb-2 items-center gap-8">
            <h2 className="text-3xl">Candidates</h2>
            <div className="mt-2">
              <span className="font-bold">{allFilteredCandidates.length} </span>
              candidates matching your search.
            </div>
          </div>
          <div className="mb-1">
            <div className="flex pt-2">
              <div className="relative w-[600px]">
                <input
                  type="text"
                  id="voice-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-md focus:ring-[#02B491] focus:border-[#02B491] block w-full p-2.5"
                  placeholder="Search through CVs and profiles"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  required
                />
                <button
                  type="button"
                  className="absolute rounded-md bg-[#02B491] text-white m-1 py-2 px-4 inset-y-0 end-0 flex justify-center items-center"
                  onClick={handleSearch}
                >
                  <svg
                    className="w-4 h-4 me-2 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  Search
                </button>
              </div>

              <MultiSelect
                options={streams}
                selectedOptions={filterStream}
                onChange={setFilterStream}
                text={"Type of candidate"}
              />

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFiltersModalOpen(true);
                }}
                className="py-2 px-4 ml-2 flex rounded-lg border items-center justify-center border-gray-500 hover:border-black hover:bg-black/5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                  />
                </svg>
                Filters
              </button>
            </div>
          </div>
          <ActiveFilters
            filterStream={filterStream}
            filterWorkingStyle={filterWorkingStyle}
            filterIndustry={filterIndustry}
            filterYearsOfExp={filterYearsOfExp}
            searchKeywords={searchKeywords}
            clearFilters={clearFilters}
            onChange={onChange}
            maxSalary={maxSalary}
            setMaxSalary={setMaxSalary}
            setSearchKeywords={setSearchKeywords}
            setFilterYearsOfExp={setFilterYearsOfExp}
          />
        </div>
      </div>

      {isInterviewRequest && (
        <InterviewRequest
          setIsInterviewRequest={setIsInterviewRequest}
          clear={clear}
          setSuccess={setSuccess}
        />
      )}

      <div className="flex ml-auto h-full pb-20 justify-center items-center bg-white">
        <div className="max-w-[1500px] w-full">
          {loading ? (
            <div className="pt-20 px-5 pl-2">
              <CandidatesLoading />
            </div>
          ) : (
            <div>
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-4">
                  {filteredData.map((candidate, index) => (
                    <CandidateCard
                      key={index}
                      candidate={candidate}
                      isInList={savedCandidates.some(
                        (c) => c.id === candidate.id
                      )}
                      onToggleCandidate={toggleCandidate}
                      onSelectCandidate={handleSelectCandidate}
                      recommendedCandidates={recommendedCandidates}
                      roles={roles}
                      setRoles={setRoles}
                      shortlistedCandidates={shortlistedCandidates}
                      setRolesNeedUpdate={setRolesNeedUpdate}
                      setNewRoleForm={setNewRoleForm}
                      handleSelectCandidate={handleSelectCandidate}
                      candidatesStatus={candidatesStatus}
                      addToast={addToast}
                    />
                  ))}
                </div>
                
                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <div className="flex justify-center items-center space-x-4 py-4 mt-4">
                    <button
                      onClick={() => {
                        setCurrentPage(prev => Math.max(prev - 1, 1));
                        
                        // Use setTimeout to ensure the scroll happens after state update
                        setTimeout(() => {
                          // Scroll to top of the page
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }, 10);
                      }}
                      disabled={currentPage === 1}
                      className={`
                        px-4 py-2 rounded-md text-sm font-medium transition-colors
                        ${currentPage === 1 
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                          : 'bg-white border border-gray-200 text-gray-700 hover:bg-gray-50'}
                      `}
                    >
                      Previous
                    </button>
                    
                    <div className="text-sm text-gray-500">
                      Page {currentPage} of {totalPages}
                    </div>

                    <button
                      onClick={() => {
                        const calculatedTotalPages = Math.ceil(allFilteredCandidates.length / CANDIDATES_PER_PAGE);
                        
                        // If next page data is prefetched, use it immediately
                        if (nextPageData && currentPage < calculatedTotalPages) {
                          // We'll handle the actual data display in the next render
                          setCurrentPage(prev => prev + 1);
                          setNextPageData(null);
                        } else {
                          setCurrentPage(prev => Math.min(prev + 1, calculatedTotalPages));
                        }
                        
                        // Use setTimeout to ensure the scroll happens after state update
                        setTimeout(() => {
                          // Scroll to top of the page
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }, 10);
                      }}
                      disabled={currentPage === totalPages}
                      className={`
                        px-4 py-2 rounded-md text-sm font-medium transition-colors
                        ${currentPage === totalPages 
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                          : 'bg-[#02B491] text-white hover:bg-[#029e80]'}
                      `}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {newRoleForm && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                <h2 className="text-lg font-semibold mb-4">Add New Role</h2>
                <form onSubmit={handleNewRoleSubmit}>
                  <label
                    htmlFor="title"
                    className="block text-md font-normal leading-6 text-gray-900"
                  >
                    Role Title
                  </label>
                  <input
                    type="text"
                    value={newRoleTitle}
                    onChange={(e) => setNewRoleTitle(e.target.value)}
                    placeholder="Sales"
                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                    required
                  />
                  <button
                    type="submit"
                    className="block w-full px-4 py-2 bg-[#02B491] text-sm text-white rounded hover:bg-[#00f2c2] hover:text-black"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setNewRoleForm(false)}
                    className="block w-full px-4 py-2 mt-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}

          {toasts.map((toast, index) => (
            <div
              key={index}
              className="fixed divide-x flex right-10 border z-50 justify-between items-center w-full max-w-[500px] p-4 bg-white rounded-lg shadow-lg mb-4"
              role="alert"
              style={{ top: `${10 + index * 70}px` }}
            >
              <div className="text-sm font-normal text-gray-500 flex">
                <svg
                  className="text-green-500 mr-1 h-5 w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="font-bold">{toast}</span>&nbsp;successfully
                added to shortlist.
              </div>
              <div className="flex items-center space-x-2 pl-2">
                <button
                  onClick={() => navigate("/shortlists")}
                  className="text-sm flex font-medium text-[#02B491] hover:bg-gray-100 rounded-lg p-1.5"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                    />
                  </svg>
                  View shortlists
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
