import React, { useState, useEffect } from "react";
import axios from "axios";
import { Edit2, Trash2 } from "lucide-react";
import CompactSpotlightCard from "./compact_spotlight_card";

function RoleDetails({
  activeStep,
  setActiveStep,
  role,
  setRole,
  roles,
  setRoleNeedUpdate,
  loading,
  selectedCompanySpotlight,
  selectedRoleSpotlight,
  companySpotlights,
  roleSpotlights,
  onCompanySpotlightSelect,
  onRoleSpotlightSelect,
  onSpotlightDelete,
  onOpenCompanySelectOrUpload,
  onOpenRoleSelectOrUpload,
}) {
  const renderField = (label, value, fieldName) => {
    if (loading) {
      return (
        <div className="h-6 ml-4 bg-gray-200 rounded-full w-48 mb-4"></div>
      );
    }

    if (fieldName === "job description PDF" && value) {
      return (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline text-blue-600"
        >
          {label}
        </a>
      );
    }

    return value ? (
      <span>{value}</span>
    ) : (
      <span className="text-red-600">(missing {fieldName})</span>
    );
  };

  const fieldsToCheck = [
    "title",
    "compensation",
    "equity",
    "job_description_link",
  ];

  const isRoleComplete = fieldsToCheck.every(
    (field) => role[field] && role[field].trim() !== ""
  );

  const [formErrors, setFormErrors] = useState({});
  const [loadingSpotlight, setLoadinSpotlight] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [spotlights, setSpotlights] = useState([]);
  const [isAddSpotlightModalOpen, setIsAddSpotlightModalOpen] = useState(false);
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  const [deleteSpotlight, setDeleteSpotlight] = useState(null);
  const [editFormData, setEditFormData] = useState({ name: "" });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [spotlightNeedUpdate, setSpotlightNeedUpdate] = useState(false);
  const [selectedSpotlightTab, setSelectedSpotlightTab] = useState("Dropbox"); // Initialize Loom as the default tab

  const [newSpotlight, setNewSpotlight] = useState({
    name: "",
    url_dropbox: "",
    url_loom: "",
  });

  const [formData, setFormData] = useState({
    title: "",
    job_description_link: "",
    key_experience: "",
    compensation: "",
    working_style: "",
    equity: "",
    interview_process: "",
    additional_info: "",
    open_to_applications: false,
    spotlight_context: "",
  });

  // Initialize form data when role changes
  useEffect(() => {
    if (role) {
      setFormData({
        title: role.title || "",
        compensation: role.compensation || "",
        job_description_link: role.job_description_link || "",
        equity: role.equity || "",
        key_experience: role.key_experience || "",
        interview_process: role.interview_process || "",
        working_style: role.working_style || "",
        additional_info: role.additional_info || "",
        open_to_applications: role.open_to_applications || false,
        spotlight_context: role.spotlight_context || "",
      });
    }
  }, [role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = e.target.type === 'checkbox' ? e.target.checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
  };

  const validateFields = () => {
    const errors = {};
    if (!formData.title) errors.title = "Role Title is required.";
    if (!formData.compensation) errors.compensation = "Compensation is required.";
    if (!formData.job_description_link) errors.job_description_link = "Job Description is required.";
    if (!formData.working_style) errors.working_style = "Working Style is required.";
    if (!formData.equity) errors.equity = "Equity is required.";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      // Prepare spotlight IDs
      const spotlightIds = [];
      if (selectedCompanySpotlight) spotlightIds.push(selectedCompanySpotlight.id);
      if (selectedRoleSpotlight) spotlightIds.push(selectedRoleSpotlight.id);

      const updatedData = {
        roleId: role.id,
        fields: {
          "Role Title": formData.title,
          "Specific Experience": formData.key_experience,
          "Interview Process": formData.interview_process,
          "Working Style": formData.working_style,
          "Compensation": formData.compensation,
          "Equity": formData.equity,
          "Job Description PDF": formData.job_description_link,
          "Additional Information": formData.additional_info,
          "Spotlight Context": formData.spotlight_context,
          "Open to applications": formData.open_to_applications ? "Open to apps" : "Not open to apps",
          "Spotlight": spotlightIds
        }
      };

      const response = await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Update the role in the parent component
      setRole(prev => ({
        ...prev,
        ...formData,
        spotlight: spotlightIds
      }));
      
      setRoleNeedUpdate(true);
      setActiveStep("Message");
    } catch (err) {
      console.error("Error updating role data:", err);
      setError("Failed to update role data: " + err.message);
    }
  };

  const handleFileUpload = (url) => {
    setFormData(prev => ({ ...prev, job_description_link: url }));
    setEditMode(false);
  };

  const handleTabClick = (tab) => {
    setSelectedSpotlightTab(tab);
  };

  const handleSpotlightChange = (spotlight) => {
    setSelectedSpotlight((prev) =>
      prev?.name === spotlight?.name ? null : spotlight
    );
  };

  const openEditModal = (spotlight) => {
    setEditFormData({
      name: spotlight.name,
      id: spotlight.id,
    });
    setIsEditModalOpen(true);
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSpotlightSubmit = async (event) => {
    event.preventDefault();

    const data = {
      fields: {
        Name: newSpotlight.name,
      },
    };

    if (selectedSpotlightTab === "Loom") {
      data.fields["URL Loom"] = newSpotlight.url_loom;
    } else {
      data.fields["URL Dropbox"] = newSpotlight.url_dropbox;
    }

    try {
      const token = localStorage.getItem("token");
      await axios.post("/api/spotlights/createSpotlight", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error(err);
    }
    setSpotlightNeedUpdate(true);
    setIsAddSpotlightModalOpen(false);
  };

  const handleDeleteSpotlight = async (event) => {
    event.preventDefault();
    const updatedData = {
      fields: {
        Deleted: true,
      },
      spotlightId: deleteSpotlight.id, // Make sure the ID is included
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`, // Use backticks for string interpolation
          "Content-Type": "application/json",
        },
      });

      // Check if the deleted spotlight is the selected spotlight
      if (selectedSpotlight && selectedSpotlight.id === deleteSpotlight.id) {
        setSelectedSpotlight(null); // Set selectedSpotlight to null if the deleted spotlight is the selected one
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
    }
    setSpotlightNeedUpdate(true);
    setDeleteSpotlight(null);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      fields: {
        Name: editFormData.name,
      },
      spotlightId: editFormData.id, // Make sure the ID is included
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`, // Use backticks for string interpolation
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error(err); // Log the error for debugging
    }
    setSpotlightNeedUpdate(true);
    setIsEditModalOpen(false);
  };

  const handleAddSpotlightChange = (e) => {
    const { name, value } = e.target;
    setNewSpotlight(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className={`relative ${activeStep === "Role Details" ? "" : "flex"}`}>
      <h2
        className={`whitespace-nowrap text-4xl pt-8 pb-4 font-normal leading-6 ${
          activeStep === "Role Details" ? "text-[#02B491]" : "text-gray-900"
        }`}
      >
        2. Role Details
      </h2>
      {activeStep === "Role Details" ? (
        <>
          <div className="mb-4 transform transition-all max-h-[400px] overflow-y-scroll">
            <form onSubmit={handleSubmit}>
              <div className="space-y-12">
                <div className="border-gray-900/10">
                  <div className="sm:col-span-8 flex space-x-8">
                    <div className="flex-1">
                      <div className="mb-4">
                        <label
                          htmlFor="title"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Role Title <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Sales"
                          value={formData.title}
                          onChange={handleChange}
                        ></input>
                        {formErrors.title && (
                          <p className="text-red-600 text-md">
                            {formErrors.title}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="compensation"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Compensation <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="compensation"
                          id="compensation"
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="£40-50,000 + £10k commission"
                          value={formData.compensation}
                          onChange={handleChange}
                        ></input>
                        {formErrors.compensation && (
                          <p className="text-red-600 text-md">
                            {formErrors.compensation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-4">
                        <label
                          htmlFor="working_style"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Working Style <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="working_style"
                          id="working_style"
                          autoComplete="working_style"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Hybrid - 1 day per week in office"
                          value={formData.working_style}
                          onChange={handleChange}
                        ></input>
                        {formErrors.working_style && (
                          <p className="text-red-600 text-md">
                            {formErrors.working_style}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        {" "}
                        <label
                          htmlFor="equity"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Would you be open to offering equity?{" "}
                          <span className="text-black">*</span>
                        </label>
                        <select
                          id="equity"
                          name="equity"
                          value={formData.equity}
                          onChange={handleChange}
                          required
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        >
                          <option value="">Select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="TBC">To be Determined</option>
                        </select>
                        {formErrors.equity && (
                          <p className="text-red-600 text-md">
                            {formErrors.equity}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <label
                        htmlFor="interview_process"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Interview Process
                      </label>
                      <textarea
                        id="interview_process"
                        name="interview_process"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A short description of the interview process for this role!"
                        value={formData.interview_process}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="key_experience"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Key Experience
                      </label>
                      <textarea
                        id="key_experience"
                        name="key_experience"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A few lines on the type of candidate you are looking for!"
                        value={formData.key_experience}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <h2 className="block pt-6 text-md flex items-center font-semibold leading-6 text-gray-900">
                    Job Description <span className="text-black">*</span>{" "}
                    <div className="relative group">
                      <svg
                        onClick={() => {
                          window.open(
                            "https://jumpstart-alumni.notion.site/Writing-a-Job-Description-33504887841241d0a52f5a2a9e23e245",
                            "_blank"
                          );
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 ml-2 cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                      <div className="absolute left-0 hidden mt-1 w-40 p-2 z-50 text-sm text-white bg-[#02B491] rounded-lg shadow-md group-hover:block">
                        For tips on writing a JD click the icon
                      </div>
                    </div>
                  </h2>
                  <div className="mt-1 flex">
                    <div className="relative w-[400px] pr-2 flex items-center">
                      <input
                        type="text"
                        name="job_description_link"
                        id="job-description"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="https://www.jumpstart-uk.com/"
                        value={formData.job_description_link}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={() => setEditMode(true)}
                        className="px-4 py-2 text-sm bg-white hover:bg-[#02B491] border-2 border-[#02B491] text-black hover:text-white rounded-lg"
                      >
                        Upload Job Description
                      </button>
                      {formErrors.job_description_link && (
                        <p className="text-red-600 text-sm ml-2">
                          {formErrors.job_description_link}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="container mx-auto py-4">
                    <h2 className="block mt-6 text-3xl font-light leading-6 text-gray-900">
                      Spotlights Recording
                    </h2>
                    <div className="sm:col-span-8 mt-2">
                      <p className="pb-8 italic">
                        Startups with a recorded pitch drive 50% more engagement. It helps candidates self-select whether they're a good fit—saving you and them time.
                      </p>

                      <div className="space-y-8">
                        {/* Company Spotlight */}
                        <div className="border-b pb-8">
                          <div className="flex justify-between items-start mb-4">
                            <div>
                              <h3 className="text-lg font-medium text-gray-900">Company spotlight</h3>
                              <p className="text-sm text-gray-600 mt-1">
                                Add a one-time video to showcase your mission, team, and culture.
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center mt-4 space-x-4">
                            <div className="w-72">
                              {selectedCompanySpotlight ? (
                                <CompactSpotlightCard
                                  spotlight={selectedCompanySpotlight}
                                  selected={true}
                                  onSelect={() => onCompanySpotlightSelect(null)}
                                  onDelete={() => onSpotlightDelete(selectedCompanySpotlight.id)}
                                />
                              ) : (
                                <div className="w-72 aspect-video rounded-lg border-2 border-dashed border-gray-300 flex items-center justify-center bg-gray-50">
                                  <p className="text-sm text-gray-500 text-center">No spotlight selected</p>
                                </div>
                              )}
                            </div>

                            <div
                              onClick={onOpenCompanySelectOrUpload}
                              className="w-72 aspect-video rounded-lg border-2 border-dashed border-[#02B491] flex flex-col items-center justify-center cursor-pointer bg-white hover:bg-gray-50 transition-colors duration-200"
                            >
                              <div className="flex flex-col items-center text-[#02B491]">
                                <span className="text-4xl font-light mb-2">+</span>
                                <span className="text-sm font-medium">Select or Upload</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Role Spotlight */}
                        <div className="pt-2">
                          <div className="flex justify-between items-start mb-4">
                            <div>
                              <h3 className="text-lg font-medium text-gray-900">Role spotlight</h3>
                              <p className="text-sm text-gray-600 mt-1">
                                Add a short video tailored to each role.
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center mt-4 space-x-4">
                            <div className="w-72">
                              {selectedRoleSpotlight ? (
                                <CompactSpotlightCard
                                  spotlight={selectedRoleSpotlight}
                                  selected={true}
                                  onSelect={() => onRoleSpotlightSelect(null)}
                                  onDelete={() => onSpotlightDelete(selectedRoleSpotlight.id)}
                                />
                              ) : (
                                <div className="w-72 aspect-video rounded-lg border-2 border-dashed border-gray-300 flex items-center justify-center bg-gray-50">
                                  <p className="text-sm text-gray-500 text-center">No spotlight selected</p>
                                </div>
                              )}
                            </div>

                            <div
                              onClick={onOpenRoleSelectOrUpload}
                              className="w-72 aspect-video rounded-lg border-2 border-dashed border-[#02B491] flex flex-col items-center justify-center cursor-pointer bg-white hover:bg-gray-50 transition-colors duration-200"
                            >
                              <div className="flex flex-col items-center text-[#02B491]">
                                <span className="text-4xl font-light mb-2">+</span>
                                <span className="text-sm font-medium">Select or Upload</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Additional Context */}
                        {(selectedCompanySpotlight || selectedRoleSpotlight) && (
                          <div className="pt-8 border-t">
                            <label htmlFor="spotlight_context" className="block text-md font-medium text-gray-900">
                              Additional context
                            </label>
                            <textarea
                              id="spotlight_context"
                              name="spotlight_context"
                              rows="2"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#02B491] text-sm"
                              placeholder="e.g. This is a recording we did for a different role but should give a good overview..."
                              value={formData.spotlight_context}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <h2 className="block text-3xl font-light leading-6 text-gray-900">
                        Receiving applications
                      </h2>

                      <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8 pb-4">
                        <div className="flex-1">
                          <div className="col-span-full">
                            <div className="flex items-center">
                              <input
                                id="open_to_applications"
                                type="checkbox"
                                checked={formData.open_to_applications}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    open_to_applications: e.target.checked,
                                  })
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="open_to_applications"
                                className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300"
                              >
                                I am happy to receive applications for this
                                role.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {error && <p className="text-red-600 text-md">{error}</p>}
            </form>
          </div>
          <button
            onClick={(e) => handleSubmit(e)}
            className="px-8 py-2 mb-4 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
          >
            Continue
          </button>
        </>
      ) : (
        <div className="pl-4 pt-6">
          <div
            className="text-[#02B491] absolute top-2 right-0 text-lg cursor-pointer hover:underline"
            onClick={() => setActiveStep("Role Details")}
          >
            Edit
          </div>
          <h2 className="pl-4 text-xl font-semibold text-black">
            {renderField("Title", role.title, "title")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 flex font-semibold">
            {renderField("Compensation", role.compensation, "compensation")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 font-semibold">
            {renderField(
              "Hiring Lead Name",
              role.hiring_lead_name,
              "hiring lead name"
            )}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 flex font-semibold">
            {renderField(
              "Hiring Lead Email",
              role.hiring_lead_email,
              "hiring lead email"
            )}
          </h2>
          <div className="pl-4 pb-6 text-md text-blue-600 font-semibold">
            {renderField(
              "Job Description PDF",
              role.job_description_link,
              "job description PDF"
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default RoleDetails;
