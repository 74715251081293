import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import JobDescription from "../images/job-description-icon.jpeg";
import HeadHunting from "../images/headhunting-icon.png";
import JobApplication from "../images/job-application-icon.png";
import Interview from "../images/interview-icon.png";
import InterviewBlack from "../images/interviews-black.png";
import Application from "../images/applications-icon.png";
import ApplicationBlack from "../images/applications-icon-black.png";
import Role from "../images/roles-icon.png";
import RoleBlack from "../images/roles-icon-black.png";
import Browse from "../images/browse-icon.png";
import BrowseBlack from "../images/browse-icon-black.png";
import { useShortlisted } from "../provider/ShortlistedContext";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCandidatesDropdownOpen, setCandidatesDropdownOpen] = useState(false);
  const [isInterviewsDropdownOpen, setInterviewsDropdownOpen] = useState(false);
  const [isInterviewHovered, setIsInterviewHovered] = useState(false);
  const [isApplicationHovered, setIsApplicationHovered] = useState(false);
  const [isRoleHovered, setIsRoleHovered] = useState(false);
  const [isBrowseHovered, setIsBrowseHovered] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCompanyHovered, setIsCompanyHovered] = useState(false);
  const [showLoomPopup, setShowLoomPopup] = useState(false); // State to control Loom video popup
  const [isHovered, setIsHovered] = useState(false);

  const { shortlistedCount, setShortlistedCount } = useShortlisted();

  const isActive = (path) => location.pathname === path;

  const avatar =
    "https://via.placeholder.com/600x400.png?text=No+Image+Available";
  const profileFallback =
    "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=";
  const [companyLogo, setCompanyLogo] = useState(
    "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
  );
  const [companyName, setCompanyName] = useState("");
  const [companyLink, setCompanyLink] = useState("");

  useEffect(() => {
    const candidatesDropdownState =
      localStorage.getItem("candidatesDropdownOpen") === "true";
    const interviewsDropdownState =
      localStorage.getItem("interviewsDropdownOpen") === "true";
    setCandidatesDropdownOpen(candidatesDropdownState);
    setInterviewsDropdownOpen(interviewsDropdownState);
  }, []);

  useEffect(() => {
    const updateDropdownState = () => {
      const path = location.pathname;
      if (path.includes("browse-candidates") || path.includes("shortlists")) {
        setCandidatesDropdownOpen(true);
      }
      if (
        path.includes("requests") ||
        path.includes("in-process") ||
        path.includes("declined")
      ) {
        setInterviewsDropdownOpen(true);
      }
    };

    updateDropdownState();
  }, [location.pathname]);

  const fetchShortlistedCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;

      const count = roles.reduce(
        (acc, role) =>
          acc +
          (role["Shortlisted Candidates"]
            ? role["Shortlisted Candidates"].length
            : 0),
        0
      );
      setShortlistedCount(count);
    } catch (err) {
      console.error("Error fetching roles:", err);
    }
  }, [setShortlistedCount]);

  useEffect(() => {
    fetchShortlistedCandidates();
  }, [fetchShortlistedCandidates]);

  const toggleCandidatesDropdown = () => {
    const newState = !isCandidatesDropdownOpen;
    setCandidatesDropdownOpen(newState);
    localStorage.setItem("candidatesDropdownOpen", newState);
  };

  const toggleInterviewsDropdown = () => {
    const newState = !isInterviewsDropdownOpen;
    setInterviewsDropdownOpen(newState);
    localStorage.setItem("interviewsDropdownOpen", newState);
  };

  const updateWalkThrough = async (status) => {
    try {
      const token = localStorage.getItem("token");
      const updatedData = {
        fields: {
          "Loom walk through": status,
        },
      };
      const response = await axios.patch(
        "/api/companies/updateCompanyData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Ensure this matches the server expectation
          },
        }
      );
      if (status === "Watched") {
        setShowLoomPopup(false);
        localStorage.setItem("loom", "Watched");
        navigate("/browse-candidates");
      }
      if (status === "Skipped") {
        localStorage.setItem("loom", "Skipped");
        setShowLoomPopup(false);
      }
    } catch (err) {}
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const companyLogoUrl = response.data.companyData.logo;
      const companyName = response.data.companyData.name;
      const companyLink = response.data.companyData.login_link;
      const loomWalkThrough = response.data.companyData.loom_walk_through;

      if (loomWalkThrough === "Did not watch") {
        setShowLoomPopup(true);
      }

      if (companyLogoUrl) {
        setCompanyLogo(companyLogoUrl);
        localStorage.setItem("companyLogo", companyLogoUrl);
      }

      setCompanyLink(companyLink);
      setCompanyName(companyName);
      localStorage.setItem("companyName", companyName); // Save company name to localStorage
      localStorage.setItem("companyLink", companyLink); // Save company name to localStorage
      localStorage.setItem("lastFetchTime", Date.now());
      localStorage.setItem("loom", loomWalkThrough);
    } catch (err) {
      console.error("Error fetching company data:", err);
    }
  };

  useEffect(() => {
    const logoFromStorage = localStorage.getItem("companyLogo");
    const nameFromStorage = localStorage.getItem("companyName");
    const lastFetchTime = localStorage.getItem("lastFetchTime");
    const linkFromStorage = localStorage.getItem("companyLink");
    const loomWalkThrough = localStorage.getItem("loom");
    if (loomWalkThrough === "Did not watch") {
      setShowLoomPopup(true);
    }
    const currentTime = Date.now();
    const twoHours = 2 * 60 * 60 * 1000;

    if (
      logoFromStorage &&
      nameFromStorage &&
      lastFetchTime &&
      currentTime - lastFetchTime < twoHours
    ) {
      setCompanyLogo(logoFromStorage);
      setCompanyLink(linkFromStorage);
      setCompanyName(nameFromStorage); // Retrieve company name from localStorage
    } else {
      fetchData();
    }
  }, []);

  const copyToClipboard = () => {
    const currentPage = location.pathname;
    const params = new URLSearchParams(location.search);
    const roleId = params.get("roleId");
    const link = `${companyLink}&page=${currentPage}&roleId=${roleId}`;

    navigator.clipboard.writeText(link).then(
      () => {
        alert("Link copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className="sm:hidden flex justify-between items-center p-4 bg-white border-b sticky top-0 z-50">
        <img
          onClick={() => navigate("/home")}
          src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
          className="h-6"
          alt="Jumpstart Logo"
        />
        <button onClick={toggleMobileMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      <aside
        className={`fixed top-0 left-0 z-40 w-56 h-screen transition-all bg-white border-r  ${
          isMobileMenuOpen ? "-translate-x-full" : "translate-x-0"
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 pt-11 overflow-y-auto flex flex-col justify-between bg-white">
          <div className="flex items-center mb-6 ">
            <img
              src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
              className="h-6 my-2 px-4 sm:block hidden "
              alt="Jumpstart Logo"
            />
          </div>
          <ul className="space-y-2 font-medium">
            <li>
              <button
                onClick={toggleCandidatesDropdown}
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onMouseEnter={() => setIsBrowseHovered(true)}
                onMouseLeave={() => setIsBrowseHovered(false)}
              >
                <img
                  src={isBrowseHovered ? BrowseBlack : Browse}
                  className="w-8 h-8 hidden text-gray-500 transition duration-75 group-hover:text-gray-900 "
                />
                {isBrowseHovered ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z"
                      clip-rule="evenodd"
                    />
                    <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                    />
                  </svg>
                )}

                <span className="flex-1 font-normal ms-3 text-left whitespace-nowrap">
                  Candidates
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className={`w-6 h-6 transform transition-transform ${
                    isCandidatesDropdownOpen ? "rotate-180" : ""
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
              {isCandidatesDropdownOpen && (
                <ul className="py-2 space-y-2">
                  <li>
                    <a
                      href="/browse-candidates"
                      className={`flex font-normal text-sm items-center pl-14 rounded-lg w-full p-2 ${
                        isActive("/browse-candidates")
                          ? "bg-green-100 text-green-700"
                          : "text-gray-900 hover:bg-gray-100"
                      }`}
                    >
                      Browse
                    </a>
                  </li>
                  <li>
                    <a
                      href="/shortlists"
                      className={`flex font-normal text-sm items-center pl-14 rounded-lg w-full p-2 ${
                        isActive("/shortlists")
                          ? "bg-green-100 text-green-700"
                          : "text-gray-900 hover:bg-gray-100"
                      }`}
                    >
                      Shortlists
                      {shortlistedCount > 0 && !isActive("/shortlists") && (
                        <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                          {shortlistedCount}
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <a
                href="/applications"
                className={`flex items-center p-2 rounded-lg transition duration-75 group ${
                  isActive("/applications")
                    ? "bg-green-100 text-green-700"
                    : "text-gray-900 hover:bg-gray-100"
                }`}
                onMouseEnter={() => setIsApplicationHovered(true)}
                onMouseLeave={() => setIsApplicationHovered(false)}
              >
                <img
                  src={isApplicationHovered ? ApplicationBlack : Application}
                  className="w-7 h-7 hidden text-gray-500 transition duration-75 group-hover:text-gray-900"
                />
                {isApplicationHovered ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                )}

                <span className="font-normal flex-1 ms-3 whitespace-nowrap">
                  Applications
                </span>
              </a>
            </li>
            <li>
              <button
                onClick={toggleInterviewsDropdown}
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onMouseEnter={() => setIsInterviewHovered(true)}
                onMouseLeave={() => setIsInterviewHovered(false)}
              >
                <img
                  src={isInterviewHovered ? InterviewBlack : Interview}
                  className="w-8 h-8 hidden text-gray-500 transition duration-75 group-hover:text-gray-900"
                />
                {isInterviewHovered ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                    <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                    />
                  </svg>
                )}

                <span className="flex-1 font-normal ms-3 text-left whitespace-nowrap">
                  Interviews
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className={`w-6 h-6 transform transition-transform ${
                    isInterviewsDropdownOpen ? "rotate-180" : ""
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
              {isInterviewsDropdownOpen && (
                <ul className="py-2 space-y-2">
                  <li>
                    <a
                      href="/requests"
                      className={`flex font-normal text-sm items-center pl-14 rounded-lg w-full p-2 ${
                        isActive("/requests")
                          ? "bg-green-100 text-green-700"
                          : "text-gray-900 hover:bg-gray-100"
                      }`}
                    >
                      Requests
                    </a>
                  </li>
                  <li>
                    <a
                      href="/in-process"
                      className={`flex font-normal text-sm items-center pl-14 rounded-lg w-full p-2 ${
                        isActive("/in-process")
                          ? "bg-green-100 text-green-700"
                          : "text-gray-900 hover:bg-gray-100"
                      }`}
                    >
                      In process
                    </a>
                  </li>
                  <li>
                    <a
                      href="/declined"
                      className={`flex font-normal text-sm items-center pl-14 rounded-lg w-full p-2 ${
                        isActive("/declined")
                          ? "bg-green-100 text-green-700"
                          : "text-gray-900 hover:bg-gray-100"
                      }`}
                    >
                      Declined
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <a
                href="/roles"
                className={`flex items-center p-2 rounded-lg transition duration-75 group ${
                  isActive("/roles")
                    ? "bg-green-100 text-green-700"
                    : "text-gray-900 hover:bg-gray-100"
                }`}
                onMouseEnter={() => setIsRoleHovered(true)}
                onMouseLeave={() => setIsRoleHovered(false)}
              >
                <img
                  src={isRoleHovered ? RoleBlack : Role}
                  className="w-8 h-8 hidden text-gray-500 transition duration-75 group-hover:text-gray-900"
                />
                {isRoleHovered ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 0 0-3-3h-3.879a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H6a3 3 0 0 0-3 3v3.162A3.756 3.756 0 0 1 4.094 9h15.812ZM4.094 10.5a2.25 2.25 0 0 0-2.227 2.568l.857 6A2.25 2.25 0 0 0 4.951 21H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-2.227-2.568H4.094Z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776"
                    />
                  </svg>
                )}

                <span className="flex-1 font-normal ms-3 whitespace-nowrap">
                  Roles
                </span>
              </a>
            </li>
          </ul>

          <div className="mt-auto pb-4 space-y-4 font-medium">
            <div>
              <a
                href="/company-profile"
                className={`flex items-center p-2 rounded-lg transition duration-75 group ${
                  isActive("/company-profile")
                    ? "bg-green-100 text-green-700"
                    : "text-gray-900 hover:bg-gray-100"
                }`}
                onMouseEnter={() => setIsCompanyHovered(true)}
                onMouseLeave={() => setIsCompanyHovered(false)}
              >
                {isCompanyHovered ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5H15v-18a.75.75 0 0 0 0-1.5H3ZM6.75 19.5v-2.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 0 1.5h-.75A.75.75 0 0 1 6 6.75ZM6.75 9a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM6 12.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 6a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75Zm-.75 3.75A.75.75 0 0 1 10.5 9h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 12a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM16.5 6.75v15h5.25a.75.75 0 0 0 0-1.5H21v-12a.75.75 0 0 0 0-1.5h-4.5Zm1.5 4.5a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Zm.75 2.25a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75h-.008ZM18 17.25a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                )}

                <span className="flex-1 font-normal ms-3 whitespace-nowrap">
                  {companyName}
                </span>
              </a>
            </div>

            <button
              onClick={copyToClipboard}
              className="px-2 py-2 rounded-md border border-black bg-white text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200"
            >
              Share Page with Colleague
            </button>
          </div>
        </div>
      </aside>

      {showLoomPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-lg max-w-4xl w-2/3 h-auto relative flex flex-col justify-between space-y-8 min-h-[60%] lg:min-h-[50%]">
            <button
              type="button"
              onClick={() => updateWalkThrough("Skipped")}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <div className="flex flex-col justify-center items-center">
              <h2 className="text-3xl font-bold">Welcome to Jumpstart</h2>
              <p className="text-gray-500">
                Where startups come to hire the top 1% of talent
              </p>
            </div>

            <div className="flex justify-center">
              <iframe
                src="https://www.loom.com/embed/7e866ad12ee447e7a36cccab322dcbf7?sid=146c61ac-a157-49b2-8c4c-94a0fd0e6e1c"
                title="Loom Walkthrough"
                className="w-[90%] h-[400px] rounded-2xl border"
                allowFullScreen
              />
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => updateWalkThrough("Watched")}
                className="px-6 py-2 text-md bg-[#02B491] text-white hover:bg-[#00F2C2] hover:border-[#00F2C2] hover:text-black rounded-xl font-normal border-2 border-[#02B491]"
              >
                View candidates
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
