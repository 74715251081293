import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import EditProfilePictureModal from "./edit_pp"; // Make sure to import your modal component

function EditCompanyProfile() {
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [file, setFile] = useState(null); // State to hold the file

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/companies/fetchCompanyData", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const companyData = response.data.companyData;

        setCompany(companyData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    website: "",
    email: "",
    linkedin: "",
    description: "",
    file: null,
    equity: "",
    funding: "",
    location: "",
    email: "",
    profile_picture_link: "",
    team_size: "",
  });

  useEffect(() => {
    if (company) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: company.name || "",
        website: company.website || "",
        linkedin: company.linkedin || "",
        description: company.description || "",
        equity: company.equity || "",
        funding: company.funding || "",
        profile_picture_link: company.logo,
        location: company.location || "",
        email: company.email || "",
        team_size: company.team_size || "",
      }));
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const updatedData = {
      fields: {
        "Startup Name": formData.name,
        Website: formData.website,
        "Startup Description": formData.description,
        "Team Size": formData.team_size,
        Location: formData.location,
        ...(formData.funding && formData.funding !== "Choose an option"
          ? { "Funding Stage": formData.funding }
          : {}),
      },
    };

    if (formData.profile_picture_link)
      updatedData.fields["Logo"] = [{ url: formData.profile_picture_link }];

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const response = await axios.patch(
        "/api/companies/updateCompanyData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Ensure this matches the server expectation
          },
        }
      );
      // Handle successful update
    } catch (err) {
      console.error("Error updating company data:", err);
      setError("Failed to update company data: " + err.message);
    }
  };

  const CompanyLogo = ({ headerImage }) => {
    const profileFallback =
      "https://via.placeholder.com/600x400.png?text=No+Image+Available"; // Update this to your actual fallback image path

    return (
      <img
        src={headerImage || profileFallback}
        alt="profile"
        className="h-[300px] w-[400px] object-cover rounded-lg bg-[var(--overlay-2)]"
        onError={(e) => {
          e.target.src = profileFallback; // Provide a fallback source
          console.error("Failed to load image at", e.target.src);
        }}
      />
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const handleFileUpload = (url) => {
    setFormData({ ...formData, file: url });
    setCompany({ ...company, logo: url });
  };

  return (
    <div className="flex flex-col pt-10 pl-72 items-left bg-white">
      <div className="max-w-6xl w-full flex flex-col h-full bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className=" border-gray-900/10 pb-4">
              <h2
                className="text-4xl font-semibold leading-7 text-gray-900"
                style={{ color: "rgb(0, 185, 140)" }}
              >
                {company.name}
              </h2>
              <div className="col-span-full mt-4">
                <h2
                  htmlFor="photo"
                  className="block text-3xl font-light leading-6 text-gray-900"
                >
                  Company Image
                </h2>
                <div className="mt-6 flex items-center gap-x-3">
                  <CompanyLogo headerImage={company.logo} />
                  <button
                    type="button"
                    onClick={openModal}
                    className="px-2 py-2 text-sm text-black flex rounded-lg font-medium transform bg-white hover:bg-[#02B491] hover:text-white border-2 border-[#02B491]"
                  >
                    Edit Profile Picture
                  </button>
                </div>
              </div>
              <h2 className="block mt-8 text-3xl font-light leading-6 text-gray-900">
                Company Information
              </h2>
              <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                <div className="flex-initial w-2/5">
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      placeholder="Jumpstart"
                      value={formData.name}
                      onChange={handleChange}
                    ></input>
                    {formErrors.name && (
                      <p className="text-red-600 text-md">{formErrors.name}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="company"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Website
                    </label>
                    <input
                      type="text"
                      name="website"
                      id="website"
                      className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      placeholder="https://www.jumpstart-uk.com/"
                      value={formData.website}
                      onChange={handleChange}
                    ></input>
                    {formErrors.linkedin && (
                      <p className="text-red-600 text-md">
                        {formErrors.website}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="city"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Location
                    </label>
                    <div>
                      <input
                        type="text"
                        name="location"
                        id="location"
                        placeholder="North London"
                        autoComplete="address-level2"
                        value={formData.location}
                        onChange={handleChange}
                        className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      ></input>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="team-size"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Team Size
                    </label>
                    <div>
                      <input
                        type="number"
                        name="team_size"
                        id="team_size"
                        placeholder="6"
                        autoComplete="team_size"
                        value={formData.team_size}
                        onChange={handleChange}
                        className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-4 mt-1">
                    <label
                      htmlFor="funding"
                      className="block text-md font-semibold text-gray-900"
                    >
                      Funding Stage
                    </label>
                    <select
                      id="funding"
                      name="funding"
                      value={formData.funding}
                      onChange={handleChange}
                      className="bg-transparent mt-2 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 p-1.5"
                    >
                      <option selected>Choose an option</option>
                      <option value="Pre-Seed">Pre-seed</option>
                      <option value="Seed">Seed</option>
                      <option value="Series A">Series A</option>
                      <option value="Series B">Series B</option>
                      <option value="Series B+">Series B+</option>
                      <option value="Series C+">Series C+</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="description"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Description
                    </label>
                    <div className="mb-2 flex space-x-2 items-center">
                      <button
                        type="button"
                        onClick={() => {
                          const textarea = document.getElementById('description');
                          const start = textarea.selectionStart;
                          const end = textarea.selectionEnd;
                          const text = textarea.value;
                          const before = text.substring(0, start);
                          const selection = text.substring(start, end);
                          const after = text.substring(end);
                          const newText = before + '**' + selection + '**' + after;
                          setFormData({ ...formData, description: newText });
                          
                          // Set cursor position after the inserted text
                          setTimeout(() => {
                            textarea.focus();
                            const newPosition = end + 4; // 4 is the length of the added '**' tags
                            textarea.setSelectionRange(newPosition, newPosition);
                          }, 0);
                        }}
                        className="p-1 rounded hover:bg-gray-100 text-gray-700 border border-gray-300"
                        title="Bold"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
                          <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
                        </svg>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          const textarea = document.getElementById('description');
                          const start = textarea.selectionStart;
                          const text = textarea.value;
                          const before = text.substring(0, start);
                          const after = text.substring(start);
                          
                          // Add a bullet point at the beginning of the line
                          let newText;
                          if (before.endsWith('\n') || before === '') {
                            newText = before + '• ' + after;
                          } else {
                            newText = before + '\n• ' + after;
                          }
                          
                          setFormData({ ...formData, description: newText });
                          
                          // Set cursor position after the bullet point
                          setTimeout(() => {
                            textarea.focus();
                            const newPosition = start + (before.endsWith('\n') || before === '' ? 2 : 3);
                            textarea.setSelectionRange(newPosition, newPosition);
                          }, 0);
                        }}
                        className="p-1 rounded hover:bg-gray-100 text-gray-700 border border-gray-300"
                        title="Bullet Point"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <line x1="8" y1="6" x2="21" y2="6"></line>
                          <line x1="8" y1="12" x2="21" y2="12"></line>
                          <line x1="8" y1="18" x2="21" y2="18"></line>
                          <line x1="3" y1="6" x2="3.01" y2="6"></line>
                          <line x1="3" y1="12" x2="3.01" y2="12"></line>
                          <line x1="3" y1="18" x2="3.01" y2="18"></line>
                        </svg>
                      </button>
                      <span className="text-sm text-gray-500">Add basic formatting to your description</span>
                    </div>
                    <textarea
                      id="description"
                      name="description"
                      rows="6"
                      className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6 p-3"
                      placeholder="A short description of your company!"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex items-center justify-start gap-x-4">
            <button
              type="submit"
              className="px-6 py-2 text-sm bg-[#02B491] text-white border-2 border-[#02B491] hover:bg-white hover:text-black rounded-lg font-medium"
            >
              Save and update
            </button>
            <button
              onClick={handleLogout}
              className="px-4 py-2 rounded-md border border-red-400 bg-white text-black text-sm hover:bg-red-400 hover:text-white "
            >
              Logout
            </button>
          </div>
        </form>
        {isModalOpen && (
          <EditProfilePictureModal
            onClose={closeModal}
            formData={formData}
            setFormData={setFormData}
            setFile={setFile}
            file={file}
            onFileUpload={handleFileUpload}
          />
        )}
      </div>
    </div>
  );
}

export default EditCompanyProfile;
