import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import axios from "axios"
import { Info } from "lucide-react"
// Updated CustomTooltip component

// Updated CustomTooltip component
const CustomTooltip = ({ children, content }) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);
  const hideTimeoutRef = useRef(null);

  const showTooltip = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setShow(true);
  };

  const hideTooltip = () => {
    hideTimeoutRef.current = setTimeout(() => {
      setShow(false);
    }, 150); // Small delay before hiding
  };

  useEffect(() => {
    if (!show || !triggerRef.current || !tooltipRef.current) return;

    const updatePosition = () => {
      const trigger = triggerRef.current.getBoundingClientRect();
      const tooltip = tooltipRef.current.getBoundingClientRect();
      
      // Calculate the vertical position
      let y = trigger.top - tooltip.height - 8;
      
      // If tooltip would go above viewport, show it below instead
      if (y < 10) {
        y = trigger.bottom + 8;
      }
      
      // Calculate horizontal position
      let x = trigger.left + (trigger.width / 2);
      
      // Ensure tooltip doesn't go off screen horizontally
      const rightEdge = x + (tooltip.width / 2);
      const leftEdge = x - (tooltip.width / 2);
      
      if (rightEdge > window.innerWidth - 10) {
        x = window.innerWidth - 10 - (tooltip.width / 2);
      }
      if (leftEdge < 10) {
        x = 10 + (tooltip.width / 2);
      }
      
      setPosition({ x, y });
    };

    updatePosition();
    
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);
    
    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [show]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="inline-block relative">
      <div
        ref={triggerRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        className="inline-flex items-center"
      >
        {children}
      </div>
      
      {show && (
        <div
          ref={tooltipRef}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          className="fixed z-50 bg-gray-900 text-white text-sm px-3 py-2 rounded shadow-lg max-w-xs"
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
            transform: 'translate(-50%, 0)',
          }}
        >
          <div className="text-center">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};
const BasicInformation = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const queryRoleId = searchParams.get("roleId")
  const [roleId, setRoleId] = useState(null)
  const [token, setToken] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    title: "",
    workingStyle: "",
    compensation: "",
    equity: "",
    hiringLeadName: "",
    hiringLeadEmail: "",
    hiringLeadLinkedin: "",
    hiringLeadPhone: "",
    additionalTeamEmails: "",
  })

  // Show/hide additional emails input
  const [showAdditionalEmails, setShowAdditionalEmails] = useState(false)

  useEffect(() => {
    const storedToken = localStorage.getItem("token") || ""
    const storedRoleId = localStorage.getItem("currentRoleId")

    setToken(storedToken)
    const finalRoleId = queryRoleId || storedRoleId
    if (finalRoleId) {
      setRoleId(finalRoleId)
      localStorage.setItem("currentRoleId", finalRoleId)
      fetchRoleData(finalRoleId, storedToken)
    }
  }, [queryRoleId])

  const fetchRoleData = async (id, authToken) => {
    try {
      const response = await axios.get("/api/roles/fetchRoleById", {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { id },
      })

      if (response.data.role) {
        const role = response.data.role.fields
        setFormData({
          title: role["Role Title"] || "",
          workingStyle: role["Working Style"] || "",
          compensation: role["Compensation"] || "",
          equity: role["Equity"] || "",
          hiringLeadName: role["Hiring Lead Name"] || "",
          hiringLeadEmail: role["Hiring Lead Email Address"] || "",
          hiringLeadLinkedin: role["Hiring Lead Linkedin"] || "",
          hiringLeadPhone: role["Hiring Lead Phone Number"] || "",
          additionalTeamEmails: role["CCs"] || "",
        })
      }
    } catch (error) {
      console.error("Error fetching role data:", error)
    }
  }

  const sendRoleUpdate = async () => {
    if (!roleId || !token) return

    try {
      const dataToUpdate = {
        fields: {
          "Role Title": formData.title,
          "Working Style": formData.workingStyle,
          Compensation: formData.compensation,
          "Hiring Lead Name": formData.hiringLeadName,
          "Hiring Lead Email Address": formData.hiringLeadEmail,
          "Hiring Lead Linkedin": formData.hiringLeadLinkedin,
          "Hiring Lead Phone Number": formData.hiringLeadPhone,
          CCs: formData.additionalTeamEmails,
        },
        roleId,
      }
      if (formData.equity) {
        dataToUpdate.fields.Equity = formData.equity
      }
      await axios.patch("/api/roles/updateRoleData", dataToUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      console.log("Basic info updated successfully")
    } catch (error) {
      console.error("Error updating basic info:", error)
      throw error
    }
  }
  const handleProgressBarNavigation = async (targetStep, route) => {
    setIsLoading(true)
    try {
      // Save the current form data
      await sendRoleUpdate()

      if (targetStep === 2) {
        // Check for spotlights before navigating to step 2
        const hasSpotlights = await checkIfStartupHasSpotlights()
        navigate(`/create-role/${hasSpotlights ? "select-spotlight" : "spotlight"}?roleId=${roleId}`)
      } else {
        // For other steps, navigate directly
        navigate(route)
      }
    } catch (error) {
      console.error("Error during navigation:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const steps = [
    { step: 1, route: `/create-role/basic-info?roleId=${roleId}` },
    { step: 2, route: `/create-role/select-spotlight?roleId=${roleId}` },
    { step: 3, route: `/create-role/job-description?roleId=${roleId}` },
  ]

  const handleProgressClick = (step, route) => {
    // Allow navigation to step 1 and 2
    if (step <= 2) {
      handleProgressBarNavigation(step, route)
    }
  }


  const checkIfStartupHasSpotlights = async () => {
    try {
      const response = await axios.get("/api/spotlights/fetchSpotlights", {
        headers: { Authorization: `Bearer ${token}` },
      })
      return response.data.spotlights && response.data.spotlights.length > 0
    } catch (error) {
      console.error("Error checking spotlights:", error)
      return false
    }
  }

  const handleNext = async () => {
    // No "required-field" blocking
    setIsLoading(true)
    try {
      // Save the current form data
      await sendRoleUpdate()

      // Then check for spotlights and navigate
      const hasSpotlights = await checkIfStartupHasSpotlights()
      navigate(`/create-role/${hasSpotlights ? "select-spotlight" : "spotlight"}?roleId=${roleId}`)
    } catch (error) {
      console.error("Error:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveDraft = async () => {
    try {
      await sendRoleUpdate()
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: { "First saved": true },
          roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      navigate("/roles")
    } catch (error) {
      console.error("Error saving draft:", error)
    }
  }

  return (
    <div className="min-h-screen flex flex-col bg-white transition-all duration-300">
      {/* Header & progress bar */}
      <div className="w-full max-w-5xl mx-auto pt-4 pb-6">
        <h1 className="text-[28px] font-medium text-center mb-6">Create role</h1>
        <div className="flex gap-2 mb-6 px-6 cursor-pointer">
    {steps.map(({ step, route }) => (
      <div
        key={step}
        onClick={() => handleProgressClick(step, route)}
        className={`h-2 flex-1 rounded-full ${
          step <= 1 ? "bg-[#08f2c3]" : "bg-[#cbcbcb]"
        }`}
      />
    ))}
  </div>
      </div>

      {/* Main content */}
      <main className="flex-1 w-full max-w-5xl mx-auto px-6 pb-12">
        <div className="space-y-4">
          {/* Role title */}
          <div className="space-y-2">
            <label className="block text-[16px] text-[#000000]">
              Role title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Software Engineer"
              className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                         text-[#3c3c3c] placeholder:text-[#b4b4b4]
                         focus:outline-none focus:border-[#08f2c3]"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />
          </div>

          {/* Working style */}
          <div className="space-y-2">
            <label className="block text-[16px] text-[#000000]">
              Working style <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="(e.g.) Hybrid - 3 days a week in our WeWork office in Soho"
              className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                         text-[#3c3c3c] placeholder:text-[#b4b4b4]
                         focus:outline-none focus:border-[#08f2c3]"
              value={formData.workingStyle}
              onChange={(e) => setFormData({ ...formData, workingStyle: e.target.value })}
            />
          </div>

          {/* Compensation & Equity */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-[16px] text-[#000000]">
                Compensation <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="£35-40k DOE"
                className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                           text-[#3c3c3c] placeholder:text-[#b4b4b4]
                           focus:outline-none focus:border-[#08f2c3]"
                value={formData.compensation}
                onChange={(e) => setFormData({ ...formData, compensation: e.target.value })}
              />
            </div>

            {/* Radio buttons for Equity */}
            <div className="space-y-2">
              <div className="flex items-center">
                <label className="block text-[16px] text-[#000000] mr-2">
                  Are you open to offering equity? <span className="text-red-500">*</span>
                </label>

              </div>
              <div className="flex space-x-4 items-center mt-2">
                {["Yes", "No", "TBC"].map((option) => (
                  <label
                    key={option}
                    className={`flex items-center justify-center w-24 h-10 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.equity === option
                        ? "bg-[#08f2c3] text-black font-medium"
                        : "bg-white border border-[#cbcbcb] text-[#3c3c3c] hover:border-[#08f2c3]"
                    }`}
                  >
                    <input
                      type="radio"
                      name="equity"
                      value={option}
                      checked={formData.equity === option}
                      onChange={() => setFormData({ ...formData, equity: option })}
                      className="sr-only"
                    />
                    <span>{option}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* Hiring Lead section */}
          <div className="space-y-4">
            <h3 className="text-[20px] font-medium text-[#000000]">Hiring Lead</h3>
            <div className="grid grid-cols-2 gap-4">
              {/* Name */}
              <div className="space-y-2">
                <label className="block text-[16px] text-[#000000]">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Rachel"
                  className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                             text-[#3c3c3c] placeholder:text-[#b4b4b4]
                             focus:outline-none focus:border-[#08f2c3]"
                  value={formData.hiringLeadName}
                  onChange={(e) => setFormData({ ...formData, hiringLeadName: e.target.value })}
                />
              </div>

              {/* Email */}
              <div className="space-y-2">
                <label className="block text-[16px] text-[#000000]">
                  Email address <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  placeholder="rachel@example.com"
                  className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                             text-[#3c3c3c] placeholder:text-[#b4b4b4]
                             focus:outline-none focus:border-[#08f2c3]"
                  value={formData.hiringLeadEmail}
                  onChange={(e) => setFormData({ ...formData, hiringLeadEmail: e.target.value })}
                />
              </div>

              {/* Phone number */}
              <div className="space-y-2 relative">
        <label className="block text-[16px] text-[#000000]">
          Phone number <span className="text-red-500">*</span>
          <CustomTooltip content={
            <span>
              We'll only use this to manage your hiring processes. For details, see our{' '}
              <a 
                href="https://jumpstart-alumni.notion.site/Terms-for-Startup-Partners-127b8f57dd84802ca447cfd81a65ff90?pvs=74" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-[#08f2c3] hover:underline"
              >
                Privacy Policy
              </a>
              .
            </span>
          }>
            <Info className="w-4 h-4 text-gray-400 ml-1 inline-block" />
          </CustomTooltip>
        </label>
        <input
          type="tel"
          placeholder="+44 20 7123 4567"
          className="w-full p-2.5 rounded-lg border border-[#cbcbcb] text-[#3c3c3c] placeholder:text-[#b4b4b4] focus:outline-none focus:border-[#08f2c3]"
          value={formData.hiringLeadPhone}
          onChange={(e) => setFormData({ ...formData, hiringLeadPhone: e.target.value })}
        />
      </div>

              {/* LinkedIn */}
              <div className="space-y-2">
                <label className="block text-[16px] text-[#000000]">
                  LinkedIn <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="https://www.linkedin.com/rache-example"
                  className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                             text-[#3c3c3c] placeholder:text-[#b4b4b4]
                             focus:outline-none focus:border-[#08f2c3]"
                  value={formData.hiringLeadLinkedin}
                  onChange={(e) => setFormData({ ...formData, hiringLeadLinkedin: e.target.value })}
                />
              </div>

              {/* Additional Team Emails */}
              <div className="space-y-2 relative">
                <div className="flex justify-between items-center">
                  <label className="block text-[16px] text-[#000000]">
                    Additional hiring team emails (optional)
                    <span
                      className="ml-1 inline-block cursor-help"
                      title="List any colleagues you would like to include in email communications. 
Please separate multiple contacts with a comma."
                    >
                      <Info className="w-4 h-4 text-gray-400 inline-block" />
                    </span>
                  </label>
                  <button
  type="button"
  onClick={() => setShowAdditionalEmails(!showAdditionalEmails)}
  className="flex items-center justify-center w-8 h-8 rounded-full 
             hover:bg-gray-100 border border-gray-300 transition-colors"
>
  {showAdditionalEmails ? (
    <span className="text-xl font-medium text-gray-600 mb-0.5">−</span>
  ) : (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-gray-600"
    >
      <path
        d="M7 0V14M0 7H14"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )}
</button>
                </div>
                {showAdditionalEmails && (
                  <input
                    type="text"
                    placeholder="tom@gmail.com, martha@gmail.com"
                    className="w-full p-2.5 rounded-lg border border-[#cbcbcb]
                               text-[#3c3c3c] placeholder:text-[#b4b4b4]
                               focus:outline-none focus:border-[#08f2c3]"
                    value={formData.additionalTeamEmails}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        additionalTeamEmails: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Sticky footer nav */}
      <div className="sticky bottom-0 w-full bg-white border-t border-gray-200">
        <div className="w-full max-w-5xl mx-auto px-6 py-3 flex justify-between items-center">
          <button
            onClick={handleSaveDraft}
            className="px-6 py-2.5 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
          >
            Save draft
          </button>

          <button
            onClick={handleNext}
            disabled={isLoading}
            className="px-6 py-2.5 bg-[#08f2c3] text-black rounded-lg hover:bg-[#06c19c] 
              disabled:opacity-50 disabled:cursor-not-allowed transition-colors inline-flex items-center"
          >
            {isLoading ? (
              <span className="inline-flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-4 w-4 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving...
              </span>
            ) : (
              "Next"
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BasicInformation