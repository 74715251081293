import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import axios from "axios"
import { Calendar, Video, X, Check } from "lucide-react"

/* -----------------------------------------
   1) BookCallModal
----------------------------------------- */
const BookCallModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();  // Add this
  const [searchParams] = useSearchParams();  // Add this
  const [hasBooked, setHasBooked] = useState(false);  // Add this
  const roleId = searchParams.get("roleId") || localStorage.getItem("currentRoleId");  // Add this

  if (!isOpen) return null
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative">
        {!hasBooked ? (
          <>
            <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>

            <h2 className="text-lg font-semibold mb-4">Book a call with Jumpstart</h2>
            <p className="text-sm text-gray-600 mb-4">
              Schedule a guided recording session with our team. We'll help you create the perfect spotlight.
            </p>

            <div className="rounded-lg overflow-hidden" style={{ height: "500px" }}>
              <iframe
                src="https://calendly.com/d/cmhy-wb9-823/record-a-video-with-jumpstart"
                width="100%"
                height="100%"
                frameBorder="0"
              ></iframe>
            </div>

            <div className="flex justify-end mt-4">
              <button
                onClick={() => setHasBooked(true)}
                className="px-6 py-2 bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c]"
              >
                I've booked my call
              </button>
            </div>
          </>
        ) : (
          <div className="p-8 text-center">
            <div className="w-16 h-16 bg-[#08f2c3]/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Calendar className="w-8 h-8 text-[#08f2c3]" />
            </div>
            <h3 className="text-lg font-medium mb-2">Call booked!</h3>
            <p className="text-gray-600 mb-4">
              We'll help you record both your company and role spotlights during the call.
            </p>
            <button
              onClick={() => {
                onClose();
                localStorage.setItem("hasBookedCall", "true");
                navigate(`/create-role/job-description?roleId=${roleId}`, { state: { from: '/spotlight' } });
              }}
              className="px-6 py-2 bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c]"
            >
              Done
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

/* -----------------------------------------
   2) AddSpotlightModal with two tabs:
      "Company spotlight" / "Role spotlight"
      + Name, Loom URL, and Context fields
----------------------------------------- */
function AddSpotlightModal({ isOpen, onClose, onAddSpotlight, spotlightToEdit }) {
  // Define all required state
  const [activeTab, setActiveTab] = useState("company")
  const [companyCompleted, setCompanyCompleted] = useState(false)

  // Each tab has its own fields
  const [companyFields, setCompanyFields] = useState({
    name: "",
    loomUrl: "",
    context: "",
  })
  const [roleFields, setRoleFields] = useState({
    name: "",
    loomUrl: "",
    context: "",
  })

  // Reset form when opening/closing or editing
  useEffect(() => {
    if (spotlightToEdit) {
      if (spotlightToEdit.Type === "Role information") {
        setActiveTab("role")
        setRoleFields({
          name: spotlightToEdit.Name || "",
          loomUrl: spotlightToEdit["URL Loom cleaned"] || "",
          context: spotlightToEdit["Context"] || "",
        })
        setCompanyCompleted(true)
      } else {
        setActiveTab("company")
        setCompanyFields({
          name: spotlightToEdit.Name || "",
          loomUrl: spotlightToEdit["URL Loom cleaned"] || "",
          context: spotlightToEdit["Context"] || "",
        })
      }
    } else {
      setActiveTab("company")
      setCompanyFields({ name: "", loomUrl: "", context: "" })
      setRoleFields({ name: "", loomUrl: "", context: "" })
      setCompanyCompleted(false)
    }
  }, [spotlightToEdit])

  const handleUpload = async () => {
    const currentFields = activeTab === "company" ? companyFields : roleFields
    const token = localStorage.getItem("token")
    if (!token) {
      console.error("No token found; cannot create or update spotlight.")
      onClose()
      return
    }

    try {
      if (spotlightToEdit && spotlightToEdit.id) {
        // -- EDIT existing spotlight --
        const updatedData = {
          fields: {
            Name: currentFields.name.trim(),
            "URL Loom": currentFields.loomUrl.trim(),
          },
          spotlightId: spotlightToEdit.id,
        }

        const response = await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })

        const updatedSpotlight = response?.data?.updatedSpotlight?.fields
        if (updatedSpotlight) {
          const transformedSpotlight = {
            id: updatedSpotlight.id,
            Name: updatedSpotlight.Name,
            "URL Loom cleaned": updatedSpotlight["URL Loom cleaned"] || "",
            "URL Dropbox cleaned": updatedSpotlight["URL Dropbox cleaned"] || "",
            Type: updatedSpotlight.Type || "",
          }

          if (activeTab === "company") {
            localStorage.setItem("selectedCompanySpotlight", JSON.stringify(transformedSpotlight))
            setCompanyCompleted(true)
            setActiveTab("role")
          } else {
            localStorage.setItem("selectedRoleSpotlight", JSON.stringify(transformedSpotlight))
            onClose()
          }
          onAddSpotlight(transformedSpotlight)
        }
      } else {
        // -- CREATE new spotlight --
        const data = {
          fields: {
            Name: currentFields.name.trim(),
            "URL Loom": currentFields.loomUrl.trim(),
            Type: activeTab === "company" ? "Company information" : "Role information",
          },
        }

        const response = await axios.post("/api/spotlights/createSpotlight", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })

        const newSpotlight = response?.data?.newSpotlightData?.fields
        if (newSpotlight) {
          const transformedSpotlight = {
            id: newSpotlight.id,
            Name: newSpotlight.Name,
            "URL Loom cleaned": newSpotlight["URL Loom cleaned"] || "",
            "URL Dropbox cleaned": newSpotlight["URL Dropbox cleaned"] || "",
            Type: newSpotlight.Type || "",
          }

          if (activeTab === "company") {
            localStorage.setItem("selectedCompanySpotlight", JSON.stringify(transformedSpotlight))
            setCompanyCompleted(true)
            setActiveTab("role")
          } else {
            localStorage.setItem("selectedRoleSpotlight", JSON.stringify(transformedSpotlight))
            onClose()
          }
          onAddSpotlight(transformedSpotlight)
        }
      }
    } catch (err) {
      console.error("Error creating/updating spotlight:", err)
    }
  }
  if (!isOpen) return null

  const currentFields = activeTab === "company" ? companyFields : roleFields
  const setCurrentFields = activeTab === "company" ? setCompanyFields : setRoleFields
  const isCurrentTabValid = currentFields.name.trim() && currentFields.loomUrl.trim()

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
          <X className="w-5 h-5" />
        </button>

        <h2 className="text-xl font-semibold mb-4">{spotlightToEdit ? "Edit spotlight" : "Add new spotlights"}</h2>

        {/* Tabs with completion indicator */}
        <div className="flex mb-6 bg-gray-100 rounded-lg p-1">
          <button
            onClick={() => setActiveTab("company")}
            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors relative ${
              activeTab === "company" ? "bg-white shadow-sm" : "text-gray-600 hover:text-gray-900"
            }`}
          >
            Company spotlight
            {companyCompleted && (
              <span className="absolute right-2 top-1/2 -translate-y-1/2">
                <Check className="w-4 h-4 text-green-500" />
              </span>
            )}
          </button>
          <button
            onClick={() => setActiveTab("role")}
            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
              activeTab === "role" ? "bg-white shadow-sm" : "text-gray-600 hover:text-gray-900"
            }`}
            disabled={!companyCompleted}
          >
            Role spotlight
          </button>
        </div>

        {/* Fields */}
        <div className="space-y-3">
          <div>
            <label className="text-sm text-gray-600">Spotlight Name</label>
            <input
              type="text"
              value={currentFields.name}
              onChange={(e) => setCurrentFields({ ...currentFields, name: e.target.value })}
              placeholder={activeTab === "role" ? "Ex: 'Frontend Engineer Overview'" : "Ex: 'Acme Intro Pitch'"}
              className="w-full p-2 border rounded-md mt-1"
            />
          </div>
          <div>
            <label className="text-sm text-gray-600">Loom URL</label>
            <input
              type="url"
              value={currentFields.loomUrl}
              onChange={(e) => setCurrentFields({ ...currentFields, loomUrl: e.target.value })}
              placeholder="https://www.loom.com/share/..."
              className="w-full p-2 border rounded-md mt-1"
            />
          </div>
          <div>
            <label className="text-sm text-gray-600">Context (optional)</label>
            <textarea
              value={currentFields.context}
              onChange={(e) => setCurrentFields({ ...currentFields, context: e.target.value })}
              placeholder="Any extra notes about this video?"
              className="w-full p-2 border rounded-md mt-1"
            />
          </div>
        </div>

        {/* Upload button */}
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleUpload}
            disabled={!isCurrentTabValid}
            className={`px-6 py-2 rounded-full ${
              isCurrentTabValid
                ? "bg-[#08f2c3] text-black hover:bg-[#06c19c]"
                : "bg-gray-200 text-gray-500 cursor-not-allowed"
            }`}
          >
            {activeTab === "company" ? "Continue to Role Spotlight" : "Upload"}
          </button>
        </div>
      </div>
    </div>
  )
}

/* -----------------------------------------
   3) A small card to display each spotlight
----------------------------------------- */
function CompactSpotlightCard({ spotlight, selected, onSelect, onEdit }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const loomUrl = spotlight["URL Loom cleaned"]
  const dropboxUrl = spotlight["URL Dropbox cleaned"]

  let previewElement = null
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop()
    const loomEmbed = `https://www.loom.com/embed/${loomId}`
    previewElement = (
      <iframe title="Loom Preview" src={loomEmbed} frameBorder="0" allowFullScreen className="w-24 h-16" />
    )
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1")
    previewElement = <video src={directVideoUrl} className="w-24 h-16 object-cover" />
  } else {
    previewElement = (
      <div className="flex flex-col items-center justify-center bg-gray-100 w-24 h-16 text-center p-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-gray-400 mb-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={1.5}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 5.25v.45m0 13.05v.45m3-13.5v.45m0 13.05v.45m-6-14.4v.45m0 13.05v.45m-3-13.5v.45m0 13.05v.45M3.75 7.5h16.5v9H3.75z"
          />
        </svg>
        <p className="text-gray-500 text-xs">No video</p>
      </div>
    )
  }

  // Optional full preview
  const PreviewModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null

    let fullPreviewElement = null
    if (loomUrl) {
      const loomId = loomUrl.split("/").pop()
      const loomEmbed = `https://www.loom.com/embed/${loomId}`
      fullPreviewElement = (
        <iframe title="Loom Full Preview" src={loomEmbed} frameBorder="0" allowFullScreen className="w-full h-[60vh]" />
      )
    } else if (dropboxUrl) {
      const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1")
      fullPreviewElement = <video controls autoPlay src={directVideoUrl} className="w-full h-[60vh] object-contain" />
    } else {
      fullPreviewElement = (
        <div className="flex items-center justify-center w-full h-[60vh] bg-gray-100">
          <p className="text-sm text-gray-500">No video available</p>
        </div>
      )
    }

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-4 w-full max-w-4xl">
          <div className="mb-4">
            <h3 className="text-lg font-medium">{spotlight.Name}</h3>
          </div>
          {fullPreviewElement}
          <div className="mt-4 flex justify-end">
            <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-900">
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-sm">
      <div
        onClick={() => onSelect(spotlight)}
        className={`flex items-center gap-3 border p-2 cursor-pointer rounded-md hover:border-[#059488] ${
          selected ? "border-[#059488]" : "border-gray-300"
        }`}
      >
        <div className="relative group">
          {previewElement}
          <button
            onClick={(e) => {
              e.stopPropagation()
              setIsPreviewOpen(true)
            }}
            className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all"
          >
            <span className="text-white text-xs">Preview</span>
          </button>
        </div>
        <div className="flex-1">
          <p className="text-sm font-medium text-gray-700 truncate max-w-[200px]">{spotlight.Name || "Untitled"}</p>
          {selected && <p className="text-xs text-[#059488] mt-1">Selected</p>}
        </div>
      </div>

      <PreviewModal isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} />

      {/* “Edit” button if needed */}
      <button
        onClick={(e) => {
          e.stopPropagation()
          onEdit(spotlight)
        }}
        className="mt-1 px-2 py-1 border text-xs rounded-md bg-white hover:bg-gray-50"
      >
        Edit
      </button>
    </div>
  )
}

/* -----------------------------------------
   4) Main page:
----------------------------------------- */
export default function RecordSpotlight() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queryRoleId = searchParams.get("roleId") || localStorage.getItem("currentRoleId")

  const [showBookModal, setShowBookModal] = useState(false)
  const [showSpotlightModal, setShowSpotlightModal] = useState(false)
  const [spotlightToEdit, setSpotlightToEdit] = useState(null)
  const [allSpotlights, setAllSpotlights] = useState([])

  // Handle back/next
  const handleBack = () => {
    navigate(`/create-role/basic-info?roleId=${queryRoleId}`)
  }
  const handleNext = () => {
    navigate(`/create-role/job-description?roleId=${queryRoleId}`, { state: { from: '/spotlight' } });
  }

  const handleSkip = async () => {
    // Remove the hasBookedCall flag when skipping
    localStorage.removeItem("hasBookedCall")
  navigate(`/create-role/job-description?roleId=${queryRoleId}`, { state: { from: '/spotlight' } });
  }

  // Called when user successfully creates/edits a spotlight
  const onAddSpotlight = (updated) => {
    setAllSpotlights((prev) => {
      const idx = prev.findIndex((s) => s.id === updated.id)
      if (idx >= 0) {
        const arr = [...prev]
        arr[idx] = updated
        return arr
      } else {
        return [...prev, updated]
      }
    })
  }

  // If user clicks “Edit” on a card
  const handleSpotlightEdit = (spot) => {
    setSpotlightToEdit(spot)
    setShowSpotlightModal(true)
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <div className="w-full max-w-5xl mx-auto pt-6 pb-4">
        <h1 className="text-[32px] font-medium text-center mb-8">Create role</h1>
        <div className="flex gap-2 mb-8">
          {[1, 2, 3].map((step) => (
            <div key={step} className={`h-2 flex-1 rounded-full ${step <= 2 ? "bg-[#08f2c3]" : "bg-[#cbcbcb]"}`} />
          ))}
        </div>
      </div>

      {/* Main content */}
      <main className="flex-1 w-full max-w-5xl mx-auto px-6 overflow-y-auto">
        <div className="space-y-4 mb-4">
          <h2 className="text-[32px] font-medium">Record your spotlight!</h2>
          <p className="text-[16px] text-[#3c3c3c] mb-4">
            Roles with spotlight videos drive 50% more candidate engagement. It's the perfect opportunity to sell your
            mission and clearly define who you're looking for.
          </p>
          <h3 className="text-[20px] font-medium mb-2">How to record:</h3>
          <p className="text-[16px] text-[#3c3c3c] mb-4">
            We ask startups to record two short videos, one showcasing your company (which can be reused for other
            roles) and one for each role. Don't worry - it's really quick and easy to do!
          </p>
        </div>

        {/* Record options */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <button
            onClick={() => setShowBookModal(true)}
            className="group flex flex-col h-full p-4 rounded-lg border border-[#cbcbcb] hover:border-[#08f2c3] transition-colors text-left relative"
          >
            <span className="absolute top-4 right-4 bg-[#08f2c3] text-black text-xs font-bold px-2 py-1 rounded">
              Recommended
            </span>
            <div className="mb-4">
              <Calendar className="w-6 h-6 text-[#08f2c3]" />
            </div>
            <h4 className="text-[20px] font-medium mb-3">Record with Jumpstart</h4>
            <ul className="flex-grow text-[16px] text-[#3c3c3c] space-y-2 mb-4">
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Record 2 in 1! We'll tackle both the company and role video in one short call</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Interactive session makes your videos more engaging</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Low effort: Just show up with a few slides, and we'll guide you</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Quick results with minimal prep needed</span>
              </li>
            </ul>
            <span className="text-[#08f2c3] text-[16px] group-hover:underline inline-flex items-center">
              Book now <span className="ml-1">→</span>
            </span>
          </button>

          <button
  onClick={() => {
    navigate(`/create-role/select-spotlight?roleId=${queryRoleId}&fromRecordSpotlight=true`)
  }}
  className="group flex flex-col h-full p-4 rounded-lg border border-[#cbcbcb] hover:border-[#08f2c3] transition-colors text-left relative"
>
            <div className="mb-4">
              <Video className="w-6 h-6 text-[#08f2c3]" />
            </div>
            <h4 className="text-[20px] font-medium mb-3">Record independently</h4>
            <ul className="flex-grow text-[16px] text-[#3c3c3c] space-y-2 mb-4">
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Flexible timing to record anytime</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Easy edits for a polished result</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Authentic voice to showcase your culture</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Record at your own pace</span>
              </li>
            </ul>
            <span className="text-[#08f2c3] text-[16px] group-hover:underline inline-flex items-center">
              Record with Loom <span className="ml-1">→</span>
            </span>
          </button>
        </div>

        {/* Display any uploaded spotlights */}
        {allSpotlights.length > 0 && (
          <div className="mt-10">
            <h3 className="text-lg font-medium mb-2">Your uploaded spotlights</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {allSpotlights.map((spotlight) => (
                <div key={spotlight.id} className="border rounded-lg overflow-hidden bg-white">
                  <div className="h-32">
                    {spotlight["URL Loom cleaned"] ? (
                      <iframe
                        title={spotlight.Name}
                        src={`https://www.loom.com/embed/${spotlight["URL Loom cleaned"].split("/").pop()}`}
                        frameBorder="0"
                        allowFullScreen
                        className="w-full h-full"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-50 flex items-center justify-center">
                        <p className="text-sm text-gray-400">No preview available</p>
                      </div>
                    )}
                  </div>
                  <div className="p-3">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm font-medium">{spotlight.Name}</p>
                        <p className="text-xs text-gray-500">
                          {spotlight.Type === "Company information" ? "Company" : "Role"} spotlight
                        </p>
                      </div>
                      <button
                        onClick={() => handleSpotlightEdit(spotlight)}
                        className="text-xs px-2 py-1 border rounded hover:bg-gray-50"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
      {/* Sticky footer */}
      <div className="sticky bottom-0 w-full bg-white border-t border-gray-200 mt-4 pt-2">
        <div className="w-full max-w-5xl mx-auto px-6 py-3 flex justify-between items-center">
          <button
            onClick={() => navigate(`/create-role/basic-info?roleId=${queryRoleId}`)}
            className="px-6 py-2 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-50"
          >
            Back
          </button>
          <div className="flex gap-3">
            <button onClick={handleSkip} className="px-6 py-2 text-gray-500 rounded-full hover:text-gray-700">
              Skip for now
            </button>
            <button onClick={handleNext} className="px-6 py-2 bg-[#08f2c3] text-black rounded-full hover:bg-[#06c19c]">
              Next
            </button>
          </div>
        </div>
      </div>
      {/* BookCallModal */}
      <BookCallModal isOpen={showBookModal} onClose={() => setShowBookModal(false)} />

      {/* AddSpotlightModal */}
      <AddSpotlightModal
        isOpen={showSpotlightModal}
        onClose={() => {
          setShowSpotlightModal(false)
          setSpotlightToEdit(null)
        }}
        onAddSpotlight={onAddSpotlight}
        spotlightToEdit={spotlightToEdit}
      />
    </div>
  )
}

