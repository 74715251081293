import React, { useState, useEffect, useCallback, useRef } from "react"
import { useNavigate, useSearchParams, useLocation } from "react-router-dom"
import axios from "axios"
import { Upload, Link, X } from "lucide-react"

// A simple overlay for drag & drop feedback
const DragOverlay = ({ isVisible }) => (
  <div
    className={`fixed inset-0 bg-[#08f2c3]/10 pointer-events-none border-2 border-[#08f2c3] z-50
      transition-opacity duration-200 flex items-center justify-center
      ${isVisible ? "opacity-100" : "opacity-0"}`}
  >
    <div className={`transform transition-transform duration-200 ${isVisible ? "scale-100" : "scale-95"}`}>
      <div className="bg-white/90 rounded-lg shadow-lg p-6 text-center">
        <p className="text-xl font-medium text-gray-800">Drop your file here</p>
        <p className="text-sm text-gray-600 mt-2">Release to upload</p>
      </div>
    </div>
  </div>
)

const DragAndDropZone = ({ onFileSelect, isUploading, url, onClear }) => {
  const fileInputRef = useRef(null)
  const [isHovering, setIsHovering] = useState(false)

  const handleDragOver = useCallback((e) => {
    e.preventDefault()
    setIsHovering(true)
  }, [])

  const handleDragLeave = useCallback((e) => {
    e.preventDefault()
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsHovering(false)
    }
  }, [])

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      setIsHovering(false)
      if (e.dataTransfer.files?.length > 0) {
        onFileSelect(e.dataTransfer.files[0])
      }
    },
    [onFileSelect],
  )

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileInput = (e) => {
    if (e.target.files?.length > 0) {
      onFileSelect(e.target.files[0])
    }
  }


  return (
    <div
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`relative border-2 border-dashed rounded-xl transition-all duration-200 cursor-pointer flex items-center justify-center
        ${isHovering ? "border-[#08f2c3] bg-[#08f2c3]/10" : "border-[#08f2c3] hover:bg-gray-50"}
        ${!url ? "min-h-[180px]" : "min-h-[80px]"}`}
    >
      <input ref={fileInputRef} type="file" accept=".pdf,.docx" onChange={handleFileInput} className="hidden" />
      {isUploading ? (
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-[#08f2c3]/10">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#08f2c3]" />
          </div>
        </div>
      ) : url ? (
        <div className="flex items-center space-x-3 w-full max-w-md p-4">
          <div className="bg-[#08f2c3]/10 rounded-lg p-3">
            <Upload size={24} className="text-[#08f2c3]" />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-700 truncate">{url.split("/").pop()}</p>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation()
              onClear?.()
            }}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X size={20} className="text-gray-400" />
          </button>
        </div>
      ) : (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full">
          <p className="text-xl font-bold text-gray-700 mb-2" style={{ fontFamily: "Poppins, sans-serif" }}>
            Upload your job description
          </p>
          <p className="text-sm text-gray-400">PDF, DOCX (max. 10MB)</p>
        </div>
      )}
    </div>
  )
}

const JobDescription = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [token, setToken] = useState("")
  const [roleId, setRoleId] = useState("")
  const [jobDescriptionUrl, setJobDescriptionUrl] = useState("")
  const [isUploading, setIsUploading] = useState(false)
  const [keySkills, setKeySkills] = useState("")
  const [interviewProcess, setInterviewProcess] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [showInterviewLink, setShowInterviewLink] = useState(false)
  const [interviewLink, setInterviewLink] = useState("")

  const [isDraggingFile, setIsDraggingFile] = useState(false)

  const queryRoleId = searchParams.get("roleId") || localStorage.getItem("currentRoleId")

  // Clear handler for onClear
  const handleClearFile = () => {
    setJobDescriptionUrl("")
  }

  useEffect(() => {
    const handleDragEnter = (e) => {
      e.preventDefault()
      if (e.dataTransfer.types.includes("Files")) {
        setIsDraggingFile(true)
      }
    }
    const handleDragLeave = (e) => {
      e.preventDefault()
      if (!e.relatedTarget) {
        setIsDraggingFile(false)
      }
    }
    const handleDrop = (e) => {
      e.preventDefault()
      setIsDraggingFile(false)
    }

    window.addEventListener("dragenter", handleDragEnter)
    window.addEventListener("dragleave", handleDragLeave)
    window.addEventListener("drop", handleDrop)
    window.addEventListener("dragover", (e) => e.preventDefault())

    return () => {
      window.removeEventListener("dragenter", handleDragEnter)
      window.removeEventListener("dragleave", handleDragLeave)
      window.removeEventListener("drop", handleDrop)
      window.removeEventListener("dragover", (e) => e.preventDefault())
    }
  }, [])
  const location = useLocation()

  // Store the previous path when component mounts
  useEffect(() => {
    const previousPath = location.state?.from || ""
    localStorage.setItem("previousPath", previousPath)
  }, [location])

  // Rest of the component remains the same until the navigation handlers

  const handleBack = () => {
    const previousPath = localStorage.getItem("previousPath")
    if (previousPath === "/spotlight") {
      navigate(`/create-role/spotlight?roleId=${roleId}`)
    } else {
      navigate(`/create-role/select-spotlight?roleId=${roleId}`)
    }
  }
  useEffect(() => {
    const storedToken = localStorage.getItem("token") || ""
    setToken(storedToken)
    setRoleId(queryRoleId || "")

    if (storedToken && queryRoleId) {
      localStorage.setItem("currentRoleId", queryRoleId)
      fetchRoleData(queryRoleId, storedToken)
    }
  }, [queryRoleId])

  const fetchRoleData = async (id, authToken) => {
    try {
      const { data } = await axios.get("/api/roles/fetchRoleById", {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { id },
      })
      const fields = data.role.fields
      setJobDescriptionUrl(fields["Job Description PDF"] || "")
      setKeySkills(fields["Specific Experience"] || "")
      setInterviewProcess(fields["Interview Process"] || "")
      setInterviewLink(fields["Hiring Lead Calendly Link"] || "")
      if (fields["Hiring Lead Calendly Link"]) {
        setShowInterviewLink(true)
      }
    } catch (error) {
      console.error("Error fetching role data:", error)
    }
  }

  const handleFileUpload = async (file) => {
    if (!file) return
    setIsUploading(true)

    try {
      const {
        data: { signature, timestamp },
      } = await axios.get("/api/cloudinarySignature", {
        params: { upload_preset: "ml_default" },
      })

      const formData = new FormData()
      formData.append("file", file)
      formData.append("upload_preset", "ml_default")
      formData.append("timestamp", timestamp)
      formData.append("api_key", "815772339983468")
      formData.append("signature", signature)
      formData.append("folder", "JobDescription")

      const response = await axios.post("https://api.cloudinary.com/v1_1/dkjsnpplv/raw/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      setJobDescriptionUrl(response.data.secure_url)
    } catch (error) {
      console.error("Upload error:", error)
    } finally {
      setIsUploading(false)
    }
  }

  const updateRoleData = async () => {
    if (!roleId || !token) return
    try {
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: {
            "Job Description PDF": jobDescriptionUrl,
            "Specific Experience": keySkills,
            "Interview Process": interviewProcess,
            "Hiring Lead Calendly Link": showInterviewLink ? interviewLink : "",
          },
          roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
    } catch (error) {
      console.error("Error updating role data:", error)
    }
  }

  const handleSkip = () => {
    navigate(`/create-role/review?roleId=${roleId}`)
  }

  const handleSaveAndContinue = async () => {
    setIsLoading(true)
    try {
      await updateRoleData()
      navigate(`/create-role/review?roleId=${roleId}`)
    } catch (error) {
      console.error("Error:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveDraft = async () => {
    try {
      await updateRoleData()
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: { "First saved": true },
          roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      navigate("/roles")
    } catch (error) {
      console.error("Error saving draft:", error)
    }
  }

  const steps = [
    { step: 1, route: `/create-role/basic-info?roleId=${roleId}` },
    { step: 2, route: `/create-role/select-spotlight?roleId=${roleId}` },
    { step: 3, route: `/create-role/job-description?roleId=${roleId}` },
  ]

  const handleProgressClick = (route) => {
    navigate(route)
  }

  return (
    <div className="h-screen flex flex-col bg-white">
      <DragOverlay isVisible={isDraggingFile} />

      {/* Header */}
      <div className="pt-4 pb-2 flex-shrink-0">
        <div className="w-full max-w-5xl mx-auto relative px-6">
          <h1 className="text-[32px] font-medium text-center">Create role</h1>
          <button
            onClick={handleSaveDraft}
            className="px-6 py-2 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-50 transition-colors absolute right-6 top-1/2 -translate-y-1/2"
          >
            Save draft
          </button>
        </div>
        {/* Progress bar */}
        <div className="w-full max-w-5xl mx-auto mt-4 px-6">
          <div className="flex gap-2 cursor-pointer">
            {steps.map(({ step, route }) => (
              <div
                key={step}
                onClick={() => handleProgressClick(route)}
                className={`h-2 flex-1 rounded-full ${step <= 3 ? "bg-[#08f2c3]" : "bg-[#cbcbcb]"}`}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-1 flex items-center justify-center">
        <div className="w-full max-w-4xl px-6">
          <section className="space-y-6">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-2xl font-medium">Job Description</h2>
              <a
                href="https://jumpstart-alumni.notion.site/Writing-a-Job-Description-33504887841241d0a52f5a2a9e23e245"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm font-medium text-black hover:text-gray-600 underline underline-offset-4"
              >
                View templates &amp; guidance
              </a>
            </div>
            <div className="space-y-2">
              {/* Pass onClear to fix the error */}
              <DragAndDropZone
                onFileSelect={handleFileUpload}
                isUploading={isUploading}
                url={jobDescriptionUrl}
                onClear={handleClearFile}
              />
              <div className="relative mt-4">
                <input
                  type="text"
                  placeholder="Or share a link to your job description"
                  className="w-full p-4 pl-11 rounded-xl border border-gray-200 text-sm text-gray-700 
                    placeholder-gray-400 focus:outline-none focus:border-[#08f2c3] focus:ring-1 focus:ring-[#08f2c3]
                    hover:border-gray-300 transition-colors"
                  value={jobDescriptionUrl}
                  onChange={(e) => setJobDescriptionUrl(e.target.value)}
                />
                <Link className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>
            </div>
            {/* Key Skills and Interview Process side by side */}
            <div className="flex gap-4">
              {/* Key Skills */}
              <div className="flex-1">
                <div className="flex items-center gap-2 mb-1">
                  <h3 className="text-lg font-medium text-gray-700">Key Skills</h3>
                  <span className="text-sm text-gray-400">(Optional)</span>
                </div>
                <textarea
                  value={keySkills}
                  onChange={(e) => setKeySkills(e.target.value)}
                  placeholder="Example: 5+ years with React.js, AWS knowledge, strong communication skills, experience with Agile methodologies, etc."
                  className="w-full p-2.5 rounded-lg border border-gray-200 text-sm text-gray-700 
                    placeholder-gray-400 focus:outline-none focus:border-[#08f2c3] focus:ring-1 focus:ring-[#08f2c3]"
                  rows={3}
                />
              </div>
              {/* Interview Process */}
              <div className="flex-1">
                <div className="flex items-center gap-2 mb-1">
                  <h3 className="text-lg font-medium text-gray-700">Interview Process</h3>
                  <span className="text-sm text-gray-400">(Optional)</span>
                </div>
                <textarea
                  value={interviewProcess}
                  onChange={(e) => setInterviewProcess(e.target.value)}
                  placeholder="Example: 30-min intro call → Technical assessment → Team interview → Final chat with CEO"
                  className="w-full p-2.5 rounded-lg border border-gray-200 text-sm text-gray-700 
                    placeholder-gray-400 focus:outline-none focus:border-[#08f2c3] focus:ring-1 focus:ring-[#08f2c3]"
                  rows={3}
                />
              </div>
            </div>
            {/* Interview Link (collapsible) */}
            <div className="mt-3">
              {!showInterviewLink && (
                <button
                  type="button"
                  onClick={() => setShowInterviewLink(true)}
                  className="text-sm text-black hover:underline flex items-center"
                >
                  <Link size={16} className="mr-1" />
                  Add interview scheduling link
                </button>
              )}
              {showInterviewLink && (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Interview Scheduling Link (e.g., Calendly)
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={interviewLink}
                      onChange={(e) => setInterviewLink(e.target.value)}
                      placeholder="https://calendly.com/your-link"
                      className="w-full p-3 pl-10 rounded-lg border border-gray-200 text-sm text-gray-700 
                        placeholder-gray-400 focus:outline-none focus:border-[#08f2c3] focus:ring-1 focus:ring-[#08f2c3]"
                    />
                    <Link className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setShowInterviewLink(false)
                      setInterviewLink("")
                    }}
                    className="text-xs text-gray-500 hover:underline flex items-center"
                  >
                    <X size={12} className="mr-1" />
                    Remove link
                  </button>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      {/* Sticky footer */}
      <div className="sticky bottom-0 w-full bg-white border-t border-gray-200 mt-4 pt-2">
        <div className="w-full max-w-5xl mx-auto px-6 py-3 flex justify-between items-center">
          <button
            onClick={handleBack}
            className="px-6 py-2.5 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
          >
            Back
          </button>
          <div className="flex gap-4">
            <button 
              onClick={handleSkip} 
              className="px-6 py-2.5 text-gray-500 hover:text-gray-700 transition-colors"
            >
              Skip for now
            </button>
            <button
              onClick={handleSaveAndContinue}
              disabled={isLoading}
              className="px-6 py-2.5 bg-[#08f2c3] text-black rounded-lg hover:bg-[#06c19c] 
                disabled:opacity-50 disabled:cursor-not-allowed transition-colors inline-flex items-center"
            >
              {isLoading ? (
                <span className="inline-flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-4 w-4 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </span>
              ) : (
                <>
                  Save & Continue <span className="ml-1">→</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDescription
