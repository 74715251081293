import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';

function CompactSpotlightCard({ spotlight, selected, onSelect, onDelete }) {
  const loomUrl = spotlight["URL Loom cleaned"];
  const dropboxUrl = spotlight["URL Dropbox cleaned"];

  let previewElement = null;
  if (loomUrl) {
    const loomId = loomUrl.split("/").pop();
    const loomEmbed = `https://www.loom.com/embed/${loomId}`;
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg overflow-hidden">
        <iframe
          title="Loom Preview"
          src={loomEmbed}
          frameBorder="0"
          allowFullScreen
          className="w-full h-full"
          sandbox="allow-same-origin allow-scripts allow-popups"
        />
      </div>
    );
  } else if (dropboxUrl) {
    const directVideoUrl = dropboxUrl.replace("?dl=0", "?raw=1");
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg overflow-hidden">
        <video src={directVideoUrl} className="w-full h-full object-cover" />
      </div>
    );
  } else {
    previewElement = (
      <div className="aspect-video w-full bg-gray-50 rounded-t-lg flex items-center justify-center">
        <div className="flex flex-col items-center justify-center text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 mb-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
            />
          </svg>
          <p className="text-sm">No preview</p>
        </div>
      </div>
    );
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete();
  };

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(spotlight);
  };

  return (
    <div
      onClick={handleSelect}
      className={`group bg-white rounded-lg transition-all duration-200 overflow-hidden cursor-pointer
        ${
          selected
            ? "ring-2 ring-[#02B491] shadow-md"
            : "border border-gray-200 hover:border-gray-300 hover:shadow-sm"
        }`}
    >
      <div className="cursor-pointer">{previewElement}</div>
      <div className="p-4 flex items-center justify-between border-t border-gray-100">
        <div className="flex-grow">
          <p className="text-sm font-medium text-gray-700 truncate">
            {spotlight.Name || "Untitled"}
          </p>
          {selected && <p className="text-xs text-[#02B491] mt-1">Selected</p>}
        </div>
        <button
          onClick={handleDelete}
          className="p-1.5 rounded-full hover:bg-gray-100 transition-all duration-200"
        >
          <Trash2 className="w-4 h-4 text-red-500" />
        </button>
      </div>
    </div>
  );
}

export default CompactSpotlightCard; 