import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BookWithJumpstart = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleBack = () => {
    navigate('/create-role/spotlight');
  };

  const handleNext = () => {
    navigate('/create-role/job-description');
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <div className="w-full max-w-5xl mx-auto pt-6 pb-8">
        <h1 className="text-[32px] font-medium text-center mb-12">Create role</h1>

        {/* Progress Steps */}
        <div className="flex gap-2 mb-12">
          {[1, 2, 3, 4].map((step) => (
            <div
              key={step}
              className={`h-2 flex-1 rounded-full ${
                step <= 2 ? 'bg-[#08f2c3]' : 'bg-[#cbcbcb]'
              }`}
            />
          ))}
        </div>
      </div>

      {/* Main Content */}
      <main className="w-full max-w-5xl mx-auto px-6">
        <div className="grid grid-cols-12 gap-8">
          {/* Left Column */}
          <div className="col-span-4">
            <h2 className="text-[40px] font-medium mb-6">Record with Jumpstart!</h2>
            <p className="text-[16px] text-[#3c3c3c]">
              What do you want them to know before the call?
            </p>
          </div>

          {/* Right Column - Calendly Widget */}
          <div className="col-span-8">
            <div className="rounded-lg border border-[#cbcbcb] overflow-hidden bg-white">
              <div 
                className="calendly-inline-widget" 
                data-url="https://calendly.com/d/cmhy-wb9-823/record-a-video-with-jumpstart"
                style={{ minWidth: '320px', height: '600px' }}
              />
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-8">
          <button
            onClick={handleBack}
            className="px-6 py-2 bg-[#f1f1f1] text-[#000000] rounded-full text-[16px]"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            className="px-12 py-3 bg-[#08f2c3] text-[#000000] rounded-full text-[16px] font-medium"
          >
            Next
          </button>
        </div>
      </main>
    </div>
  );
};

export default BookWithJumpstart;

