import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const ApplicationsFeedback = () => {
  const feedbackRef = useRef(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [customFeedback, setCustomFeedback] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [feedbackStep, setFeedbackStep] = useState('initial');
  const [feedbackId, setFeedbackId] = useState(null);

  // Local state for controlling the "Other" text box in negative flow
  const [showNegativeOther, setShowNegativeOther] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (feedbackRef.current && !feedbackRef.current.contains(event.target)) {
        showSuccessAndReset();
      }
    };

    if (showFeedback) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFeedback]);

  // Map the step to one of Airtable’s possible categories
  const getFeedbackCategory = (step) => {
    if (step === 'positive') return 'Positive';
    if (step === 'improve') return 'Improve';
    if (step === 'negative') return 'Negative';
    return 'General';
  };

  // Create or update feedback in Airtable
  const handleFeedback = async (feedback, isUpdate = false, forcedCategory = null) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authorization token found in localStorage!');
        return;
      }

      // 1) Category is either forced or derived from step
      const category = forcedCategory ?? getFeedbackCategory(feedbackStep);

      let fields = {
        'Feedback Category': category,
      };

      // If it's "custom" feedback or typed text on positive step => "Feedback Text"
      if (feedback === 'custom' || (category === 'Positive' && customFeedback)) {
        fields['Feedback Text'] = customFeedback;
      }
      // Otherwise store quick-click in "Feedback Option"
      else if (feedback !== 'positive') {
        fields['Feedback Option'] = feedback;
      }

      // Add "Submitted At" for new records
      if (!isUpdate) {
        fields['Submitted At'] = new Date().toISOString();
      }

      // Update existing record or create new one
      if (isUpdate && feedbackId) {
        await axios.patch(
          `/api/feedback/updateFeedback?id=${feedbackId}`,
          { fields },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        showSuccessAndReset();
      } else {
        const response = await axios.post(
          '/api/feedback/createFeedback',
          { fields },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.recordId) {
          setFeedbackId(response.data.recordId);
          // Keep box open if it's positive
          if (feedback === 'positive') {
            showSuccessAndReset(false);
          } else {
            showSuccessAndReset();
          }
        }
      }

      setError(null);
    } catch (err) {
      console.error('Error submitting feedback:', err);
      setError('Failed to submit feedback. Please try again.');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Show success for 3s, optionally reset & close
  const showSuccessAndReset = (shouldReset = true) => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);

    if (shouldReset) {
      setShowFeedback(false);
      setCustomFeedback('');
      setFeedbackStep('initial');
      setFeedbackId(null);
      setShowNegativeOther(false); // reset the "Other" sub-step
    }
  };

  // Handle initial choice among "positive", "improve", "negative"
  const handleInitialChoice = async (choice) => {
    if (choice === 'positive') {
      setFeedbackStep('positive');
      try {
        await handleFeedback('positive', false, 'Positive');
      } catch (error) {
        setFeedbackStep('initial');
      }
    } else if (choice === 'improve') {
      setFeedbackStep('improve');
    } else if (choice === 'negative') {
      setFeedbackStep('negative');
    } else {
      handleFeedback(choice);
    }
  };

  // "Close" or "Skip"
  const resetFeedback = () => {
    showSuccessAndReset();
  };

  // When opening, hide success message
  const openFeedback = () => {
    setShowSuccess(false);
    setShowFeedback(true);
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      {error && (
        <div className="bg-white p-4 rounded-lg shadow-lg border border-red-200 mb-4 text-red-600">
          {error}
        </div>
      )}
      
      {!showFeedback && (
        <button
          onClick={openFeedback}
          className="bg-white hover:bg-gray-50 text-gray-800 font-semibold py-3 px-6 border-2 border-[#02B491] rounded-lg shadow-lg transition-all duration-200 hover:scale-105 hover:shadow-xl"
        >
         Share feedback
        </button>
      )}

      {showFeedback && (
        <div ref={feedbackRef} className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-72">
          {showSuccess && (
            <div className="bg-green-50 border border-green-200 text-green-600 p-2 rounded-md mb-3">
              Thanks for your feedback!
            </div>
          )}

          <div className="text-gray-700 mb-3 font-medium">
            {feedbackStep === 'initial' && 'Are you happy with the number and quality of your applicants?'}
            {feedbackStep === 'positive' && 'Would you like to add any specific comments about what worked well?'}
            {feedbackStep === 'improve' && 'How could we improve the applicants?'}
            {feedbackStep === 'negative' && 'What was the main issue?'}
          </div>

          <div className="flex flex-col space-y-2">
            {/* STEP: INITIAL */}
            {feedbackStep === 'initial' && (
              <>
                <button
                  onClick={() => handleInitialChoice('positive')}
                  className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200 text-green-700"
                >
                  Yes! Very happy
                </button>
                <button
                  onClick={() => handleInitialChoice('improve')}
                  className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200 text-amber-600"
                >
                  They could be improved
                </button>
                <button
                  onClick={() => handleInitialChoice('negative')}
                  className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200 text-red-700"
                >
                  I'm a bit disappointed
                </button>
              </>
            )}

            {/* STEP: IMPROVE */}
            {feedbackStep === 'improve' && (
              <>
                <div className="space-y-2">
                  <textarea
                    placeholder="e.g. 'I'd like to see more candidates with relevant experience'"
                    className="w-full p-2 border border-gray-200 rounded-md text-sm"
                    value={customFeedback}
                    onChange={(e) => setCustomFeedback(e.target.value)}
                    rows={3}
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setFeedbackStep('initial')}
                      className="px-3 py-2 text-gray-600 hover:text-gray-800"
                    >
                      ← Back
                    </button>
                    <button
                      onClick={() => handleFeedback('custom')}
                      className="flex-1 bg-[#02B491] text-white py-2 rounded-md hover:bg-[#02B491]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!customFeedback.trim()}
                    >
                      Submit feedback
                    </button>
                  </div>
                </div>
              </>
            )}

            {/* STEP: POSITIVE */}
            {feedbackStep === 'positive' && (
              <>
                <div className="space-y-2">
                  <textarea
                    placeholder="Optional: Tell us what worked well for you..."
                    className="w-full p-2 border border-gray-200 rounded-md text-sm"
                    value={customFeedback}
                    onChange={(e) => setCustomFeedback(e.target.value)}
                    rows={3}
                  />
                  <div className="flex space-x-2">
                    {/* "Skip" closes without updating, so it remains "Positive" */}
                    <button
                      onClick={resetFeedback}
                      className="px-3 py-2 text-gray-600 hover:text-gray-800"
                    >
                      Skip
                    </button>
                    <button
                      onClick={() => handleFeedback('positive', true, 'Positive')}
                      className="flex-1 bg-[#02B491] text-white py-2 rounded-md hover:bg-[#02B491]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!customFeedback.trim()}
                    >
                      Add comment
                    </button>
                  </div>
                </div>
              </>
            )}

            {/* STEP: NEGATIVE */}
            {feedbackStep === 'negative' && (
              <>
                {/* If user hasn't clicked "Other" yet */}
                {!showNegativeOther && (
                  <>
                    <button
                      onClick={() => handleFeedback('Not Relevant')}
                      className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200"
                    >
                      Applicants are missing specific role requirements
                    </button>
                    <button
                      onClick={() => handleFeedback('Low Quality')}
                      className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200"
                    >
                      Overall candidate quality is lower than expected
                    </button>
                    <button
                      onClick={() => handleFeedback('Not Enough')}
                      className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200"
                    >
                      Not enough applicants to choose between
                    </button>

                    {/* The "Other" button toggles the text box */}
                    <button
                      onClick={() => setShowNegativeOther(true)}
                      className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200"
                    >
                      Other
                    </button>

                    <button
                      onClick={() => {
                        setShowNegativeOther(false);
                        setFeedbackStep('initial');
                      }}
                      className="text-left px-3 py-2 hover:bg-gray-50 rounded-md transition-colors border border-gray-200 text-gray-600"
                    >
                      ← Back
                    </button>
                  </>
                )}

                {/* If "Other" is clicked, show the free text area */}
                {showNegativeOther && (
                  <div className="border-t border-gray-200 pt-2 mt-2">
                    <textarea
                      placeholder="Tell us more about what you were looking for..."
                      className="w-full p-2 border border-gray-200 rounded-md text-sm"
                      value={customFeedback}
                      onChange={(e) => setCustomFeedback(e.target.value)}
                      rows={3}
                    />
                    <button
                      onClick={() => handleFeedback('custom')}
                      className="mt-2 w-full bg-[#02B491] text-white py-2 rounded-md hover:bg-[#02B491]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!customFeedback.trim()}
                    >
                      Submit feedback
                    </button>
                  </div>
                )}
              </>
            )}
          </div>

          {/* Close button for all steps except "positive" */}
          {feedbackStep !== 'positive' && (
            <button
              onClick={resetFeedback}
              className="mt-3 text-sm text-gray-500 hover:text-gray-700"
            >
              Close
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ApplicationsFeedback;
