import React from 'react';

const LogoLoader = ({ }) => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center">
      <div className="relative w-16 h-16">
        {/* Primary spinner */}
        <svg 
          className="animate-spin"
          viewBox="0 0 50 50"
        >
          <circle
            className="stroke-[#08f2c3]"
            strokeWidth="4"
            stroke="currentColor"
            fill="none"
            r="20"
            cx="25"
            cy="25"
            strokeDasharray="80"
            strokeDashoffset="20"
          />
        </svg>
        
        {/* Secondary spinner (rotates opposite direction) */}
        <svg 
          className="absolute inset-0 animate-spin-reverse"
          viewBox="0 0 50 50"
        >
          <circle
            className="stroke-black"
            strokeWidth="4"
            stroke="currentColor"
            fill="none"
            r="20"
            cx="25"
            cy="25"
            strokeDasharray="80"
            strokeDashoffset="60"
            opacity="0.2"
          />
        </svg>
      </div>
      
      <style jsx>{`
        @keyframes spin-reverse {
          from {
            transform: rotate(360deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
        
        .animate-spin {
          animation: spin 1.5s linear infinite;
        }
        
        .animate-spin-reverse {
          animation: spin-reverse 2s linear infinite;
        }
      `}</style>
      
      <p className="mt-4 text-gray-500 text-sm font-medium"></p>
    </div>
  );
};

export default LogoLoader;