import React, { useEffect, useState, useCallback, useRef } from "react";
import linkedin from "../../images/linkedin-logo.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import Loading from "../../images/loading-black.svg";
import { useNavigate } from "react-router-dom";
import Office from "../../images/office-icon.png";
import Languages from "../../images/languages-icon.png";
import save from "../../images/save-icon.png";
import { useShortlisted } from "../../provider/ShortlistedContext";
import LoadingWhite from "../../images/loading-white.svg";
import LoadingBlack from "../../images/loading-black.svg";
import VideoIntro from "../../images/video-icon-green.png";

/**
 * Returns a custom sponsorship/visa message depending on the "RTW doc" value.
 */
function getVisaMessage(visaStatus, fullName = "") {
  const firstName = fullName.split(" ")[0]; // e.g. "Jane Doe" -> "Jane"
  const jumpstartEmail = "daniel@jumpstart-uk.com";
  const contactTeamMsg = `Contact the <a href="mailto:${jumpstartEmail}" class="underline">Jumpstart team</a> if you have any concerns.`;

  switch (visaStatus) {
    case "Yes - Post Study Visa":
      return `${firstName} has a two-year post-study visa, so may require sponsorship in future. ${contactTeamMsg}`;
    case "No - I require Visa sponsorship to work in the UK":
      return `${firstName} needs visa sponsorship to work in the UK. ${contactTeamMsg}`;
    case "Yes - High Potential Individual Visa":
      return `${firstName} has a two-year high performance individual visa, so may require sponsorship in future. ${contactTeamMsg}`;
    case "Yes - Skilled Worker Visa":
    case "No - Requires Visa sponsorship":
      return `${firstName} requires visa sponsorship to work in the UK. Contact the <a href="mailto:${jumpstartEmail}" class="underline">Jumpstart team</a> for future details.`;
    case "Other":
      return `${firstName} may require visa sponsorship, please confirm directly with the <a href="mailto:${jumpstartEmail}" class="underline">Jumpstart team</a> if concerned.`;
    default:
      // If British or Irish passport, show nothing:
      if (
        visaStatus === "Yes - British Passport" ||
        visaStatus === "Yes - Irish Passport" ||
        visaStatus === "Yes - Settlement Status"
      ) {
        return "";
      }
      // Fallback for anything else:
      if (visaStatus) {
        return `${firstName} may require visa sponsorship in the future. ${contactTeamMsg}`;
      }
      return "";
  }
}

function Candidate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [candidateRoles, setCandidateRoles] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const { shortlistedCount, setShortlistedCount } = useShortlisted();
  const [showRoleMenu, setShowRoleMenu] = useState(false);
  const roleMenuRef = useRef(null);
  const [newRoleTitle, setNewRoleTitle] = useState("");
  const [newRoleForm, setNewRoleForm] = useState(false);

  // Your existing language icons map
  const languageLogos = {
    JavaScript: "/images/coding_logo/javascript.png",
    Python: "/images/coding_logo/python.png",
    C: "/images/coding_logo/c.png",
    Swift: "/images/coding_logo/swift.png",
    "C#": "/images/coding_logo/csharp.png",
    "C++": "/images/coding_logo/cplusplus.png",
    Java: "/images/coding_logo/java.svg",
    Go: "/images/coding_logo/go.png",
    PHP: "/images/coding_logo/php.svg",
    Ruby: "/images/coding_logo/ruby.webp",
    Kotlin: "/images/coding_logo/kotlin.png",
    Rust: "/images/coding_logo/rust.png",
    Clojure: "/images/coding_logo/clojure.png",
    Haskell: "/images/coding_logo/haskell.png",
    Dart: "/images/coding_logo/dart.png",
    Elixir: "/images/coding_logo/elixir.webp",
    "Objective-C": "/images/coding_logo/objc.svg",
    Perl: "/images/coding_logo/perl.png",
    Scala: "/images/coding_logo/scala.png",
    Typescript: "/images/coding_logo/typescript.png",
    React: "/images/coding_logo/react.png",
    Vue: "/images/coding_logo/vue.png",
    Angular: "/images/coding_logo/angular.png",
    "Next.js": "/images/coding_logo/next-js.svg",
    MATLAB: "/images/coding_logo/matlab.png"

  };

  // (You can remove if no longer needed)
  const validRightToWorkStatuses = [
    "No - I don't have the right to work in the UK",
    "No - Requires Visa sponsorship",
    "No - Looking for Visa sponsorship, but willing to self-sponsor if necessary",
    "No - Requires visa sponsorship - is well versed in the process + has a lawyer at hand if needed",
  ];

  const handleAddToShortlist = () => {
    setShowRoleMenu(!showRoleMenu);
  };

  const formatDuration = (duration) => {
    if (!duration) return "";
    const years = Number(duration.years) || 0;
    const months = Number(duration.months) || 0;
    
    const totalYears = years + Math.floor(months / 12);
    const remainingMonths = months % 12;
    
    const parts = [];
    if (totalYears > 0) {
      parts.push(`${totalYears} yrs`);
    }
    if (remainingMonths > 0) {
      parts.push(`${remainingMonths} mos`);
    }
    return parts.length > 0 ? parts.join(", ") : "";
  };

  const transformRoles = (rolesToTransform) => {
    const roleSet = new Set(rolesToTransform);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return rolesToTransform;
  };

  const newRole = () => {
    setNewRoleForm(true);
  };

  // === INTERVIEWS ===
  const fetchInterviews = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const response = await axios.get("/api/interviews/fetchInterviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const interviews = response.data.interviews || [];

      const transformedCandidate = interviews.map((interview) => {
        let stage;
        if (
          interview["Stage"] === "Interviewing (request accepted)" ||
          interview["Stage"] === "Interviewing (application)"
        ) {
          stage = "Being interviewed";
        } else {
          stage = interview["Stage"];
        }
        return {
          role_id: interview["id"] || undefined,
          role_title: interview["Role Title (from Role)"]?.[0] || "",
          candidate: interview["Candidate"]?.[0] || "",
          stage: stage,
          rationale: interview["Application Rationale"] || undefined,
        };
      });

      setCandidateRoles(transformedCandidate);
    } catch (err) {
      console.error(err);
    }
  };

  // === SHORTLISTS ===
  const fetchShortlistedCandidates = useCallback(async () => {
    if (!rolesNeedUpdate) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedRoles = response.data.roles || [];
      let shortlistedCandidatesByRole = {};
      let rolesList = [];

      fetchedRoles.forEach((roleObj) => {
        const roleTitle = roleObj["Role Title"];
        const roleUrlId = roleObj["URL ID"];
        const roleId = roleObj["id"];
        rolesList.push(roleTitle);

        if (roleObj["Shortlisted Candidates"]) {
          if (!shortlistedCandidatesByRole[roleTitle]) {
            shortlistedCandidatesByRole[roleTitle] = {
              roleUrlId: roleUrlId,
              roleId: roleId,
              candidates: [],
            };
          }
          shortlistedCandidatesByRole[roleTitle].candidates =
            shortlistedCandidatesByRole[roleTitle].candidates.concat(
              roleObj["Shortlisted Candidates"]
            );
        }
      });

      setShortlistedCandidates(shortlistedCandidatesByRole);
      setLoading(false);
      setButtonLoading(false);
      setRoles(fetchedRoles);
      setRolesNeedUpdate(false);
    } catch (err) {
      console.error(err);
    }
  }, [rolesNeedUpdate]);

  // === FETCH CANDIDATE DATA ===
  useEffect(() => {
    fetchInterviews();
    fetchShortlistedCandidates();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/candidates/fetchCandidate?id=${id}`
        );
        const candidateRecord = response.data.candidate;

        if (!candidateRecord) {
          throw new Error("No candidate data found");
        }

        const fields = candidateRecord.fields || {};

        let sweRoles = fields["SWE relevant roles"] || "";
        if (sweRoles) {
          sweRoles = Array.isArray(sweRoles)
            ? sweRoles[0].split(",").map((role) => role.trim())
            : sweRoles.split(",").map((role) => role.trim());
          sweRoles = transformRoles(sweRoles);
        } else {
          sweRoles = [];
        }

        const stream = fields["Stream (from CRM)"]?.[0];

        let salary;
        if (stream === "Generalist" || stream === "Marketing") {
          salary = fields["Salary - cleaned"] || "";
        } else if (stream === "Sales") {
          salary = fields["Base range + commission"] || "";
        } else if (stream === "Software Engineering") {
          salary = fields["SWE Salary - cleaned"] || "";
        }

        const transformedData = {
          id: candidateRecord.id,
          url_id: fields["ID"] || "",
          minimum_salary: fields["Minimum salary"] || "",
          ideal_salary: fields["Ideal salary"] || "",
          createdTime: candidateRecord["createdTime"] || "",
          years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
          cv: fields["CV RAW"] || "",
          video: fields["Raw video intro"] || "",
          linkedin: fields["LinkedIn RAW"] || "",
          industry_exp: fields["Industry experience"] || undefined,
          name: fields["Name"] || "",
          lastName: fields["Surname"] || "",
          undergrad_degree_type: fields["Undergrad degree type"] || "",
          undergrad_degree_subject: fields["Undergrad subject"] || "",
          undergrad_university: fields["Undergrad university"] || "",
          undergrad_graduation_year: fields["Undergrad graduation year"] || "",
          undergrad_result: fields["Undergrad result"] || "",
          postgrad_degree_type: fields["Postgrad degree type"] || "",
          postgrad_degree_subject: fields["Postgrad subject"] || "",
          postgrad_university: fields["Postgrad university"] || "",
          postgrad_graduation_year: fields["Postgrad graduation year"] || "",
          postgrad_result: fields["Postgrad result"] || "",
          roles: fields["Generalist roles suitable for copy"] || "",
          email: fields["Email"] || "",
          image: fields.Photo ? fields.Photo[0].url : null,
          work_experience_1_role: fields["(1) Work Experience Role"] || "",
          work_experience_1_company: fields["(1) Work Experience Company"] || "",
          work_experience_1_description:
            fields["(1) Work Experience Description"] || "",
          work_experience_1_duration: {
            years: fields["(1) Time spent in work experience YRS"] || 0,
            months: fields["(1) Time spent in work experience MOS"] || 0,
          },
          work_experience_2_role: fields["(2) Work Experience Role"] || "",
          work_experience_2_company: fields["(2) Work Experience Company"] || "",
          work_experience_2_description:
            fields["(2) Work Experience Description"] || "",
          work_experience_2_duration: {
            years: fields["(2) Time spent in work experience YRS"] || 0,
            months: fields["(2) Time spent in work experience MOS"] || 0,
          },
          working_style: fields["Preferred working style copy"] || "",
          github: fields["Github RAW"] || "",
          swe_roles: sweRoles,
          languages: fields["Languages copy"] || "",
          time_coding: fields["Time spent coding"] || "",
          coding_languages: fields["Coding languages new"] || "",
          description: fields["Top achievement"] || "",
          mostRecentCohort: fields["Most recent cohort"] || "",
          recentTarget: fields["Recent target achieved"] || "",
          stream: stream || "",
          search_fields: fields["Search Aggregate Fields"] || "",
          sales:
            fields["Proven Sales Experience / High Potential for Sales"] || "",
          exp_swe: fields["Level of exp SWE"] || "",
          portfolio: fields["SWE Portfolio"] || "",
          marketing_portfolio: fields["Marketing portfolio RAW"] || "",

          // === Pull the "RTW doc" as right_to_work ===
          right_to_work: fields["RTW doc"] || "",
        };

        setCandidate(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setButtonLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, rolesNeedUpdate, fetchShortlistedCandidates]);

  if (error) {
    return <p>Error: {error}</p>;
  }
  if (!candidate) {
    return <p>No candidate data found</p>;
  }

  const handleBackToAllCandidates = () => {
    const lastVisitedPage = sessionStorage.getItem("lastVisitedPage");
    if (lastVisitedPage) {
      navigate(lastVisitedPage);
    } else {
      navigate("/browse-candidates");
    }
  };

  // This function chooses the highlight colors for each stream
  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "bg-emerald-400",
          borderColor: "border-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "bg-blue-400",
          borderColor: "border-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "bg-orange-400",
          borderColor: "border-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "bg-purple-400",
          borderColor: "border-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "bg-gray-400",
          borderColor: "border-gray-400",
        };
    }
  };

  const { bgColor, textColor, workBgColor, fillColor, bgColor2, borderColor } =
    getColorClasses(candidate.stream);

  const handleNewRoleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      fields: {
        "Role Title": newRoleTitle,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const newRoleObj = response.data.newRoleData;
      handleRoleSelect(newRoleObj, candidate.id);
      setRolesNeedUpdate(true);
      setNewRoleForm(false);
      setNewRoleTitle("");
    } catch (err) {
      console.error(err);
    }
  };

  const handleRoleSelect = async (role, candidateId) => {
    setButtonLoading(true);

    const currentShortlistedCandidates = role["Shortlisted Candidates"] || [];
    const updatedShortlistedCandidates = [
      ...new Set([...currentShortlistedCandidates, candidateId]),
    ];

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const updatedRoleData = response.data.updatedRoleData.fields;
      setRoles((prevRoles) => {
        return prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
      });

      // recalc new total
      const newShortlistedCount = roles.reduce(
        (acc, r) =>
          acc +
          (r["Shortlisted Candidates"] ? r["Shortlisted Candidates"].length : 0),
        0
      );
      setShortlistedCount(newShortlistedCount);

      setRolesNeedUpdate(true);
      fetchShortlistedCandidates();
    } catch (err) {
      console.error(err);
    } finally {
      setButtonLoading(false);
      setShowRoleMenu(false);
    }
  };

  const handleRemoveCandidate = async (shortlistedDict, roleKey, candidateId) => {
    setButtonLoading(true);
    const currentShortlistedCandidates =
      shortlistedDict[roleKey]["candidates"] || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates.filter(
      (cand) => cand !== candidateId
    );

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: shortlistedDict[roleKey].roleId,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const newShortlistedCount = shortlistedCount - 1;
      setShortlistedCount(newShortlistedCount);
      setRolesNeedUpdate(true);
      fetchShortlistedCandidates();
    } catch (err) {
      console.error(err);
    } finally {
      setShowRoleMenu(false);
      setButtonLoading(false);
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isShortlistedForRole = (candId, dictOfRoles) => {
    for (const roleName in dictOfRoles) {
      if (dictOfRoles[roleName].candidates.includes(candId)) {
        return roleName;
      }
    }
    return null;
  };

  const roleShortlistedFor = isShortlistedForRole(candidate.id, shortlistedCandidates);

  if (loading) {
    return (
      <div className="flex flex-col items-center bg-white pb-20">
        <div className="max-w-[700px] flex flex-col h-full mt-20 bg-white animate-pulse">
          <div className="h-[400px] w-[700px] bg-gray-300 rounded-xl"></div>
          <div className="h-10 w-48 bg-gray-300 rounded mt-2"></div>
          <div className="flex h-6 mt-2">
            <div className="h-6 w-20 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-24 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-24 mr-2 bg-gray-300 rounded"></div>
          </div>
          <div className="flex h-6 mt-2">
            <div className="h-6 w-60 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-60 mr-2 bg-gray-300 rounded"></div>
          </div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-80 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-80 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
        </div>
      </div>
    );
  }

  // Role application logic
  const appliedRole = candidateRoles.find(
    (roleObj) => roleObj.candidate === candidate.id
  );
  const roleAppliedTag =
    appliedRole && appliedRole.stage === "Application pending" ? (
      <span
        className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
      >
        <>Applied to <strong>{appliedRole.role_title}</strong></>
      </span>
    ) : null;

  const roleAppliedButtons =
    appliedRole && appliedRole.stage === "Application pending" ? (
      <div className="flex mt-auto mb-4">
        {submitting ? (
          <div className="flex mt-4 justify-center items-center w-full h-12 bg-gray-100 rounded-lg">
            <img src={Loading} className="h-6" alt="Loading" />
          </div>
        ) : (
          <>
            <button
              onClick={() => handleSubmit("Interviewing")}
              className="px-2 py-2 mt-4 text-md mr-1 text-black flex rounded-lg font-medium transform hover:bg-white bg-[#02B491] hover:text-black text-white transition duration-400 border-2 border-[#02B491]"
            >
              Accept Interview Request
            </button>
            <button
              onClick={() => handleSubmit("Rejected @ CV")}
              className="px-2 py-2 mt-4 text-md ml-1  text-black flex rounded-lg font-medium transform hover:bg-white bg-gray-500 hover:text-black text-white transition duration-400 border-2 border-gray-500"
            >
              Decline Interview Request
            </button>
          </>
        )}
      </div>
    ) : null;

  const previewText =
    appliedRole?.rationale?.split(" ").slice(0, 10).join(" ") + "...";

  const roleAppliedRational =
    appliedRole && appliedRole.rationale ? (
      <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
        <div className="font-normal text-md text-gray-700 pt-2">
          <span className="font-semibold text-black">
            Application Rationale:
          </span>{" "}
          {isExpanded ? appliedRole.rationale : previewText}
          <button onClick={handleToggle} className="text-blue-500 ml-2 underline">
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
    ) : null;

  const handleSubmit = async (stage) => {
    if (!appliedRole) return;
    const updatedData = {
      fields: { Stage: stage },
      interviewId: appliedRole.role_id,
    };
    setError("");
    setSubmitting(true);
    try {
      await axios.patch(`/api/interviews/updateInterviewRequest`, updatedData);
      navigate("/applications");
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const workingStyleMapping = Array.isArray(candidate.working_style)
    ? candidate.working_style.map((style) => {
        if (style === "5 days a week in office") {
          return "Fully in office";
        } else if (style === "Hybrid (1-4 days in office)") {
          return "Hybrid";
        } else if (style === "Fully remote") {
          return "Fully remote";
        }
        return style;
      })
    : [];

  // === Get the custom visa message and display it under name + links
  const visaMessage = getVisaMessage(candidate.right_to_work, candidate.name);

  return (
    <div className="pt-20 pl-64 pr-2 bg-white min-h-screen h-full flex item-start">
      <div>
        <button
          type="button"
          onClick={handleBackToAllCandidates}
          className="mx-4 px-4 py-2  text-gray-800 rounded-md"
        >
          ← Back to all candidates
        </button>
      </div>
      <div>
        <div className="flex relative flex-col items-center bg-white rounded-xl p-4 mb-10 pb-10">
          <div className="max-w-[700px] flex flex-col h-full bg-white gap-2">
            <div className="relative">
              <img
                src={candidate.image}
                className="h-[400px] w-[700px] object-cover rounded-xl bg-[var(--overlay-2)]"
                alt={candidate.name}
              />
              {roleAppliedTag}
            </div>
            <div className="flex flex-col flex-grow justify-between">
              {/* NAME + ICONS */}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <h5 className="text-3xl font-bold tracking-tight text-gray-900">
                    {candidate.name}
                  </h5>
                  {candidate.linkedin && (
                    <img
                      src={linkedin}
                      alt="LinkedIn"
                      title="LinkedIn"
                      className="h-6 w-6 ml-2 cursor-pointer"
                      onClick={() => window.open(candidate.linkedin, "_blank")}
                    />
                  )}
                  {candidate.cv && (
                    <img
                      src={cv}
                      alt="CV"
                      title="CV"
                      className="h-6 w-6 ml-2 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(candidate.cv, "_blank");
                      }}
                    />
                  )}
                  {candidate.video && (
                    <img
                      src={VideoIntro}
                      alt="Video Intro"
                      title="Video Intro"
                      className="h-6 w-6 ml-2 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(candidate.video, "_blank");
                      }}
                    />
                  )}
                  {candidate.github && (
                    <img
                      src={github}
                      alt="GitHub"
                      title="GitHub"
                      className="h-6 w-6 ml-2 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(candidate.github, "_blank");
                      }}
                    />
                  )}
                  {candidate.portfolio && (
                    <svg
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(candidate.portfolio, "_blank");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6 cursor-pointer ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.19 8.688a4.5 
                          4.5 0 0 1 1.242 7.244l-4.5 
                          4.5a4.5 4.5 0 0 
                          1-6.364-6.364l1.757-1.757m13.35-.622 
                          1.757-1.757a4.5 4.5 0 0 
                          0-6.364-6.364l-4.5 4.5a4.5 
                          4.5 0 0 0 1.242 7.244"
                      />
                    </svg>
                  )}
                </div>
                {/* Salary in top-right */}
                <div className="flex flex-col text-right">
                  Expected Salary Range:
                  {candidate.minimum_salary && (
                    <h5 className="text-md font-semibold tracking-tight text-gray-900">
                      {candidate.minimum_salary} - {candidate.ideal_salary}
                    </h5>
                  )}
                </div>
              </div>

              {/* Roles / Sales / SWE Roles, etc. */}
              <div className="mt-1">
                {candidate.roles &&
                  candidate.roles.map((roleVal, index) => (
                    <span
                      key={index}
                      className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor}`}
                    >
                      {roleVal}
                    </span>
                  ))}
                {candidate.sales && (
                  <span
                    className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.sales}
                  </span>
                )}
                {candidate.swe_roles &&
                  candidate.swe_roles.map((r, idx) => (
                    <span
                      key={idx}
                      className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor}`}
                    >
                      {r}
                    </span>
                  ))}
                {candidate.exp_swe && (
                  <span
                    className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.exp_swe}
                  </span>
                )}
                {candidate.time_coding && (
                  <span
                    className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.time_coding} of coding exp
                  </span>
                )}

                {/* If you still want to show "No - Requires Visa sponsorship" as a badge, keep this logic:
                    (But we are now doing a custom message above, so up to you.)
                */}
                {candidate.right_to_work &&
                  validRightToWorkStatuses.includes(candidate.right_to_work) && (
                    <span
                      className={`inline-flex mb-2 mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor}`}
                    >
                      {candidate.right_to_work}
                    </span>
                  )}
              </div>

              {/* Tech stack icons */}
              <div className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md text-black text-md font-medium flex gap-x-2">
                {candidate.coding_languages &&
                  candidate.coding_languages.map((language, index) => (
                    <img
                      key={index}
                      src={
                        languageLogos[language.trim()] ||
                        "/images/coding_logo/default.png"
                      }
                      alt={language}
                      className="h-6 w-6"
                    />
                  ))}
              </div>

              {/* Working style & languages */}
              <div className="flex pb-2 pt-1">
                {candidate.working_style &&
                  Array.isArray(candidate.working_style) &&
                  workingStyleMapping.length > 0 && (
                    <p className="pr-5 font-normal flex text-md text-black items-center">
                      <img
                        className="h-6 pr-1"
                        src={Office}
                        alt="Office icon"
                      />
                      {workingStyleMapping.join(" or ")}
                    </p>
                  )}
                {candidate.languages &&
                  Array.isArray(candidate.languages) &&
                  candidate.languages.some(
                    (lang) => lang !== "N/A"
                  ) && (
                    <p className="font-normal flex text-md text-black items-center">
                      <img
                        className="h-6 pr-2"
                        src={Languages}
                        alt="Languages icon"
                      />
                      {candidate.languages
                        .filter((lang) => lang !== "N/A")
                        .join(", ")}
                    </p>
                  )}
              </div>

              {/* Education */}
              {candidate.undergrad_degree_type && (
                <p className="text-md">
                  <span className="font-semibold">
                    {candidate.undergrad_degree_type}{" "}
                    {candidate.undergrad_degree_subject}
                  </span>
                  {candidate.undergrad_university && " @ "}
                  {candidate.undergrad_university} ({candidate.undergrad_result}
                  {candidate.undergrad_result &&
                    candidate.undergrad_graduation_year &&
                    " - "}
                  {candidate.undergrad_graduation_year})
                </p>
              )}

              {candidate.postgrad_degree_type && (
                <p className="text-md pt-2">
                  <span className="font-semibold">
                    {candidate.postgrad_degree_type}{" "}
                    {candidate.postgrad_degree_subject}
                  </span>
                  {candidate.postgrad_university && " @ "}
                  {candidate.postgrad_university} ({candidate.postgrad_result}
                  {candidate.postgrad_result &&
                    candidate.postgrad_graduation_year &&
                    " - "}
                  {candidate.postgrad_graduation_year})
                </p>
              )}

              {/* Work experience container */}
              <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
                {candidate.recentTarget && (
                  <div className="font-normal text-sm text-gray-700 pt-2">
                    <span className="font-semibold text-black">
                      Recent Target Achieved:
                    </span>{" "}
                    {candidate.recentTarget}
                  </div>
                )}

                {candidate.work_experience_1_company && (
                  <>
                    <p className="text-sm pt-2">
                      <span className="font-semibold">
                        {candidate.work_experience_1_role}
                      </span>{" "}
                      @ {candidate.work_experience_1_company}
                      {candidate.work_experience_1_duration &&
                        ` (${formatDuration(
                          candidate.work_experience_1_duration
                        )})`}
                    </p>
                    <p className="text-gray-500 text-sm">
                      {candidate.work_experience_1_description}
                    </p>
                  </>
                )}

                {candidate.work_experience_2_company && (
                  <>
                    <p className="text-sm pt-2">
                      <span className="font-semibold">
                        {candidate.work_experience_2_role}
                      </span>{" "}
                      @ {candidate.work_experience_2_company}
                      {candidate.work_experience_2_duration &&
                        ` (${formatDuration(
                          candidate.work_experience_2_duration
                        )})`}
                    </p>
                    <p className="text-gray-500 text-sm">
                      {candidate.work_experience_2_description}
                    </p>
                  </>
                )}
              </div>

              {/* "Most proud of" or top achievements */}
              {candidate.description && (
                <p className="mt-2 font-normal text-md text-gray-700 flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.7"
                      stroke="currentColor"
                      className="size-5 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 18.75h-9m9 
                          0a3 3 0 0 1 3 3h-15a3 3 
                          0 0 1-3-3m9 
                          0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 
                          18.75v-3.375c0-.621.504-1.125 
                          1.125-1.125h.872m5.007 
                          0H9.497m5.007 
                          0a7.454 7.454 0 0 1-.982-3.172M9.497 
                          14.25a7.454 7.454 0 0 0 
                          .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 
                          6.003 0 0 0 7.73 
                          9.728M5.25 
                          4.236V4.5c0 2.108.966 
                          3.99 2.48 5.228M5.25 
                          4.236V2.721C7.456 
                          2.41 9.71 
                          2.25 12 
                          2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 
                          9.728a6.726 6.726 0 0 0 2.748 
                          1.35m8.272-6.842V4.5c0 
                          2.108-.966 3.99-2.48 
                          5.228m2.48-5.492a46.32 
                          46.32 0 0 1 2.916.52 6.003 
                          6.003 0 0 1-5.395 
                          4.972m0 
                          0a6.726 6.726 0 0 1-2.749 
                          1.35m0 
                          0a6.772 6.772 0 0 1-3.044 0"
                      />
                    </svg>
                  </div>
                  {candidate.description}
                </p>
              )}
 {candidate.most_impressive_swe && (
                <div className="mt-2 mb-4 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 18.75h-9m9 
                        0a3 3 0 0 1 3 3h-15a3 3 
                        0 0 1-3-3m9 
                        0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 
                        18.75v-3.375c0-.621.504-1.125 
                        1.125-1.125h.872m5.007 
                        0H9.497m5.007 
                        0a7.454 7.454 0 0 1-.982-3.172M9.497 
                        14.25a7.454 7.454 0 0 0 .981-3.172M5.25 
                        4.236c-.982.143-1.954.317-2.916.52A6.003 
                        6.003 0 0 0 7.73 
                        9.728M5.25 
                        4.236V4.5c0 2.108.966 
                        3.99 2.48 
                        5.228M5.25 
                        4.236V2.721C7.456 
                        2.41 9.71 
                        2.25 12 
                        2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 
                        9.728a6.726 6.726 0 0 0 2.748 
                        1.35m8.272-6.842V4.5c0 
                        2.108-.966 3.99-2.48 
                        5.228m2.48-5.492a46.32 
                        46.32 0 0 1 2.916.52 6.003 
                        6.003 0 0 1-5.395 
                        4.972m0 
                        0a6.726 6.726 0 0 1-2.749 
                        1.35m0 
                        0a6.772 6.772 0 0 1-3.044 0"
                    />
                  </svg>
                  <p className="font-normal pl-1 text-md text-gray-700">
                    {candidate.most_impressive_swe}
                  </p>
                </div>
              )}
              {/* Move Visa Status Section to the end */}
              {visaMessage && (
                <div className={`mt-6 p-3 rounded-md bg-gray-50 border border-gray-200`}>
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-600">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                    </svg>
                    <p 
                      className="text-md text-gray-700"
                      dangerouslySetInnerHTML={{ __html: visaMessage }}
                    />
                  </div>
                </div>
              )}

              {/* Application Rationale & CTA Buttons */}
              {roleAppliedRational}
              {roleAppliedButtons}
            </div>
          </div>
        </div>
      </div>

      {/* Right column: Shortlist buttons */}
      <div>
        {roleShortlistedFor ? (
          <div className="flex items-center justify-between">
            <button
              onClick={() =>
                handleRemoveCandidate(
                  shortlistedCandidates,
                  roleShortlistedFor,
                  candidate.id
                )
              }
              className="px-6 ml-4 py-2 mt-4 text-md border-2 text-black border-red-500 bg-white rounded-lg font-medium  hover:bg-red-500 hover:text-white"
              disabled={buttonLoading}
            >
              {buttonLoading ? (
                <div className="flex justify-center items-center w-full rounded-lg">
                  <img
                    src={LoadingBlack}
                    className="h-6 fill-red-500"
                    alt="buttonLoading"
                  />
                </div>
              ) : (
                `Remove from ${roleShortlistedFor} shortlist`
              )}
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-between relative">
            <button
              onClick={handleAddToShortlist}
              className="px-6 ml-4 py-2 mt-4 text-md bg-black text-black flex rounded-lg font-medium  
                hover:bg-[#02B491] justify-center hover:text-white border-2 border-[#02B491] bg-white"
            >
              {buttonLoading ? (
                <div className="flex justify-center items-center w-full rounded-lg">
                  <img src={LoadingBlack} className="h-6" alt="buttonLoading" />
                </div>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.593 3.322c1.1.128 
                        1.907 1.077 1.907 2.185V21L12 17.25 
                        4.5 21V5.507c0-1.108.806-2.057 
                        1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                    />
                  </svg>
                  Add to shortlist
                </>
              )}
            </button>
            {showRoleMenu && (
              <div
                ref={roleMenuRef}
                className="absolute ml-4 mt-44 top-300 w-48 bg-white shadow-lg rounded-lg z-10"
              >
                {roles.map((roleObj, index) => (
                  <button
                    key={index}
                    onClick={() => handleRoleSelect(roleObj, candidate.id)}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                  >
                    {roleObj["Role Title"]}
                  </button>
                ))}
                <button
                  onClick={newRole}
                  className="block w-full px-4 py-2 text-left bg-[#02B491] rounded-b-lg text-sm text-white hover:bg-[#00f2c2] hover:text-black"
                >
                  + New Role
                </button>
              </div>
            )}
          </div>
        )}

        {/* New Role Modal */}
        {newRoleForm && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-lg font-semibold mb-4">Add New Role</h2>
              <form onSubmit={handleNewRoleSubmit}>
                <label
                  htmlFor="title"
                  className="block text-md font-normal leading-6 text-gray-900"
                >
                  Role Title
                </label>
                <input
                  type="text"
                  value={newRoleTitle}
                  onChange={(e) => setNewRoleTitle(e.target.value)}
                  placeholder="Sales"
                  className="w-full p-2 mb-2 border border-gray-300 rounded"
                  required
                />
                <button
                  type="submit"
                  className="block w-full px-4 py-2 bg-[#02B491] text-sm text-white rounded hover:bg-[#00f2c2] hover:text-black"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => setNewRoleForm(false)}
                  className="block w-full px-4 py-2 mt-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Candidate;
