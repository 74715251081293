import React, { useState, useEffect } from "react"

const Countdown = () => {
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  })
  const [showPopup, setShowPopup] = useState(false)
  const [isExpired, setIsExpired] = useState(false)

  useEffect(() => {
    // Updated target date to Friday, 21st of March 2025, at 12:00:00
    const targetDate = new Date("2025-03-21T12:00:00")

    const updateCountdown = () => {
      const now = new Date()
      const timeDifference = targetDate - now

      // Check if deadline has passed
      if (timeDifference <= 0) {
        setIsExpired(true)
        return
      }

      // Calculate days/hours/minutes/seconds remaining
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

      setCountdown({
        days: String(days),
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      })
    }

    // Run immediately and then every second
    updateCountdown()
    const intervalId = setInterval(updateCountdown, 1000)
    return () => clearInterval(intervalId)
  }, [])

  const togglePopup = () => setShowPopup(!showPopup)

  // If deadline is reached, don't render anything
  if (isExpired) {
    return null
  }

  // Banner is visible only if not expired
  return (
    <div className="bg-white rounded-xl p-6 shadow-lg relative">
      <div className="flex flex-col md:flex-row items-start md:items-center gap-5">
        <div className="flex-shrink-0 w-full md:w-auto">
          <div className="flex items-center justify-between md:justify-start gap-2 mb-4">
            <h2 className="text-3xl font-bold text-black">Our next role drop is in</h2>
            <button
              onClick={togglePopup}
              className="text-emerald-500 hover:text-emerald-600 bg-transparent border-none cursor-pointer"
              aria-label="What is a role drop?"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </button>
          </div>
          <div className="flex gap-3">
            <div className="flex flex-col items-center bg-emerald-500 px-3 py-2 rounded-lg w-16">
              <span className="text-2xl font-bold text-white leading-none">{countdown.days}</span>
              <span className="text-white text-xs mt-0.5">days</span>
            </div>
            <div className="flex flex-col items-center bg-emerald-500 px-3 py-2 rounded-lg w-16">
              <span className="text-2xl font-bold text-white leading-none">{countdown.hours}</span>
              <span className="text-white text-xs mt-0.5">hours</span>
            </div>
            <div className="flex flex-col items-center bg-emerald-500 px-3 py-2 rounded-lg w-16">
              <span className="text-2xl font-bold text-white leading-none">{countdown.minutes}</span>
              <span className="text-white text-xs mt-0.5">mins</span>
            </div>
            <div className="flex flex-col items-center bg-emerald-500 px-3 py-2 rounded-lg w-16">
              <span className="text-2xl font-bold text-white leading-none">{countdown.seconds}</span>
              <span className="text-white text-xs mt-0.5">secs</span>
            </div>
          </div>
        </div>
        <div className="md:max-w-xl">
          {/* Updated the text styling: bold "Ready to hire?" and green for "Request to interview..." */}
          <p className="text-2xl leading-normal">
            <span className="font-bold text-black">Ready to hire?</span>{" "}
            <span className="text-emerald-500 font-semibold">
              Request to interview candidates now
            </span>{" "}
            — simply browse candidates and submit your shortlist!
          </p>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
            <h3 className="text-xl font-bold mb-2">What is a role drop?</h3>
            <p className="text-gray-700 mb-4">
              Our fortnightly role drops are a scheduled release of new opportunities for candidates. 
              We drop roles on Friday and pass top 1% applicants to you on Monday, giving candidates the weekend to apply. 
              It's the best way to find relevant candidates.
            </p>
            <button
              onClick={togglePopup}
              className="bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Countdown