import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FakeLogin = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [animationClass, setAnimationClass] = useState("animate-fadeInUp");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const username = query.get("username");
    const password = query.get("password");

    if (username && password) {
      // Clear localStorage before attempting new login
      localStorage.clear();
      // Log the username & password for debugging
      console.log("Starting login with:");
      console.log("  username:", username);
      console.log("  password:", password);

      login(username, password);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const login = async (username, password) => {
    setError(""); // Reset error message
    setIsLoading(true); // Start loading state

    for (let attempt = 1; attempt <= 3; attempt++) {
      try {
        // Wait for 1 second before each attempt (reduced delay)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        console.log(`Attempt ${attempt} - sending credentials to /api/login...`);
        const response = await axios.post("/api/login", { username, password });
        console.log("Login successful, received token");
        
        const { token } = response.data;
        localStorage.setItem("token", token); // Store token in local storage

        // Update last login timestamp
        try {
          const teamLogin = query.get("teamLogin") === "true";
          if (!teamLogin) {
            console.log("Updating 'Last Login' timestamp...");
            await axios.patch(
              "/api/companies/updateCompanyData",
              {
                fields: {
                  "Last Login": new Date().toISOString(),
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log("'Last Login' timestamp updated successfully");
          }
        } catch (updateError) {
          console.error("Failed to update 'Last Login':", updateError);
          // Continue despite this error - not critical
        }

        // Handle redirect
        const redirectPage = query.get("page") || "/browse-candidates";
        const roleId = query.get("roleId");
        const redirectUrl = roleId
          ? `${redirectPage}?roleId=${roleId}`
          : redirectPage;

        console.log(`Redirecting to: ${redirectUrl}`);
        
        // Trigger fade-out animation
        setAnimationClass("animate-fadeOutUp");

        // Delay navigation to let the animation finish
        setTimeout(() => {
          setIsLoading(false);
          navigate(redirectUrl); // Redirect to the specified page
          window.location.reload();
        }, 500);

        return; // Exit the function if login is successful
      } catch (err) {
        // Log detailed info about the error
        console.error(`Attempt ${attempt} failed`);
        
        if (err.response) {
          const status = err.response.status;
          const data = err.response.data;
          
          console.error("Server responded with:");
          console.error("  status:", status);
          console.error("  data:", data);
          
          // Handle different types of errors with more specific messages
          if (status === 401) {
            // Only set error after the final attempt
            if (attempt === 3) {
              setError("Invalid username or password. Please check your credentials and try again.");
            }
          } else if (status === 429) {
            setError("Too many login attempts. Please try again later.");
            break; // Stop retrying if rate limited
          } else if (status >= 500) {
            setError("Server error. Please try again later or contact support.");
            // Continue retrying for server errors
          }
        } else if (err.request) {
          console.error("No response received:", err.request);
          setError("Unable to connect to the server. Please check your internet connection.");
        } else {
          console.error("Error setting up request:", err.message);
          setError("An unexpected error occurred. Please try again later.");
        }
        
        // Only retry a few times to avoid excessive API calls
        if (attempt === 3) {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white">
      <h2 className={`text-xl pb-2 font-bold ${animationClass}`}>
        {isLoading ? "Logging you in" : "Login"}
      </h2>
      {isLoading && (
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      )}
      {error && (
        <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
          <p className="text-red-600 text-sm">{error}</p>
        </div>
      )}
    </div>
  );
};

export default FakeLogin;
